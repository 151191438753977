import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import moment from "moment";
import { BehaviorSubject, forkJoin, Observable, of, Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { IdName } from "../models/basic/idname.model";
import { TokenResult } from "../models/basic/tokenresult.model";
import { User } from "../models/basic/user.model";
import { LoginError } from "../models/enums/loginerror.enum";
import { RequestBase, StandardRequestBase } from "./request-base";
import { RolesService } from "./roles.service";

@Injectable()
export class UsersService extends RequestBase {
  static Login: Subject<any> = new Subject();
  static Logout: Subject<any> = new Subject();
  static TokenSet: Subject<void> = new Subject();
  static BeforeLogout: Subject<any> = new Subject();
  static ActualUserRights: BehaviorSubject<any> = new BehaviorSubject(null);
  static ActiveUser: BehaviorSubject<any> = new BehaviorSubject(null);

  minioIconLink: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private tokenSubject: BehaviorSubject<string | null>;

  BasePath = "config/api/users";
  DBUserBasePath = "config/api/dbusers";

  static LogoutStatic() {
    UsersService.BeforeLogout.next(null);
    localStorage.removeItem("user");
    localStorage.removeItem("credentials");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("expires");
    localStorage.removeItem("expiresat");
    localStorage.removeItem("userid");
    UsersService.Logout.next(null);
    UsersService.ActualUserRights.next(null);
    UsersService.ActiveUser.next(null);
  }

  static SetToken(res) {
    localStorage.setItem("token", res.access_token);
    localStorage.setItem("expires", res.expires + "");
    localStorage.setItem(
      "expiresat",
      moment().add(moment.duration(res.expires, "seconds")).toJSON()
    );
    UsersService.TokenSet.next();
  }

  constructor(
    http: HttpClient,
    private standardService: StandardRequestBase,
    private roleService: RolesService
  ) {
    super(http);
    this.tokenSubject = new BehaviorSubject<string | null>(this.getToken());
  }

  getToken(): string | null {
    return localStorage.getItem("token") || null;
  }

  getTokenObservable(): Observable<string | null> {
    return this.tokenSubject.asObservable();
  }

  ReadUserInfos(): Observable<IdName[]> {
    return this.http.get<IdName[]>(
      this.API_BASE_URL + this.BasePath + "/ReadUserInfos",
      this.options
    );
  }

  readUsers(): Observable<User[]> {
    return this.http.get<User[]>(
      this.API_BASE_URL + this.BasePath + "/readusers",
      this.options
    );
  }

  findUser(id: string): Observable<User> {
    return this.http.get<User>(
      this.API_BASE_URL + this.BasePath + "/finduser?id=" + id,
      this.options
    );
  }

  createUser(user: User) {
    return this.http
      .post(
        this.API_BASE_URL + this.BasePath + "/AddOrUpdateUser",
        user,
        this.options
      )
      .pipe(catchError(this.handleError("createUser", [])));
  }

  deleteUser(id: string) {
    return this.http
      .delete(
        this.API_BASE_URL + this.BasePath + "/deleteuser?id=" + id,
        this.options
      )
      .pipe(catchError(this.handleError("deleteUser", [])));
  }

  login(credentials) {
    return this.http
      .post<TokenResult>(
        this.API_BASE_URL + this.BasePath + "/tokenlogin",
        credentials,
        this.options
      )
      .pipe(catchError(this.handleError("login", null)));
  }

  linkExternal(credentials): Observable<boolean> {
    return this.http
      .post<boolean>(
        this.API_BASE_URL + this.BasePath + "/linkexternal",
        credentials,
        this.options
      )
      .pipe(catchError(this.handleError("linkexternal", null)));
  }

  refreshToken() {
    return this.http.get<TokenResult>(
      this.API_BASE_URL +
        this.BasePath +
        "/refreshtoken?refreshtoken=" +
        localStorage.getItem("refresh_token"),
      this.options
    );
  }

  GetCurrentUser(): Observable<any> {
    return this.http.get<any>(
      this.API_BASE_URL + this.BasePath + "/GetCurrentUser",
      this.options
    );
  }

  ResetMFA(id): Observable<any> {
    return this.http.get<any>(
      this.API_BASE_URL + this.BasePath + "/ResetMFA?id=" + id,
      this.options
    );
  }

  logout() {
    UsersService.LogoutStatic();
  }

  executeLogin(credentials) {
    const promise = new Promise<LoginError>((resolve, reject) => {
      this.login(credentials).subscribe((res: TokenResult) => {
        if (res) {
          if (res.error) {
            resolve(res.error_type);
          } else {
            this.setTokenResult(res).then(() => {
              resolve(LoginError.None);
            });
          }
        } else {
          resolve(LoginError.Password);
        }
      });
    });
    return promise;
  }

  executeLinkExternal(credentials) {
    const promise = new Promise<boolean>((resolve, reject) => {
      this.linkExternal(credentials).subscribe((res: boolean) => {
        resolve(res);
      });
    });
    return promise;
  }

  setTokenResult(res) {
    const promise = new Promise((resolve, reject) => {
      if (res) {
        localStorage.setItem("refresh_token", res.refresh_token);
        UsersService.SetToken(res);
        this.tokenSubject.next(res.access_token);
        this.GetCurrentUser().subscribe((user) => {

          localStorage.setItem("user", JSON.stringify(user));
          // call user info api to get username property
          this.getUserInfo(user?.IdentityId);
          this.getUserRoles(user?.Roles);
          UsersService.ActualUserRights.next({
            IsAdmin: user.IsAdministrator === true,
            Rights: user.Rights,
          });
          UsersService.Login.next(user);
          UsersService.ActiveUser.next(user);
          resolve(user);
        });
      } else {
        UsersService.ActualUserRights.next(null);
        UsersService.Login.next(null);
        UsersService.ActiveUser.next(null);
        resolve(null);
      }
    });
    return promise;
  }

  getUserInfo(IdentityId) {
    this.standardService
      .executeGet("config/api/dbusers", "GetUserInfo", "id=" + IdentityId)
      .subscribe((intUser) => {
        localStorage.setItem("userInfo", JSON.stringify(intUser));
      });
  }

  getUserRoles(_roles) {
    if (!_roles) return;
    const requests = _roles.map((id) =>
      this.roleService.FindRole(id).pipe(
        catchError((error) => {
          return of(null); // Return an empty observable if there's an error
        })
      )
    );

    forkJoin(requests).subscribe((results: []) => {
      const roles = [];
      results.forEach((role) => {
        if (role) {
          roles.push(role["Name"]["Value"]);
        }
      });
      localStorage.setItem("userRoles", JSON.stringify(roles));
    });
  }

  initUserFromLocalStorage() {
    const userJson = localStorage.getItem("user");
    if (userJson) {
      const user = JSON.parse(userJson);
      UsersService.ActualUserRights.next({
        IsAdmin: user.IsAdministrator === true,
        Rights: user.Rights,
      });
      UsersService.ActiveUser.next(user);
      return user;
    }
    return null;
  }

  executeAnonymousLogin(anonymousUser) {
    const p =
      this.API_BASE_URL +
      this.BasePath +
      "/TokenLoginAnonymous?userID=" +
      anonymousUser;
    return this.http
      .get<TokenResult>(p, this.options)
      .pipe(catchError(this.handleError("anonymousLogin", null)))
      .subscribe((res) => {
        this.setTokenResult(res).then(() => {});
      });
  }

  ExternalLogin(provider, apibase, returnUrl) {
    const p =
      this.API_BASE_URL +
      this.BasePath +
      "/ExternalLogin?provider=" +
      provider +
      "&apibase=" +
      apibase +
      "&returnUrl=" +
      returnUrl;
    return this.http.get<TokenResult>(p, this.options);
  }

  //ExternalAuthCallback(returnUrl) {
  //    const p = this.API_BASE_URL + this.BasePath + '/ExternalAuthCallback?returnUrl=' + returnUrl;
  //    return this.http.get<TokenResult>(p, this.options);
  //}
  LinkLogin(provider, returnUrl) {
    const p =
      this.API_BASE_URL +
      this.BasePath +
      "/LinkLogin?provider=" +
      provider +
      "&apibase=" +
      this.API_BASE_URL.substring(0, this.API_BASE_URL.length - 1) +
      "&returnUrl=" +
      returnUrl;
    return this.http.post<TokenResult>(p, null, this.options);
  }

  //LinkLoginCallback(provider, apibase, returnUrl) {
  //    const p = this.API_BASE_URL + this.BasePath + '/LinkLoginCallback?provider=' + provider + '&apibase=' + apibase + "&returnUrl=" + returnUrl;
  //    return this.http.get<TokenResult>(p, this.options).pipe(catchError(this.handleError('anonymousLogin', null))).subscribe(res => {

  //    })
  //}

  verifyEmail(payload) {
    return this.http.post<TokenResult>(
      this.API_BASE_URL + this.DBUserBasePath + "/forgot/verifyemail",
      payload,
      this.options
    );
  }

  verifyOTP(payload) {
    return this.http.post<TokenResult>(
      this.API_BASE_URL + this.DBUserBasePath + "/forgot/verifyotp",
      payload,
      this.options
    );
  }

  resendOTP(payload) {
    return this.http.post<TokenResult>(
      this.API_BASE_URL + this.DBUserBasePath + "/forgot/resendotp",
      payload,
      this.options
    );
  }

  resetPassword(payload) {
    return this.http.post<TokenResult>(
      this.API_BASE_URL + this.DBUserBasePath + "/forgot/setnewpassword",
      payload,
      this.options
    );
  }

  changePassword(payload) {
    return this.http.post<TokenResult>(
      this.API_BASE_URL + this.DBUserBasePath + "/UpdateUserPassword",
      payload,
      this.options
    );
  }
}
