import { Component, HostListener, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TourguideDialog } from 'src/app/components/dialogs/tourguide/tourguide.dialog/tourguide.dialog.component';
import { CreateDialogFlagService } from '../../create.flag.service';

@Component({
  selector: 'app-tour-guide',
  templateUrl: './tour-guide.component.html',
  styleUrls: ['./tour-guide.component.scss'],
  providers: [DialogService],
})
export class TourGuideComponent {
  items = [
    {
      title: 'Test Drive Demo Applications',
      subtitle: 'Secure interaction with Apps',
      icon: 'layers',
      type: 'youtube',
      url: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
    },
    {
      title: 'Set Up Your First Integration',
      subtitle: 'Enable reading and writing to different Data Sources.',
      icon: 'terminal',
      type: 'link',
      url: '/default/settings/front-pages/integration',
    },
    {
      title: 'Construct and Launch an Application',
      subtitle: 'Create and launch applications using drag-and-drop interface elements.',
      icon: 'rocket_launch',
      type: 'dialog',
      url: 'application',
    },
    {
      title: 'Initiate a Workflow',
      subtitle: 'Create a service side workflow.',
      icon: 'device_hub',
      type: 'dialog',
      url: 'workflow',
    },
  ];
  display: boolean = false;
  ref!: DynamicDialogRef;
  isShowDialog = false;
  dialogUrl: string | null = null;

  constructor(
    public dialogService: DialogService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private createDialogFlagService: CreateDialogFlagService,
    private cdref: ChangeDetectorRef
  ) {}

  toggleOverlay() {
    this.display = !this.display;
    this.cdref.detectChanges();
  }

  openDialog(item: any): void {
    if (item.type === 'dialog') {
      if (item.title === this.items[2].title) {
        this.createDialogFlagService.setShowCreateDialog({ isShow: true, action: 'application' });
        this.router.navigate(['default/settings/dashboard']);
        return;
      }
      this.createDialogFlagService.setShowCreateDialog({ isShow: true, action: 'workflow' });
      this.router.navigate(['default/settings/dashboard']);
      return;
    }

    this.toggleOverlay();
    const sanitizedItem = { ...item };
    if (item.type === 'youtube' || item.type === 'link') {
      sanitizedItem.url = this.sanitizer.bypassSecurityTrustResourceUrl(item.url);
    }
    if (item.type === 'youtube') {
      this.ref = this.dialogService.open(TourguideDialog, {
        header: item.title,
        width: '50%',
        data: sanitizedItem,
      });

      this.ref.onClose.subscribe(() => {
        this.closeDialog(); // Reset z-index when dialog is closed
      });
    } else if (item.type === 'dialog') {
      this.isShowDialog = true;
      this.dialogUrl = item.url;
    } else if (item.type === 'link') {
      this.router.navigate([item.url]).then((success) => {
        if (success) {
          this.closeDialog();
        }
      });
    }
  }

  closeDialog() {
    // this.dialogStateService.setZIndex(0);
    this.isShowDialog = false;
    this.dialogUrl = null;
  }

  ngOnDestroy() {
    // if (this.ref) {
    //   this.ref.close();
    // }
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    const targetElement = event.target as HTMLElement;
    if (!targetElement.closest('.tour-guide-container')) {
      this.display = false;
    }
  }
}
