import {
	OnInit,
	Component,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
} from '@angular/core';
import { MetaService } from '../../../services/meta.service';
import {
	ABaseTreeNode,
	IDKeeper,
} from '../../../components/common/basetreecontrol/base.tree.control';
import { TranslatedString } from '../../../models/translatedstring.model';
import { LayoutService } from '../../../services/layout.service';
import { MediaService } from '../../../services/media.service';
import { SideNavService } from '../../../services/sidenav.service';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import {
	LayoutWizardDialog,
	LayoutWizardInitArgs,
} from '../../../components/wizards/layout/layout.wizard.dialog';
import { NavigationHelper } from '../../../helpers/navigation.helper';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import {
	ArrayHelpers,
	TranslateFormatText,
} from '../../../helpers/array.helpers';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { map } from "rxjs/operators";

@Component({
	selector: 'navigation-structure-settings',
	templateUrl: './navigation.structure.settings.html',
	styleUrls: ['./navigation.structure.settings.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationStructureSettings implements OnInit {
	static GetSettingsEntry() {
		return {
			Caption: '@@Navigation',
			ID: 'navigation',
			Icon: 'menu',
			Index: 20,
			Parent: 'layout',
			Security: {
				Name: 'evidanza.App.Shared.Security.LayoutRight',
				Value: 2,
			},
			Content: NavigationStructureSettings,
		};
	}
	InitArgs;
	Pages;
	RootNodes = [];
	SelectedNode;
	SelectedItem;
	IDKeeper = new IDKeeper();
	Icons = [];
	IsCapsule = false;
	SearchText: string = '';
	isShowIconDialog = false;
	toggleWindow = true;
	isEdit = false;
	ShowLoginRequired: boolean = true;
	private static FindNodeByID(searchNodes, id) {
		let retVal = null;
		if (searchNodes) {
			searchNodes.some((x) => {
				if (x.Data._Id === id) {
					retVal = x;
					return true;
				}
				retVal = NavigationStructureSettings.FindNodeByID(x.Children, id);
				if (retVal) {
					return true;
				}
				return false;
			});
		}
		return retVal;
	}
	get filteredPages() {
		if (!this.SearchText) {
			return this.Pages;
		}
		const searchTextLower = this.SearchText.toLowerCase();
		return this.Pages.filter((page) =>
			page.Caption.toLowerCase().includes(searchTextLower)
		);
	}
	openIconSelector() {
		this.isShowIconDialog = true;
	}
	closeIconSelector() {
		this.isShowIconDialog = false;
	}

	SelectedNavigationStructure;
	NavigationNodes;
	Edit() {
		this.isEdit = true;
		this.ToggleWindow()
	}

	Cancel() {
		this.isEdit = false;
		this.PageURL=null;
		this.getAllApplications()
		this.ToggleWindow()
	}

	private static GetNodes(list, idKeeper, draggable): NavigationNode[] {
		const retVal = [];
		list.forEach((x) => {
			const node = new NavigationNode(
				idKeeper.NextID,
				x.Data,
				false,
				draggable
			);
			if (x.Children && x.Children.length > 0) {
				node.HasChildren = true;
				node.Children = NavigationStructureSettings.GetNodes(
					x.Children,
					idKeeper,
					draggable
				);
			}
			retVal.push(node);
		});
		return retVal;
	}
	loadNavNodes(Caption) {
		if (Caption) {
			this.service.GetNavigationNodesForStructure(Caption).subscribe(x => {
				if (x) {
					this.NavigationNodes = x;
					const structure = NavigationHelper.BuildStructure(x);
					this.RootNodes = NavigationStructureSettings.GetNodes(structure, this.IDKeeper, !this.IsCapsule);
					this.cdRef.detectChanges();
				}
			});
		}
	}
	SelectedItemData;
	private static GetNodeIDs(nodeList) {
		const retVal = [];
		if (nodeList) {
			nodeList.forEach((x) => {
				retVal.push(x.Data._Id);
				retVal.push(...NavigationStructureSettings.GetNodeIDs(x.Children));
			});
		}
		return retVal;
	}

	constructor(
		private service: MetaService,
		private mediaService: MediaService,
		public cdRef: ChangeDetectorRef
	) {
		const pages = [];
		SideNavService.SystemURLs.forEach((x) => {
			pages.push({
				Caption: '/' + x,
				URL: '/' + x,
			});
		});
		this.Pages = pages;
	}

	ngOnInit(): void {
		this.getAllApplications();
		const selectedApp = localStorage.getItem('selectedApp');
		if (selectedApp) {
			const parsedData = JSON.parse(selectedApp)
			this.InitArgs = parsedData
			this.loadNavNodes(this.InitArgs.Caption);
			this.service.GetNavigationStructureByKey(this.InitArgs.Caption).subscribe(nav => {
				if (nav) {
					this.SelectedNavigationStructure = nav;
				}
			});
			this.SelectedApplication = parsedData.SID;
			this.setSelectedApplication(parsedData);
		}
	}

	setSelectedApplication(app) {
		this.service.GetAllPages().subscribe((r) => {
			if (r) {
				ArrayHelpers.sortAlphabetical(r, 'Caption');
				// this.Pages.push(...r);
				this.Pages = r;
			}
		});
		if (app) {
			this.IsCapsule = app.IsCapsule || app.IsOverridden;
			this.service
				.GetNavigationNodesForStructure(app.Caption)
				.subscribe((x) => {
					if (x) {
						const structure = NavigationHelper.BuildStructure(x);
						this.RootNodes = NavigationStructureSettings.GetNodes(
							structure,
							this.IDKeeper,
							!this.IsCapsule
						);
						this.cdRef.detectChanges();
					}
				});
		}
		this.mediaService
			.ListObjects(MediaService.IconSourceID, '')
			.subscribe((icons) => {
				if (icons) {
					const list = [];
					icons.forEach((icon) => {
						list.push(icon.Name.replace('.svg', ''));
					});
					this.Icons = list;
				}
			});
	}

	NavigationSubscription;
	Apps;
	SelectedApplication;
	getAllApplications() {
		LayoutService.Loading.next(true);
		this.NavigationSubscription = this.service
			.GetNavigationStructures()
			.pipe(map((data) => data.filter(item => item.Caption != 'default'))) // hide default navigation
			.subscribe((navs) => {
				this.Apps = navs.map((nav) => {
					let Caption2 = nav.Caption
					if (Caption2.indexOf("workspace-") > -1) {
						Caption2 = nav?.Caption?.split("workspace-")[1]
					}
					return {
						...nav,
						Caption2
					}
				});
				LayoutService.Loading.next(false);
				this.cdRef.detectChanges();
			});
	}

	onSelectedAppChange() {
		const app = this.Apps.filter((app) => app.SID === this.SelectedApplication);
		this.InitArgs = app
		localStorage.setItem('selectedApp', JSON.stringify(app[0]));
		this.loadNavNodes(app[0]?.Caption)
		this.setSelectedApplication(app[0]);

	}

	nodeSelected(ev) {
		if (ev && ev.length > 0) {
			const node = ev[0];
			this.checkForSave().then((x) => {
				if (x === MessageBoxResult.Abort) {
					if (this.SelectedItem) {
						this.SelectedNode = this.SelectedItem.Node;
						this.cdRef.detectChanges();
					}
				} else {
					if (node["Data"]["LoginRequired"] == null || node["Data"]["LoginRequired"] == undefined) {
						node["Data"]["LoginRequired"] = true;
					}
					if (x === MessageBoxResult.No) {
						this.SelectedNode = null;
						window.setTimeout(() => {
							this.SelectedNode = node;
							this.cdRef.detectChanges();
						}, 50);
					}
					this.SelectedItem = {
						HasChanges: node.IsNew,
						Node: node,
						Data: JSON.parse(JSON.stringify(node.Data)),
						MoveInfo: null,
					};
					this.SelectedItemData = this.SelectedItem;
					this.PageURL="/"+this.SelectedItem["Data"]["StructureKey"]+this.SelectedItem["Data"]["Url"]
					setTimeout(() => {
						this.cdRef.detectChanges();
					}, 50);
				}
			});
		}
	}
	copyToClipboard(text: string): void {
		let copyText = "";
		copyText+=window.location.protocol;
		copyText+="//"+window.location.host;
		if (text) {
			copyText+=text;
			navigator.clipboard.writeText(copyText).then(() => {
				const notificationText = new TranslateFormatText('@@Page URL copied to your clipboard');
				const notificationStatus = new TranslateFormatText('@@Success')
				NotificationHelper.Success(notificationText,notificationStatus);
			}).catch(err => {
				const notificationText = new TranslateFormatText('@@Page URL could not be copied to your clipboard');
				const notificationStatus = new TranslateFormatText('@@Failed');
				NotificationHelper.Error(notificationText,notificationStatus);

			});
		}
	}
	
	ShowConfirmation: boolean = false;
	onDrop(ev) {
		if (ev && this.SelectedItem) {
			const moveInfo = {
				NewParentID: null,
				Index: ev.DropIndex,
			};
			if (ev.DropTarget) {
				moveInfo.NewParentID = ev.DropTarget.Data._Id;
			}
			this.SelectedItem.MoveInfo = moveInfo;
			this.SelectedItem.HasChanges = true;
		}
	}

	Save() {
		// this.saveInternal(this.SelectedItem, null);
		if (this.ShowConfirmation) {
			const delText = '@@Changing main node will also effect child nodes. Are you sure want to save?';
			const text = new TranslateFormatText(delText);
			MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Confirm'),
				MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
					if (x === MessageBoxResult.Yes) {
						this.saveInternal(this.SelectedItem, null);
						this.cdRef.detectChanges();
					}
				});
		}
		else {
			this.saveInternal(this.SelectedItem, null);
			this.cdRef.detectChanges();
		}
	}

	NodeSave() {
		if (this.SelectedItem?.Data?.Caption?.DefaultValue.trim() == '') {
			NotificationHelper.Error('Name field is required', '@@Error');
			LayoutService.Loading.next(false);
			return;
		}
		if (this.ShowConfirmation) {
			const delText = '@@Changing main node will also effect child nodes. Are you sure want to save?';
			const text = new TranslateFormatText(delText);
			MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Confirm'),
				MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
					if (x === MessageBoxResult.Yes) {
						this.saveInternal(this.SelectedItem, () => {
							if (!this.isEdit) {
								this.ToggleWindow()
								this.setSelectedApplication(this.InitArgs)
							}
						})
					}
				});
		}
		else {
			this.saveInternal(this.SelectedItem, () => {
				if (!this.isEdit) {
					this.ToggleWindow()
					this.setSelectedApplication(this.InitArgs)
				}
			})
		}
	}
	saveInternal(sel, onSaved) {
		if (sel) {
			LayoutService.Loading.next(true);
			const saveReq = {
				NodeToSave: sel.Data,
				NewSibbling: null,
				OldSibbling: null,
			};

			if (sel.MoveInfo) {
				// Wenn Node bewegt wurde
				if (sel.MoveInfo.NewParentID) {
					// und nun unterhalb einer anderen Node liegt
					const newParent = NavigationStructureSettings.FindNodeByID(
						this.RootNodes,
						sel.MoveInfo.NewParentID
					);
					if (newParent && newParent.Children) {
						// und diese Node gefunden werden kann
						if (sel.Data.ParentID === sel.MoveInfo.NewParentID) {
							// Wenn alte ParentNode gleich der neuen
							const newPrevious =
								sel.MoveInfo.Index > 0
									? newParent.Children[sel.MoveInfo.Index - 1].Data._Id
									: null;
							if (newPrevious !== sel.Data.PreviousSibbling) {
								// und sich die Position geaendert hat
								sel.Data.PreviousSibbling = newPrevious; // Neue Position setzen
								if (sel.MoveInfo.Index < newParent.Children.length - 1) {
									// Wenn neue Position nicht letzte in der Liste
									saveReq.NewSibbling =
										newParent.Children[sel.MoveInfo.Index + 1].Data; // nachfolgende Node mit updaten
								}
								if (!sel.Node.IsNew) {
									// Wenn Node nicht neu
									newParent.Children.some((x, i) => {
										// pruefen, ob es eine Node gab, die hinter der zu speichernden Node lag und diese anpassen
										if (x.Data.PreviousSibbling === sel.Data._Id) {
											if (i === 0) {
												x.Data.PreviousSibbling = null;
											} else {
												x.Data.PreviousSibbling =
													newParent.Children[i - 1].Data._Id;
											}
											saveReq.OldSibbling = x.Data;
											return true;
										}
										return false;
									});
								}
							}
						} else {
							// Wenn ParentNodes ungleich
							if (!sel.Node.IsNew) {
								// Wenn Node nicht neu, KindListe des alten Parents suchen
								let childList;
								if (sel.Data.ParentID) {
									const oldParent = NavigationStructureSettings.FindNodeByID(
										this.RootNodes,
										sel.Data.ParentID
									);
									if (oldParent) {
										childList = oldParent.Children;
									}
								} else {
									childList = this.RootNodes;
								}
								if (childList) {
									// und pruefen, ob es eine Node gab, die hinter der zu speichernden Node lag und diese anpassen
									childList.some((x, i) => {
										if (x.Data.PreviousSibbling === sel.Data._Id) {
											if (i === 0) {
												x.Data.PreviousSibbling = null;
											} else {
												x.Data.PreviousSibbling = childList[i - 1].Data._Id;
											}
											saveReq.OldSibbling = x.Data;
											return true;
										}
										return false;
									});
								}
							}
							sel.Data.ParentID = sel.MoveInfo.NewParentID; // Neue ParentID setzen
							sel.Data.PreviousSibbling =
								sel.MoveInfo.Index > 0 // Neue Position setzen
									? newParent.Children[sel.MoveInfo.Index - 1].Data._Id
									: null;
							if (sel.MoveInfo.Index < newParent.Children.length - 1) {
								// Wenn neue Position nicht letzte in der Liste
								saveReq.NewSibbling =
									newParent.Children[sel.MoveInfo.Index + 1].Data; // nachfolgende Node mit updaten
							}
						}
					}
				} else {
					// und nun in den RootNodes liegt
					if (sel.Data.ParentID) {
						// Wenn Node vorher unter anderer Node lag
						if (!sel.Node.IsNew) {
							// und nicht neu ist
							const oldParent = NavigationStructureSettings.FindNodeByID(
								this.RootNodes,
								sel.Data.ParentID
							);
							if (oldParent && oldParent.Children) {
								// alte ParentNode suchen
								oldParent.Children.some((x, i) => {
									// und pruefen, ob es eine Node gab, die hinter der zu speichernden Node lag und diese anpassen
									if (x.Data.PreviousSibbling === sel.Data._Id) {
										if (i === 0) {
											x.Data.PreviousSibbling = null;
										} else {
											x.Data.PreviousSibbling =
												oldParent.Children[i - 1].Data._Id;
										}
										saveReq.OldSibbling = x.Data;
										return true;
									}
									return false;
								});
							}
						}
						sel.Data.ParentID = null; // ParentID auf null und neue Position setzen
						sel.Data.PreviousSibbling =
							sel.MoveInfo.Index > 0
								? this.RootNodes[sel.MoveInfo.Index - 1].Data._Id
								: null;
						if (sel.MoveInfo.Index < this.RootNodes.length - 1) {
							// Wenn neue Position nicht letzte in der Liste
							saveReq.NewSibbling = this.RootNodes[sel.MoveInfo.Index + 1].Data; // nachfolgende Node mit updaten
						}
					} else {
						// Wenn Node schon in den RootNodes lag
						const newPrevious =
							sel.MoveInfo.Index > 0
								? this.RootNodes[sel.MoveInfo.Index - 1].Data._Id
								: null;
						if (newPrevious !== sel.Data.PreviousSibbling) {
							// pruefen, ob sich Position geaendert hat
							sel.Data.PreviousSibbling = newPrevious; // neue Position setzen
							if (sel.MoveInfo.Index < this.RootNodes.length - 1) {
								// Wenn neue Position nicht letzte in der Liste
								saveReq.NewSibbling =
									this.RootNodes[sel.MoveInfo.Index + 1].Data; // nachfolgende Node mit updaten
							}
							if (!sel.Node.IsNew) {
								// Wenn Node nicht neu ist
								this.RootNodes.some((x, i) => {
									// pruefen, ob es eine Node gab, die hinter der zu speichernden Node lag und diese anpassen
									if (x.Data.PreviousSibbling === sel.Data._Id) {
										if (i === 0) {
											x.Data.PreviousSibbling = null;
										} else {
											x.Data.PreviousSibbling = this.RootNodes[i - 1].Data._Id;
										}
										saveReq.OldSibbling = x.Data;
										return true;
									}
									return false;
								});
							}
						}
					}
				}
			}
			this.service.SaveNavigationNode(saveReq).subscribe((r) => {
				LayoutService.Loading.next(false);
				if (r) {
					sel.HasChanges = false;
					sel.MoveInfo = null;
					delete sel.Node.IsNew;
					sel.Node.Data = sel.Data;
					sel.Node.Data._Id = r.SID;
					sel.Node.Data._version = r.Version;
					sel.Node.Caption = TranslatedString.GetTranslation(sel.Data.Caption);
					if (saveReq.NewSibbling) {
						saveReq.NewSibbling.PreviousSibbling = r.SID;
					}
					this.getAllApplications()
					this.loadNavNodes(this.InitArgs.Caption)
					this.PageURL=null;
					if (this.InitArgs) {
						const tft = new TranslateFormatText(
							'@@Navigationsstruktur {0} erfolgreich gespeichert'
						);
						tft.FormatParams.push(this.InitArgs.Caption);
						NotificationHelper.Info(tft, '@@Speichern');
					}
				}
				if (typeof onSaved === 'function') {
					onSaved();
				}
			});
		}
	}

	checkForSave() {
		const sel = this.SelectedItem;
		const promise = new Promise<MessageBoxResult>((resolve, reject) => {
			if (sel && sel.HasChanges && sel.Data.Url && sel.Data.Caption) {
				MessageBoxHelper.ShowDialog(
					new TranslateFormatText('@@Wollen Sie die Aenderungen speichern?'),
					new TranslateFormatText('@@Frage'),
					MessageBoxButtons.YesNoAbort,
					MessageBoxIcon.Question
				).then(
					(x) => {
						if (x === MessageBoxResult.Yes) {
							this.saveInternal(sel, () => {
								resolve(x);
							});
						} else {
							if (x === MessageBoxResult.No) {
								sel.HasChanges = false;
								if (sel.MoveInfo) {
									// Position rückgängig machen
									if (sel.Data.ParentID) {
										const parent = NavigationStructureSettings.FindNodeByID(
											this.RootNodes,
											sel.Data.ParentID
										);
										if (parent) {
											const rootNodes = [...this.RootNodes];
											if (sel.MoveInfo.NewParentID) {
												if (sel.MoveInfo.NewParentID === sel.Data.ParentID) {
													const index = parent.Children.indexOf(sel.Node);
													if (index > -1) {
														parent.Children.splice(index, 1);
														if (
															sel.Node.IsNew &&
															parent.Children.length === 0
														) {
															parent.HasChildren = false;
															parent.Children = null;
														}
													}
												} else {
													const newParent =
														NavigationStructureSettings.FindNodeByID(
															this.RootNodes,
															sel.MoveInfo.NewParentID
														);
													if (newParent && newParent.Children) {
														const index = newParent.Children.indexOf(sel.Node);
														if (index > -1) {
															newParent.Children.splice(index, 1);
															if (newParent.Children.length === 0) {
																newParent.HasChildren = false;
																newParent.Children = null;
															}
														}
													}
												}
											} else {
												const index = rootNodes.indexOf(sel.Node);
												if (index > -1) {
													rootNodes.splice(index, 1);
												}
											}
											if (!sel.Node.IsNew) {
												if (sel.Data.PreviousSibbling) {
													parent.Children.some((c, i) => {
														if (c.Data._Id === sel.Data.PreviousSibbling) {
															parent.Children.splice(i + 1, 0, sel.Node);
															return true;
														}
														return false;
													});
												} else {
													parent.Children.splice(0, 0, sel.Node);
												}
											}
											this.RootNodes = rootNodes;
										}
									} else {
										const rootNodes = [...this.RootNodes];
										if (sel.MoveInfo.NewParentID) {
											const parent = NavigationStructureSettings.FindNodeByID(
												this.RootNodes,
												sel.MoveInfo.NewParentID
											);
											if (parent && parent.Children) {
												const index = parent.Children.indexOf(sel.Node);
												if (index > -1) {
													parent.Children.splice(index, 1);
													if (parent.Children.length === 0) {
														parent.HasChildren = false;
														parent.Children = null;
													}
												}
											}
										} else {
											rootNodes.splice(sel.MoveInfo.Index, 1);
										}
										if (!sel.Node.IsNew) {
											if (sel.Data.PreviousSibbling) {
												rootNodes.some((c, i) => {
													if (c.Data._Id === sel.Data.PreviousSibbling) {
														rootNodes.splice(i + 1, 0, sel.Node);
														return true;
													}
													return false;
												});
											} else {
												rootNodes.splice(0, 0, sel.Node);
											}
										}
										this.RootNodes = rootNodes;
									}
								} else if (sel.Node.IsNew) {
									// Ansonsten neue Node wieder entfernen
									if (sel.Data.ParentID) {
										const parent = NavigationStructureSettings.FindNodeByID(
											this.RootNodes,
											sel.Data.ParentID
										);
										if (parent) {
											const index = parent.Children.indexOf(sel.Node);
											if (index > -1) {
												parent.Children.splice(index, 1);
												if (parent.Children.length === 0) {
													parent.HasChildren = false;
													parent.Children = null;
												}
												this.RootNodes = [...this.RootNodes];
											}
										}
									} else {
										const childList = [...this.RootNodes];
										const index = childList.indexOf(sel.Node);
										if (index > -1) {
											childList.splice(index, 1);
											this.RootNodes = childList;
										}
									}
								}
							}
							resolve(x);
						}
					},
					(y) => {
						reject(y);
					}
				);
			} else {
				resolve(MessageBoxResult.None);
			}
		});
		this.ShowLoginRequired = true;
		return promise;
	}
	updateDisableLogin(e) {
		//Check for changes of Disable Login Checkbox value is changed
		this.SelectedItem.HasChanges = true;
		this.cdRef.detectChanges()
	}
	DisableLoginRequired: boolean = false;
	AddMainNode() {
		this.checkForSave().then((x) => {
			if (x !== MessageBoxResult.Abort) {
				this.PageURL=null;
				const data = {
					StructureKey: this.InitArgs?.Caption,
					PreviousSibbling: null,
					LoginRequired: true
				};
				if (this.RootNodes.length > 0) {
					data.PreviousSibbling =
						this.RootNodes[this.RootNodes.length - 1].Data._Id;
				}
				this.ShowConfirmation = false;

				const node = new NavigationNode(
					this.IDKeeper.NextID,
					data,
					true,
					!this.IsCapsule
				);
				this.RootNodes = [...this.RootNodes, node];
				this.SelectedNode = node;
				this.ShowLoginRequired = true;
				this.SelectedItem = {
					HasChanges: node.IsNew,
					Node: node,
					Data: JSON.parse(JSON.stringify(node.Data)),
					MoveInfo: null,
				};
			}
		});
	}

	Add() {
		this.checkForSave().then((x) => {
			if (x !== MessageBoxResult.Abort) {
				this.PageURL=null;
				if (this.SelectedItem) {
					const data = {
						StructureKey: this.InitArgs?.Caption ?? '',
						PreviousSibbling: null,
						ParentID: this.SelectedItem.Data._Id,
						LoginRequired: this.SelectedItem.Data["LoginRequired"] !== undefined ? this.SelectedItem.Data["LoginRequired"] : true
					};
					if (this.SelectedItem.Node.HasChildren) {
						if (this.SelectedItem.Node.Children.length > 0) {
							data.PreviousSibbling =
								this.SelectedItem.Node.Children[
									this.SelectedItem.Node.Children.length - 1
								].Data._Id;
						}
					} else {
						this.SelectedItem.Node.HasChildren = true;
						this.SelectedItem.Node.Children = [];
					}
					this.SelectedItem.Node.IsExpanded = true;
					const node = new NavigationNode(
						this.IDKeeper.NextID,
						data,
						true,
						!this.IsCapsule
					);
					this.SelectedItem.Node.Children.push(node);
					this.RootNodes = [...this.RootNodes];
					this.SelectedNode = node;
					this.SelectedItem = {
						HasChanges: node.IsNew,
						Node: node,
						Data: JSON.parse(JSON.stringify(node.Data)),
						MoveInfo: null,
					};
					this.SelectedItemData = JSON.parse(JSON.stringify(this.SelectedItem));
					this.loadNavNodes(this.InitArgs.Caption)
					this.ShowLoginRequired = false;
					this.cdRef.detectChanges();
				}
			}
		});
	}

	Delete() {
		const sel = this.SelectedItem;
		if (sel) {
			const delText =
				'@@Sind Sie sicher, dass Sie den Navigationsknoten {0} loeschen moechten? Dadurch werden auch saemtliche Unterknoten geloescht.';
			const text = new TranslateFormatText(delText);
			text.FormatParams.push(sel.Node.Caption);
			MessageBoxHelper.ShowDialog(
				text,
				new TranslateFormatText('@@Loeschen'),
				MessageBoxButtons.YesNo,
				MessageBoxIcon.Question
			).then((x) => {
				if (x === MessageBoxResult.Yes) {
					sel.HasChanges = false;
					const req = {
						StructureKey: sel.Data.StructureKey,
						NodesToDelete: [sel.Data._Id],
						OldSibbling: null,
					};
					if (sel.Data.ParentID) {
						const parent = NavigationStructureSettings.FindNodeByID(
							this.RootNodes,
							sel.Data.ParentID
						);
						if (parent) {
							const index = parent.Children.indexOf(sel.Node);
							if (index > -1) {
								parent.Children.splice(index, 1);
								if (parent.Children.length === 0) {
									parent.HasChildren = false;
									parent.Children = null;
								} else if (!sel.Node.IsNew && parent.Children.length > index) {
									req.OldSibbling = parent.Children[index].Data;
									if (index === 0) {
										req.OldSibbling.PreviousSibbling = null;
									} else {
										req.OldSibbling.PreviousSibbling =
											parent.Children[index - 1].Data._Id;
									}
								}
								this.RootNodes = [...this.RootNodes];
							}
						}
					} else {
						const childList = [...this.RootNodes];
						const index = childList.indexOf(sel.Node);
						if (index > -1) {
							childList.splice(index, 1);
							if (!sel.Node.IsNew && childList.length > index) {
								req.OldSibbling = childList[index].Data;
								if (index === 0) {
									req.OldSibbling.PreviousSibbling = null;
								} else {
									req.OldSibbling.PreviousSibbling =
										childList[index - 1].Data._Id;
								}
							}
							this.RootNodes = childList;
						}
					}
					if (!sel.Node.IsNew) {
						req.NodesToDelete.push(
							...NavigationStructureSettings.GetNodeIDs(sel.Node.Children)
						);
						this.service.DeleteNavigationNodes(req).subscribe();
					}
					this.SelectedNode = null;
					this.SelectedItem = null;
					this.cdRef.detectChanges();
					this.loadNavNodes(this.InitArgs.Caption)
				}
			});
		}
		this.ToggleWindow();
	}

	setIcon(icon) {
		if (this.SelectedItem) {
			this.SelectedItem.Data.Icon = icon;
			this.SelectedItem.HasChanges = true;
		}
	}
	PageURL:string;
	onPageChanged(ev) {
		if (ev) {
			if (ev && this.SelectedNavigationStructure) {
				if (ev.value == this.SelectedNavigationStructure["LandingPage"]) {
					NotificationHelper.Error("Navigation node page can not be same as Landing page", "Failed to select this page");
					this.SelectedItem.HasChanges = false;
					if (this.SelectedItemData) {
						// this.SelectedItem = this.SelectedItemData;
						let data = this.SelectedItem["Data"];
						let { Caption, LoginRequired, ParentID, PreviousSibbling, StructureKey } = data
						let dataNode = {
							Caption,
							LoginRequired,
							ParentID,
							PreviousSibbling,
							StructureKey
						}
						this.SelectedItem["Data"] = dataNode;
						this.PageURL="";
					}
					this.cdRef.detectChanges();
					return;
				}
			}
			if (ev && this.NavigationNodes) {
				let matchedNodes = this.NavigationNodes.find((node) => node["Url"] == ev.value);
				if (matchedNodes) {
					NotificationHelper.Error("Navigation node page is used by another node", "Failed to select this page");
					this.SelectedItem.HasChanges = false;
					if (this.SelectedItemData) {
						// this.SelectedItem = this.SelectedItemData;
						let data = this.SelectedItem["Data"];
						let { Caption, LoginRequired, ParentID, PreviousSibbling, StructureKey } = data
						let dataNode = {
							Caption,
							LoginRequired,
							ParentID,
							PreviousSibbling,
							StructureKey
						}
						this.SelectedItem["Data"] = dataNode;
						this.PageURL="";
					}
					this.cdRef.detectChanges();
					return;
				}
			}
			const sel = this.SelectedItem;
			this.PageURL="/"+this.SelectedItem["Data"]["StructureKey"]+this.SelectedItem["Data"]["Url"];
			this.cdRef.detectChanges();
			if (sel) {
				if (ev.value === '') {
					if (sel.Data) {
						const initArgs = new LayoutWizardInitArgs();
						initArgs.Page = {
							Name: TranslateHelper.TranslatorInstance.instant('@@Neue Seite'),
							URL: '/',
						};
						initArgs.CheckURL = true;
						initArgs.ShowCancel = true;
						LayoutWizardDialog.ShowDialog(initArgs, (page) => {
							if (page && page.Layout) {
								page.Layout.PageName = page.Name;
								page.Layout.CapsuleTag = page.CapsuleTag;
								this.service
									.AddOrUpdateLayoutWithKey(page.Layout, page.URL)
									.subscribe((result) => {
										if (result) {
											sel.Data.Url = page.URL;
											sel.HasChanges = true;
											const newPage = {
												Caption: page.URL,
												URL: page.URL,
											};
											if (page.Name) {
												newPage.Caption = page.Name;
											}
											this.Pages.push(newPage);
											this.PageURL = "/"+this.SelectedItem["Data"]["StructureKey"]+page.URL;
											this.cdRef.detectChanges();
										}
									});
							} else {
								sel.Data.Url = null;
								sel.HasChanges = true;
							}
						});
					}
				} else {
					sel.HasChanges = true;
				}
			}
		}
	}

	async checkHasChanges() {
		const retVal = await this.checkForSave();
		return retVal !== MessageBoxResult.Abort;
	}
	SetLoginRequired() {
		this.ShowLoginRequired = true;
		this.ShowConfirmation = true;
		if (this.SelectedItem) {
			if (this.SelectedItem["Data"]["ParentID"]) {
				this.ShowLoginRequired = false
			}
		}
		this.cdRef.detectChanges();
	}
	ToggleWindow() {
		if (this.SelectedItem?.Data?.Caption?.DefaultValue === '' || !this.SelectedItem?.Data?.Url) {
			this.SelectedItem = null;
		}
		this.toggleWindow = !this.toggleWindow;
		if (this.RootNodes.length > 0) {
			const filtered = this.RootNodes.filter((node) => node?.Caption !== "" && node?.Data.Url)
			this.RootNodes = filtered
			if (this.RootNodes.length > 0 && this.RootNodes[this.RootNodes.length - 1].HasChildren && (this.RootNodes[this.RootNodes.length - 1]?.Children[0].Caption === '' || !this.RootNodes[this.RootNodes.length - 1]?.Children[0]?.Data?.Url)) {
				const filteredChildren = this.RootNodes[this.RootNodes.length - 1].Children.filter(child => child.Caption !== '' && child?.Data?.Url)
				this.RootNodes[this.RootNodes.length - 1].Children = filteredChildren
				if (filteredChildren.length <= 0) {
					this.RootNodes[this.RootNodes.length - 1].HasChildren = false;
				}
			}
		}
	}
}

export class NavigationNode extends ABaseTreeNode {
	Data;
	IsNew;

	constructor(id, data, isNew, draggable) {
		super(id);
		this.Data = data;
		this.IsNew = isNew;
		this.Draggable = draggable;
		this.Caption = TranslatedString.GetTranslation(data.Caption);
	}
}
