import { ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { catchError, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { jwtDecode } from 'jwt-decode';

import config from '../../../assets/config.json';
import { TYPE_DICT } from '../../models/datamodel/adbinfo.model';
import { DataModelService } from '../../services/datamodel.service';
import { DataSource } from '../../models/datamodel/datasource.model';
import { TranslatedString } from '../../models/translatedstring.model';
import { plainToClass } from 'class-transformer';
import { BaseListSettings, DeleteTexts, SaveTexts } from '../../settings/base.list.settings';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { NotificationHelper } from '../../helpers/notification.helper';
import { LayoutService } from '../../services/layout.service';
import { DataSourceDetail } from '../../settings/datamodel/datasource/datasource.settings';
import { RestService } from '../../services/rest.service';
import { DataModel } from '../../models/datamodel/datamodel.model';

@Component({
  selector: 'lib-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.css'],
})
export class IntegrationComponent extends BaseListSettings implements OnInit {
  FilteredDrivers = [];
  FilteredOtherDrivers = [];
  Drivers = [];
  OtherDrivers = [];
  dataModelList = [];
  datasourcesList = null;
  DATASOURCESlIST = [];
  SelectedDataModel;
  isShowDialog = false;
  SelectedDB;
  dataSource;
  searchValue = '';

  dataModeControl = new FormControl('');
  dataModelFilteredOptions: Observable<any[]>;

  imageSource = '../../../assets/db/';

  dataSourceTypes = [
    'cb3a9b71-e35a-4fb6-84bd-6ae20d584d42',
    'e5246d82-0716-4ac0-aef9-589a9d1a22b3',
    'c88671c3-e258-41f0-8d68-38e011a0840c',
    '497f5f18-bac5-4f91-bfb6-d4d20cc0205d',
    'd90798a2-6173-4083-b3d3-12f0c1d17b6c',
    '7c60db8d-2a8c-4154-b55f-a826c10b8dfc',
    'd86bd6e7-fe80-4564-8029-68b9ee2c0142',
    '14215a44-ac8c-4646-8ad9-0af7a78b8d00',
    '181567f1-3587-456c-83cd-33289bdbd524',
    '79cffd9a-8f7c-4b37-bd29-0d8ed7f969de',
    'f336a442-5c6c-4fd5-9b88-4e5d4dd35232',
    '152aaebb-d826-43c5-b0f7-5016ade5b498',
    '1c97b593-e4bd-43b5-9d87-d9cb86b9fc14',
    '5395c896-08f9-498a-b0a0-2dfbc0838b18',
    'a113a278-21bd-4157-acc7-e327ebae3161',
    '8711e27d-dba8-4762-bee6-c209f622c980',
    'b6aa8610-f3f4-4da4-9f7f-31c397dce938',
  ];
  defaultDataModel = { SID: '' };
  constructor(
    private translate: TranslateService,
    private dataService: DataModelService,
    private restService: RestService,
    protected factoryResolver: ComponentFactoryResolver,
    protected cdRef: ChangeDetectorRef,
    private router: Router
  ) {
    super(factoryResolver, cdRef);
  }

  ngOnInit(): void {
    this.getDrivers();
    this.getModels();
    this.getDataSources();

    this.dataModelFilteredOptions = this.dataModeControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.dataModelFilter(value || ''))
    );
  }

  redirectToDefault() {
    let url;
    if (this.defaultDataModel.SID) {
      url = `/default/settings/data/dataModel/${this.defaultDataModel.SID}/datasources`;
    } else {
      url = '/default/settings/data/dataModel/dm-overview'
    }
    this.router.navigate([url])
  }
  onFocusFieldDataModel() {
    this.dataModeControl.setValue(this.dataModeControl.value || '');
  }

  loadData(data) {
    this.dataService.GetDataSource(data).subscribe((datasource) => {
      const ds = plainToClass(DataSource, datasource);
      this.setSelectedItem(ds);
    });
    this.ToggleWindow();
    this.cdRef.detectChanges();
    LayoutService.Loading.next(false);
  }

  onSearch() {
    this.FilteredDrivers = this.Drivers.filter((item) => item.Name.toLowerCase().includes(this.searchValue.toLowerCase()));
    this.FilteredOtherDrivers = this.OtherDrivers.filter((item) => item.Name.toLowerCase().includes(this.searchValue.toLowerCase()));
    this.datasourcesList = this.DATASOURCESlIST.filter((item) => item.Caption.toLowerCase().includes(this.searchValue.toLowerCase()));
  }

  dataModelFilter(value: any): any[] {
    const filterValue = typeof value == 'string' ? value.toLowerCase() : value['Caption'].toLowerCase();
    return this.dataModelList.filter((option) => option.Caption && option.Caption.toLowerCase().includes(filterValue));
  }

  displayModelValue(option): string {
    return option.Caption;
  }

  onSelectedModel(event) {
    this.SelectedDataModel = event.option.value;
  }

  getDataSources() {
    this.dataService.GetDataSourceInfos().subscribe((datasources) => {
      const filteredDataSource = datasources.filter((x) => this.dataSourceTypes.includes(x.DBId));
      const sortedDataSource = filteredDataSource.sort((a, b) => {
        return this.dataSourceTypes.indexOf(a.DBId) - this.dataSourceTypes.indexOf(b.DBId);
      });
      this.DATASOURCESlIST = sortedDataSource;
      this.datasourcesList = sortedDataSource;
      this.cdRef.detectChanges();
    });
  }

  getDrivers() {
    this.dataService.GetSortedDrivers().subscribe((_drivers) => {
      if (_drivers) {
        // filter drivers according to user role
        let Drivers = [];
        let driversToAdd = _drivers;
        const userRole = JSON.parse(localStorage.getItem('user'))?.Roles;
        if (userRole && userRole.length > 0 && userRole[0] === config.FREE_USER_ID) {
          Drivers = _drivers.filter((driver) => driver.Name !== 'ROLAP' && TYPE_DICT.has(driver.DBInfoType));
        }
        else {
          Drivers = driversToAdd.filter((x) => TYPE_DICT.has(x.DBInfoType));
        }

        // filter and sort drivers
        const filteredDrivers = Drivers.filter((x) => this.dataSourceTypes.includes(x.Id));
        const sortedDrivers = filteredDrivers.sort((a, b) => {
          return this.dataSourceTypes.indexOf(a.Id) - this.dataSourceTypes.indexOf(b.Id);
        });
        const otherDrivers = Drivers.filter((x) => !this.dataSourceTypes.includes(x.Id));
        this.OtherDrivers = otherDrivers;
        this.FilteredOtherDrivers = otherDrivers;
        this.FilteredDrivers = sortedDrivers;
        this.Drivers = sortedDrivers;
        this.cdRef.detectChanges();
      }
    });
  }

  getModels() {
    this.dataService.GetModels().subscribe((data) => {
      this.dataModelList = data;
      this.getDefaultDataModel();
      this.InitArgs = data[0];
      // this.SelectedDataModel = data[0];
      this.cdRef.detectChanges();
    });
  }

  openDialog(db) {
    this.SelectedDB = db;
    this.dataSource = this.getNewItem();
    if (!this?.defaultDataModel?.SID) {
      LayoutService.Loading.next(true);
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode<{ unique_name: '' }>(token);
      const modelName = decodedToken?.unique_name?.toLowerCase() + '-workspace';
      const defaultDataModelPayload = new DataModel();
      defaultDataModelPayload.Name = `${modelName}-default-datamodel`;
      this.dataService
      .SaveModel(defaultDataModelPayload).subscribe(response => {
        LayoutService.Loading.next(false);
        this.getModels()
        this.isShowDialog = true;
        })
      }
      this.isShowDialog = true;
  }

  closeDialog() {
    this.isShowDialog = false;
  }

  getContentType() {
    return DataSourceDetail;
  }

  addDataModel(name) {
    if (!name) {
      NotificationHelper.Error('Name field is required', '@@Error');
      return;
    }
    const caption = new TranslatedString(name);
    const Description = new TranslatedString('');
    const data = new DataModel();
    data.Caption = caption;
    data.Description = Description;
    data.IsReadOnly = false;
    data.IsVisible = true;
    data.MaxSaveCombinations = 100000;
    data.Name = name;
    data.SelectTopRows = 1000;
    LayoutService.Loading.next(true);
    this.dataService.SaveModel(data).subscribe({
      next: (result) => {
        if (result) {
          NotificationHelper.Success(`@@DataModel ${result.Caption} saved successfully`, '@@Save');
          this.getModels();
          this.SelectedDataModel = result;
        }
        LayoutService.Loading.next(false);
      },
      error: () => {
        LayoutService.Loading.next(false);
      },
    });
  }

  getDefaultDataModel() {
    const token = localStorage.getItem('token');
    const decodedToken = jwtDecode<{ unique_name: '' }>(token);
    const modelName = decodedToken?.unique_name?.toLowerCase() + '-workspace-default-datamodel';
    this.defaultDataModel = this.dataModelList.find((dataModel) => dataModel.Caption.includes(modelName));
  }

  loadList(handler) {
    if (this.InitArgs) {
      this.dataService.GetDataSources(this.InitArgs.SID).subscribe((sources) => {
        const list = [];
        sources.forEach((source) => {
          list.push({
            Caption: source.Caption,
            ID: source.SID,
            IsCapsule: source.IsCapsule,
            IsOverridden: source.IsOverridden,
          });
        });
        handler(list);
      });
    }
    LayoutService.Loading.next(false);
  }

  // loadData(data) {
  //     this.dataService.GetDataSource(data).subscribe((datasource) => {
  //         const ds = plainToClass(DataSource, datasource);
  //         this.setSelectedItem(ds);
  //     });
  // }

  getNewItem() {
    const ds = new DataSource();
    const name = 'DataSource_';
    let length = 1;
    if (this.ListItems.length > 0) {
      length = this.ListItems.length + 1;
    }
    ds.Name = name + length;
    ds.Caption = new TranslatedString(this.translate.instant('@@New data source'));
    if (this.InitArgs) {
      ds.DataModelID = this.InitArgs.SID;
    }

    ds.DBInfoType = this.SelectedDB.DBInfoType;
    return ds;
  }

  // getDeleteText(sel): DeleteTexts {
  // 	const retVal = new DeleteTexts();
  // 	retVal.Question = new TranslateFormatText(
  // 		"@@Sind Sie sicher, dass Sie die Datenquelle '{0}' loeschen moechten?"
  // 	);
  // 	retVal.Question.FormatParams.push(sel.Caption);
  // 	retVal.Success = new TranslateFormatText(
  // 		"@@Datenquelle '{0}' erfolgreich geloescht."
  // 	);
  // 	retVal.Success.FormatParams.push(sel.Caption);
  // 	retVal.Title = new TranslateFormatText('@@Datenquelle loeschen');
  // 	return retVal;
  // }

  getSaveSuccessText(sel): SaveTexts {
    let caption = TranslatedString.GetTranslation(sel.Caption);
    if (!caption) {
      caption = sel.Name;
    }
    const retVal = new SaveTexts();
    retVal.Text = new TranslateFormatText("@@Datenquelle '{0}' erfolgreich gespeichert.");
    retVal.Text.FormatParams.push(caption);
    retVal.Title = new TranslateFormatText('@@Datenquelle speichern');
    return retVal;
  }

  delete(data, handler) {
    this.dataService.DeleteSource(data).subscribe((res) => {
      handler(res);
    });
  }

  getDeleteText(sel): DeleteTexts {
    const retVal = new DeleteTexts();
    retVal.Question = new TranslateFormatText("@@Are you sure you want to delete data source '{0}'?");
    retVal.Question.FormatParams.push(sel.Caption);
    retVal.Success = new TranslateFormatText("@@Data source '{0}' successfully deleted.");
    retVal.Success.FormatParams.push(sel.Caption);
    retVal.Title = new TranslateFormatText('@@Delete data source');
    return retVal;
  }

  // 	getSaveSuccessText(sel): SaveTexts {
  // 		let caption = TranslatedString.GetTranslation(sel.Caption);
  // 		if (!caption) {
  // 				caption = sel.Name;
  // 		}
  // 		const retVal = new SaveTexts();
  // 		retVal.Text = new TranslateFormatText('@@Data source \'{0}\' saved successfully.');
  // 		retVal.Text.FormatParams.push(caption);
  // 		retVal.Title = new TranslateFormatText('@@Save data source');
  // 		return retVal;
  // }

  // Function to validate if the object contains all properties of the class
  protected validateObjectProperties(obj, optionalProps = []) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && (obj[key] === null || obj[key] === undefined)) {
        if (optionalProps.indexOf(key) < 0) return false; // If any property value is null or undefined, return false
      }
    }
    return true; // All properties have non-null values
  }

  saveInternal(item, handler) {
    if (item?.Name.trim() == '') {
      NotificationHelper.Error('Name field is required', '@@Error');
      LayoutService.Loading.next(false);
      return;
    }
    this.dataService.SaveDataSource(item).subscribe((result) => {
      if (result) {
        handler(result, result.SID, result.Caption);
      }
    });
  }

  handleNew(item, result) {
    item.SID = result.SID;
    item.Version = result.Version;
    item.InternalID = result.InternalID;
  }

  updateListItem(item, result) {
    item.IsCapsule = result.IsCapsule;
    item.IsOverridden = result.IsOverridden;
  }

  checkConnection() {
    this.dataSource.DataModelID = this.defaultDataModel.SID;
    this.cdRef.detectChanges();

    const item = this.dataSource;
    LayoutService.Loading.next(true);
    this.dataService.CheckConnection(item).subscribe((x) => {
      if (x.Successfull) {
        NotificationHelper.Success('@@Successfully connected', '@@Success');
      } else {
        NotificationHelper.Error(x.Error, '@@Error');
      }
      LayoutService.Loading.next(false);
    });
  }

  onSaveClick() {
    const item = this.dataSource;
    if (!item.Name) {
      NotificationHelper.Error('Name field is required', '@@Error');
      return;
    }

    LayoutService.Loading.next(true);

    if (!this?.defaultDataModel?.SID) {
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode<{ unique_name: '' }>(token);
      const modelName = decodedToken?.unique_name?.toLowerCase() + '-workspace';
      const defaultDataModelPayload = new DataModel();
      defaultDataModelPayload.Name = `${modelName}-default-datamodel`;
      this.dataService
        .SaveModel(defaultDataModelPayload)
        .pipe(
          switchMap((response) => {
            if (response) {
              item.DataModelID = response.SID;
              return this.dataService.SaveDataSource(item);
            }
          }),
          tap((response) => {
            if (response) {
              this.closeDialog();
              this.cdRef.detectChanges();
              NotificationHelper.Success(`Data source ${item.Name} saved successfully `, '@@Success');
            }
          }),
          catchError((error) => {
            NotificationHelper.Error('@@Error', 'Failed to save Data Source.');
            return of(null);
          }),
          finalize(() => {
            this.getDataSources();
            LayoutService.Loading.next(false);
          })
        )
        .subscribe();
      return;
    }

    item.DataModelID = this.defaultDataModel.SID;
    this.dataService.SaveDataSource(item).subscribe((response) => {
      if (response) {
        this.closeDialog();
        this.cdRef.detectChanges();
        NotificationHelper.Success(`Data source ${item.Name} saved successfully `, '@@Success');
        this.getDataSources();
        LayoutService.Loading.next(false);
      }
    });
  }

  onSelectDataSource(item) {
    this.router.navigateByUrl(`default/settings/data/dataModel/${item.DataModelID}/datasources`);
  }
}
