import { OnInit, Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { REGISTRY, CONTROLGROUPS, WIDGETREGISTRY } from '../../../services/dynamic.component.service';
import { NavigationService } from '../../../services/navigation.service';
import { Page } from '../../../models/enums/page.enum';
import { ALayoutMenuTab } from '../../../appbuilder/common/menutabcontrol/menu.tab.control';
import { LayoutService } from '../../../services/layout.service';
import { SettingsService } from '../../../services/settings.service';
import { ComponentPortal } from '@angular/cdk/portal';
import { MetaService } from '../../../services/meta.service';
import { plainToClass } from 'class-transformer';
import { Layout } from '../../../models/layout.model';
import { LayoutHelper } from '../../../helpers/layout.helper';

@Component({
    selector: 'controls-menu-tab',
    template: '<accordion-menu-control [Items]="Items"></accordion-menu-control>',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlsMenuTab implements OnInit {
    Items = [];
    ngOnInit(): void {
        const items = [];
        CONTROLGROUPS.forEach(x => {
            const item = {
                SID:x.SID,
                Caption: x.Caption,
                Content: new ComponentPortal(x.Content),
                Visible: true,
                Class:'toolbox'
            };
            if (typeof x.CheckVisible === 'function') {
                item.Visible = x.CheckVisible();
            }
            items.push(item);
        });
        this.Items = items;
    }
}

@Component({
    selector: 'controls-a-menu-tab-content',
    templateUrl: './controls.menu.tab.content.html'
})
export abstract class AMenuTabContent extends ALayoutMenuTab {
    Items = [];
    dragging = false;
    dragType;
    dragItem;

    SearchValue: string;
    FilteredItems = [];

    constructor(protected settingsService: SettingsService, protected metaService: MetaService, cdRef: ChangeDetectorRef, private translate: TranslateService) {
        super(cdRef);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.Subscriptions.push(LayoutService.DragType.subscribe((dt) => {
            this.dragType = dt;
        }));
    }

    //#region Drag&Drop
    dragStart(event, item,caption) {
        item.Name = LayoutHelper.GetNextElementName(this.Layout, item.Type, this.translate.instant(caption));
        this.settingsService.DragStart.next({ event, item });
        this.dragItem = item.Type;
        this.dragging = true;
        event.stopPropagation();
    }
    dragEnd(event) {
        this.settingsService.DragEnd.next(event);
        LayoutService.RefreshTree.next(true);
        this.dragging = false;
        this.dragItem = null;
    }
    checkDragItem(item) {
        if (item === this.dragItem) {
            return true;
        }
        return false;
    }
    //#endregion


    //#region Search
    resetSearch() {
        this.SearchValue = null;
        this.UpdateFiltered();
    }

    UpdateFiltered() {
        this.FilteredItems = [];
        if (this.Items) {
            if (this.SearchValue) {
                this.FilteredItems = this.getFilteredList(this.Items);
            }
            else {
                this.FilteredItems = this.Items;
            }
        }
    }

    getFilteredList(originalList: any[]) {
        if (!this.SearchValue) {
            return originalList;
        }
        const list = [];
        const toLower = this.SearchValue.toLowerCase();       
        if (originalList) {
            originalList.forEach(x => {               
                if (x.Caption && this.translate.instant(x.Caption).toLowerCase().indexOf(toLower) > -1) {
                    list.push(x);
                }
            });
        }
        return list;
    }
    Sort(sort) {
        this.Items.sort((a, b) => {
            if (sort == 'name') {
                if (a.Caption > b.Caption) {
                    return 1;
                }
                if (a.Caption < b.Caption) {
                    return -1;
                }
            }
            if (sort == 'type') {
                if (a.Category > b.Category) {
                    return 1;
                }
                if (a.Category < b.Category) {
                    return -1;
                }
            }
            return 0;
        });
    }
    //#endregion

}

@Component({
    selector: 'controls-menu-tab-content',
    templateUrl: './controls.menu.tab.content.html'
})
export abstract class ControlsMenuTabContent extends AMenuTabContent {

    abstract GetID(): string;

    ngOnInit() {
        super.ngOnInit();
        const id = this.GetID();
        const page = NavigationService.SelectedPage.getValue();
        let activepage = Page.All;
        switch (page) {
            case 'settings': activepage = Page.None; break;
            case 'ribbon': activepage = Page.Ribbon; break;
            case 'output': activepage = Page.Print; break;
            case 'template': activepage = Page.App; break;
            case 'widget': activepage = Page.App; break;
            case 'layout': activepage = Page.Layout; break;
            case 'content': activepage = Page.App; break;
        }
        const items = [];
        const keys = Object.keys(REGISTRY);
        keys.forEach((key) => {
            const reg = REGISTRY[key];
            if (reg.type === id && (reg.page.indexOf(activepage) > -1 || reg.page.indexOf(Page.All) > -1)) {
                items.push({
                    Key: key,
                    Caption: reg.Label,
                    Icon: reg.Icon,
                    Default: reg.Control.Default,
                    Category: reg.Category
                });
            }
        });
        this.Items = items;
        this.Sort('type');
        this.FilteredItems = this.getFilteredList(this.Items);
    }
}

@Component({
    selector: 'menu-tab-content-controls-accordion',
    templateUrl: './controls.menu.tab.content.html'
})
export class ControlsAccordion extends ControlsMenuTabContent {
    GetID(): string {
        return 'Control';
    }
}

@Component({
    selector: 'menu-tab-content-static-accordion',
    templateUrl: './controls.menu.tab.content.html'
})
export class StaticAccordion extends ControlsMenuTabContent {
    GetID(): string {
        return 'StaticControl';
    }
}

@Component({
    selector: 'menu-tab-content-layout-accordion',
    templateUrl: './controls.menu.tab.content.html'
})
export class LayoutAccordion extends ControlsMenuTabContent {
    GetID(): string {
        return 'Layout';
    }
}

@Component({
    selector: 'menu-tab-content-template-accordion',
    templateUrl: './controls.menu.tab.content.html'
})
export class TemplateAccordion extends AMenuTabContent {

    static CheckVisible(): boolean {
        const page = NavigationService.SelectedPage.getValue();
        return page !== 'output';
    }

    ngOnInit() {
        super.ngOnInit();
        this.metaService.ReadTemplates().subscribe((templates) => {
            const templateList: any = plainToClass(Layout, templates);
            const items = [];
            templateList.forEach(x => {
                items.push({
                    Key: x.ElementType,
                    Caption: x.Name,
                    Default: x
                });
            });
            this.Items = items;
            this.FilteredItems = this.getFilteredList(this.Items);
            this.cdRef.detectChanges();
        });
    }
}

@Component({
    selector: 'menu-tab-content-widget-accordion',
    templateUrl: './controls.menu.tab.content.html'
})
export class WidgetAccordion extends AMenuTabContent {

    static CheckVisible(): boolean {
        const page = NavigationService.SelectedPage.getValue();
        return page !== 'output' && page !== 'widget';
    }

    ngOnInit() {
        super.ngOnInit();
        this.metaService.ReadWidgets().subscribe((widgets) => {
            const widgetList: any = plainToClass(Layout, widgets);
            const items = [];
            widgetList.forEach(x => {
                items.push({
                    Key: x.ElementType,
                    Caption: x.Name,
                    Default: x
                });
            });
            const keys = Object.keys(WIDGETREGISTRY);
            keys.forEach((key) => {
                const widget = WIDGETREGISTRY[key];
                if (widget) {
                    if (typeof widget.HideInControlsMenu === 'boolean' && widget.HideInControlsMenu) {
                        return;
                    }
                    const layout = new Layout();
                    layout['ElementType'] = 'widget';
                    layout['Type'] = 'widget';
                    layout['NativeComponent'] = key;
                    items.push({
                        Key: 'widget',
                        Caption: widget.Label,
                        Default: layout
                    });
                }
            });

            this.Items = items;
            this.FilteredItems = this.getFilteredList(this.Items);
            this.cdRef.detectChanges();
        });
    }
}

@Component({
    selector: 'report-controls-accordion',
    templateUrl: './controls.menu.tab.content.html'
})
export class ReportControlsAccordion extends ControlsMenuTabContent {
    GetID(): string {
        return 'Reportcontrol';
    }
}

@Component({
    selector: 'report-objects-accordion',
    templateUrl: './controls.menu.tab.content.html'
})
export class ReportObjectsAccordion extends ControlsMenuTabContent {
    GetID(): string {
        return 'Reportobject';
    }
}
