import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectorRef, Component, ContentChild, Directive, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TreeNode } from 'primeng/api';
import { SETTINGS_LIST } from '../../../components/pages/settings.page';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { PermissionHelper } from "../../../helpers/permissions.helper";
import { MenuTabPosition } from '../../../models/enums/menutabposition.enum';
import { LayoutService } from '../../../services/layout.service';
import { MetaService } from '../../../services/meta.service';
import { NavigationService } from '../../../services/navigation.service';
import { UsersService } from "../../../services/users.service";
import { APropertyTab } from '../../menutabs/properties/properties.menu.tab';

@Directive({
    selector: '[specialContent]'
})
export class MenuTabSpecialContentDirective { }
@Directive({
    selector: '[middleContent]'
})
export class MenuTabMiddleContentDirective { }

@Component({
    selector: 'menu-tab-control',
    templateUrl: './menu.tab.control.html',
    styleUrls: ['./menu.tab.control.css']
})
export class MenuTabControl {
    @ContentChild(MenuTabSpecialContentDirective, { read: TemplateRef }) specialContentTemplate;
    @ContentChild(MenuTabMiddleContentDirective, { read: TemplateRef }) middleContentTemplate;
    Instance;
    Style = {
        padding: '10px'
    };
    SelectedPanelLeft;
    SelectedPanelRight;
    LeftPanels = [];
    LeftPanelWidth = '350px';
    RightPanels = [];
    RightPanelWidth = '330px';
    RightPanelMinWidth = '330px';
    NaviPanels = [];
    AvailablePanels = [];
    TempRightPanelWidth = null;
    SelectedTreeNodes = [];
    //#region Items
    ItemsValue = [];
    @Input()
    get Items() {
        return this.ItemsValue;
    }
    set Items(val) {
        this.ItemsValue = val;
        this.GenerateTree();
        this.ShowRightMenu = false;
        this.showPanelRight(0);
        this.ItemsChange.emit(val);
    }
    @Output() ItemsChange = new EventEmitter<any>();
    //#endregion
    GenerateTree() {
        this.AvailablePanels = [];
        this.LeftPanels = [];
        this.RightPanels = [];
        this.NaviPanels = [];
        let ClientPanels: TreeNode = {};
        ClientPanels.children = [];
        ClientPanels.label = this.translate.instant("@@ClientPanels");
        if (this.ItemsValue) {
            for (var i = 0; i < this.ItemsValue.length; i++) {
                switch (this.ItemsValue[i].Position) {
                    case MenuTabPosition.Left:
                        this.LeftPanels.push(this.ItemsValue[i]);
                        break;
                    case MenuTabPosition.Right:
                        this.RightPanels.push(this.ItemsValue[i]);
                        break;
                    case MenuTabPosition.Navi:
                        this.NaviPanels.push(this.ItemsValue[i]);
                        let item: TreeNode = {};
                        item.data = this.ItemsValue[i];
                        item.icon = this.ItemsValue[i].Icon;
                        item.label = this.ItemsValue[i].Tooltip;
                        item.selectable = true;
                        item.type = "Client";
                        ClientPanels.children.push(item);
                        break;
                    default:
                        this.NaviPanels.push(this.ItemsValue[i]);
                        break;
                }
            }
        }
        this.AvailablePanels.push(ClientPanels);
        this.AddSettingsPanels();

        let savedPanels = localStorage.getItem("Appbuilder_ActivePanels");
        if (savedPanels) {
            let panels = JSON.parse(savedPanels);
            for (var i = 0; i < panels.length; i++) {
                let panel = panels[i];
                let found = this.NaviPanels.find((value) => value.ID == panel.ID);
                if (found) {
                    panels[i] = found;
                }
                if (this.NavigationItemsObj[panel.ID]) {
                    if (Array.isArray(this.NavigationItemsObj[panel.ID])) {
                        if (panel.InitArgs) {
                            let obj = this.NavigationItemsObj[panel.ID].find((navobj) => JSON.stringify(navobj.InitArgs) == JSON.stringify(panel.InitArgs));
                            if (obj) {
                                panels[i] = obj;
                            }
                        }
                    } else {
                        panels[i] = this.NavigationItemsObj[panel.ID];
                    }
                }
                if (panels[i].Type == "Settings") {
                    panels[i].Visible = this.checkSettingPanelVisibility(panels[i]);
                }
            }
            this.NaviPanels = panels;
        }

    }
    AddSettingsPanels() {
        let Panels: TreeNode = {};
        Panels.children = [];
        Panels.label = this.translate.instant("@@SettingsPanels");

        for (var i = 0; i < this.InternalNavigationItems.length; i++) {
            let val = this.InternalNavigationItems[i];
            if (this.checkSettingPanelVisibility(val)) {
                val.Type = "Settings";
                let item: TreeNode = {};
                item.data = val;
                item.icon = val.Icon;
                item.label = val.Caption;
                item.selectable = true;
                item.type = val.Type;
                if (val.UpdateFunction && (!val.Children || val.Children.length == 0)) {
                    val.UpdateFunction(val);
                }
                if (val.Children && val.Children.length > 0) {
                    item.children = this.PrepareSettingsPanels(val.Children);
                }
                Panels.children.push(item);
            }
        }
        this.AvailablePanels.push(Panels);
    }
    private PrepareSettingsPanels(Items): TreeNode[] {
        let result: TreeNode[] = [];
        if (this.ShowMenu) {
            for (var i = 0; i < Items.length; i++) {
                let val = Items[i];
                if (val.ID != 'pages' && val.ID != 'templates' && val.ID != 'widgets' && this.checkSettingPanelVisibility(val)) {
                    val.Type = "Settings";
                    let item: TreeNode = {};
                    item.data = val;
                    item.icon = val.Icon;
                    item.label = val.Caption;
                    item.selectable = true;
                    item.type = val.Type;
                    if (val.UpdateFunction && (!val.Children || val.Children.length == 0)) {
                        val.UpdateFunction(val);
                    }
                    if (val.Children && val.Children.length > 0) {
                        item.children = this.PrepareSettingsPanels(val.Children);
                    }
                    result.push(item)
                }
            }
        }
        return result;
    }
    //#region ShowMenu
    ShowMenuValue = false;
    @Input()
    get ShowMenu() {
        return this.ShowMenuValue;
    }
    set ShowMenu(val) {
        this.ShowMenuValue = val;
        this.GenerateTree();
       
        this.ShowMenuChange.emit(val);
    }
    @Output() ShowMenuChange = new EventEmitter<any>();
    //#endregion
    ShowLeftMenu = false;
    SelectedIndexLeft = 0;
    SelectedIndexNavi = 0;
    SelectedIndexRight = 0;
    ShowRightMenu = false;
    IsSettingsPanel = false;
    SelectedSettingsPanel = null;

    NaviPanelEditMode = false;
    InternalNavigationItems = [];
    NavigationItemsObj = {}
    //#region NavigationItems
    NavigationItemsValue = [];
    @Input()
    get NavigationItems() {
        return this.NavigationItemsValue;
    }
    set NavigationItems(val) {
        this.NavigationItemsValue = val;
        this.NavigationItemsObj = {};
        this.FillNavItemsObj(val);
        this.NavigationItemsChange.emit(val);
    }
    @Output() NavigationItemsChange = new EventEmitter<any>();
    FillNavItemsObj(val) {
        if (val && val.length > 0) {
            for (let i = 0; i < val.length; i++) {
                let item = val[i];
                if (!this.NavigationItemsObj[item.ID]) {
                    this.NavigationItemsObj[item.ID] = item;
                } else {
                    this.NavigationItemsObj[item.ID] = [this.NavigationItemsObj[item.ID], item];
                }
                
                if (item.Children && item.Children.length > 0) {
                    this.FillNavItemsObj(item.Children);
                }
            }
        }
    }
    //#endregion

    @Output() ContentSizeChanged = new EventEmitter<any>();

    constructor(private cdRef: ChangeDetectorRef, private service: MetaService, private router: Router, private translate: TranslateService) {
        this.NavigationItems = SETTINGS_LIST;

        this.InternalNavigationItems = SETTINGS_LIST;
        this.Instance = this;
        NavigationService.Refresh.subscribe(() => {
            this.NavigationItems = SETTINGS_LIST;
            this.InternalNavigationItems = SETTINGS_LIST;
            this.GenerateTree();
        });
        LayoutHelper.RefreshMenuTabs.subscribe(() => {
            this.cdRef.detectChanges();
        })
    }
    //#region NavigationPanel
    SelectedComponent;
    SetComponent(nav, comp) {
        this.SelectedComponent = comp;
        if (nav && typeof nav.UpdateFunction === 'function') {
            nav.UpdateFunction(nav);
        }
    }

    async CheckComponent() {
        if (this.SelectedComponent) {
            if (typeof this.SelectedComponent.checkHasChanges === 'function') {
                return await this.SelectedComponent.checkHasChanges();
            }
        }
        return true;
    }
    //#endregion

    //#region Left panel
    showPanelLeft(i) {
        this.LeftPanels.forEach(x => x.IsActive = false);
        this.NaviPanels.forEach(x => x.IsActive = false);
        this.IsSettingsPanel = false;
        this.SelectedIndexNavi = null;
        this.selectedPortal = null;
        this.SelectedSettingsPanel = null;
        if (this.ShowLeftMenu && (this.SelectedIndexLeft == i || i == null)) {
            this.ShowLeftMenu = false;
            this.SelectedPanelLeft = null;
            this.SelectedIndexLeft = null;
        } else {
            this.ShowLeftMenu = true;
            this.SelectedIndexLeft = i;
            const entry = this.LeftPanels[i];
            entry.IsActive = true;
            if (entry.Content) {
                this.SelectedPanelLeft = new ComponentPortal(entry.Content);
            } else {
                this.SelectedPanelLeft = null;
            }
        }
        this.cdRef.detectChanges();
        this.ContentSizeChanged.emit();
    }
    onResizeLeftEnd(event) {
        this.LeftPanelWidth = event.rectangle.width + 'px';
        this.ContentSizeChanged.emit();
    }
    //#endregion
    //#region Right panel
    showPanelRight(i) {
        this.RightPanels.forEach(x => x.IsActive = false);
        if (this.ShowRightMenu && (this.SelectedIndexRight == i || i == null)) {
            this.ShowRightMenu = false;
            this.SelectedPanelRight = null;
            this.SelectedIndexRight = null;
            this.TempRightPanelWidth = this.RightPanelWidth;
            this.RightPanelWidth = '0px';
            this.RightPanelMinWidth = '0px';
        } else {
            this.ShowRightMenu = true;
            this.SelectedIndexRight = i;
            const entry = this.RightPanels[i];
            if (entry) {
                entry.IsActive = true;
                if (entry.Content) {
                    this.SelectedPanelRight = new ComponentPortal(entry.Content);
                } else {
                    this.SelectedPanelRight = null;
                }
            } else {
                this.SelectedPanelRight = null;
            }
            
            this.RightPanelWidth = this.TempRightPanelWidth;
            this.RightPanelMinWidth = '330px';
        }
        this.cdRef.detectChanges();
        this.ContentSizeChanged.emit();
    }
    InternalResizeValue = false;
    get InternalResize() {
        return this.InternalResizeValue;
    }
    set InternalResize(value) {
        this.InternalResizeValue = value;
        this.cdRef.detectChanges();
    }
    onResizeRightStart(event) {
        this.InternalResize = true;
    }
    onResizeRightEnd(event) {
        this.RightPanelWidth = event.rectangle.width + 'px';
        this.InternalResize = false;
        this.ContentSizeChanged.emit();
    }
    //#endregion
    //#region Left Navigation
    UseNavigation = false;
    selectedPortal = null;
    selectedEntry = null;
    showNaviPanel(i) {
        if (!this.NaviPanelEditMode) {
            this.selectedPortal = null;
            this.LeftPanels.forEach(x => x.IsActive = false);
            this.NaviPanels.forEach(x => x.IsActive = false);
            this.SelectedIndexLeft = null;
            this.SelectedSettingsPanel = null;
            this.IsSettingsPanel = false;
            this.UseNavigation = false;
            if (this.ShowLeftMenu && (this.SelectedIndexNavi == i || i == null)) {
                this.ShowLeftMenu = false;
                this.SelectedPanelLeft = null;
                this.SelectedIndexNavi = null;
            } else {
                this.ShowLeftMenu = true;
                this.SelectedIndexNavi = i;
                const entry = this.NaviPanels[i];
                this.selectedEntry = entry;
                entry.IsActive = true;
                if (entry.Type == "Settings") {
                    this.IsSettingsPanel = true;
                    if (entry.Children && entry.Children.length > 0) {
                        this.SelectedSettingsPanel = entry.Children;
                        this.UseNavigation = true;
                    } else {
                        this.selectedPortal = new ComponentPortal(entry.Content);
                    }
                } else {
                    if (entry.Content) {
                        this.SelectedPanelLeft = new ComponentPortal(entry.Content);
                    } else {
                        this.SelectedPanelLeft = null;
                    }
                }
            }
            this.cdRef.detectChanges();
            this.ContentSizeChanged.emit();
        }
    }
    PanelSelectionOpen = false;
    OpenPanelSelection() {

    }
    AddClientPanel(panel) {
        if (this.NaviPanels.indexOf(panel.data) == -1) {
            this.NaviPanels.push(panel.data);
        }
        localStorage.setItem("Appbuilder_ActivePanels", JSON.stringify(this.NaviPanels));
        this.PanelSelectionOpen = false;
        this.cdRef.detectChanges();
    }
    AddSettingsPanel(panel) {
        if (this.NaviPanels.indexOf(panel.data) == -1) {
            this.NaviPanels.push(panel.data);
        }
        localStorage.setItem("Appbuilder_ActivePanels", JSON.stringify(this.NaviPanels));
        this.PanelSelectionOpen = false;
        this.cdRef.detectChanges();
    }
    EditNaviPanels() {
        this.NaviPanelEditMode = !this.NaviPanelEditMode;
    }
    RemoveNaviPanel(panel) {
        let index = this.NaviPanels.indexOf(panel);
        if (index > -1) {
            this.NaviPanels.splice(this.NaviPanels.indexOf(panel), 1);
        }
        localStorage.setItem("Appbuilder_ActivePanels", JSON.stringify(this.NaviPanels));
    }
    //#endregion
    checkTabVisibility() {
        this.ItemsValue.forEach(x => {
            if (typeof x.CheckVisible === 'function') {
                const visible = x.CheckVisible();
                this.setTabVisibility(x, visible);
            }
            if (typeof x.CheckVisibleAsync === 'function') {
                x.CheckVisibleAsync((val) => {
                    this.setTabVisibility(x, val);
                });
            }
        });
    }

    private setTabVisibility(tab, visible) {
        tab.Visible = visible;
        if (tab.IsActive && !tab.Visible) {
            tab.IsActive = false;
            switch (tab.Position) {
                case MenuTabPosition.Left:
                    this.showPanelLeft(null);
                    break;
                case MenuTabPosition.Right:
                    this.showPanelRight(null);
                    break;
                case MenuTabPosition.Navi:
                    this.showNaviPanel(null)
                    break;
            }
        }
    }
    
    ComponentAttached(event) {
        if (event.instance && this.selectedEntry && this.selectedEntry.InitArgs) {
            event.instance.InitArgs = this.selectedEntry.InitArgs;
        }
    }

    PanelOver() {
        /*LayoutService.ResetHighlight.next(true);*/
    }

    // Check the visibility of settings panels content in the side navigation panel.
    checkSettingPanelVisibility(item) {
        let user = UsersService.ActiveUser.getValue();
        if (user) {
            if (user.IsAdministrator) {
                return true;
            } else {
                if (item.Security) {
                    return  PermissionHelper.GetRight(item.Security.Name, item.Security.Value);
                } else {
                    return true;
                }
            }
        }
        return false;
    }

    goToSettingArea() {
        this.router.navigate([])
            .then((result) => {
            window.open('default/settings/dashboard', '_blank');
        });
    }
}
@Directive()
export abstract class ALayoutMenuTab extends APropertyTab {
    Layout;
    ContentLayout;
    ngOnInit() {
        super.ngOnInit();
        const page = NavigationService.SelectedPage.getValue();
        if (page === 'content' || page === 'widget' || page === 'template' || page === 'output' || page === 'layout' || page === 'settings') {
            this.Subscriptions.push(LayoutService.SelectedLayout.subscribe((layout) => {
                this.ContentLayout = layout;
                this.Layout = LayoutHelper.GetActiveResolution(layout);
                this.onLayoutSet();
                this.cdRef.detectChanges();
            }));
        }
    }

    onLayoutSet() {
    }
}
