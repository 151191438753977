import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { DataPreviewInfo } from '../../../models/rest/requestparameters';
import { TranslatedString } from '../../../models/translatedstring.model';
import { DataPreviewService } from '../../../services/data.preview.service';
import { DataModelService, DataSourceQuery } from '../../../services/datamodel.service';
import { DataSourcePreviewPanel } from '../../../workflow/modules/dataoperations/datasource/datasource.settings';
import { BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import { ADataModelDetail } from '../common/adatamodeldetail';
import { DataPreviewDetail } from '../common/adatapreview.settings';
import { DataObjectTranslateDialog } from '../dataobject/dataobject.translate.dialog';
import { ContainerFieldEditDialog, ContainerFieldInitArgs } from '../dialogs/container.field.edit.dialog';
import { ContainerColumnDialog, ContainerColumnDialogArgs } from '../dialogs/containerview.column.dialog';

@Component({
    selector: 'containerview-settings',
    templateUrl: '../../base.list.settings.html'
})
export class ContainerViewSettings extends BaseListSettings {

    public static GetSettingsEntry() {
        return {
            Caption: '@@ContainerViews',
            ID: 'containerviews',
            Icon: 'table_chart',
            Index: 10,
            Parent: 'relational',
            Security: {
                Name: 'evidanza.MiddleWare.Shared.Security.RelationalContainerRight',
                Value: 4
            },
            Content: ContainerViewSettings
        };
    }

    constructor(private translate: TranslateService, private dataModelService: DataModelService,
        protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
        this.ShowDelete = false;
    }

    getContentType() {
        return ContainerViewDetail;
    }

    loadList(handler) {
        const comp = this.Component;
        if (comp) {
            comp.DataSources = [];
        }
        if (this.InitArgs) {
            this.dataModelService.GetContainersOfType(this.InitArgs.SID,
                'evidanza.MiddleWare.Shared.Container.ContainerView').subscribe((result) => {
                if (result) {
                    const list = [];
                    result.forEach((item) => {
                        list.push({
                            Caption: item.Caption,
                            ID: item.SID,
                            IsCapsule: item.IsCapsule,
                            IsOverridden: item.IsOverridden
                        });
                    });
                    handler(list);
                }
            });
            if (comp) {
                const query = new DataSourceQuery(this.InitArgs.SID);
                query.DBUsages = [[4096]]; // RelationalRead
                query.ResourceBase = 0; // Database
                this.dataModelService.GetDataSourcesByQuery(query).subscribe(sources => {
                    comp.DataSources = sources;
                });
            }
        }
    }

    loadData(data) {
        if (data) {
            this.dataModelService.GetContainer(data).subscribe((view) => {
                if (view) {
                    this.setSelectedItem(view);
                }
            });
        }
    }

    getNewItem() {
        const name = this.translate.instant('@@Neuer View');
        return {
            DataModelID: this.InitArgs.SID,
            Name: null,
            Fields: [],
            DataSourceId: null,
            Schema: null,
            SQLStatement: null,
            IsQuery: false
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie den View \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@View \'{0}\' erfolgreich geloescht');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@View loeschen');
        return retVal;
    }

    delete(data) {
        this.dataModelService.DeleteContainer(data).subscribe();
    }

    getSaveSuccessText(sel: any): SaveTexts {
        let caption = TranslatedString.GetTranslation(sel.Caption);
        if (!caption) {
            caption = sel.Name;
        }
        const retVal = new SaveTexts;
        retVal.Text = new TranslateFormatText('@@View \'{0}\' erfolgreich gespeichert');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@View speichern');
        return retVal;
    }

    saveInternal(item, handler) {
        if (item) {
            const data = Object.assign({}, item);
            if (typeof data.IsQuery !== 'boolean' || !data.IsQuery) {
                delete data.SQLStatement;
            }
            delete data.IsQuery;
            delete data.Resource;
            this.dataModelService.SaveContainer('evidanza.MiddleWare.Shared.Container.ContainerView', data).subscribe((result) => {
                if (result) {
                    handler(result, result.SID, result.Caption);
                }
            });
        }
    }

    protected handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
        item.InternalID = result.InternalID;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'containerview-detail',
    templateUrl: './containerview.settings.html',
    styleUrls: ['./containerview.settings.css']
})
export class ContainerViewDetail extends ADataModelDetail {
    DataSources = [];
    Rows = [];

    constructor(private preview: DataPreviewService, cdRef: ChangeDetectorRef) {
        super();
        this.cdRef = cdRef;
    }

    setSelectedItem(item) {
        const rows = [];
        if (item) {
            if (item.Fields) {
                item.Fields.forEach(f => {
                    const row = DataPreviewDetail.fillRow({}, f);
                    rows.push(row);
                });
            }
            if (item.SQLStatement) {
                item.IsQuery = true;
            }
        }
        this.Rows = rows;
        super.setSelectedItem(item);
    }

    addColumns() {
        const view = this.SelectedItem;
        if (view) {
            const args = new ContainerColumnDialogArgs();
            args.PreviewInfo = new DataPreviewInfo();
            args.PreviewInfo.Name = view.Name;
            args.PreviewInfo.ContainerType = 'evidanza.MiddleWare.Shared.Container.ContainerView';
            args.PreviewInfo.Options.set('Schema', view.Schema);
            args.PreviewInfo.Resource = view.SQLStatement;
            args.DataSourceId = view.DataSourceId;
            if (view.Fields) {
                args.Fields = view.Fields;
            }
            BaseDialog.ShowDialog({
                ContentType: ContainerColumnDialog,
                InitArgs: args,
                Title: '@@Spalten hinzufuegen',
                Handler: (result) => {
                    if (result && result.SelectedColumns && result.SelectedColumns.length > 0) {
                        result.SelectedColumns.forEach((col) => {
                            view.Fields.push(col);
                            const row = DataPreviewDetail.fillRow({}, col);
                            this.Rows.push(row);
                        });
                        this.OnItemChanged();
                    }
                    return true;
                }
            });
        }
    }

    editField(row) {
        const selected = this.SelectedItem;
        if (selected && selected.Fields) {
            let fieldIndex, field;
            if (selected.Fields.some((x, i) => {
                if (x.ID === row.ID) {
                    fieldIndex = i;
                    field = x;
                    return true;
                }
                return false;
            })) {
                const args = new ContainerFieldInitArgs();
                args.Field = field;
                args.Settings.IsPrimary.Visible = true;
                args.Settings.IsPrimary.Editable = true;
                args.Settings.DataTyp.Editable = true;
                args.Settings.FieldLength.Editable = true;
                args.Settings.NumericPrecision.Editable = true;
                BaseDialog.ShowDialog({
                    ContentType: ContainerFieldEditDialog,
                    InitArgs: args,
                    Title: '@@Feld bearbeiten',
                    Handler: (f) => {
                        if (f) {
                            DataPreviewDetail.fillRow(row, f);
                            selected.Fields.splice(fieldIndex, 1, f);
                            this.OnItemChanged();
                        }
                        return true;
                    }
                });
            }
        }
    }

    deleteField(row, index) {
        const selected = this.SelectedItem;
        if (selected && selected.Fields) {
            const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Feld {0} loeschen moechten?');
            text.FormatParams.push(row.Caption);
            MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Feld loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x === MessageBoxResult.Yes) {
                        let fieldIndex = -1;
                        if (selected.Fields.some((f, i) => {
                            if (f.ID === row.ID) {
                                fieldIndex = i;
                                return true;
                            }
                            return false;
                        })) {
                            selected.Fields.splice(fieldIndex, 1);
                            this.Rows.splice(index, 1);
                            this.OnItemChanged();
                        }
                    }
                });
        }
    }
    Initialize(InitArgs) {
        if (InitArgs) {
            this.DataSources = InitArgs.DataSources;
            this.setSelectedItem(InitArgs.Data);
            if (InitArgs.LoadFields) {
                this.InitColumns();
            }
        }
    }

    InitColumns() {
        if (this.SelectedItem) {
            const view = this.SelectedItem;
            const args = new ContainerColumnDialogArgs();
            args.PreviewInfo = new DataPreviewInfo();
            args.PreviewInfo.Name = view.Name;
            args.PreviewInfo.ContainerType = 'evidanza.MiddleWare.Shared.Container.ContainerView';
            args.PreviewInfo.Options.set('Schema', view.Schema);
            args.PreviewInfo.Resource = view.SQLStatement;
            args.DataSourceId = view.DataSourceId;
            this.preview.GetStructureWithOptions(args.DataSourceId, args.PreviewInfo).subscribe((result) => {
                let AllColumns = [];
                if (result) {
                    if (result.Structure) {
                        result.Structure.forEach(function (col) {
                            col.ColSelected = true;
                            col.ColVisible = true;
                        });
                        AllColumns = result.Structure;
                    }
                    AllColumns.forEach((col) => {
                        view.Fields.push(col);
                        const row = DataPreviewDetail.fillRow({}, col);
                        this.Rows.push(row);
                    });
                }
                this.cdRef.detectChanges();
            });
        }
    }

    GetDialogResult() {
        return this.SelectedItem;
    }

    showPreview() {
        if (this.SelectedItem && this.SelectedItem.SID) {
            DataSourcePreviewPanel.ShowPreviewForContainer(this.SelectedItem.SID, '@@Vorschau');
        }
    }

    translateFields() {
        const selected = this.SelectedItem;
        if (selected && selected.Fields) {
            DataObjectTranslateDialog.ShowDialog(selected, () => {
                this.Rows.forEach(row => {
                    const field = selected.Fields.find(x => x.ID == row.ID);
                    if (field) {
                        row.Caption = TranslatedString.GetTranslation(field.Caption);
                    }
                });
                this.OnItemChanged();
            });
        }
    }
}
