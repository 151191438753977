import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { UnitSelectionControl } from '../../../appbuilder/controls/unittypeselection/unitselection.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { LayoutUnit } from '../../../models/basic/layoutunit.model';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ElementProperty } from '../../../models/layoutbase.model';
import { MenuTabLabelPosition } from '../../../models/menutab/menutab.property.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { MediaService } from '../../../services/media.service';
import { IBaseComponent } from '../base.component';

@Component({
    selector: 'evi-icon',
    templateUrl: './icon.control.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconControl extends IBaseComponent {

    static Type: any = 'icon';
    static Default = { Type: 'icon',Layout: {
        _Editable: true,
    } };

    IconValue;
    get Icon() {
        if (this.IconValue) {
            return this.IconValue;
        }
        return this.LayoutElement.Icon;
    }
    set Icon(val) {
        this.IconValue = val;
        this.cdRef.detectChanges();
    }

    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.PropertyList.push(new ElementProperty('Icon', 'string', '@@Icon'));
    }

    ControlInitialized() {
    }
}
export class IconPanel extends BasePanel {
    static override SIDS = ['65886290-d5dc-4f8c-98fd-d31208bf4cb1']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'65886290-d5dc-4f8c-98fd-d31208bf4cb1',
            ID: 'icon',
            Caption: '@@Icon',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'icon' || item.ElementType == 'usermenu' || item.ElementType == 'sidenav-toggle' ;
            }
        })
        MediaService.IconList.subscribe((icons) => {
            if (icons) {
                let Icons = [];
                icons.forEach((icon) => {
                    Icons.push({ Value: icon })
                })
                PROPERTIES.push({
                    ID: "Icon",
                    Parent: "icon",
                    Content: new ComponentPortal(ComboboxThemeControl),
                    Label: "@@Icon",
                    InitArgs: {
                        Placeholder: "@@Select",
                        Multiple: false,
                        ItemsSource: Icons,
                        DisplayMemberPath: 'Value',
                        ValueMemberPath: 'Value',
                        IconMemberPath: 'Value'
                    }
                })
                PROPERTIES.push({
                    ID: "IconHeight",
                    Parent: "icon",
                    Content: new ComponentPortal(UnitSelectionControl),
                    Type: LayoutUnit,
                    Label: "@@IconHeight",
                    LabelPosition: MenuTabLabelPosition.Top,
                    CheckVisibility: (item) => {
                        return item.Icon;
                    },
                    InitArgs: {
                        Minimum: 0
                    }
                });
                PROPERTIES.push({
                    ID: "IconWidth",
                    Parent: "icon",
                    Content: new ComponentPortal(UnitSelectionControl),
                    Type: LayoutUnit,
                    Label: "@@IconWidth",
                    LabelPosition: MenuTabLabelPosition.Top,
                    CheckVisibility: (item) => {
                        return item.Icon;
                    },
                    InitArgs: {
                        Minimum: 0
                    }
                });

            }
        });
    }
}