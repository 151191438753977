import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { InputswitchThemeControl } from '../../../appbuilder/controls/inputswitch/inputswitch.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { CacheService } from '../../../cache/cache.service';
import { ArrayHelpers } from '../../../helpers/array.helpers';
import { EnumHelper } from '../../../helpers/enum.helper';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { TagType } from '../../../models/enums/tagtype.enum';
import { MultiPropertyChangeValue } from '../../../models/layout/layout.change.model';
import { ElementProperty } from '../../../models/layoutbase.model';
import { MenuTabLabelPosition } from '../../../models/menutab/menutab.property.model';
import { TranslatedString } from '../../../models/translatedstring.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LanguageService } from '../../../services/language.service';
import { LayoutService } from '../../../services/layout.service';
import { MetaService } from '../../../services/meta.service';
import { IBaseComponent } from '../base.component';

@Component({
    selector: 'evi-href',
    templateUrl: './href.control.html',
    styleUrls: ['./href.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HrefControl extends IBaseComponent {
    static Type: any = 'href';
    static Default = { Type: 'href', Caption: 'Text', Layout: {
        _Editable: true,
    } };

    DataSourceText = '';
    DataBindingField;

    get URL() {
        if (this.LayoutElement && this.LayoutElement.NavigationStructure && this.LayoutElement.URL) {
            return '/' + this.LayoutElement.NavigationStructure + '/' + this.LayoutElement.URL;
        } else {
            return null;
        }
    }

    //#region Caption
    CaptionValue: string;
    @Input()
    get Caption() {
        if (this.CaptionValue != null) {
            return this.CaptionValue;
        }
        return this.LayoutElement.Caption;
    }
    set Caption(val) {
        this.CaptionValue = val;
    }
    //#endregion
    //#region Tooltip
    TooltipValue;
    @Input()
    get Tooltip() {
        if (this.TooltipValue != null) {
            return this.TooltipValue;
        }
        return this.LayoutElement.Tooltip;
    }
    set Tooltip(val) {
        this.TooltipValue = val;
        this.cdRef.detectChanges();
    }
    //#endregion
    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.PropertyList.push(new ElementProperty('Caption', 'string', '@@Text'));
        this.PropertyList.push(new ElementProperty('Tooltip', 'string', '@@Tooltip'));
    }

    ControlInitialized() {
        this.Subscriptions['DataSourceChange'] = this.DataSourceChange.subscribe(x => {
            this.CheckDataSourceText();
        });
        this.Subscriptions['LanguageChanged'] = LanguageService.SelectedLanguage.subscribe(() => {
            this.CheckDataSourceText();
        });
        this.OnDataBindingChanged();
    }

    OnDataBindingChanged() {
        super.OnDataBindingChanged();
        MetaHelper.FindTableProperties(this.Layout, this.LayoutElementValue).then((result) => {
            if (result && result.SID !== this.LayoutElementValue.DataSource && result.Fields) {
                this.DataBindingField = result.Fields.find(x => x.ID === this.LayoutElementValue.DataSource);
                this.CheckDataSourceText();
            } else {
                this.DataBindingField = null;
            }
        });
    }

    CheckDataSourceText() {
        if (this.DataBindingField && this.DataSourceValue) {
            if (this.DataBindingField.Type === '876cf188-243b-49ed-91ab-b2cf27216a30') {
                EnumHelper.GetEnumValues(this.LayoutElementValue.DataSourceID).then((data) => {
                    let enumEntry;
                    if (data) {
                        enumEntry = data.find(x => x.Value === this.DataSourceValue);
                    }
                    if (enumEntry) {
                        this.DataSourceText = enumEntry.Caption;
                    } else {
                        this.DataSourceText = '' + this.DataSourceValue;
                    }
                    this.cdRef.detectChanges();
                });
                return;
            } else if (this.DataBindingField.Type === 'a0c232a9-4ab7-444c-a7e2-d23899a5673b') {
                this.DataSourceText = TranslatedString.GetTranslation(this.DataSourceValue);
            } else if (this.DataBindingField.Type === 'cfcb4295-9d1e-49b4-b466-3fe74d1b0c69' && this.LayoutElementValue.DateFormat) {
                this.DataSourceText = moment(this.DataSourceValue).format(this.LayoutElementValue.DateFormat);
            } else {
                this.DataSourceText = '' + this.DataSourceValue;
            }
        } else {
            this.DataSourceText = '';
        }
        this.cdRef.detectChanges();
    }
}
export class HrefPanel extends BasePanel {
    static override SIDS = ['0efddb24-27fe-4a75-85fd-02fa3edd7572']
    public static PagesSub: BehaviorSubject<any> = new BehaviorSubject(null)
    static override SetSelectedItem(item) {
        if (item && item.InternalNavigation) {
            if (item.NavigationStructure) {
                HrefPanel.FetchPages(item.NavigationStructure);
            }
        }
       
    }
    private static FetchPages(id) {
        let metaService = InjectorHelper.InjectorInstance.get<MetaService>(MetaService);
        metaService.GetNavigationNodesForStructure(id).subscribe((pages) => {
             if (pages) {
                const pageList = [];
                pages.forEach(page => {
                    pageList.push({
                        Caption: page.Caption?page.Caption.DefaultValue:page.Caption,
                        URL: page.Url
                    });
                });
                ArrayHelpers.sortAlphabetical(pageList, 'Caption');
                HrefPanel.PagesSub.next(pageList)
            }
        })
    }
    static InitPanel() {
        HrefPanel.InitSelectedItem();
        PROPERTYGROUPS.push({
            SID: '0efddb24-27fe-4a75-85fd-02fa3edd7572',
            ID: 'href',
            Caption: '@@Href',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'href';
            }
        })
        PROPERTIES.push({
            ID: "Caption",
            Parent: "href",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Text",
            Row: 1,
            InitArgs: {
                DataType: 'string'
            }
        });
        PROPERTIES.push({
            ID: "Tooltip",
            Parent: "href",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Tooltip",
            Row: 2,
            InitArgs: {
                DataType: 'string'
            }
        });
        PROPERTIES.push({
            ID: "InternalNavigation",
            Parent: "href",
            Content: new ComponentPortal(InputswitchThemeControl),
            Label: "@@InternalNavigation",
            LabelPosition: MenuTabLabelPosition.Right,
            Row: 3,
            InitArgs: {
                Action: (action) => {
                    if (!action.Event) {
                        delete HrefPanel.SelectedItem.NavigationStructure;
                        delete HrefPanel.SelectedItem.URL;
                        const mlpcv = new MultiPropertyChangeValue();
                        mlpcv.ElementIDs = [HrefPanel.SelectedItem.ID],
                            ['NavigationStructure', 'URL'].forEach(x => {
                                mlpcv.Properties.push({
                                    PropertyName: x,
                                    Value: null
                                });
                            });
                        LayoutService.OnMultiLayoutPropertyChanged([mlpcv]);
                        LayoutService.LayoutChanged.next(null);
                    }
                }
            },
        });
        PROPERTIES.push({
            ID: "URL",
            Parent: "href",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Url",
            Row: 4,
            InitArgs: {
                InputType: "text"
            },
            CheckVisibility: (item) => {
                return !item.InternalNavigation;
            }
        });
        let metaService = InjectorHelper.InjectorInstance.get<MetaService>(MetaService);
        metaService.GetNavigationStructures().subscribe(structures => {
            if (structures) {
                let navi = [];
                structures.forEach(page => {
                    navi.push({
                        Caption: page.Caption,
                        ID: page.SID
                    });
                });
                PROPERTIES.push({
                    ID: "NavigationStructure",
                    Parent: "href",
                    Content: new ComponentPortal(ComboboxThemeControl),
                    Label: "@@NavigationStructure",
                    Row: 5,
                    InitArgs: {
                        Caption: '@@Select',
                        ItemsSource: navi,
                        ValueMemberPath: 'Caption',
                        DisplayMemberPath: 'Caption',
                        Action: (action) => {
                            if (action && action.SelectedItem && action.SelectedItem.NavigationStructure) {
                                HrefPanel.FetchPages(action.SelectedItem.NavigationStructure);
                            }
                        }
                    },
                    CheckVisibility: (item) => {
                        return item.InternalNavigation;
                    }
                });
                PROPERTIES.push({
                    ID: "URL",
                    Parent: "href",
                    Content: new ComponentPortal(ComboboxThemeControl),
                    Label: "@@URL",
                    Row: 6,
                    InitArgs: {
                        Caption: '@@Select',
                        ItemsSourceSub: HrefPanel.PagesSub,
                        ValueMemberPath: 'URL',
                        DisplayMemberPath: 'Caption',
                        Action: (item) => {

                        }
                    },
                    CheckVisibility: (item) => {
                        return item.NavigationStructure;
                    }
                });
            }
        });
        
        PROPERTIES.push({
            ID: "DateFormat",
            Parent: "href",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@DateFormat",
            Row: 10,
            CheckVisibility: (item) => {
                return item.DataSourceType == 'cfcb4295-9d1e-49b4-b466-3fe74d1b0c69'
            },
            InitArgs: {
                InputType: "text"
            }
        });
        PROPERTIES.push({
            ID: "BreakLine",
            Parent: "href",
            Content: new ComponentPortal(InputswitchThemeControl),
            Label: "@@Zeilenumbruch",
            LabelPosition: MenuTabLabelPosition.Right,
            Row: 11
        });
    }
}