import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ArrayHelpers, TranslateFormatText } from '../../helpers/array.helpers';
import { StandardRequestBase } from '../../services/request-base';
import { RolesService } from '../../services/roles.service';
import { UsersService } from '../../services/users.service';
import { DataCheck } from '../../workflow/workflow.dialog';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';
import {NotificationHelper} from "../../helpers/notification.helper";
import {LayoutService} from "../../services/layout.service";

@Component({
    selector: 'groups-settings',
    templateUrl: '../base.list.settings.html'
})
export class GroupsSettings extends BaseListSettings {

    constructor(private translate: TranslateService, private standardService: StandardRequestBase,
        protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
    }

    getContentType() {
        return GroupDetail;
    }

    loadList(handler) {
        this.standardService.executeGet('config/api/usergroups', 'GetGroups').subscribe(result => {
            if (result) {
                var list = [];
                result.forEach(function (entry) {
                    list.push({
                        Caption: entry.Name,
                        ID: entry.Id
                    });
                });
                handler(list);
            }
        });
    }

    loadData(data) {
        this.standardService.executeGet('config/api/usergroups', 'GetGroup', 'id=' + data).subscribe(result => {
            if (result) {
                this.setSelectedItem(result);
            }
        });
    }

    getNewItem() {
        return {
            Name: this.translate.instant('@@Neue Gruppe'),
            Users: [],
            Groups: [],
            BoundGroups: [],
            Disabled: false,
            Roles: []
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie die Gruppe \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Gruppe \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Gruppe loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        //const retVal = new TranslateFormatText('@@Gruppe \'{0}\' erfolgreich gespeichert.');
        //retVal.FormatParams.push(sel.Name);
        //return retVal;
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Gruppe \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Gruppe speichern');
        return retVal;
    }


    delete(data, handler) {
        this.standardService.executeDelete('config/api/usergroups', 'RemoveGroup', 'id=' + data).subscribe((res) => {
            handler(res);
        });
    }

    saveInternal(item, handler) {

        if (item?.Name.trim() == '') {
            NotificationHelper.Error('Name field is required', '@@Error');
            LayoutService.Loading.next(false);
            return;
        }

        this.standardService.executePost(item, 'config/api/usergroups', 'AddOrUpdateGroup').subscribe(result => {
            if (result) {
                handler(result, result, item.Name);
            }
        });
    }

    handleNew(item, result) {
        item.SID = result;
    }

    protected async checkCanSave(): Promise<DataCheck> {
        var retVal = new DataCheck();
        var sel = this.Component.SelectedItem;
        if (sel) {
            if (typeof sel.Name !== 'string' || sel.Name == '') {
                retVal.IsCorrect = false;
                retVal.Error = this.translate.instant('@@Fehlender Name');
            }
        }
        return retVal;
    }

    static GetSettingsEntry() {
        return {
            Caption: '@@Gruppen',
            ID: 'groups',
            Icon: 'people',
            Index: 1,
            Parent: 'userCont',
            Security: {
                Name: 'evidanza.App.Shared.Security.UsermanagementRight',
                Value: 4
            },
            Content: GroupsSettings
        }
    }
}

@Component({
    selector: 'groups-detail',
    templateUrl: './groups.settings.html',
    styleUrls: ['./groups.settings.css']
})
export class GroupDetail extends BaseListDetail {

    constructor(private userService: UsersService, private rolesService: RolesService) {
        super();
    }

    ngOnInit(): void {
        this.userService.ReadUserInfos().subscribe(result => {
            if (result) {
                let userData = result.map((res)=>{
                    if(res?.Name?.indexOf("@")){
                        res.Name = res.Name?.split("@")[0]
                    }
                    return res
                })
                ArrayHelpers.sortAlphabetical(userData, 'Name');
                this.Users = userData;
                this.FilteredUsers = this.FilterUsers(this._UserSearchValue, this._UserSelectedOnly);
            }
        });
        this.rolesService.ReadRoleInfos().subscribe(result => {
            if (result) {
                ArrayHelpers.sortAlphabetical(result, 'Name');
                this.Roles = result;
                this.FilteredRoles = this.FilterRoles(this._RoleSearchValue, this._RolesSelectedOnly);
            }
        });
    }

    //#region Users
    Users = [];
    FilteredUsers = [];

    _UserSearchValue;
    get UserSearchValue() {
        return this._UserSearchValue;
    }
    set UserSearchValue(value) {
        this._UserSearchValue = value;
        this.FilteredUsers = this.FilterUsers(this._UserSearchValue, this._UserSelectedOnly);
    }

    _UserSelectedOnly;
    get UserSelectedOnly() {
        return this._UserSelectedOnly;
    }
    set UserSelectedOnly(value) {
        this._UserSelectedOnly = value;
        this.FilteredUsers = this.FilterUsers(this._UserSearchValue, this._UserSelectedOnly);
    }

    FilterUsers(value, selectedOnly) {
        if (value) {
            if (selectedOnly == true && (!this.SelectedItem || !this.SelectedItem.Users || this.SelectedItem.Users.length == 0)) {
                return [];
            }
            value = value.toLocaleLowerCase();
            return this.Users.filter((roles: any) => {
                return (!value || (value && roles.Name.toLocaleLowerCase().indexOf(value) !== -1)) &&
                    (!selectedOnly || (selectedOnly && this.SelectedItem.Users.indexOf(roles.Id) > -1));
            });
        } else {
            if (selectedOnly == true) {
                if (this.SelectedItem && this.SelectedItem.Users && this.SelectedItem.Users.length > 0) {
                    return this.Users.filter((roles: any) => {
                        return this.SelectedItem.Users.indexOf(roles.Id) > -1;
                    });
                } else {
                    return [];
                }
            } else {
                return this.Users;
            }
        }
    }

    onUserSelectionChange(ev) {
        if (this.SelectedItem && ev && ev.options) {
            let doFilter = false;
            ev.options.forEach(option => {
                if (option.value) {
                    if (option.selected) {
                        if (this.SelectedItem.Users) {
                            this.SelectedItem.Users.push(option.value.Id);
                        } else {
                            this.SelectedItem.Users = [option.value.Id];
                        }
                    } else {
                        if (this.SelectedItem.Users) {
                            const index = this.SelectedItem.Users.indexOf(option.value.Id);
                            if (index >= 0 && index < this.SelectedItem.Users.length) {
                                this.SelectedItem.Users.splice(index, 1);
                                if (this._UserSelectedOnly == true) {
                                    doFilter = true;
                                }
                            }
                        }
                    }
                }
            });
            if (doFilter) {
                this.FilteredUsers = this.FilterUsers(this._UserSearchValue, this._UserSelectedOnly);
            }
            this.OnItemChanged();
        }
    }

    checkUserSelection(data) {
        if (data && this.SelectedItem && this.SelectedItem.Users) {
            return this.SelectedItem.Users.indexOf(data.Id) > -1;
        }
        return false;
    }
    //#endregion


    //#region Roles
    Roles = [];
    FilteredRoles = [];

    _RoleSearchValue;
    get RoleSearchValue() {
        return this._RoleSearchValue;
    }
    set RoleSearchValue(value) {
        this._RoleSearchValue = value;
        this.FilteredRoles = this.FilterRoles(this._RoleSearchValue, this._RolesSelectedOnly);
    }

    _RolesSelectedOnly;
    get RolesSelectedOnly() {
        return this._RolesSelectedOnly;
    }
    set RolesSelectedOnly(value) {
        this._RolesSelectedOnly = value;
        this.FilteredRoles = this.FilterRoles(this._RoleSearchValue, this._RolesSelectedOnly);
    }

    FilterRoles(value, selectedOnly) {
        if (value) {
            if (selectedOnly == true && (!this.SelectedItem || !this.SelectedItem.Roles || this.SelectedItem.Roles.length == 0)) {
                return [];
            }
            value = value.toLocaleLowerCase();
            return this.Roles.filter((roles: any) => {
                return (!value || (value && roles.Name.toLocaleLowerCase().indexOf(value) !== -1)) &&
                    (!selectedOnly || (selectedOnly && this.SelectedItem.Roles.indexOf(roles.Id) > -1));
            });
        } else {
            if (selectedOnly == true) {
                if (this.SelectedItem && this.SelectedItem.Roles && this.SelectedItem.Roles.length > 0) {
                    return this.Roles.filter((roles: any) => {
                        return this.SelectedItem.Roles.indexOf(roles.Id) > -1;
                    });
                } else {
                    return [];
                }
            } else {
                return this.Roles;
            }
        }
    }

    onRoleSelectionChange(ev) {
        if (this.SelectedItem && ev && ev.options) {
            let doFilter = false;
            ev.options.forEach(option => {
                if (option.value) {
                    if (option.selected) {
                        if (this.SelectedItem.Roles) {
                            this.SelectedItem.Roles.push(option.value.Id);
                        } else {
                            this.SelectedItem.Roles = [option.value.Id];
                        }
                    } else {
                        if (this.SelectedItem.Roles) {
                            const index = this.SelectedItem.Roles.indexOf(option.value.Id);
                            if (index >= 0 && index < this.SelectedItem.Roles.length) {
                                this.SelectedItem.Roles.splice(index, 1);
                                if (this._RolesSelectedOnly == true) {
                                    doFilter = true;
                                }
                            }
                        }
                    }
                }
            });
            if (doFilter) {
                this.FilteredRoles = this.FilterRoles(this._RoleSearchValue, this._RolesSelectedOnly);
            }
            this.OnItemChanged();
        }
    }

    checkRoleSelection(data) {
        if (data && this.SelectedItem && this.SelectedItem.Roles) {
            return this.SelectedItem.Roles.indexOf(data.Id) > -1;
        }
        return false;
    }
    //#endregion
}