import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageBoxHelper } from '../../components/dialogs/messagebox/messagebox.dialog';
import { ArrayHelpers, TranslateFormatText } from '../../helpers/array.helpers';
import { TranslateHelper } from '../../helpers/injector.helper';
import { NotificationHelper } from '../../helpers/notification.helper';
import { MessageBoxButtons } from '../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../models/enums/messageboxresult.enum';
import { Registry } from '../../script/modules/registry.script';
import { LayoutService } from '../../services/layout.service';
import { ScriptLibraryService } from '../../services/scriptlibrary.service';
import { SideNavService } from '../../services/sidenav.service';
import { UsersService } from '../../services/users.service';
import { DataCheck } from '../../workflow/workflow.dialog';
import { VersioningOverview } from '../versioningoverview/versioning.overview';

@Component({
    selector: 'script-library-version-settings',
    templateUrl: './scriptlibrary.version.settings.html',
    styleUrls: ['./scriptlibrary.version.settings.css']
})
export class ScriptLibraryVersionSettings implements OnInit, OnDestroy {
    ListItems = [];
    FilteredItems = [];
    selectedNode;
    HasChanges = false;
    SearchValue: string;
    SelectedItem;
    editor;
    EditMode = false;
    ActiveUser;
    UserSub;

    toggleWindow = true;
    static GetSettingsEntry() {
        return {
            Caption: '@@ScriptLibrary',
            ID: 'scriptlibrary',
            Icon: 'code',
            Index: 30,
            Parent: 'data',
            Security: {
                Name: 'evidanza.App.Shared.Security.DataRight',
                Value: 2048
            },
            Content: ScriptLibraryVersionSettings
        };
    }

    constructor(private cdRef: ChangeDetectorRef, private service: ScriptLibraryService, private router: Router) {
        Registry.initializeRegistry(service);
    }

    ngOnInit(): void {
        this.refresh();
        this.UserSub = UsersService.ActiveUser.subscribe(x => {
            this.ActiveUser = x;
        });
    }

    ngOnDestroy(): void {
        if (this.UserSub) {
            this.UserSub.unsubscribe();
            this.UserSub = null;
        }
    }

    ToggleWindow() {
        this.setHasChanges(false);
        this.toggleWindow = !this.toggleWindow;
    }

    onItemClick(selection) {
        if (this.selectedNode !== selection) {
            this.checkHasChanges().then(check => {
                if (check) {
                    this.selectedNode = selection;
                    this.setSelectedItem(null);
                    if (selection && selection.SID) {
                        this.service.LoadByID(selection.SID).subscribe((result) => {
                            if (result) {
                                this.setSelectedItem(result);
                                this.EditMode = this.ActiveUser && selection.CanEditInfo && selection.CanEditInfo.EditUserID == this.ActiveUser.SID;
                            }
                        });
                    }
                }
            });
        }
    }

    setSelectedItem(item) {
        this.EditMode = false;
        this.SelectedItem = item;
        this.cdRef.detectChanges();
    }

    checkHasChanges() {
        return new Promise<boolean>(resolve => {
            if (this.HasChanges) {
                if (this.selectedNode && this.selectedNode.CanEditInfo && !this.selectedNode.CanEditInfo.CanEdit) {
                    resolve(true);
                } else {
                    MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Wollen Sie die Aenderungen speichern?'),
                        new TranslateFormatText('@@Frage'), MessageBoxButtons.YesNoAbort, MessageBoxIcon.Question).then(retVal => {
                            if (retVal === MessageBoxResult.Yes) {
                                if (this.EditMode) {
                                    this.save(false).then(x => {
                                        resolve(x);
                                    });
                                } else {
                                    this.setEditMode(false).then(x => {
                                        resolve(x);
                                    });
                                }
                            } else if (retVal === MessageBoxResult.Abort) {
                                resolve(false);
                            } else { // No
                                this.setHasChanges(false);
                                resolve(true);
                            }
                        });
                }
            } else {
                resolve(true);
            }
        });
    }

    save(fromSaveButton: boolean): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            LayoutService.Loading.next(true);
            this.checkCanSave().then(dc => {
                if (dc.IsCorrect) {
                    const sel = this.SelectedItem;
                    if(sel?.Name.trim() == '') {
                        NotificationHelper.Error('Name field is required', '@@Error');
                        LayoutService.Loading.next(false);
                        return;
                    }
                    if (sel) {
                        this.service.SaveScriptToChangeLog(sel).subscribe((result) => {
                            if (result) {
                                this.setHasChanges(false);
                                const text = new TranslateFormatText('@@Script \'{0}\' erfolgreich gespeichert.');
                                text.FormatParams.push(result.Caption);
                                NotificationHelper.Success(text, new TranslateFormatText('@@Script speichern'));
                                let sort = false;
                                let index = -1;
                                if (this.ListItems.some((y, i) => {
                                    if (result.SID === y.SID) {
                                        index = i;
                                        sort = y.Caption != result.Caption;
                                        return true;
                                    }
                                    return false;
                                })) {
                                    this.ListItems.splice(index, 1, result);
                                } else {
                                    sort = true;
                                    sel.SID = result.SID;
                                    sel.Version = result.Version;
                                    this.ListItems.push(result);
                                }
                                this.checkItemStyle(result);
                                if (sort) {
                                    ArrayHelpers.sortAlphabetical(this.ListItems, 'Caption');
                                }
                                this.UpdateFiltered();
                                if (fromSaveButton) {
                                    this.selectedNode = result;
                                }
                            } else {
                                NotificationHelper.Error(new TranslateFormatText('@@AlreadySavedByAdmin'),
                                    new TranslateFormatText('@@Script speichern'));
                            }
                            LayoutService.Loading.next(false);
                        });
                    } else {
                        LayoutService.Loading.next(false);
                    }
                } else {
                    const t = '@@Aenderungen konnten nicht gespeichert werden. Bitte ueberpruefen Sie folgende Einstellungen:\n{0}';
                    const text = new TranslateFormatText(t);
                    text.FormatParams.push(dc.Error);
                    MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Warnung'), MessageBoxButtons.Ok, MessageBoxIcon.Warning);
                    LayoutService.Loading.next(false);
                }
                resolve(dc.IsCorrect);
            });
        });
    }

    checkCanSave(): Promise<DataCheck> {
        return new Promise((resolve) => {
            if (this.editor) {
                this.editor.getAction('editor.action.formatDocument').run().then(() => {
                    resolve(new DataCheck());
                });
            } else {
                resolve(new DataCheck());
            }
        });
    }

    UpdateFiltered() {
        let list = [];
        if (this.ListItems) {
            if (this.SearchValue) {
                const toLower = this.SearchValue.toLowerCase();
                list = this.ListItems.filter(x => x.Caption.toLowerCase().indexOf(toLower) > -1);
            } else {
                list.push(...this.ListItems);
            }
        }
        this.FilteredItems = list;
    }

    addItem() {
        this.checkHasChanges().then(check => {
            if (check) {
                this.selectedNode = null;
                this.setSelectedItem(null);
                this.setSelectedItem({
                    Name: TranslateHelper.TranslatorInstance.instant('@@Neues Script'),
                    Text: '',
                    IsServerScript: true
                });
                this.EditMode = true;
            }
        });
    }

    refresh() {
        this.checkHasChanges().then(check => {
            if (check) {
                LayoutService.Loading.next(true);
                this.SearchValue = null;
                this.EditMode = false;
                this.ListItems = [];
                this.FilteredItems = [];
                this.selectedNode = null;
                this.setSelectedItem(null);
                this.HasChanges = false;
                this.service.Get(null).subscribe((result) => {
                    if (result) {
                        result.forEach(x => this.checkItemStyle(x));
                        ArrayHelpers.sortAlphabetical(result, 'Caption');
                        this.ListItems = result;
                        this.UpdateFiltered();
                    }
                    LayoutService.Loading.next(false);
                    this.cdRef.detectChanges();
                });
            }
        });
    }

    checkItemStyle(data) {
        if (data) {
            const style = {};
            if (data.IsCapsule) {
                style['font-style'] = 'italic';
            }
            if (data.IsOverridden) {
                style['font-weight'] = 'bold';
            }
            if (data.CanEditInfo) {
                if (data.CanEditInfo.CanEdit) {
                    if (this.ActiveUser && data.CanEditInfo.EditUserID == this.ActiveUser.SID) {
                        style['text-decoration'] = 'underline';
                    }
                } else {
                    style['text-decoration'] = 'line-through';
                }
            }
            data.Style = style;
        }
    }

    setHasChanges(hasChanges) {
        if (typeof hasChanges === 'boolean') {
            this.HasChanges = hasChanges;
            LayoutService.PreventBrowserClose.next(this.HasChanges);
        }
    }

    EditorInit(editor) {
        this.editor = editor;
    }

    setEditMode(fromButton) {
        return new Promise<boolean>(resolve => {
            if (this.SelectedItem) {
                this.service.RequestScriptLock(this.SelectedItem).subscribe(x => {
                    if (x && x.CanEditInfo) {
                        let sort = false;
                        let index = -1;
                        if (this.ListItems.some((y, i) => {
                            if (x.SID === y.SID) {
                                index = i;
                                sort = y.Caption != x.Caption;
                                return true;
                            }
                            return false;
                        })) {
                            this.ListItems.splice(index, 1, x);
                            this.checkItemStyle(x);
                            if (sort) {
                                ArrayHelpers.sortAlphabetical(this.ListItems, 'Caption');
                            }
                            this.UpdateFiltered();
                            if (fromButton) {
                                this.selectedNode = x;
                            }
                        }
                        if (x.CanEditInfo.CanEdit) {
                            this.EditMode = true;
                            this.setHasChanges(false);
                            this.cdRef.detectChanges();
                            resolve(true);
                        } else {
                            const text = new TranslateFormatText(x.CanEditInfo.NewVersion ? '@@AlreadySavedBy{0}' : '@@LockedBy{0}');
                            text.FormatParams.push(x.CanEditInfo.EditUserName);
                            MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@LockedObject'),
                                MessageBoxButtons.Ok, MessageBoxIcon.Information);
                            resolve(false);
                        }
                    } else {
                        resolve(true);
                    }
                });
            } else {
                resolve(true);
            }
        });
    }

    async deleteItem(item?: any) {
        if (item) {
            await this.onItemClick(item)
        }
        const sel = this.selectedNode;
        if (sel && !this.EditMode) {
            if (sel.CanEditInfo && !sel.CanEditInfo.CanEdit) {
                return;
            }
            const tft = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Script \'{0}\' loeschen moechten?');
            tft.FormatParams.push(sel.Caption);
            MessageBoxHelper.ShowDialog(tft, new TranslateFormatText('@@Script loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x == MessageBoxResult.Yes) {
                        this.deleteItemInternal(sel, false);
                    }
                });
        }
    }

    private deleteItemInternal(sel, ignoreVersions: boolean) {
        this.service.TryDeleteScript(sel.SID, ignoreVersions).subscribe(result => {
            if (result) {
                if (result.Deleted) {
                    this.setHasChanges(false);
                    this.refresh();
                    const success = new TranslateFormatText('@@Script \'{0}\' erfolgreich geloescht.');
                    success.FormatParams.push(sel.Caption);
                    NotificationHelper.Success(success, '@@Script loeschen');
                } else if (result.IsLocked) {
                    const text = new TranslateFormatText('@@LockedBy{0}');
                    text.FormatParams.push(result.LockedBy);
                    MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@LockedObject'),
                        MessageBoxButtons.Ok, MessageBoxIcon.Information);
                } else if (result.MultipleVersions) {
                    const text = new TranslateFormatText('@@MultipleVersionsInfoText');
                    text.FormatParams.push(result.LockedBy);
                    MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@MultipleVersionsInfoHeader'),
                        MessageBoxButtons.None, MessageBoxIcon.Information, 500, null, [
                        { Caption: '@@DeleteAllVersions', ID: 0 },
                        { Caption: '@@GoToVersionsOverview', ID: 1 },
                        { Caption: '@@Cancel', ID: 2 }
                    ]).then(result => {
                        if (result == 0) {
                            this.deleteItemInternal(sel, true);
                        } else if (result == 1) {
                            const navStruct = SideNavService.SelectedNavigationStructure.getValue();
                            if (navStruct) {
                                this.router.navigateByUrl(navStruct + VersioningOverview.Path);
                            }
                        }
                    });
                }
            }
        });
    }
}
