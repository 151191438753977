import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NetworkConnection } from '../helpers/network.helpers';
import { OfflineStrategy } from '../models/enums/offlinestrategy.enum';
import { OfflineClient } from '../offline/offline.client';
import { SettingsService } from './settings.service';

@Injectable()
export class RequestBase {
    API_BASE_URL = SettingsService.API_BASE_URL.getValue();
    headers = new HttpHeaders();
    noPreFlightHeaders = new HttpHeaders();
    online = true;
    options = {
        headers: this.headers
    };
    optionsNoPre = {
        headers: this.noPreFlightHeaders,
        withCredentials: true
    };
    private _httpClient;
    private _offlineClient;
    public get http(): HttpClient {
        let client = this._httpClient;
        let settings: any = localStorage.getItem("GeneralSettings");
        if (settings) {
            settings = JSON.parse(settings);
        }
        if (settings != null) {
            if (settings.Offline && settings.Offline.Active) {
                switch (settings.Offline.Strategy) {
                    case OfflineStrategy.CacheFirst:
                    case OfflineStrategy.CacheOnly:
                    case OfflineStrategy.NetworkFirst:
                        if (NetworkConnection.Online.getValue()) {
                            client = this._httpClient;
                        } else {
                            client = this._offlineClient;
                        }
                        break;
                    case OfflineStrategy.NetworkOnly:
                        client = this._httpClient;
                        break;
                }
            } else {
                client = this._httpClient;
            }
        } else {
            client = this._httpClient;
        }
        return client;
    }
    constructor(private httpClient: HttpClient) {
        this.headers = this.headers.append('Content-Type', 'application/json');
        this.options.headers = this.headers;
        this.noPreFlightHeaders = this.noPreFlightHeaders.append('Content-Type', 'text/plain');
        SettingsService.API_BASE_URL.subscribe((url) => {
            this.API_BASE_URL = url;
        });
        this._httpClient = httpClient;
        this._offlineClient = new OfflineClient(null);
    }

    public handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            console.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}

@Injectable()
export class StandardRequestBase extends RequestBase {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    executeGet(path: string, func: string, params?: string): Observable<any> {
        if (params) {
            return this.http.get<any>(this.API_BASE_URL + path + '/' + func + '?' + params, this.options);
        } else {
            return this.http.get<any>(this.API_BASE_URL + path + '/' + func, this.options);
        }
    }

    executePost(data: any, path: string, func: string, params?: string): Observable<any> {
        if (params) {
            return this.http.post<any>(this.API_BASE_URL + path + '/' + func + '?' + params, data, this.options);
        } else {
            return this.http.post<any>(this.API_BASE_URL + path + '/' + func, data, this.options);
        }
    }

    executePut(data: any, path: string, func: string, params?: string): Observable<any> {
        if (params) {
            return this.http.put<any>(this.API_BASE_URL + path + '/' + func + '?' + params, data, this.options);
        } else {
            return this.http.put<any>(this.API_BASE_URL + path + '/' + func, data, this.options);
        }
    }

    executeDelete(path: string, func: string, params?: string): Observable<any> {
        if (params) {
            return this.http.delete<any>(this.API_BASE_URL + path + '/' + func + '?' + params, this.options);
        } else {
            return this.http.delete<any>(this.API_BASE_URL + path + '/' + func, this.options);
        }
    }
}