import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { ViewType } from '../../../models/enums/viewtype.enum';
import { ILayoutEditClient, LayoutEditService } from '../../../models/layout/layout.edit.client';
import { MultiLayoutElement } from '../../../models/layoutelement.model';
import { MENUTABREGISTRY } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { MenuTabControl } from '../../common/menutabcontrol/menu.tab.control';
import { Router } from '@angular/router';

@Component({
    selector: 'overlay',
    templateUrl: './overlay.layout.html',
    styleUrls: ['./overlay.layout.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverlayLayout implements OnInit, OnDestroy {

    //#region ShowMenu
    ShowMenuValue = false;
    @Input()
    get ShowMenu() {
        return this.ShowMenuValue;
    }
    set ShowMenu(val) {
        this.ShowMenuValue = val;
        this.ShowMenuChange.emit(val);
    }
    @Output() ShowMenuChange = new EventEmitter<any>();
    //#endregion
    menuItems = [];
    Subscriptions = {};
    EditSubscriptions = {};
    ShowCancel = false;
    LayoutEditClient: ILayoutEditClient;

    get TriggerEvents() {
        return LayoutService.TriggerEventsInEditMode.getValue();
    }
    set TriggerEvents(val: boolean) {
        LayoutService.TriggerEventsInEditMode.next(val);
    }

    @ViewChild('menuTabControl') menuTabControl: MenuTabControl;

    constructor(private cdRef: ChangeDetectorRef, private router: Router) {
    }   

    ngOnInit(): void {
        this.Subscriptions['ViewType'] = LayoutService.ViewType.subscribe((vt) => {
            if (vt == ViewType.Edit) {
                const tabs = [];
                MENUTABREGISTRY.forEach(x => {
                    const item = Object.assign({}, x);
                    item.Visible = true;
                    if (typeof x.CheckVisible === 'function') {
                        item.Visible = x.CheckVisible();
                    }
                    if (typeof x.CheckVisibleAsnyc === 'function') {
                        x.CheckVisibleAsync().then((val) => item.Visible = val);
                    }
                    tabs.push(item);
                });
                this.menuItems = tabs;
                this.cdRef.detectChanges();
                this.EditSubscriptions['SelectedItem'] = LayoutService.SelectedItem.subscribe((item) => {
                    if (this.menuTabControl) {
                        this.menuTabControl.checkTabVisibility();
                    }
                    if (item) {
                        item.Selected = true;
                    } 
                });
                this.EditSubscriptions['SelectedItems'] = LayoutService.SelectedItems.subscribe((result) => {
                    if (this.menuTabControl) {
                        this.menuTabControl.checkTabVisibility();
                    }
                });
                this.EditSubscriptions['LayoutEditMode'] = LayoutService.LayoutEditMode.subscribe(x => {
                    this.ShowCancel = x;
                });
                this.EditSubscriptions['KeyPress'] = LayoutService.KeyPress.subscribe((event) => {
                    if (event.code === 'Delete') {
                        this.Delete();
                    }
                    if (event.key === 's' && event.ctrlKey) {
                        this.Save();
                    }
                });
            } else {
                Object.keys(this.EditSubscriptions).forEach((key) => {
                    this.EditSubscriptions[key].unsubscribe();
                })
            }
        });
        this.Subscriptions['LayoutEditClient'] = LayoutEditService.ActualLayoutEditClient.subscribe(x => {
            this.LayoutEditClient = x;
            this.cdRef.detectChanges();
        });
        this.Subscriptions['LayoutEditClientChange'] = LayoutEditService.LayoutEditClientChange.subscribe(() => {
            this.cdRef.detectChanges();
        });
    }

    ngOnDestroy(): void {
        const keys = Object.keys(this.Subscriptions);
        keys.forEach((key) => {
            this.Subscriptions[key].unsubscribe();
        });
    }

    Delete() {
        const elem = LayoutService.SelectedItem.getValue();
        if (elem) {
            if (elem instanceof MultiLayoutElement) {
                return;
            }
            let templatePath = '';
            const layout = LayoutService.SelectedLayout.getValue();
            if (layout && layout.Workflows) {
                const parents = MetaHelper.FindParentPath(layout, elem);
                if (parents) {
                    parents.forEach(x => {
                        if (x.ElementType === 'template') {
                            templatePath += x.Name + '.';
                        }
                    });
                }
            }
            LayoutHelper.OnDeleteElement(elem, templatePath, layout, null);
        }
    }

    RefreshTree() {
        LayoutService.RefreshTree.next(true);
    }

    Save() {
        if (this.LayoutEditClient) {
            this.LayoutEditClient.onSave();
            this.RefreshTree();
        }
    }

    CancelEdit() {
        LayoutEditService.CloseActual();
    }
}
