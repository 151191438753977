import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { ContainerTableHelper } from '../../../helpers/containertable.helper';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { TranslatedString } from '../../../models/translatedstring.model';
import { DataSettingsService } from '../../../services/data.settings.service';
import { DataModelService, DataSourceQuery } from '../../../services/datamodel.service';
import { DataSourcePreviewPanel } from '../../../workflow/modules/dataoperations/datasource/datasource.settings';
import { DataCheck } from '../../../workflow/workflow.dialog';
import { BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import { ADataModelDetail } from '../common/adatamodeldetail';
import { DataPreviewDetail } from '../common/adatapreview.settings';
import { DataObjectTranslateDialog } from '../dataobject/dataobject.translate.dialog';
import { ContainerFieldEditDialog, ContainerFieldInitArgs } from '../dialogs/container.field.edit.dialog';

@Component({
    selector: 'containertable-settings',
    templateUrl: '../../base.list.settings.html'
})
export class ContainerTableSettings extends BaseListSettings {
    TableObjectInfo;

    private static checkForDuplicate(fieldList): boolean {
        for (let i = 0; i < fieldList.length; i++) {
            for (let j = i + 1; j < fieldList.length; j++) {
                if (fieldList[i].Name === fieldList[j].Name) {
                    return true;
                }
            }
        }
        return false;
    }

    public static GetSettingsEntry() {
        return {
            Caption: '@@ContainerTables',
            ID: 'containertables',
            Icon: 'table_chart',
            Index: 0,
            Parent: 'relational',
            Security: {
                Name: 'evidanza.MiddleWare.Shared.Security.RelationalContainerRight',
                Value: 2
            },
            Content: ContainerTableSettings
        };
    }

    constructor(private translate: TranslateService, private dataModelService: DataModelService, private dSService: DataSettingsService,
        protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
        this.ShowDelete = false;
    }

    getContentType() {
        return ContainerTableDetail;
    }

    loadList(handler) {
        const comp = this.Component;
        if (comp) {
            comp.DataSources = [];
        }
        if (this.InitArgs) {
            this.dataModelService.GetContainersOfType(this.InitArgs.SID,
                'evidanza.MiddleWare.Shared.Container.ContainerTable').subscribe((result) => {
                if (result) {
                    const list = [];
                    result.forEach((item) => {
                        list.push({
                            Caption: item.Caption,
                            ID: item.SID,
                            IsCapsule: item.IsCapsule,
                            IsOverridden: item.IsOverridden
                        });
                    });
                    handler(list);
                }
            });
            if (comp) {
                const query = new DataSourceQuery(this.InitArgs.SID);
                query.DBUsages = [[4096], [8192], [32768]]; // RelationalRead und RelationalWrite und RelationalCreateStructure
                query.ResourceBase = 0; // Database
                this.dataModelService.GetDataSourcesByQuery(query).subscribe(sources => {
                    comp.DataSources = sources;
                });
            }
        }
    }

    loadData(data) {
        if (data) {
            this.dataModelService.GetContainer(data).subscribe((table) => {
                if (table) {
                    this.setSelectedItem(table);
                }
            });
        }
    }

    getNewItem() {
        const name = this.translate.instant('@@Neue Tabelle');
        return {
            IsNew: true,
            Data: {
                DataModelID: this.InitArgs.SID,
                Name: name,
                Fields: [],
                DataSourceId: null,
                Schema: null,
                IsCaseSensitive: false
            }
        };
    }

    async deleteItem() {
        const sel = this.selectedNode;
        if (sel) {
            const text = this.getDeleteText(sel);
            const retVal = await MessageBoxHelper.ShowDialog(text.Question, new TranslateFormatText('@@Loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question);
            if (retVal === MessageBoxResult.Yes) {
                let deleteTable = false;
                if (this.TableObjectInfo) {
                    const exists = await this.dSService.CheckExistsTable(this.TableObjectInfo.Name,
                        this.TableObjectInfo.Schema, this.TableObjectInfo.DS).toPromise();
                    if (exists) {
                        const delText = new TranslateFormatText('@@Moechten Sie auch die zugehoerige Tabelle in der Datenquelle loeschen?');
                        const delRet = await MessageBoxHelper.ShowDialog(delText, new TranslateFormatText('@@Tabelle loeschen'),
                            MessageBoxButtons.YesNo, MessageBoxIcon.Question);
                        deleteTable = delRet === MessageBoxResult.Yes;
                    }
                }
                this.dSService.DeleteContainerTable(sel.ID, deleteTable).subscribe((result) => {
                    if (result) {
                        this.selectedNode = null;
                        this.setSelectedItem(null);
                        this.HasChanges = false;
                        this.ListItems.splice(this.ListItems.indexOf(sel), 1);
                        if (deleteTable && !result.TableDropped) {
                            MessageBoxHelper.ShowDialog(new TranslateFormatText(result.TableDropError),
                                new TranslateFormatText('@@Fehler'), MessageBoxButtons.Ok, MessageBoxIcon.Error);
                        }
                    }
                });
            }
        }
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie die Tabelle \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Tabelle \'{0}\' erfolgreich geloescht');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Tabelle loeschen');
        return retVal;
    }

    delete(data) {
        // Nichts tun, wird im deleteItem erledigt
    }

    getSaveSuccessText(sel: any): SaveTexts {
        let caption = TranslatedString.GetTranslation(sel.Caption);
        if (!caption) {
            caption = sel.Name;
        }
        const retVal = new SaveTexts;
        retVal.Text = new TranslateFormatText('@@Tabelle \'{0}\' erfolgreich gespeichert');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@Tabelle speichern');
        return retVal;
    }

    protected async checkCanSave(): Promise<DataCheck> {
        const retVal = new DataCheck();
        if (this.Component && this.Component.SelectedItem) {
            const sel = this.Component.SelectedItem;
            if (this.TableObjectInfo) {
                delete this.TableObjectInfo.DropTable;
                const errorList = [];
                let nameSet = false;
                let schemaSet = false;
                if (typeof sel.Name === 'string' && sel.Name !== '') {
                    nameSet = true;
                } else {
                    errorList.push(this.translate.instant('@@Name nicht gesetzt'));
                }
                if (typeof sel.Schema === 'string' && sel.Schema !== '') {
                    schemaSet = true;
                } else {
                    errorList.push(this.translate.instant('@@Schema nicht gesetzt'));
                }
                if (sel.Fields && sel.Fields.length > 0) {
                    if (ContainerTableSettings.checkForDuplicate(sel.Fields)) {
                        errorList.push(this.translate.instant('@@Mehrere Spalten mit gleichem Namen'));
                    }
                } else {
                    errorList.push(this.translate.instant('@@Keine Spalten definiert'));
                }
                const nameCheck = ContainerTableHelper.CheckTableAndFieldNames(sel);
                if (nameCheck.InvalidTableName) {
                    errorList.push(this.translate.instant('@@Ungueltiger Tabellenname'));
                }
                if (nameCheck.InvalidColumnNames.length > 0) {
                    errorList.push(this.translate.instant('@@Ungueltige Spaltennamen') + ' (' + nameCheck.InvalidColumnNames.join(', ') + ')');
                }
                if (nameSet && schemaSet) { // Name und Schema m�ssen gesetzt sein
                    if (this.TableObjectInfo.IsNew) {
                        if (sel.DataSourceId) {
                            const exists = await this.dSService.CheckExistsTable(sel.Name, sel.Schema, sel.DataSourceId).toPromise();
                            if (exists) {
                                errorList.push(this.translate.instant('@@Tabelle mit gewaehltem Namen und Schema auf Datenquelle bereits vorhanden.'));
                            }
                        }
                    } else {
                        if (sel.DataSourceId !== this.TableObjectInfo.DS) {
                            let exists = await this.dSService.CheckExistsTable(sel.Name, sel.Schema, sel.DataSourceId).toPromise();
                            if (exists) {
                                errorList.push(this.translate.instant('@@Tabelle mit gewaehltem Namen und Schema auf Datenquelle bereits vorhanden.'));
                            } else {
                                exists = await this.dSService.CheckExistsTable(this.TableObjectInfo.Name,
                                    this.TableObjectInfo.Schema, this.TableObjectInfo.DS).toPromise();
                                if (exists) {
                                    const mb = await MessageBoxHelper.ShowDialog(
                                        new TranslateFormatText('@@Moechten Sie die Tabelle auf der alten Datenquelle loeschen?'),
                                        new TranslateFormatText('@@Frage'), MessageBoxButtons.YesNo, MessageBoxIcon.Question);
                                    if (mb === MessageBoxResult.Yes) {
                                        this.TableObjectInfo.DropTable = true;
                                    }
                                }
                            }
                        } else if (sel.Schema !== this.TableObjectInfo.Schema) {
                            const exists = await this.dSService.CheckExistsTable(this.TableObjectInfo.Name,
                                this.TableObjectInfo.Schema, this.TableObjectInfo.DS).toPromise();
                            if (exists) {
                                const text = '@@Durch eine Schema-Aenderung wird eine neue Tabelle angelegt. ' +
                                    'Moechten Sie die alte Tabelle loeschen?';
                                const mb = await MessageBoxHelper.ShowDialog(new TranslateFormatText(text),
                                    new TranslateFormatText('@@Frage'), MessageBoxButtons.YesNo, MessageBoxIcon.Question);
                                if (mb === MessageBoxResult.Yes) {
                                    this.TableObjectInfo.DropTable = true;
                                }
                            }
                        }
                    }
                }
                if (errorList.length > 0) {
                    retVal.IsCorrect = false;
                    retVal.Error = errorList.join('\n');
                }
            }
        }
        return retVal;
    }

    saveInternal(item, handler) {
        if (item) {
            item.Fields.forEach((field) => {
                if (field.DataTyp !== 'System.String') {
                    field.FieldLength = 0;
                }
            });
            const tableInfo = this.TableObjectInfo;
            if (tableInfo) {
                const request: any = {
                    TableToSave: item
                };
                if (item.DataSourceId) {
                    request.CreateNewTable = true;
                }
                if (!tableInfo.IsNew) {
                    if (tableInfo.DropTable) {
                        request.DropOldTable = true;
                        request.OldDataSource = tableInfo.DS;
                        request.OldName = tableInfo.Name;
                        request.OldSchema = tableInfo.Schema;
                    } else if (item.Name !== tableInfo.Name) {
                        request.OldName = tableInfo.Name;
                    }
                }
                this.dSService.SaveContainerTable(request).subscribe((result) => {
                    if (result) {
                        if (result.TableSaved) {
                            handler(result.TableSIDVersion, result.TableSIDVersion.SID, result.TableSIDVersion.Caption);
                        } else {
                            MessageBoxHelper.ShowDialog(new TranslateFormatText(result.TableSaveError),
                                new TranslateFormatText('@@Fehler'), MessageBoxButtons.Ok, MessageBoxIcon.Error);
                        }
                        if (result.TableCreated) {
                            this.TableObjectInfo = {
                                IsNew: false,
                                Name: item.Name,
                                Schema: item.Schema,
                                DS: item.DataSourceId
                            };
                        }
                        if (request.CreateNewTable && !result.TableCreated && result.TableCreatedError) {
                            MessageBoxHelper.ShowDialog(new TranslateFormatText(result.TableCreatedError),
                                new TranslateFormatText('@@Fehler'), MessageBoxButtons.Ok, MessageBoxIcon.Error);
                        }
                        if (request.DropOldTable && !result.OldTableDropped && result.TableDropError) {
                            MessageBoxHelper.ShowDialog(new TranslateFormatText(result.TableDropError),
                                new TranslateFormatText('@@Fehler'), MessageBoxButtons.Ok, MessageBoxIcon.Error);
                        }
                    }
                });
            }
        }
    }

    protected handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
        item.InternalID = result.InternalID;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }

    setSelectedItem(item) {
        if (item) {
            if (item.IsNew) {
                super.setSelectedItem(item.Data);
                this.TableObjectInfo = {
                    IsNew: true
                };
            } else {
                super.setSelectedItem(item);
                this.TableObjectInfo = {
                    IsNew: false,
                    Name: item.Name,
                    Schema: item.Schema,
                    DS: item.DataSourceId
                };
            }
        } else {
            super.setSelectedItem(item);
            this.TableObjectInfo = null;
        }
    }
}

@Component({
    selector: 'containertable-detail',
    templateUrl: './containertable.settings.html',
    styleUrls: ['./containertable.settings.css']
})
export class ContainerTableDetail extends ADataModelDetail {
    DataSources = [];
    Rows = [];

    constructor(private translate: TranslateService) {
        super();
    }

    setSelectedItem(item) {
        const rows = [];
        if (item && item.Fields) {
            item.Fields.forEach(f => {
                const row = DataPreviewDetail.fillRow({}, f);
                rows.push(row);
            });
        }
        this.Rows = rows;
        super.setSelectedItem(item);
    }

    addField() {
        const field = {
            ID: UUID.UUID(),
            Name: this.translate.instant('@@Neues Feld'),
            DataTyp: 'System.String',
            IsPrimary: false,
            Nullable: true,
            FieldLength: 50,
            Expression: null,
            NumericPrecision: 2
        };
        this.showDialog(field, '@@Neues Feld', true, (f) => {
            const row = DataPreviewDetail.fillRow({}, f);
            this.Rows.push(row);
            this.SelectedItem.Fields.push(f);
        });
    }

    editField(row) {
        const selected = this.SelectedItem;
        if (selected && selected.Fields) {
            let fieldIndex, field;
            if (selected.Fields.some((x, i) => {
                if (x.ID === row.ID) {
                    fieldIndex = i;
                    field = x;
                    return true;
                }
                return false;
            })) {
                this.showDialog(field, '@@Feld bearbeiten', false, (f) => {
                    DataPreviewDetail.fillRow(row, f);
                    selected.Fields.splice(fieldIndex, 1, f);
                });
            }
        }
    }

    deleteField(row, index) {
        const selected = this.SelectedItem;
        if (selected && selected.Fields) {
            const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Feld {0} loeschen moechten?');
            text.FormatParams.push(row.Caption);
            MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Feld loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x === MessageBoxResult.Yes) {
                        let fieldIndex = -1;
                        if (selected.Fields.some((f, i) => {
                            if (f.ID === row.ID) {
                                fieldIndex = i;
                                return true;
                            }
                            return false;
                        })) {
                            selected.Fields.splice(fieldIndex, 1);
                            this.Rows.splice(index, 1);
                            this.OnItemChanged();
                        }
                    }
                });
        }
    }

    showDialog(field, title, isNew, handler) {
        const selected = this.SelectedItem;
        if (selected) {
            const args = new ContainerFieldInitArgs();
            args.Field = field;
            args.Field.OldName = args.Field.Name;
            args.Settings.Name.Editable = true;
            args.Settings.DataTyp.Editable = true;
            args.Settings.Expression.Editable = true;
            args.Settings.FieldLength.Editable = true;
            args.Settings.IsPrimary.Editable = true;
            args.Settings.Nullable.Editable = true;
            args.Settings.NumericPrecision.Editable = true;
            BaseDialog.ShowDialog({
                ContentType: ContainerFieldEditDialog,
                InitArgs: args,
                Title: title,
                Handler: (f) => {
                    if (f) {
                        if (!f.Name) {
                            MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Bitte vergeben Sie einen Namen'),
                                new TranslateFormatText('@@Fehlender Name'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                            return false;
                        }
                        if (selected.Fields.some(x => x.ID !== f.ID && x.Name === f.Name)) {
                            const text = '@@Ein Feld mit diesem Namen ist bereits vorhanden. Bitte waehlen Sie einen anderen Namen';
                            MessageBoxHelper.ShowDialog(new TranslateFormatText(text), new TranslateFormatText('@@Doppelter Name'),
                                MessageBoxButtons.Ok, MessageBoxIcon.Information);
                            return false;
                        }
                        if (f.IsPrimary) {
                            selected.Fields.forEach(x => x.IsPrimary = false);
                            this.Rows.forEach(x => x.IsPrimary = '');
                        }
                        if (f.Name === f.OldName || isNew) {
                            f.OldName = null;
                        }
                        handler(f);
                        this.OnItemChanged();
                    }
                    return true;
                }
            });
        }
    }

    showPreview() {
        if (this.SelectedItem && this.SelectedItem.SID) {
            DataSourcePreviewPanel.ShowPreviewForContainer(this.SelectedItem.SID, '@@Vorschau');
        }
    }

    translateFields() {
        const selected = this.SelectedItem;
        if (selected && selected.Fields) {
            DataObjectTranslateDialog.ShowDialog(selected, () => {
                this.Rows.forEach(row => {
                    const field = selected.Fields.find(x => x.ID == row.ID);
                    if (field) {
                        row.Caption = TranslatedString.GetTranslation(field.Caption);
                    }
                });
                this.OnItemChanged();
            });
        }
    }
}
