import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { ObjectPreviewDialog } from '../../../components/dialogs/objectpreview/object.preview.dialog';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { MethodType } from '../../../models/enums/methodtype.enum';
import { DataPreviewInfo } from '../../../models/rest/requestparameters';
import { TranslatedString } from '../../../models/translatedstring.model';
import { DataSettingsService } from '../../../services/data.settings.service';
import { DataModelService, DataSourceQuery } from '../../../services/datamodel.service';
import { DataSourcePreviewPanel } from '../../../workflow/modules/dataoperations/datasource/datasource.settings';
import { DataCheck } from '../../../workflow/workflow.dialog';
import { BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import { DataObjectTranslateDialog } from '../dataobject/dataobject.translate.dialog';
import { ContainerFieldEditDialog, ContainerFieldInitArgs } from '../dialogs/container.field.edit.dialog';
import { ContainerColumnDialog, ContainerColumnDialogArgs } from '../dialogs/containerview.column.dialog';
import { ADataModelDetail } from './adatamodeldetail';

@Component({
    selector: 'preview-settings',
    templateUrl: '../../base.list.settings.html'
})
export abstract class APreviewSettings extends BaseListSettings {

    constructor(private translate: TranslateService, private dMService: DataModelService, private dSService: DataSettingsService,
        protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
        this.ShowDelete = false;
    }

    abstract getTypeName(): string;

    abstract getDBTypes(): number[][];

    abstract getResourceBase(): number;

    protected ComponentSet() {
        super.ComponentSet();
        if (this.Component) {
            this.Component.TypeName = this.getTypeName();
        }
    }

    loadList(handler) {
        const comp = this.Component;
        if (comp) {
            comp.DataSources = [];
        }
        if (this.InitArgs) {
            this.dMService.GetContainersOfType(this.InitArgs.SID, this.getTypeName()).subscribe((result) => {
                if (result) {
                    const list = [];
                    result.forEach((item) => {
                        list.push({
                            Caption: item.Caption,
                            ID: item.SID,
                            IsCapsule: item.IsCapsule,
                            IsOverridden: item.IsOverridden
                        });
                    });
                    handler(list);
                }
            });
            if (comp) {
                const dataSourceQuery = new DataSourceQuery(this.InitArgs.SID);
                dataSourceQuery.DBUsages = this.getDBTypes();
                dataSourceQuery.ResourceBase = this.getResourceBase();
                this.dMService.GetDataSourcesByQuery(dataSourceQuery).subscribe(sources => {
                    comp.DataSources = sources;
                });
            }
        }
    }

    loadData(data) {
        if (data) {
            this.dSService.GetDataPreview(data).subscribe((cont) => {
                if (cont) {
                    const list = [];
                    if (cont.Options) {
                        cont.Options.forEach((opt) => {
                            list.push({ Data: opt });
                        });
                    }
                    cont.Options = list;
                    this.setSelectedItem(cont);
                }
            });
        }
    }

    getNewItem() {
        const name = this.translate.instant('@@Neuer Container');
        return {
            DataModelID: this.InitArgs.SID,
            Name: name,
            DataSourceId: null,
            Resource: null,
            Fields: [],
            Options: [],
            StructureOptions: []
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Container \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Container \'{0}\' erfolgreich geloescht');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Container loeschen');
        return retVal;
    }

    delete(data) {
        this.dMService.DeleteContainer(data).subscribe();
    }

    getSaveSuccessText(sel: any): SaveTexts {
        let caption = TranslatedString.GetTranslation(sel.Caption);
        if (!caption) {
            caption = sel.Name;
        }
        const retVal = new SaveTexts;
        retVal.Text = new TranslateFormatText('@@Container \'{0}\' erfolgreich gespeichert');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@Container speichern');
        return retVal;
    }

    protected async checkCanSave(): Promise<DataCheck> {
        const retVal = new DataCheck();
        if (this.Component) {
            const selItem = this.Component.SelectedItem;
            if (selItem && (!selItem.Resource || !selItem.DataSourceId)) {
                retVal.Error = this.translate.instant('@@Bitte wählen Sie Datenquelle und Ressource aus');
                retVal.IsCorrect = false;
            }
        }
        return retVal;
    }

    saveInternal(item, handler) {
        if (item) {
            const saveReq = {
                SID: item.SID,
                Name: item.Name,
                Caption: item.Caption,
                CapsuleTag: item.CapsuleTag,
                DataSourceId: item.DataSourceId,
                Resource: item.Resource,
                DataModelID: this.InitArgs.SID,
                Fields: [],
                TypeName: this.getTypeName(),
                Options: {}
            };
            if (item.Fields) {
                item.Fields.forEach((field) => {
                    saveReq.Fields.push(JSON.stringify(field));
                });
            }
            if (item.Options) {
                item.Options.forEach((opt) => {
                    if (opt.Data && opt.Data.Value != null) {
                        saveReq.Options[opt.Data.Name] = opt.Data.Value.toString();
                    }
                });
            }
            this.dSService.SaveDataPreview(saveReq).subscribe((result) => {
                if (result) {
                    handler(result, result.SID, result.Caption);
                }
            });
        }
    }

    protected handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
        item.InternalID = result.InternalID;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'datapreview-settings',
    templateUrl: '../../base.list.settings.html'
})
export abstract class ADataPreviewSettings extends APreviewSettings {
    getContentType() {
        return DataPreviewDetail;
    }
}

@Component({
    selector: 'objectpreview-settings',
    templateUrl: '../../base.list.settings.html'
})
export abstract class AObjectPreviewSettings extends APreviewSettings {
    getContentType() {
        return ObjectPreviewDetail;
    }
}

export abstract class APreviewDetail extends ADataModelDetail {
    DataSources = [];
    TypeName;
    cdRef;
    constructor(protected previewService: DataModelService, cdRef: ChangeDetectorRef) {
        super();
        this.cdRef = cdRef;
    }

    updateOptions() {
        const cont = { ...this.SelectedItem };
        if (cont) {
            const preview = this.GetPreviewInfo();
            cont.Options = [];
            this.previewService.GetAvailableOptions(cont.DataSourceId, preview).subscribe((result) => {
                if (result && result.Options) {
                    const list = [];
                    result.Options.forEach((r) => {
                        list.push({ Data: r });
                    });
                    cont.Options = list;
                }
                this.SelectedItem = cont;
                this.cdRef.detectChanges();
            });
        }
    }

    protected GetPreviewInfo(): DataPreviewInfo {
        const cont = this.SelectedItem;
        if (cont) {
            const preview = new DataPreviewInfo();
            preview.Name = cont.Name;
            preview.ContainerType = this.TypeName;
            if (cont.Options) {
                cont.Options.forEach((opt) => {
                    if (opt.Data && opt.Data.Value) {
                        preview.Options.set(opt.Data.Name, opt.Data.Value.toString());
                    }
                });
            }
            preview.Resource = cont.Resource;
            preview.Methodtype = MethodType.Get;
            return preview;
        }
        return null;
    }
}

@Component({
    selector: 'datapreview-detail',
    templateUrl: './adatapreview.settings.html',
    styleUrls: ['./adatapreview.settings.css']
})
export class DataPreviewDetail extends APreviewDetail {
    Rows = [];

    public static fillRow(row, f) {
        row.ID = f.ID;
        row.Name = f.Name;
        row.Caption = TranslatedString.GetTranslation(f.Caption);
        row.Type = f.DataTyp;
        ContainerFieldEditDialog.AllTypes.some(x => {
            if (x.Value === f.DataTyp) {
                row.Type = x.Caption;
                return true;
            }
            return false;
        });
        row.IsPrimary = f.IsPrimary ? 'done' : '';
        row.IsNullable = f.Nullable ? 'done' : '';
        row.Length = f.FieldLength;
        row.Expression = f.Expression;
        return row;
    }

    constructor(protected previewService: DataModelService, cdRef: ChangeDetectorRef) {
        super(previewService, cdRef);
    }

    setSelectedItem(item) {
        const rows = [];
        if (item && item.Fields) {
            item.Fields.forEach(f => {
                const row = DataPreviewDetail.fillRow({}, f);
                rows.push(row);
            });
        }
        this.Rows = rows;
        super.setSelectedItem(item);
    }

    addColumns() {
        const cont = this.SelectedItem;
        if (cont) {
            const args = new ContainerColumnDialogArgs();
            args.PreviewInfo = this.GetPreviewInfo();
            args.DataSourceId = cont.DataSourceId;
            if (cont.Fields) {
                args.Fields = cont.Fields;
            }
            BaseDialog.ShowDialog({
                ContentType: ContainerColumnDialog,
                InitArgs: args,
                Title: '@@Spalten hinzufuegen',
                Handler: (result) => {
                    if (result) {
                        if (result.SelectedColumns && result.SelectedColumns.length > 0) {
                            result.SelectedColumns.forEach((col) => {
                                cont.Fields.push(col);
                                const row = DataPreviewDetail.fillRow({}, col);
                                this.Rows.push(row);
                            });
                            this.OnItemChanged();
                        }
                        if (result.Options) {
                            cont.StructureOptions = result.Options;
                        }
                    }
                    return true;
                }
            });
        }
    }

    editField(row) {
        const selected = this.SelectedItem;
        if (selected && selected.Fields) {
            let fieldIndex, field;
            if (selected.Fields.some((x, i) => {
                if (x.ID === row.ID) {
                    fieldIndex = i;
                    field = x;
                    return true;
                }
                return false;
            })) {
                const args = new ContainerFieldInitArgs();
                args.Field = field;
                args.Settings.IsPrimary.Visible = false;
                args.Settings.DataTyp.Editable = true;
                args.Settings.FieldLength.Editable = true;
                args.Settings.NumericPrecision.Editable = true;
                BaseDialog.ShowDialog({
                    ContentType: ContainerFieldEditDialog,
                    InitArgs: args,
                    Title: '@@Feld bearbeiten',
                    Handler: (f) => {
                        if (f) {
                            DataPreviewDetail.fillRow(row, f);
                            selected.Fields.splice(fieldIndex, 1, f);
                            this.OnItemChanged();
                        }
                        return true;
                    }
                });
            }
        }
    }

    deleteField(row, index) {
        const selected = this.SelectedItem;
        if (selected && selected.Fields) {
            const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Feld {0} loeschen moechten?');
            text.FormatParams.push(row.Caption);
            MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Feld loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x === MessageBoxResult.Yes) {
                        let fieldIndex = -1;
                        if (selected.Fields.some((f, i) => {
                            if (f.ID === row.ID) {
                                fieldIndex = i;
                                return true;
                            }
                            return false;
                        })) {
                            selected.Fields.splice(fieldIndex, 1);
                            this.Rows.splice(index, 1);
                            this.OnItemChanged();
                        }
                    }
                });
        }
    }

    showPreview() {
        if (this.SelectedItem && this.SelectedItem.SID) {
            DataSourcePreviewPanel.ShowPreviewForContainer(this.SelectedItem.SID, '@@Vorschau');
        }
    }

    translateFields() {
        const selected = this.SelectedItem;
        if (selected && selected.Fields) {
            DataObjectTranslateDialog.ShowDialog(selected, () => {
                this.Rows.forEach(row => {
                    const field = selected.Fields.find(x => x.ID == row.ID);
                    if (field) {
                        row.Caption = TranslatedString.GetTranslation(field.Caption);
                    }
                });
                this.OnItemChanged();
            });
        }
    }

    Initialize(InitArgs) {
        if (InitArgs) {
            this.DataSources = InitArgs.DataSources;
            this.TypeName = InitArgs.Data.ContainerType;
            this.setSelectedItem(InitArgs.Data);
        }
    }
    GetDialogResult() {
        return this.SelectedItem;
    }
}

@Component({
    selector: 'objectpreview-detail',
    templateUrl: './aobjectpreview.settings.html',
    styleUrls:['./adatapreview.settings.css']
})
export class ObjectPreviewDetail extends APreviewDetail {
    constructor(protected previewService: DataModelService, private translate: TranslateService, cdRef: ChangeDetectorRef) {
        super(previewService, cdRef);
    }

    showPreview() {
        const cont = this.SelectedItem;
        if (cont) {
            const initArgs = {
                UseTable: false,
                Settings: this.GetPreviewInfo(),
                DataSource: cont.DataSourceId
            };
            ObjectPreviewDialog.ShowDialog(initArgs, this.translate.instant('@@Vorschau'));
        }
    }

    Initialize(InitArgs) {
        if (InitArgs) {
            this.DataSources = InitArgs.DataSources;
            this.TypeName = InitArgs.Data.ContainerType;
            this.SelectedItem = InitArgs.Data;
        }
    }
    GetDialogResult() {
        return this.SelectedItem;
    }
}

