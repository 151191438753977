import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, KeyValueDiffers, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { promise } from 'protractor';
import { Observable, of } from 'rxjs';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { FileSelectionThemeControl } from '../../../appbuilder/controls/fileselection/fileselection.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { CacheService } from '../../../cache/cache.service';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { MediaSource } from '../../../models/datamodel/mediasource.model';
import { ObjectFit } from '../../../models/enums/objectfit.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { MenuTabLabelPosition } from '../../../models/menutab/menutab.property.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { MediaService } from '../../../services/media.service';
import { FileExplorerDialog } from '../../fileexplorer/fileexplorer.dialog';
import { IBaseComponent } from '../base.component';

@Component({
    selector: 'evi-image',
    templateUrl: './image.control.html',
    styleUrls: ['./image.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageControl extends IBaseComponent {

    static Type: any = 'image';
    static Default = { IsCheckedValue: false, Type: 'image',Layout: {
        _Editable: true,
    } };

    URLValue: Observable<any>;
    get URL() {
        return this.URLValue;
    }
    set URL(value) {
        this.URLValue = value;
        if (value && value.subscribe) {
            this.URLValue.subscribe(() => {
                this.cdRef.markForCheck()
            })
        }
    }

    //#region MediaSource
    MediaSourceValue;
    @Input()
    get MediaSource() {
        return this.MediaSourceValue;
    }
    set MediaSource(val) {
        this.MediaSourceValue = val;
        this.MediaSourceChange.emit(this.MediaSourceValue);
    }

    @Output() MediaSourceChange = new EventEmitter<any>();
    //#endregion

    //#region DataSource
    DataSourceValue;

    @Input()
    get DataSource() {
        return this.DataSourceValue;
    }
    set DataSource(val) {
        if (this.DataSourceValue != val) {
            this.DataSourceValue = val;
            if (this.LayoutElement && this.LayoutElement.DataSource) {
                this.URL = this.CreateUrl();
            }
            this.triggerEvent('DataSourceChanged', this.DataSourceValue);
            this.DataSourceChange.emit(this.DataSourceValue);
        }
    }

    @Output() DataSourceChange = new EventEmitter<any>();
    //#endregion
    Sources;
    
    constructor(private mediaService: MediaService, private dialog: MatDialog, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('imageUploaded');
        this.EventList.push('click');
    }
    ControlInitialized() {
        this.URL = this.CreateUrl();
    }
    ngOnInit(): void {
        this.URL = this.CreateUrl();
        super.ngOnInit();
    }
    onLayoutElementChanged() {
        this.URL = this.CreateUrl();
    }
    CreateUrl(): Observable<any> {
        if (this.LayoutElement) {
            if (this.LayoutElement.UseForBlob) {
                of(this.DataSource);
            } else if (this.LayoutElement && this.LayoutElement.MediaSource) {
                return new Observable((subscriber) => {
                    CacheService.ReadMediaSourceSub(this.LayoutElement.MediaSource).subscribe((source) => {
                        let fileName = this.LayoutElement.FileName;
                        if (this.LayoutElement.DataSource) {
                            if (this.DataSourceValue) {
                                fileName = this.DataSourceValue;
                            }
                        }
                        if (fileName) {
                            if (source.Private) {
                                this.mediaService.LoadImage(source.SID, fileName).subscribe((image) => {
                                    subscriber.next(image);
                                });
                            } else {
                                if (this.LayoutElement.UseCaching) {
                                    subscriber.next(MediaSource.GetURL(source, fileName));
                                } else {
                                    subscriber.next(MediaSource.GetURL(source, fileName + '?a=' + Math.random()));
                                }
                            }
                        } else {
                            subscriber.next("")
                        }
                    });
                })
            } else {
                of("");
            }
        } else {
            of("");
        }
    }

    editImage() {
        if (this.Editable && this.LayoutElement && this.LayoutElement.MediaSource) {
            const dialogRef = this.dialog.open(FileExplorerDialog, {
                panelClass: 'fileexplorer-dialog-container',
                data: {
                    MediaSourceID: this.LayoutElement.MediaSource,
                    ImageInstance: this
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.DataSource = result;
                }
            });
        }
    }

    deleteImage() {
        if (this.Editable) {
            this.DataSource = null;
        }
    }

    onFileUploaded(ev) {
        this.triggerEvent('imageUploaded', ev);
    }

    onClick() {
        this.triggerEvent('click');
    }
}
export class ImagePanel extends BasePanel {
    static override SIDS = ['950f8d05-65c6-4b29-8063-646095329fb7']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'950f8d05-65c6-4b29-8063-646095329fb7',
            ID: 'image',
            Caption: '@@Image',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'image';
            }
        })
        // PROPERTIES.push({
        //     ID: "UseForBlob",
        //     Parent: "image",
        //     Content: new ComponentPortal(CheckBoxThemeControl),
        //     InitArgs: {
        //         Caption: "@@UseForBlob"
        //     },
        //     Row:1,
        // })
        PROPERTIES.push({
            ID: "UseCaching",
            Parent: "image",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@UseCaching"
            },
            Row: 2,
            CheckVisibility: (item) => {
                return !item.UseForBlob;
            }
        })
        let mediaService = InjectorHelper.InjectorInstance.get<MediaService>(MediaService);
        mediaService.GetUsable().subscribe((data) => {
            PROPERTIES.push({
                ID: "MediaSource",
                Parent: "image",
                Content: new ComponentPortal(ComboboxThemeControl),
                Label: "@@SelectMediaSource",
                Row: 3,
                CheckVisibility: (item) => {
                    return !item.UseForBlob;
                },
                InitArgs: {
                    Placeholder: "@@Select",
                    Multiple: false,
                    ItemsSource: data,
                    DisplayMemberPath: 'Caption',
                    ValueMemberPath: 'SID'
                }
            })
        });
        PROPERTIES.push({
            ID: "FileName",
            Parent: "image",
            Content: new ComponentPortal(FileSelectionThemeControl),
            Row: 4,
            CheckVisibility: (item) => {
                return !item.UseForBlob && item.MediaSource;
            }
        })
        
        PROPERTIES.push({
            ID: "ImageFit",
            Parent: "image",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@ObjectFit",
            Row: 5,
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                EnumSource: ObjectFit
            }

        })
    }
}