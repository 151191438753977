import {AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MessageBoxHelper} from '../../components/dialogs/messagebox/messagebox.dialog';
import {ArrayHelpers, TranslateFormatText} from '../../helpers/array.helpers';
import {NotificationHelper} from '../../helpers/notification.helper';
import {MessageBoxButtons} from '../../models/enums/messageboxbuttons.enum';
import {MessageBoxIcon} from '../../models/enums/messageboxicon.enum';
import {MessageBoxResult} from '../../models/enums/messageboxresult.enum';
import {StandardRequestBase} from '../../services/request-base';
import {RolesService} from '../../services/roles.service';
import {SecurityService} from '../../services/security.service';
import {UsersService} from '../../services/users.service';
import {DataCheck} from '../../workflow/workflow.dialog';
import {BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts} from '../base.list.settings';
import {IdentityMFAMailDialog} from '../provider/identity/identity.mfa.mail.dialog';
import next from "ajv/dist/vocabularies/next";
import {LayoutService} from "../../services/layout.service";

@Component({
    selector: 'user-settings',
    templateUrl: './user.settings.settings.html'
})
export class UserSettings extends BaseListSettings {

    ManagedLocally;
    RolesList = [];
    selectedRole: any;
    selRole: string;

    toggleWindow = true;

    constructor(private service: UsersService, private translate: TranslateService, private standardService: StandardRequestBase,
                private rolesService: RolesService, protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
    }

    getContentType() {
        return UserDetail;
    }

    protected ComponentSet() {
        super.ComponentSet();
        this.rolesService.ReadRoleInfos().subscribe(result => {
            if (result) {
                const roleList = [];
                const otherRoles = [];
                result.forEach(role => {
                    if (role.Id === '0a5d664e-be8d-4a8c-b748-0d4793280d0d') {
                        roleList.splice(0, 0, role);
                    } else if (role.Id === 'df03329c-6538-4918-ba8c-3f743c2928a0') {
                        roleList.push(role);
                    } else {
                        otherRoles.push(role);
                    }
                });
                ArrayHelpers.sortAlphabetical(otherRoles, 'Name');
                roleList.push(...otherRoles);
                if (this.Component) {
                    this.Component.Roles = roleList;
                    this.Component.FilteredRoles = roleList;
                }
                if (roleList) {
                    const list = [];
                    result.forEach((entry) => {
                        list.push({
                            label: entry.Name,
                            value: entry.Id,
                        });
                    });
                    this.RolesList = list;
                }
            }
        });

        if (this.Component) {
            this.Component.ManagedLocally = this.ManagedLocally;
        }
    }

    protected init() {
        this.standardService.executeGet('config/api/config', 'GetManagementStatus').subscribe((result) => {
            this.ManagedLocally = result;
            this.ShowAdd = result;
            this.ShowDelete = result;
            if (this.Component) {
                this.Component.ManagedLocally = result;
            }
        });
    }

    updateRole(event) {
        if (event.value == null) {
            this.selRole = "";
        } else {
            this.selRole = event.value.value;
        }
        this.UpdateFiltered();
    }

    UpdateFiltered() {
        if (this.ListItems) {
            let list = [];

            if (this.ShowFilter && this.SearchValue && this.selRole) {
                const toLower = this.SearchValue.toLowerCase();
                list = this.ListItems.filter(x => x.Caption.toLowerCase().indexOf(toLower) > -1 && x.Roles.includes(this.selRole));

            } else if (this.ShowFilter && this.SearchValue) {
                const toLower = this.SearchValue.toLowerCase();
                list = this.ListItems.filter(x => x.Caption.toLowerCase().indexOf(toLower) > -1);
            } else if (this.ShowFilter && this.selRole) {
                list = this.ListItems.filter(x => x.Roles.includes(this.selRole));
            } else {
                list.push(...this.ListItems);
            }
            this.FilteredItems = list;
        }
    }


    loadList(handler) {
        this.service.ReadUserInfos().subscribe(result => {
            if (result) {
                const list = [];
                result.forEach((entry) => {
                    list.push({
                        Caption: entry.Name,
                        ID: entry.Id,
                        Roles: entry['Roles']
                    });
                });
                handler(list);
            }
        });
    }

    InternalUserFound = false;

    loadData(data) {
        this.service.findUser(data).subscribe(result => {
            if (result) {
                this.InternalUserFound = false;
                if (this.ManagedLocally) {
                    this.ShowDelete = !result['IsManagedByPortal'];
                    this.ShowSave = !result['IsManagedByPortal'];
                }
                if (result.IdentityId) {
                    this.standardService.executeGet('config/api/dbusers', 'GetUserInfo', 'id=' + result.IdentityId).subscribe(intUser => {
                        this.setSelectedItem(result);
                        this.Component.setInternalUser(intUser);
                        if (intUser) {
                            this.InternalUserFound = true;
                        }
                    });
                } else {
                    this.setSelectedItem(result);
                }
            }
        });
    }

    getNewItem() {
        return {
            DisplayName: this.translate.instant('@@Neuer Benutzer'),
            FirstName: null,
            LastName: null,
            Email: null,
            IsExtern: false,
            IdentityId: null,
            Disabled: false,
            IsAdministrator: false,
            Roles: []
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Are you sure you want to delete user \'{0}\'?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@User \'{0}\' successfully deleted.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Delete');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        let caption = '';
        if (sel.DisplayName) {
            caption = sel.DisplayName;
        } else if (sel.FirstName) {
            caption = sel.FirstName;
            if (sel.LastName) {
                caption += ' ' + sel.LastName
            }
        } else if (sel.LastName) {
            caption = sel.LastName
        }
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@User \'{0}\' successfully saved.');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@Save');
        return retVal;
    }


    delete(data, handler) {
        this.service.deleteUser(data).subscribe((res) => {
            handler(res);
        });
    }

    protected async checkCanSave(): Promise<DataCheck> {
        var ret = new DataCheck();
        ret.IsCorrect = this.Component.Passwort == this.Component.PasswortCompare;
        return ret;
    }

    saveInternal(item, handler) {
        if(!this.formValidation(item, this.Component.Username)) {
            NotificationHelper.Error('Please fill mandatory fields', '@@Error');
            LayoutService.Loading.next(false);
            return;
        }
        if (!item.IdentityId || !this.InternalUserFound) {
            let newUser = {
                UserName: this.Component.Username,
                Email: item.Email,
                Password: this.Component.Password,
                FirstName: item.FirstName,
                LastName: item.LastName
            };

            this.standardService.executePost(newUser, 'config/api/dbusers', 'CreateNewUser').subscribe({
                next: (result) => {
                    item.IdentityId = result;
                    this.InternalUserFound = true;
                    this.saveUser(item, handler);
                },
                error: (error) => {
                    // NotificationHelper.Error(error.statusText, '@@Error');
                }
            });
        } else if (this.Component.Password) {
            let newUser = {
                UserName: this.Component.Username,
                Email: item.Email,
                Password: this.Component.Password,
                FirstName: item.FirstName,
                LastName: item.LastName,
                ID: item.IdentityId
            };

            this.standardService.executePost(newUser, 'config/api/dbusers', 'UpdateUser').subscribe(result => {
                this.saveUser(item, handler);
            });
        } else {
            this.saveUser(item, handler);
        }
    }

    saveUser(item, handler) {
        this.service.createUser(item).subscribe({
            next: (result) => {
                if (result) {
                    let caption = result;
                    if (item.DisplayName) {
                        caption = item.DisplayName;
                    } else if (item.FirstName) {
                        caption = item.FirstName;
                        if (item.LastName) {
                            caption += ' ' + item.LastName
                        }
                    } else if (item.LastName) {
                        caption = item.LastName
                    }
                    handler(result, result, caption);
                }
            },
            error: (error) => {
                // NotificationHelper.Error(error.statusText, '@@Error');
            }
        });
    }

    formValidation(item, Username) {
        return !!(item.FirstName && item.LastName && item.DisplayName && item.Email && Username);
    }

    handleNew(item, result) {
        item.SID = result;
    }

    static GetSettingsEntry() {
        return {
            Caption: '@@Benutzer',
            ID: 'user',
            Icon: 'person',
            Index: 0,
            Parent: 'userCont',
            Security: {
                Name: 'evidanza.App.Shared.Security.UsermanagementRight',
                Value: 2
            },
            Content: UserSettings
        }
    }

    ToggleWindow() {
        this.toggleWindow = !this.toggleWindow;
    }
}

@Component({
    selector: 'user-detail',
    templateUrl: './user.settings.html',
    styleUrls: ['./user.settings.css']
})
export class UserDetail extends BaseListDetail implements AfterViewInit {
    isUsernameEdit = false;
    TwoFactorEnabled = false;

    _Username;
    get Username() {
        return this._Username;
    }

    set Username(value) {
        this._Username = value;
        if (this.Initialized) {
            this.cdRef.detectChanges();
        }
    }

    _PasswordCompare;
    get PasswordCompare() {
        return this._PasswordCompare;
    }

    set PasswordCompare(value) {
        this._PasswordCompare = value;
        if (this.Initialized) {
            this.cdRef.detectChanges();
        }
    }

    _Password;
    get Password() {
        return this._Password;
    }

    set Password(value) {
        this._Password = value;
        if (this.Initialized) {
            this.cdRef.detectChanges();
        }
    }

    AllProviders = [];
    Roles = [];
    FilteredRoles = [];
    ManagedLocally;
    _RoleSearchValue;
    get RoleSearchValue() {
        return this._RoleSearchValue;
    }

    set RoleSearchValue(value) {
        this._RoleSearchValue = value;
        this.FilteredRoles = this.FilterRoles(this._RoleSearchValue, this._RolesSelectedOnly);
    }

    _RolesSelectedOnly;
    get RolesSelectedOnly() {
        return this._RolesSelectedOnly;
    }

    set RolesSelectedOnly(value) {
        this._RolesSelectedOnly = value;
        this.FilteredRoles = this.FilterRoles(this._RoleSearchValue, this._RolesSelectedOnly);
    }

    constructor(cdRef: ChangeDetectorRef, private service: UsersService, private secService: SecurityService) {
        super();
        this.cdRef = cdRef;
    }

    ngAfterViewInit(): void {
        this.Initialized = true;
    }

    ngOnInit(): void {
        this.secService.GetSecurityProviders().subscribe(result => {
            if (result) {
                ArrayHelpers.sortAlphabetical(result, 'Name');
                this.AllProviders = result;
            }
        });
    }

    Initialized = false;

    setSelectedItem(item) {
        this._Username = '';
        this._Password = '';
        this._PasswordCompare = '';
        this.TwoFactorEnabled = false;
        this.isUsernameEdit = false;
        if (this.Initialized) {
            this.cdRef.detectChanges();
        }
        super.setSelectedItem(item);
    }

    setInternalUser(authUser) {
        if (authUser) {
            this._Username = authUser.UserName;
            this.isUsernameEdit = true;
            this.TwoFactorEnabled = authUser.TwoFactorEnabled;
            if (this.Initialized) {
                this.cdRef.detectChanges(); 
            }
        }
    }

    FilterRoles(value, selectedOnly) {
        if (value) {
            if (selectedOnly == true && (!this.SelectedItem || !this.SelectedItem.Roles || this.SelectedItem.Roles.length == 0)) {
                return [];
            }
            value = value.toLocaleLowerCase();
            return this.Roles.filter((roles: any) => {
                return (!value || (value && roles.Name.toLocaleLowerCase().indexOf(value) !== -1)) &&
                    (!selectedOnly || (selectedOnly && this.SelectedItem.Roles.indexOf(roles.Id) > -1));
            });
        } else {
            if (selectedOnly == true) {
                if (this.SelectedItem && this.SelectedItem.Roles && this.SelectedItem.Roles.length > 0) {
                    return this.Roles.filter((roles: any) => {
                        return this.SelectedItem.Roles.indexOf(roles.Id) > -1;
                    });
                } else {
                    return [];
                }
            } else {
                return this.Roles;
            }
        }
    }

    onRoleSelectionChange(ev) {
        if (this.SelectedItem && ev && ev.options) {
            let doFilter = false;
            ev.options.forEach(option => {
                if (option.value) {
                    if (option.selected) {
                        if (this.SelectedItem.Roles) {
                            this.SelectedItem.Roles.push(option.value.Id);
                        } else {
                            this.SelectedItem.Roles = [option.value.Id];
                        }
                    } else {
                        if (this.SelectedItem.Roles) {
                            const index = this.SelectedItem.Roles.indexOf(option.value.Id);
                            if (index >= 0 && index < this.SelectedItem.Roles.length) {
                                this.SelectedItem.Roles.splice(index, 1);
                                if (this._RolesSelectedOnly == true) {
                                    doFilter = true;
                                }
                            }
                        }
                    }
                }
            });
            if (doFilter) {
                this.FilteredRoles = this.FilterRoles(this._RoleSearchValue, this._RolesSelectedOnly);
            }
            this.OnItemChanged();
        }
    }

    PasswordsMatch;

    PasswordChanged() {
        if (this.Password != this.PasswordCompare) {
            this.PasswordsMatch = false;
        } else {
            this.PasswordsMatch = true;
            this.OnItemChanged();
        }
        this.cdRef.detectChanges();
    }

    checkRoleSelection(data) {
        if (data && this.SelectedItem && this.SelectedItem.Roles) {
            return this.SelectedItem.Roles.indexOf(data.Id) > -1;
        }
        return false;
    }

    ResetMFA() {
        if (this.SelectedItem) {
            const sid = this.SelectedItem.SID;
            if (sid) {
                MessageBoxHelper.ShowDialog(new TranslateFormatText('@@MFAResetQuestion'), new TranslateFormatText('@@Question'),
                    MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x == MessageBoxResult.Yes) {
                        this.service.ResetMFA(sid).subscribe(x => {
                            if (x) {
                                NotificationHelper.Success('@@ResetMFASuccessHeader', '@@ResetMFASuccessContent');
                                IdentityMFAMailDialog.ShowDialog({
                                    Users: [sid]
                                });
                                this.TwoFactorEnabled = false;
                                if (this.Initialized) {
                                    this.cdRef.detectChanges();
                                }
                            }
                        });
                    }
                });
            }
        }
    }
}
