import { MaterialCssVariables, MaterialCssVarsService } from 'angular-material-css-vars';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Subject } from 'rxjs';
import { CacheService } from '../cache/cache.service';
import { LayoutUnit } from '../models/basic/layoutunit.model';
import { CSSVarType } from '../models/enums/cssvartype.enum';
import { Color } from '../models/style/color.model';
import { Theme } from '../models/style/theme.model';
import { AppBuilderSettingsService } from '../services/appbuildersettings.service';
import { LayoutService } from '../services/layout.service';
import { MediaService } from '../services/media.service';
import { MetaService } from '../services/meta.service';

// @dynamic
export class ThemeHelper {
    private static materialCssVarsService: MaterialCssVarsService;
    private static metaService: MetaService;
    private static appbuilderService: AppBuilderSettingsService;

    public static AcitveTheme: Theme;
    public static ActiveThemeChartPalette: string[];
    public static ActiveThemeChanged: Subject<any> = new Subject();
    public static Themes;
    public static CustomFontSizes;
    public static CustomFonts;
    public static CustomFontsChanged: BehaviorSubject<any> = new BehaviorSubject(null)
    public static CustomFontsMap;
    public static ThemesMap;
    public static Initialize(materialCssVarsService: MaterialCssVarsService, metaService: MetaService, appbuilderService: AppBuilderSettingsService) {
        this.materialCssVarsService = materialCssVarsService;
        this.metaService = metaService;
        this.appbuilderService = appbuilderService;
        this.LoadThemes();
    }
    public static CSSFiles;
    private static CSSStyles = [];
    public static AvailableClassesSub: BehaviorSubject<any> = new BehaviorSubject<any>(null)
    private static insertAt(str, sub, pos) {
        return `${str.slice(0, pos)}${sub}${str.slice(pos)}`;
    } 
    public static LoadCSS(ids) {
        this.metaService.GetCustomCSSByIDs(ids).subscribe((files) => {
            ThemeHelper.CSSFiles = files;
            const availableClasses = [];
            if (files && files.length > 0) {
                const head = document.getElementsByTagName('head')[0];

                if (ThemeHelper.CSSStyles) {
                    ThemeHelper.CSSStyles.forEach((style) => {
                        try {
                            head.removeChild(style);
                        } catch { }
                    })
                }
                if (!ThemeHelper.CSSStyles) {
                    ThemeHelper.CSSStyles = [];
                }

                files.forEach((file) => {
                    let style = document.createElement('style');
                    style.type = 'text/css';

                    let css = ThemeHelper.ParseCSS(file.Text);
                    if (css && css.length > 0) {
                        for (let i = 0; i < css.length; i++) {
                            let item = css[i];
                            if (item['selectorText']) {
                                let count = (item['selectorText'].match(/\./g) || []).length
                                if (count == 1) {
                                    availableClasses.push({
                                        ClassName: item['selectorText'].substring(1)
                                    });
                                }
                                let rep = ThemeHelper.insertAt(item['selectorText'], 'customerclass_', 1);
                                file.Text = file.Text.replaceAll(item['selectorText'], rep);
                            }
                        }
                    }
                    style.appendChild(document.createTextNode(file.Text));
                    ThemeHelper.CSSStyles.push(style);
                    ThemeHelper.AvailableClassesSub.next(availableClasses)
                    head.appendChild(style);
                });
            }
        });
    }
    private static ParseCSS(styleContent) {
        var doc = document.implementation.createHTMLDocument(""),
            styleElement = document.createElement("style");

        styleElement.textContent = styleContent;
        // the style will only be parsed once it is added to a document
        doc.body.appendChild(styleElement);

        return styleElement.sheet.cssRules;
    }
    public static LoadThemes() {
        return new Promise((resolve, reject) => {
            this.Themes = null;
            this.ThemesMap = {};
            this.metaService.GetAllThemes().subscribe((themes) => {
                this.Themes = themes;
                themes.forEach((theme) => {
                    theme = plainToClass(Theme, theme);
                    this.ThemesMap[theme.SID] = theme;
                })
                this.appbuilderService.ReadAll().subscribe((result) => {
                    if (result && result.length > 0) {
                        ThemeHelper.SetAppBuilderTheme(result[0]);
                    }
                })
                resolve(true);
            })
        })
    }
    public static ActiveFontStyle =  {};
    public static SetTheme(theme: Theme) {
        if (theme == null) {
            theme = Theme.GenerateFallback();
        }
        this.materialCssVarsService.setDarkTheme(theme.IsDarkTheme);
        if (theme.IsDarkTheme) {
            document.documentElement.style.setProperty('--surface-b', '#17212f');
            document.documentElement.style.setProperty('--paper-bg', '#393939');
            document.documentElement.style.setProperty('--text-color', 'rgba(255, 255, 255, 0.87)');
        } else {
            document.documentElement.style.setProperty('--surface-b', '#f8f9fa');
            document.documentElement.style.setProperty('--paper-bg', '#EFEFEF');
            document.documentElement.style.setProperty('--text-color', '#495057');
        }
        if (theme.Font) {
            if (theme.Font.Bold != null) {
                if (theme.Font.Bold) {
                    document.documentElement.style.setProperty('--base-font-weight', 'bold');
                    this.ActiveFontStyle['font-weight'] = 'bold';
                } else {
                    document.documentElement.style.removeProperty('--base-font-weight');
                    this.ActiveFontStyle['font-weight'] = 'normal';
                }
            }
            if (theme.Font.Italic != null) {
                if (theme.Font.Italic) {
                    document.documentElement.style.setProperty('--base-font-style', 'italic');
                    this.ActiveFontStyle['font-style'] = 'italic';
                } else {
                    document.documentElement.style.removeProperty('--base-font-style');
                    delete this.ActiveFontStyle['font-style'];
                }
            }
            if (theme.Font.Underline != null) {
                if (theme.Font.Underline) {
                    document.documentElement.style.setProperty('--base-text-decoration', 'underline');
                    this.ActiveFontStyle['text-decoration'] = 'underline';
                } else {
                    document.documentElement.style.removeProperty('--base-text-decoration');
                    delete this.ActiveFontStyle['text-decoration'];
                }
            }
            if (theme.Font.FontFamilyName != null) {
                if (theme.Font.FontFamilyName) {
                    document.documentElement.style.setProperty('--base-font-family', theme.Font.FontFamilyName);
                    this.ActiveFontStyle['font-family'] = theme.Font.FontFamilyName;
                } else {
                    document.documentElement.style.removeProperty('--base-font-family');
                    delete this.ActiveFontStyle['font-family'];
                }
            }
            if (theme.Font.FontSize != null) {
                if (theme.Font.FontSize) {
                    this.ActiveFontStyle['font-size'] = theme.Font.FontSize + 'pt';
                    document.documentElement.style.setProperty('--base-font-size', this.ActiveFontStyle['font-size']);
                } else {
                    document.documentElement.style.removeProperty('--base-font-size');
                    delete this.ActiveFontStyle['font-size'];
                }
            }
        } else {
            document.documentElement.style.removeProperty('--base-font-weight');
            document.documentElement.style.removeProperty('--base-font-size');
            document.documentElement.style.removeProperty('--base-font-family');
            document.documentElement.style.removeProperty('--base-text-decoration');
            document.documentElement.style.removeProperty('--base-font-style');

            delete this.ActiveFontStyle['font-weight'];
            delete this.ActiveFontStyle['font-style'];
            delete this.ActiveFontStyle['text-decoration'];
            delete this.ActiveFontStyle['font-family'];
            delete this.ActiveFontStyle['font-size'];
        }

        this.AcitveTheme = theme;
        this.ActiveThemeChanged.next(theme);
        if (theme.Primary) {
            const primHex = Color.HexFromColor(theme.Primary);
            this.materialCssVarsService.setPrimaryColor(primHex);
            document.documentElement.style.setProperty('--primary-color', primHex);
            document.documentElement.style.setProperty('--primary-gradient-bg', theme.PrimaryGradient);
        }
        if (theme.Accent) {
            const accentHex = Color.HexFromColor(theme.Accent);
            this.materialCssVarsService.setAccentColor(accentHex);
            document.documentElement.style.setProperty('--accent-color', accentHex);
        }
        if (theme.Warn) {
            const warnHex = Color.HexFromColor(theme.Warn);
            this.materialCssVarsService.setWarnColor(warnHex);
            document.documentElement.style.setProperty('--warn-color', warnHex);
        }
        if (theme.Background) {
            if (!theme.IsDarkTheme) { 
                document.documentElement.style.setProperty('--background-color', Color.GetStyleText(theme.Background));
            }
        }
        if (theme.DarkmodeBackground) {
            if (theme.IsDarkTheme) {   
                document.documentElement.style.setProperty('--background-color', Color.GetStyleText(theme.DarkmodeBackground));
            }
        }
        if (theme.PaperColor) {
            if (!theme.IsDarkTheme) {
                document.documentElement.style.setProperty('--paper-bg', Color.GetStyleText(theme.PaperColor));
            }
        }
        if (theme.DarkmodePaperColor) {
            if (theme.IsDarkTheme) {
                document.documentElement.style.setProperty('--paper-bg', Color.GetStyleText(theme.DarkmodePaperColor));
            }
        }
        if (theme.BorderColor) {
            document.documentElement.style.setProperty('--border-color', Color.GetStyleText(theme.BorderColor));
        }
        if (theme.BorderThickness) {
            document.documentElement.style.setProperty('--border-thickness', LayoutUnit.ToStyle(theme.BorderThickness));
        }
        if (theme.BorderRadiusBottomLeft) {
            document.documentElement.style.setProperty('--border-radius-bottom-left', LayoutUnit.ToStyle(theme.BorderRadiusBottomLeft));
        }
        if (theme.BorderRadiusBottomRight) {
            document.documentElement.style.setProperty('--border-radius-bottom-right', LayoutUnit.ToStyle(theme.BorderRadiusBottomRight));
        }
        if (theme.BorderRadiusTopLeft) {
            document.documentElement.style.setProperty('--border-radius-top-left', LayoutUnit.ToStyle(theme.BorderRadiusTopLeft));
        }
        if (theme.BorderRadiusTopRight) {
            document.documentElement.style.setProperty('--border-radius-top-right', LayoutUnit.ToStyle(theme.BorderRadiusTopRight));
        }
        if (theme.BorderColor) {
            document.documentElement.style.setProperty('--border-color', Color.GetStyleText(theme.BorderColor));
        }
        if (theme.BorderColor) {
            document.documentElement.style.setProperty('--border-color', Color.GetStyleText(theme.BorderColor));
        }
        if (theme.BorderColor) {
            document.documentElement.style.setProperty('--border-color', Color.GetStyleText(theme.BorderColor));
        }
        if (theme.BorderFocusColor) {
            document.documentElement.style.setProperty('--border-focus-color', Color.GetStyleText(theme.BorderFocusColor));
        }
        if (theme.BorderHoverColor) {
            document.documentElement.style.setProperty('--border-hover-color', Color.GetStyleText(theme.BorderHoverColor));
        }
        if (theme.ShadowColor) {
            document.documentElement.style.setProperty('--shadow-color', Color.GetStyleText(theme.ShadowColor));
        }
        if (theme.Foreground) {
            if (!theme.IsDarkTheme) {   
                document.documentElement.style.setProperty('--foreground-color', Color.GetStyleText(theme.Foreground));
            }
        }
        if (theme.DarkmodeForegroundText) {
            if (theme.IsDarkTheme) {
                document.documentElement.style.setProperty('--foreground-text-color', Color.GetStyleText(theme.DarkmodeForegroundText));
                document.documentElement.style.setProperty('--foreground-color', Color.GetStyleText(theme.DarkmodeForegroundText));
            }
        }
        if (theme.ForegroundText) {
            if (!theme.IsDarkTheme) { 
                document.documentElement.style.setProperty('--foreground-text-color', Color.GetStyleText(theme.ForegroundText));
            }
        }
        if (theme.ForegroundTextHover) {
            document.documentElement.style.setProperty('--foreground-text-hover-color', Color.GetStyleText(theme.ForegroundTextHover));
        }

        if (theme.DataTableHeaderBackground) {
            document.documentElement.style.setProperty('--datatable-header-background', Color.GetStyleText(theme.DataTableHeaderBackground));
        }
        if (theme.DataTableHeaderForeground) {
            document.documentElement.style.setProperty('--datatable-header-foreground', Color.GetStyleText(theme.DataTableHeaderForeground));
        }
        if (theme.DataTableCellText) {
            document.documentElement.style.setProperty('--datatable-cell-text', Color.GetStyleText(theme.DataTableCellText));
        }
        if (theme.DataTableCellTextHover) {
            document.documentElement.style.setProperty('--datatable-cell-text-hover', Color.GetStyleText(theme.DataTableCellTextHover));
        }
        const dtcbh = Color.GetStyleText(theme.DataTableCellBackgroundHover);
        if (dtcbh) {
            document.documentElement.style.setProperty('--datatable-cell-background-hover', dtcbh);
        } else {
            document.documentElement.style.setProperty('--datatable-cell-background-hover', 'var(--primary-color)');
        }
        if (theme.DataTableLine) {
            document.documentElement.style.setProperty('--datatable-line', Color.GetStyleText(theme.DataTableLine));
        }
        if (theme.SpreadSheetHeaderBackground) {
            document.documentElement.style.setProperty('--spreadsheet-header-background', Color.GetStyleText(theme.SpreadSheetHeaderBackground));
        }
        if (theme.SpreadSheetHeaderForeground) {
            document.documentElement.style.setProperty('--spreadsheet-header-foreground', Color.GetStyleText(theme.SpreadSheetHeaderForeground));
        }
        if (theme.SpreadSheetCellText) {
            document.documentElement.style.setProperty('--spreadsheet-cell-text', Color.GetStyleText(theme.SpreadSheetCellText));
        }
        if (theme.SpreadSheetCellTextHover) {
            document.documentElement.style.setProperty('--spreadsheet-cell-text-hover', Color.GetStyleText(theme.SpreadSheetCellTextHover));
        }
        const sscbh = Color.GetStyleText(theme.SpreadSheetCellBackgroundHover);
        if (sscbh) {
            document.documentElement.style.setProperty('--spreadsheet-cell-background-hover', sscbh);
        } else {
            document.documentElement.style.setProperty('--spreadsheet-cell-background-hover', 'var(--primary-color)');
        }
        if (theme.SpreadSheetLine) {
            document.documentElement.style.setProperty('--spreadsheet-line', Color.GetStyleText(theme.SpreadSheetLine));
        }
       
        if (theme.BackgroundDisabledButton) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.BackgroundDisabledButton, Color.ColorToRGBString(theme.BackgroundDisabledButton));
        } 
        if (theme.DarkAccentText) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.DarkAccentText, Color.ColorToRGBString(theme.DarkAccentText));
        } 
        if (theme.LightAccentText) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.LightAccentText, Color.ColorToRGBString(theme.LightAccentText));
        } 
        if (theme.DarkWarnText) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.DarkWarnText, Color.ColorToRGBString(theme.DarkWarnText));
        } 
        if (theme.LightWarnText) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.LightWarnText, Color.ColorToRGBString(theme.LightWarnText));
        } 
        if (theme.BackgroundStatusBar) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.BackgroundStatusBar, Color.ColorToRGBString(theme.BackgroundStatusBar));
        } 
        if (theme.BackgroundAppBar) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.BackgroundAppBar, Color.ColorToRGBString(theme.BackgroundAppBar));
        }
        if (theme.BackgroundBackground) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.BackgroundBackground, Color.ColorToRGBString(theme.BackgroundBackground));
        }
        if (theme.BackgroundHover) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.BackgroundHover, Color.ColorToRGBString(theme.BackgroundHover));
            this.materialCssVarsService.setVariable(MaterialCssVariables.BackgroundHoverAlpha, Color.ColorToAlpha(theme.BackgroundHover));
        } 
        if (theme.BackgroundCard) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.BackgroundCard, Color.ColorToRGBString(theme.BackgroundCard));
        }
        if (theme.BackgroundDialog) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.BackgroundDialog, Color.ColorToRGBString(theme.BackgroundDialog));
        } 
        if (theme.BackgroundDisabledButton) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.BackgroundDisabledButton, Color.ColorToRGBString(theme.BackgroundDisabledButton));
            this.materialCssVarsService.setVariable(MaterialCssVariables.BackgroundDisabledButtonAlpha, Color.ColorToAlpha(theme.BackgroundDisabledButton));
        } 
        if (theme.BackgroundRaisedButton) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.BackgroundRaisedButton, Color.ColorToRGBString(theme.BackgroundRaisedButton));
        } 
        if (theme.BackgroundFocusedButton) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.BackgroundFocusedButton, Color.ColorToRGBString(theme.BackgroundFocusedButton));
        } 
        if (theme.BackgroundSelectedButton) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.BackgroundSelectedButton, Color.ColorToRGBString(theme.BackgroundSelectedButton));
        } 
        if (theme.BackgroundSelectedDisabledButton) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.BackgroundSelectedDisabledButton, Color.ColorToRGBString(theme.BackgroundSelectedDisabledButton));
        } 
        if (theme.BackgroundDisabledButtonToggle) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.BackgroundDisabledButtonToggle, Color.ColorToRGBString(theme.BackgroundDisabledButtonToggle));
        } 
        if (theme.BackgroundUnselectedChip) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.BackgroundUnselectedChip, Color.ColorToRGBString(theme.BackgroundUnselectedChip));
        } 
        if (theme.BackgroundDisabledListOption) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.BackgroundDisabledListOption, Color.ColorToRGBString(theme.BackgroundDisabledListOption));
        } 

        if (theme.ForegroundBase) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundBase, Color.ColorToRGBString(theme.ForegroundBase));
        } 
        if (theme.ForegroundDivider) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundDivider, Color.ColorToRGBString(theme.ForegroundDivider));
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundDividerAlpha, Color.ColorToAlpha(theme.ForegroundDivider));
        } 
        if (theme.ForegroundDividers) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundDividers, Color.ColorToRGBString(theme.ForegroundDividers));
        } 
        if (theme.ForegroundDisabled) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundDisabled, Color.ColorToRGBString(theme.ForegroundDisabled));
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundDisabledAlpha, Color.ColorToAlpha(theme.ForegroundDisabled));
        } 
        if (theme.ForegroundDisabledButton) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundDisabledButton, Color.ColorToRGBString(theme.ForegroundDisabledButton));
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundDisabledButtonAlpha, Color.ColorToAlpha(theme.ForegroundDisabledButton));
        } 
        if (theme.ForegroundDisabledText) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundDisabledText, Color.ColorToRGBString(theme.ForegroundDisabledText));
        } 
        if (theme.ForegroundElevation) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundElevation, Color.ColorToRGBString(theme.ForegroundElevation));
        } 
        if (theme.ForegroundHintText) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundHintText, Color.ColorToRGBString(theme.ForegroundHintText));
        } 
        if (theme.ForegroundSecondaryText) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundSecondaryText, Color.ColorToRGBString(theme.ForegroundSecondaryText));
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundSecondaryTextAlpha, Color.ColorToAlpha(theme.ForegroundSecondaryText));
        } 
        if (theme.ForegroundIcon) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundIcon, Color.ColorToRGBString(theme.ForegroundIcon));
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundIconAlpha, Color.ColorToAlpha(theme.ForegroundIcon));
        } 
        if (theme.ForegroundIcons) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundIcons, Color.ColorToRGBString(theme.ForegroundIcons));
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundIconsAlpha, Color.ColorToAlpha(theme.ForegroundIcons));
        } 
        if (theme.DarkmodeForegroundText) {
            if (theme.IsDarkTheme) {
                this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundText, Color.ColorToRGBString(theme.DarkmodeForegroundText));
            }
            if (theme.IsDarkTheme) {
                this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundTextAlpha, Color.ColorToRGBString(theme.DarkmodeForegroundText));
            }
        }
        if (theme.ForegroundText) {
            if (!theme.IsDarkTheme) {
                this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundText, Color.ColorToRGBString(theme.ForegroundText));
            }
            if (!theme.IsDarkTheme) {
                this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundTextAlpha, Color.ColorToRGBString(theme.ForegroundText));
            }            
        } 
        if (theme.ForegroundSliderMin) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundSliderMin, Color.ColorToRGBString(theme.ForegroundSliderMin));
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundSliderMinAlpha, Color.ColorToAlpha(theme.ForegroundSliderMin));
        } 
        if (theme.ForegroundSliderOff) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundSliderOff, Color.ColorToRGBString(theme.ForegroundSliderOff));
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundSliderOffAlpha, Color.ColorToAlpha(theme.ForegroundSliderOff));
        } 
        if (theme.ForegroundSliderOffActive) {
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundSliderOffActive, Color.ColorToRGBString(theme.ForegroundSliderOffActive));
            this.materialCssVarsService.setVariable(MaterialCssVariables.ForegroundSliderOffActiveAlpha, Color.ColorToAlpha(theme.ForegroundSliderOffActive));
        }
        if (theme.ChartPalette) {
            CacheService.ReadChartPaletteValues(theme.ChartPalette).then(x => {
                this.ActiveThemeChartPalette = x;
            });
        }
    }
    public static LoadCustomCSSVariables() {
        this.metaService.GetAllCustomCSSVariables().subscribe((variables) => {
            if (variables) {
                LayoutService.CustomCSSVariables = variables;
                ThemeHelper.FillVariableMap();
            }
        })
    }

    private static FillVariableMap() {
        LayoutService.CSSVariablesMap = {};
        LayoutService.SystemCSSVariables.forEach((variable) => {
            LayoutService.CSSVariablesMap[variable.SID] = variable;
        });
        if (LayoutService.CustomCSSVariables) {
            LayoutService.CustomCSSVariables.forEach((variable) => {
                LayoutService.CSSVariablesMap[variable.SID] = variable;
            });
        }
    }

    public static SetCustomVariables() {
        this.CustomFontSizes = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];
        this.CustomFonts = [];
        this.CustomFontsMap = {};
        if (LayoutService.CustomCSSVariables) {
            LayoutService.CustomCSSVariables.forEach((variable) => {
                document.documentElement.style.setProperty(variable.VariableName, variable.Value);
            });
        } else {
            this.metaService.GetAllCustomCSSVariables().subscribe((variables) => {
                if (variables) {
                    LayoutService.CustomCSSVariables = variables;
                    ThemeHelper.FillVariableMap();
                    variables.forEach((variable) => {
                        if (variable.VariableName) {
                            document.documentElement.style.setProperty(variable.VariableName, variable.Value);
                        }
                        if (variable.Type == CSSVarType.FontSize) {
                            try {
                                this.CustomFontSizes.push(parseInt(variable.Value));
                            } catch {}
                        }
                        if (variable.Type == CSSVarType.Font) {
                            this.CustomFonts.push(variable.Name);
                            this.CustomFontsMap[variable.Name] = variable;
                        }
                    });
                    MediaService.CSSFontList.next(ThemeHelper.CustomFonts)
                }
                this.CustomFontSizes = this.CustomFontSizes.sort((a, b) => {
                    if (a > b) {
                        return 1;
                    }
                    if (a < b) {
                        return -1;
                    }
                    return 0;
                });
                let fontSizeStyles = [];
                this.CustomFontSizes.forEach((size) => {
                    fontSizeStyles.push('.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="' + size + 'px"]::before, .ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value="' + size + 'px"]::before { content: "' + size + '"; }');
                });
                if (fontSizeStyles.length > 0) {
                    this.AppendCSS(fontSizeStyles);
                }
            })
        }
    }

    public static AppendCSS(value) {
        if (value && value.length > 0) {
            const head = document.getElementsByTagName('head')[0];
            const style = document.createElement('style');
            style.type = 'text/css';
            value.forEach((rule) => {
                style.appendChild(document.createTextNode(rule));
            });
            head.appendChild(style);
        }
    }

    public static SetAppBuilderTheme(theme) {
        if (theme) {
            if (theme.HoverBackground) {
                const primHex = Color.HexFromColor(theme.HoverBackground);
                document.documentElement.style.setProperty('--appbuilder-hover-background', primHex);
            }
            if (theme.Primary) {
                const primHex = Color.HexFromColor(theme.Primary);
                document.documentElement.style.setProperty('--appbuilder-primary-color', primHex);
            }
            if (theme.ActionsBackground) {
                const primHex = Color.HexFromColor(theme.ActionsBackground);
                document.documentElement.style.setProperty('--appbuilder-actions-background', primHex);
            }
            if (theme.Actions) {
                const primHex = Color.HexFromColor(theme.Actions);
                document.documentElement.style.setProperty('--appbuilder-actions-color', primHex);
            }
            if (theme.Hover) {
                const primHex = Color.HexFromColor(theme.Hover);
                document.documentElement.style.setProperty('--appbuilder-hover-color', primHex);
            } else {
                document.documentElement.style.setProperty('--appbuilder-hover-color', '#cfcfcf');
            }
            if (theme.ToolboxBackground) {
                const primHex = Color.HexFromColor(theme.ToolboxBackground);
                document.documentElement.style.setProperty('--appbuilder-toolbox-background', primHex);
            }
            if (theme.ToolboxColor) {
                const primHex = Color.HexFromColor(theme.ToolboxColor);
                document.documentElement.style.setProperty('--appbuilder-toolbox-color', primHex);
            }
            //#region WorkflowDefaults
            if (theme.WorkflowBackground) {
                const primHex = Color.HexFromColor(theme.WorkflowBackground);
                document.documentElement.style.setProperty('--workflow-background', primHex);
            }
            if (theme.WorkflowColor) {
                const primHex = Color.HexFromColor(theme.WorkflowColor);
                document.documentElement.style.setProperty('--workflow-color', primHex);
            }

            if (theme.WorkflowConditionBackground) {
                const primHex = Color.HexFromColor(theme.WorkflowConditionBackground);
                document.documentElement.style.setProperty('--workflow-condition-background', primHex);
            }
            if (theme.WorkflowConditionColor) {
                const primHex = Color.HexFromColor(theme.WorkflowConditionColor);
                document.documentElement.style.setProperty('--workflow-condition-color', primHex);
            }

            if (theme.WorkflowRemoveBackground) {
                const primHex = Color.HexFromColor(theme.WorkflowRemoveBackground);
                document.documentElement.style.setProperty('--workflow-remove-background', primHex);
            }
            if (theme.WorkflowRemoveColor) {
                const primHex = Color.HexFromColor(theme.WorkflowRemoveColor);
                document.documentElement.style.setProperty('--workflow-remove-color', primHex);
            }

            if (theme.WorkflowActionBackground) {
                const primHex = Color.HexFromColor(theme.WorkflowActionBackground);
                document.documentElement.style.setProperty('--workflow-action-background', primHex);
            }
            if (theme.WorkflowActionColor) {
                const primHex = Color.HexFromColor(theme.WorkflowActionColor);
                document.documentElement.style.setProperty('--workflow-action-color', primHex);
            }

            if (theme.WorkflowModuleBackground) {
                const primHex = Color.HexFromColor(theme.WorkflowModuleBackground);
                document.documentElement.style.setProperty('--workflow-module-background', primHex);
            }
            if (theme.WorkflowModuleColor) {
                const primHex = Color.HexFromColor(theme.WorkflowModuleColor);
                document.documentElement.style.setProperty('--workflow-module-color', primHex);
            }

            if (theme.WorkflowStartBackground) {
                const primHex = Color.HexFromColor(theme.WorkflowStartBackground);
                document.documentElement.style.setProperty('--workflow-start', primHex);
            }
            if (theme.WorkflowStartColor) {
                const primHex = Color.HexFromColor(theme.WorkflowStartColor);
                document.documentElement.style.setProperty('--workflow-start-color', primHex);
            }
            if (theme.WorkflowEndBackground) {
                const primHex = Color.HexFromColor(theme.WorkflowEndBackground);
                document.documentElement.style.setProperty('--workflow-end', primHex);
            }
            if (theme.WorkflowEndColor) {
                const primHex = Color.HexFromColor(theme.WorkflowEndColor);
                document.documentElement.style.setProperty('--workflow-end-color', primHex);
            }
            if (theme.WorkflowConnector) {
                const primHex = Color.HexFromColor(theme.WorkflowConnector);
                document.documentElement.style.setProperty('--workflow-connector-stroke-color', primHex);
            }
            if (theme.WorkflowConnectorHover) {
                const primHex = Color.HexFromColor(theme.WorkflowConnectorHover);
                document.documentElement.style.setProperty('--workflow-connector-stroke-hover', primHex);
            }
            if (theme.WorkflowConnectorLabelBackground) {
                const primHex = Color.HexFromColor(theme.WorkflowConnectorLabelBackground);
                document.documentElement.style.setProperty('--workflow-connector-label-background', primHex);
            }
            if (theme.WorkflowConnectorLabelText) {
                const primHex = Color.HexFromColor(theme.WorkflowConnectorLabelText);
                document.documentElement.style.setProperty('--workflow-connector-label-text', primHex);
            }
            if (theme.WorkflowBreakpointBackground) {
                const primHex = Color.HexFromColor(theme.WorkflowBreakpointBackground);
                document.documentElement.style.setProperty('--workflow-breakpoint-background', primHex);
            }
            if (theme.WorkflowBreakpointColor) {
                const primHex = Color.HexFromColor(theme.WorkflowBreakpointColor);
                document.documentElement.style.setProperty('--workflow-breakpoint-color', primHex);
            }
            if (theme.WorkflowBreakpointSelectedBackground) {
                const primHex = Color.HexFromColor(theme.WorkflowBreakpointSelectedBackground);
                document.documentElement.style.setProperty('--workflow-breakpoint-selected-background', primHex);
            }
            if (theme.WorkflowBreakpointSelectedColor) {
                const primHex = Color.HexFromColor(theme.WorkflowBreakpointSelectedColor);
                document.documentElement.style.setProperty('--workflow-breakpoint-selected-color', primHex);
            }
            //#endregion
            //#region WorkflowGroups
            let keys = Object.keys(theme);
            let filteredKeys = keys.filter((value) => value.startsWith('WorkflowGroup_'));
            if (filteredKeys && filteredKeys.length > 0) {
                filteredKeys.forEach((key) => {
                    const primHex = Color.HexFromColor(theme[key]);
                    let prop = key.replace('WorkflowGroup_', '');
                    if (key.endsWith('_Background')) {
                        prop = prop.replace('_Background', '-background');
                    } else {
                        prop = prop.replace('_Color', '-color');
                    }
                    document.documentElement.style.setProperty('--workflow-group-' + prop, primHex);
                });
            }
            //#endregion
        }
        
    }
}
