import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { deserialize, plainToClass } from 'class-transformer';
import { ChangeKeyDialog } from '../../components/dialogs/changekeydialog/change.key.dialog';
import { MessageBoxHelper } from '../../components/dialogs/messagebox/messagebox.dialog';
import { LayoutWizardDialog, LayoutWizardInitArgs } from '../../components/wizards/layout/layout.wizard.dialog';
import { ArrayHelpers, TranslateFormatText } from '../../helpers/array.helpers';
import { CapsuleHelper } from '../../helpers/capsule.helper';
import { ClipboardHelper } from '../../helpers/clipboard.helper';
import { LayoutHelper } from '../../helpers/layout.helper';
import { NotificationHelper } from '../../helpers/notification.helper';
import { PageEditClient } from '../../helpers/page.edit.client';
import { MessageBoxButtons } from '../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../models/enums/messageboxresult.enum';
import { Layout } from '../../models/layout.model';
import { LayoutEditService } from '../../models/layout/layout.edit.client';
import { LayoutService } from '../../services/layout.service';
import { MetaService } from '../../services/meta.service';
import { NavigationService } from '../../services/navigation.service';
import { SideNavService } from '../../services/sidenav.service';
import { UsersService } from '../../services/users.service';
import { VersioningOverview } from '../versioningoverview/versioning.overview';

@Component({
    selector: 'pages-version-settings',
    templateUrl: './pages.version.settings.html',
    styleUrls: ['./pages.version.settings.css']
})
export class PagesVersionSettings implements OnInit, OnDestroy {
    ShowCopyButton = true;
    ShowDuplicateButton = false;
    ShowPasteButton = false;
    ShowCapsules = false;
    CopyType = "LayoutCopy";
    ClipboardChanged;
    Pages = [];
    ActiveUser;
    UserSub;

    static GetSettingsEntry() {
        return {
            Caption: '@@Seiten',
            ID: 'pages',
            Icon: 'tab',
            Index: 10,
            Parent: 'layout',
            Security: {
                Name: 'evidanza.App.Shared.Security.LayoutRight',
                Value: 2
            },
            Content: PagesVersionSettings
        };
    }

    constructor(private cdRef: ChangeDetectorRef, private service: MetaService,
        private translate: TranslateService, private router: Router) {
    }

    ngOnInit(): void {
        NavigationService.SelectedPage.next('content');
        this.UserSub = UsersService.ActiveUser.subscribe(x => {
            this.ActiveUser = x;
        });
        if (ClipboardHelper.Inititalized) {
            this.ShowCopyButton = true;
            this.ShowDuplicateButton = false;
            if (ClipboardHelper.ClipboardContent && ClipboardHelper.ClipboardContent.type &&
                ClipboardHelper.ClipboardContent.type == this.CopyType) {
                this.ShowPasteButton = true;
            } else {
                this.ShowPasteButton = false;
            }
            this.ClipboardChanged = ClipboardHelper.ClipboardChanged.subscribe((data: any) => {
                if (data && data.type && data.type == this.CopyType) {
                    this.ShowPasteButton = true;
                } else {
                    this.ShowPasteButton = false;
                }
            });
        } else {
            this.ShowCopyButton = true;
            this.ShowDuplicateButton = false;
        }
        CapsuleHelper.GetCapsuleTags().then(r => {
            if (r) {
                this.ShowCapsules = true;
            }
        });
        this.refresh();
    }

    ngOnDestroy(): void {
        NavigationService.SelectedPage.next('settings');
        if (this.UserSub) {
            this.UserSub.unsubscribe();
            this.UserSub = null;
        }
        if (this.ClipboardChanged) {
            this.ClipboardChanged.unsubscribe();
            this.ClipboardChanged = null;
        }
    }

    checkItemStyle(data) {
        data.previewURL = data.URL.includes('workspace') ? '/'+data.URL.split('/')[2] : data.URL

        if (data) {
            const style = {};
            if (data.IsCapsule) {
                style['font-style'] = 'italic';
            }
            if (data.IsOverridden) {
                style['font-weight'] = 'bold';
            }
            if (data.CanEditInfo) {
                if (data.CanEditInfo.CanEdit) {
                    if (this.ActiveUser && data.CanEditInfo.EditUserID == this.ActiveUser.SID) {
                        style['text-decoration'] = 'underline';
                    }
                } else {
                    style['text-decoration'] = 'line-through';
                }
            }
            data.Style = style;
        }
    }

    addItem() {
        const initArgs = new LayoutWizardInitArgs();
        initArgs.Page = {
            Name: this.translate.instant('@@Neue Seite'),
            URL: '/'
        };
        initArgs.ShowCancel = true;
        initArgs.CheckURL = true;
        LayoutWizardDialog.ShowDialog(initArgs, (x) => {
            if (x && x.Layout) {
                x.Layout.PageName = x.Name;
                x.Layout.CapsuleTag = x.CapsuleTag;
                this.service.AddPage(x.Layout, x.URL).subscribe(pi => {
                    if (pi) {
                        this.Pages.push(pi);
                        this.checkItemStyle(pi);
                        ArrayHelpers.sortAlphabetical(this.Pages, 'Name');
                        this.editLayout(pi);
                    }
                });
            }
        });
    }

    refresh() {
        LayoutService.Loading.next(true);
        this.Pages = [];
        this.service.LoadAllPages().subscribe(result => {
            if (result) {
                result.forEach(x => this.checkItemStyle(x));
                this.Pages = result;
            }
            LayoutService.Loading.next(false);
            this.cdRef.detectChanges();
        });
    }

    editLayout(page) {
        this.service.LoadPage(page.URL).subscribe(l => {
            if (l) {
                const layout = plainToClass(Layout, l);
                const editClient = new PageEditClient(layout, page, this.service);
                editClient.PageInfoChanged.subscribe(pi => {
                    let index = -1;
                    if (this.Pages.some((p, i) => {
                        if (p.SID == pi.SID) {
                            index = i;
                            return true;
                        }
                    })) {
                        this.Pages.splice(index, 1, pi);
                        this.checkItemStyle(pi);
                    }
                });
                LayoutEditService.SetNext(editClient, true);
            }
        });
    }

    copyItem(page) {
        if (page) {
            this.service.LoadPage(page.URL).subscribe(l => {
                if (l && ClipboardHelper.Inititalized) {
                    const layout = plainToClass(Layout, l);
                    const entry = {
                        type: this.CopyType,
                        content: JSON.stringify(layout)
                    };
                    ClipboardHelper.CopyToClipboard(entry);
                    NotificationHelper.Info("@@CopyToClipboard", "@@ElementCopiedToClipboard");
                } else {
                    NotificationHelper.Info("@@Clipboard Permissions not granted","@@ClipboardPermission");
                }
            });
        }
    }

    duplicateItem(page) {
        if (page) {
            this.service.LoadPage(page.URL).subscribe(l => {
                if (l) {
                    const layout = plainToClass(Layout, l);
                    this.setDuplicateItem(JSON.stringify(layout));
                }
            });
        }
    }

    private setDuplicateItem(json) {
        const copy = deserialize(Layout, json);
        delete copy['_Id'];
        delete copy['_version'];
        copy['PageName'] += ' (copy)';
        const forbiddenKeys = [];
        this.Pages.forEach(x => {
            forbiddenKeys.push(x.URL);
        });
        ChangeKeyDialog.ShowDialog(forbiddenKeys, true, (key,pg) => {
            if (key) {
                const urlCheck = { URL: key };
                LayoutHelper.checkURL(urlCheck);
                this.service.IsValidPageKey(urlCheck.URL).subscribe(x => {
                    if (x) {
                        if(pg){
                            copy["PageName"] = pg;
                        }
                        this.service.AddPage(copy, urlCheck.URL).subscribe(pi => {
                            if (pi) {
                                this.Pages.push(pi);
                                this.checkItemStyle(pi);
                                ArrayHelpers.sortAlphabetical(this.Pages, 'Name');
                                this.editLayout(pi);
                            }
                        });
                    }
                });
            }
        },copy["PageName"],true);
    }

    pasteItem() {
        this.setDuplicateItem(ClipboardHelper.ClipboardContent.content);
    }

    deleteItem(page) {
        if (page && page.CanEditInfo) {
            if (page.CanEditInfo.CanEdit) {
                if (this.ActiveUser && page.CanEditInfo.EditUserID == this.ActiveUser.SID) {
                    return;
                }
            } else {
                return;
            }
            const text = '@@Sind Sie sicher, dass Sie die Seite \'{0}\' loeschen moechten? ' +
                'Die Seite kann somit in bestehenden Navigationsstrukturen nicht mehr gefunden werden.';
            const tft = new TranslateFormatText(text);
            tft.FormatParams.push(page.Caption);
            MessageBoxHelper.ShowDialog(tft, new TranslateFormatText('@@Seite loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x == MessageBoxResult.Yes) {
                        this.deleteItemInternal(page, false);
                    }
                });
        }
    }

    private deleteItemInternal(page, ignoreVersions: boolean) {
        this.service.TryDeletePage(page.SID, ignoreVersions).subscribe(result => {
            if (result) {
                if (result.Deleted) {
                    this.refresh();
                    const success = new TranslateFormatText('@@Seite \'{0}\' erfolgreich geloescht.');
                    success.FormatParams.push(page.Caption);
                    NotificationHelper.Success(success, '@@Seite loeschen');
                } else if (result.IsLocked) {
                    const text = new TranslateFormatText('@@LockedBy{0}');
                    text.FormatParams.push(result.LockedBy);
                    MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@LockedObject'),
                        MessageBoxButtons.Ok, MessageBoxIcon.Information);
                } else if (result.MultipleVersions) {
                    const text = new TranslateFormatText('@@MultipleVersionsInfoText');
                    text.FormatParams.push(result.LockedBy);
                    MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@MultipleVersionsInfoHeader'),
                        MessageBoxButtons.None, MessageBoxIcon.Information, 500, null, [
                        { Caption: '@@DeleteAllVersions', ID: 0 },
                        { Caption: '@@GoToVersionsOverview', ID: 1 },
                        { Caption: '@@Cancel', ID: 2 }
                    ]).then(result => {
                        if (result == 0) {
                            this.deleteItemInternal(page, true);
                        } else if (result == 1) {
                            const navStruct = SideNavService.SelectedNavigationStructure.getValue();
                            if (navStruct) {
                                this.router.navigateByUrl(navStruct + VersioningOverview.Path);
                            }
                        }
                    });
                }
            }
        });
    }

    editKey(page) {
        if (page) {
            const forbiddenKeys = [];
            this.Pages.forEach(x => {
                forbiddenKeys.push(x.URL);
            });
            ChangeKeyDialog.ShowDialog(forbiddenKeys, true, (key) => {
                if (key) {
                    const urlCheck = { URL: key };
                    LayoutHelper.checkURL(urlCheck);
                    this.service.ChangePageKey(page.SID, urlCheck.URL).subscribe(x => {
                        if (typeof x === 'number') {
                            if (x == 0) {
                                page.URL = urlCheck.URL;
                                NotificationHelper.Success('@@SuccessfullyReplacedKeyText', '@@SuccessfullyReplacedKeyHeader');
                                this.refresh();
                            } else {
                                ChangeKeyDialog.ShowMessage(x);
                            }
                        }
                    });
                }
            });
        }
    }
}
