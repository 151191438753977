import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { ExternalRestEndPoint } from 'src/app/services/external-rest-endpoints.service';
import { UsersService } from 'src/app/services/users.service';
import { LayoutService } from 'src/app/services/layout.service';
import config from '../../../assets/config.json';
@Component({
  selector: 'lib-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush, // Use OnPush strategy
})
export class SidebarComponent implements OnInit {
  addonList = []; // Initialize as an empty array
  sidebarList = [
    {
      path: '/default/settings/dashboard',
      caption: 'Dashboard',
      icon: 'dashboard',
    },

    {
      path: '/default/settings/data/workflow/wf-service',
      caption: 'Workflows',
      icon: 'device_hub',
    },

    {
      path: '/default/settings/front-pages/marketplace-apps',
      caption: 'Pre-built Apps',
      icon: 'apps',
    },
  ];

  constructor(
    private addonService: ExternalRestEndPoint,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private userService: UsersService,
    private router: Router,
    private cdref: ChangeDetectorRef
  ) {}
  isHaveRoutesAccess = true;

  ngOnInit(): void {
    const userRole = JSON.parse(localStorage.getItem('user'))?.Roles;
    if (userRole && userRole.length > 0 && userRole[0] === config.FREE_USER_ID) {
      this.isHaveRoutesAccess = false;
    }
    if (this.isHaveRoutesAccess) {
      const integrationRoute = {
        path: '/default/settings/front-pages/integration',
        caption: 'Integrations',
        icon: 'terminal',
      };
      const themesRoute = {
        path: '/default/settings/front-pages/themes',
        caption: 'Themes',
        icon: 'palette',
      };
      this.sidebarList.splice(1, 0, integrationRoute);
      this.sidebarList.splice(3, 0, themesRoute);
    }

    this.getAddonList();
    this.addonService.getAddonsData$.subscribe((data) => {
      if (data) {
        this.addonList = this.getActiveAddons(data);
        this.cdref.detectChanges();
      }
    });
  }

  updateSidebarLinks() {}
  getActiveAddons(arr) {
    arr.forEach((addon) => {
      this.matIconRegistry.addSvgIcon(addon.IconName, this.domSanitizer.bypassSecurityTrustResourceUrl(addon.IconLink));
    });
    this.cdref.detectChanges();
    return arr.filter((x) => x.IsActive);
  }

  getAddonList() {
    this.loading(true);
    this.addonService.GetAddons({}).subscribe((res) => {
      if (res) {
        const link = this.getAddonIconLink(res);
        if (this.userService.minioIconLink) {
          this.userService.minioIconLink.next(link);
        }
        this.addonList = this.getActiveAddons(res);
      }
      this.cdref.detectChanges();
      this.loading(false);
    });
  }

  getAddonIconLink(res) {
    let _link = '';
    const _obj = res[0];
    if (_obj && _obj?.IconLink) {
      const value = _obj.IconLink.split(_obj.IconName);
      _link = value[0];
    }
    return _link;
  }

  loading(value) {
    LayoutService.Loading.next(value);
  }
  handleRedirection(link: string) {
    // if (caption === 'Workflows') {
    // this.router.navigate(['/default/settings/data/workflow/wf-service']);
    this.router.navigate([link]);
    // }
  }
}
