import { ChangeDetectorRef, Inject, Component, Input } from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import { IBaseComponent } from '../base.component';
import { LayoutService } from '../../../services/layout.service';
import {
	PROPERTIES,
	PROPERTYGROUPS,
} from '../../../services/dynamic.component.service';
import { MetaService } from '../../../services/meta.service';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ComponentPortal } from '@angular/cdk/portal';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { DataModelService } from '../../../services/datamodel.service';
import { RTLHelper } from '../../../helpers/rtl.helper';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { DataService } from '../../../services/data.service';
import { RequestFilter } from '../../../models/rest/requestfilter';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { MultiPropertyChangeValue } from '../../../models/layout/layout.change.model';
import {
	RequestOptionsColumn,
	RequestOptionsControl,
	RequestOptionsDialogArgs,
} from '../../../components/common/requestoptions/request.options.control';
import { ButtonThemeControl } from '../../../appbuilder/controls/button/button.theme.control';
import { MetaHelper } from '../../../helpers/meta.helper';
import { RequestOptions } from '../../../models/rest/requestoptions';
import { FilterHelper } from '../../../helpers/filter.helper';
import { TextAreaThemeControl } from '../../../appbuilder/controls/textarea/textarea.theme.control';
import { ViewType } from '../../../models/enums/viewtype.enum';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { ReportingPreviewComponent } from './preview-dialogue/preview-dialogue.component';
import { ElementProperty } from '../../../models/layoutbase.model';
import { SelfServiceObjectService } from '../../../services/datamodel.service';
import { Color } from '../../../models/style/color.model';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { Comparer } from 'src/app/models/enums/comparer.enum';
import { FilterMatchMode } from 'primeng/api/filtermatchmode';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { Concat } from 'src/app/models/enums/contact.enum';
const textColor = '#000';
const textColorSecondary = '#c4c4c4';
const surfaceBorder = '#090909';
@Component({
	selector: 'evi-simplereporting',
	templateUrl: './simplereporting.component.html',
	styleUrls: ['./simplereporting.component.css'],
})
export class SimpleReportingControl extends IBaseComponent {
	static Type: any = 'reporting';
	static Default = {
		Source: 'simplereporting',
		Type: 'reporting',
		Layout: {
			_Editable: true,
			ChartType: 'bar',
		},
	};

	Options: any = {
		responsive: true,
		maintainAspectRatio: false,
		aspectRatio: 1,
		plugins: {
			legend: {
				display: false,
				labels: {
					color: textColor,
				},
			},
		},
		scales: {
			x: {
				display: true,
				title: {
					display: true,
					text: '',
					color: 'black',
					font: {
						weight: 400,
						size: 12,
					},
				},
				ticks: {
					color: textColorSecondary,
					font: {
						weight: 500,
					},
				},
				grid: {
					display: false,
					color: surfaceBorder,
					drawBorder: false,
				},
			},
			y: {
				display: true,
				title: {
					display: true,
					text: '',
					color: 'black',
					font: {
						weight: 400,
						size: 12,
					},
				},
				ticks: {
					color: textColorSecondary,
				},
				grid: {
					display: false,
					color: surfaceBorder,
					drawBorder: false,
				},
			},
		},
	};
	Chartdata: any = { labels: [], datasets: [] };
	Loading: boolean = false;
	DefaultPalette = [
		'#FF5733',
		'#33FF57',
		'#5733FF',
		'#FFD700',
		'#8A2BE2',
		'#00FFFF',
		'#FF4500',
		'#008000',
		'#4B0082',
	];
	IsRtl = false;

	private zoomSubscription: Subscription;
	divWidth = 100;
	constructor(
		cdRef: ChangeDetectorRef,
		private dataService: SelfServiceObjectService,
		private metaService: MetaService,
		@Inject(LayoutService.CONTAINER_DATA) public data
	) {
		super(cdRef, data);
		this.PropertyList.push(new ElementProperty('Filter', 'list', '@@Filter'));
		this.PropertyList.push(
			new ElementProperty('ChartPalette', 'string', '@@Chart Palette')
		);
	}

	ngOnInit() {
		super.ngOnInit();
		this.IsRtl = RTLHelper.Direction == 'rtl';
		this.Subscriptions['RTL'] = RTLHelper.DirectionChanged.subscribe((dir) => {
			this.IsRtl = dir == 'rtl';
		});

		this.zoomSubscription = this.metaService.zoom$.subscribe(zoom => {
			this.divWidth = 0;
			this.cdRef.detectChanges();

			setTimeout(() => {
				this.divWidth = 100;
				this.cdRef.detectChanges();
			}, 300)
		});
	}

	ControlInitialized() {
		this.cdRef.detectChanges();
		// console.log('ControlInitialized');
		this.Subscriptions['selection'] = LayoutService.SelectionChanged.subscribe(
			(value) => {
				this.cdRef.detectChanges();
			}
		);
		if (this.SRDataSource) {
			this.LoadChartData();
		}
		this.ChangeChartSettings();
	}
	ExecuteRefresh() {
		if (this.SRDataSource) {
			this.LoadChartData();
		}
		this.ChangeChartSettings();
	}

	@Input()
	get SourceType(): string {
		return this.LayoutElement.SourceType;
	}
	set SourceType(val: string) {
		this.LayoutElement.SourceType = val;
		this.cdRef.detectChanges();
	}

	@Input()
	get DisableAutoLoad(): boolean {
		return this.LayoutElement.DisableAutoLoad;
	}
	set DisableAutoLoad(val: boolean) {
		this.LayoutElement.DisableAutoLoad = val;
		this.cdRef.detectChanges();
	}

	@Input()
	get SRDataModelID(): string {
		return this.LayoutElement.SRDataModelID;
	}
	set SRDataModelID(val: string) {
		this.LayoutElement.SRDataModelID = val;
		this.cdRef.detectChanges();
	}

	@Input()
	get SRDataSource(): string {
		return this.LayoutElement.SRDataSource;
	}
	set SRDataSource(val: string) {
		this.LayoutElement.SRDataSource = val;
		this.cdRef.detectChanges();
	}

	@Input()
	get LegendPosition(): string {
		return this.LayoutElement.LegendPosition;
	}
	set LegendPosition(val: string) {
		this.LayoutElement.LegendPosition = val;
		this.cdRef.detectChanges();
	}

	@Input()
	get SRDataFilter() {
		return this.LayoutElement.SRDataFilter;
	}
	set SRDataFilter(val: RequestOptions) {
		this.LayoutElement.SRDataFilter = val;
		this.cdRef.detectChanges();
	}

	@Input()
	get ChartType(): string {
		return this.LayoutElement.ChartType;
	}

	set ChartType(val: string) {
		this.LayoutElement.ChartType = val;
		this.cdRef.detectChanges();
	}

	@Input()
	get ChartPalette(): string {
		return this.LayoutElement.ChartPalette;
	}

	set ChartPalette(val: string) {
		this.ChartPalette = val;
		this.cdRef.detectChanges();
	}
	@Input()
	get NoOfRecords(): number {
		return this.LayoutElement.NoOfRecords;
	}
	set NoOfRecords(val: number) {
		this.LayoutElement.NoOfRecords = val;
		this.cdRef.detectChanges();
	}

	@Input()
	get XLabel(): string {
		return this.LayoutElement.XLabel;
	}
	set XLabel(val: string) {
		this.LayoutElement.XLabel = val;
		this.cdRef.detectChanges();
	}

	@Input()
	get BoldXLabel(): boolean {
		return this.LayoutElement.BoldXLabel;
	}
	set BoldXLabel(val: boolean) {
		this.LayoutElement.BoldXLabel = val;
		this.cdRef.detectChanges();
	}

	@Input()
	get ItalicXLabel(): boolean {
		return this.LayoutElement.ItalicXLabel;
	}
	set ItalicXLabel(val: boolean) {
		this.LayoutElement.ItalicXLabel = val;
		this.cdRef.detectChanges();
	}
	@Input()
	get HideXAxisGrid() {
		return this.LayoutElement.HideXAxisGrid;
	}
	set HideXAxisGrid(val: boolean) {
		this.LayoutElement.HideXAxisGrid = val;
		this.cdRef.detectChanges();
	}
	@Input()
	get HideYAxisGrid() {
		return this.LayoutElement.HideYAxisGrid;
	}
	set HideYAxisGrid(val: boolean) {
		this.LayoutElement.HideYAxisGrid = val;
		this.cdRef.detectChanges();
	}

	get ColorXLabel(): string {
		return this.LayoutElement.ColorXLabel;
	}
	set ColorXLabel(val: string) {
		this.LayoutElement.ColorXLabel = val;
		this.cdRef.detectChanges();
	}
	@Input()
	get FontSizeXLabel() {
		return this.LayoutElement.FontSizeXLabel;
	}
	set FontSizeXLabel(val: number) {
		this.LayoutElement.FontSizeXLabel = val;
		this.cdRef.detectChanges();
	}

	@Input()
	get YLabel(): string {
		return this.LayoutElement.YLabel;
	}
	set YLabel(val: string) {
		this.LayoutElement.YLabel = val;
		this.cdRef.detectChanges();
	}

	@Input()
	get BoldYLabel(): boolean {
		return this.LayoutElement.BoldYLabel;
	}
	set BoldYLabel(val: boolean) {
		this.LayoutElement.BoldYLabel = val;
		this.cdRef.detectChanges();
	}

	@Input()
	get ItalicYLabel(): boolean {
		return this.LayoutElement.ItalicYLabel;
	}
	set ItalicYLabel(val: boolean) {
		this.LayoutElement.ItalicYLabel = val;
		this.cdRef.detectChanges();
	}
	@Input()
	get ColorYLabel(): string {
		return this.LayoutElement.ColorYLabel;
	}
	set ColorYLabel(val: string) {
		this.LayoutElement.ColorYLabel = val;
		this.cdRef.detectChanges();
	}

	@Input()
	get FontSizeYLabel() {
		return this.LayoutElement.FontSizeYLabel;
	}
	set FontSizeYLabel(val: number) {
		this.LayoutElement.FontSizeYLabel = val;
		this.cdRef.detectChanges();
	}
	@Input()
	get GridColor(): string {
		return this.LayoutElement.GridColor;
	}
	set GridColor(val: string) {
		this.LayoutElement.GridColor = val;
		this.cdRef.detectChanges();
	}
	@Input()
	get GroupByTableColumns(): string {
		return this.LayoutElement.GroupByTableColumns;
	}
	set GroupByTableColumns(val: string) {
		this.LayoutElement.GroupByTableColumns = val;
		this.cdRef.detectChanges();
	}
	@Input()
	get AggregationColumns(): string {
		return this.LayoutElement.AggregationColumns;
	}
	set AggregationColumns(val: string) {
		this.LayoutElement.AggregationColumns = val;
		this.cdRef.detectChanges();
	}
	@Input()
	get Aggregation(): string {
		return this.LayoutElement.Aggregation;
	}
	set Aggregation(val: string) {
		this.LayoutElement.Aggregation = val;
		this.cdRef.detectChanges();
	}
	@Input()
	get GroupByDateColumns() {
		return this.LayoutElement.GroupByDateColumns;
	}
	set GroupByDateColumns(val: string) {
		this.LayoutElement.GroupByDateColumns = val;
		this.cdRef.detectChanges();
	}

	@Input()
	get GroupByDate() {
		return this.LayoutElement.GroupByDate;
	}
	set GroupByDate(val: string) {
		this.LayoutElement.GroupByDate = val;
		this.cdRef.detectChanges();
	}
	LoadChartData() {
		if (
			!this.SRDataSource ||
			!this.GroupByTableColumns ||
			!this.AggregationColumns ||
			!this.Aggregation
		) {
			this.Chartdata = { labels: [], datasets: [] };
			return;
		}
		this.Loading = true;
		const filters = FilterHelper.PrepareFilter(this.LayoutElement);
		if (this.GroupByTableColumns && this.GroupByTableColumns.length > 0) {
			filters.GroupBy = [this.GroupByTableColumns];
		}
		if (this.AggregationColumns && this.AggregationColumns.length > 0) {
			const aggregationObj = {
				Field: this.AggregationColumns,
				Operation: this.Aggregation,
				Alias: this.AggregationColumns,
			};
			filters.Aggregations = [aggregationObj];
		}
		if (this.GroupByDateColumns && this.GroupByDate) {
			filters.GroupByDate = {
				Field: this.GroupByDateColumns,
				GroupingLevel: this.GroupByDate,
			};
		}
		if (!this.GroupByDate) {
			delete filters.GroupByDate;
		}
		filters.EndRow = null;
		if (
			this.GroupByTableColumns &&
			this.SRDataSource &&
			this.AggregationColumns &&
			this.Aggregation
		) {
			this.Chartdata = { labels: [], datasets: [] };
			this.dataService
				.ExecuteObjectQueryExtended(this.SRDataSource, filters)
				.subscribe({
					next: (data) => {
						if (data && data.length > 0) {
							let result;
							this.NoOfRecords && this.NoOfRecords > 0
								? (result = this.GetMaxNoOfResults(
										data[0].Data,
										this.NoOfRecords,
										this.AggregationColumns
								  ))
								: (result = data[0].Data);

							if (this.GroupByDate) {
								this.PrepareChartData(result);
							} else {
								this.PrepareChartdataWithoutGroupBy(result);
							}
						}
						this.GetPalette();
						this.Loading = false;
						this.cdRef.detectChanges();
					},
					error: (error) => {
						NotificationHelper.Error(error.error['error'], '@@Error');
					},
				});
			return;
		}
	}
	GetMaxNoOfResults(array, maxResults, sortByField) {
		// Sort the array in descending order based on the specified field
		array.sort((a, b) => b[sortByField] - a[sortByField]);

		// Slice the array to get the top-rated movies based on the maxResults
		const topResults = array.slice(0, maxResults);

		return topResults;
	}
	PrepareChartData(data) {
		const groupedData = this.groupByFieldName(data, this.GroupByTableColumns);
		// labels => uniquely _Created kr k set krwa dena hy
		let chartData: any = { labels: [], datasets: [] };

		// set unique labels for the x-axis
		data.forEach((item) => {
			// if (this.GroupByDate === 'Month') {
			// 	const month = this.getMonthNameByNumber(
			// 		item[this.GroupByDateColumns].split('-')[1].replace(/^0+/, '')
			// 	);
			// 	chartData.labels.push(month);
			// }
			// if (this.GroupByDate === 'Year') {
			// 	const year = item[this.GroupByDateColumns];
			// 	chartData.labels.push(year);
			// }
			// if (this.GroupByDate === 'Day') {
			// 	const day = item[this.GroupByDateColumns];
			// 	chartData.labels.push(day);
			// }
			chartData.labels.push(item[this.GroupByDateColumns]);
		});
		chartData.labels = [...new Set(chartData.labels)];

		//  set the datasets according to the given aggregation
		const datasets = Object.keys(groupedData).map((key) => {
			const datasetData = [];
			groupedData[key].forEach((item) => {
				const index = chartData.labels.indexOf(item[this.GroupByDateColumns]);
				datasetData[index] = item[this.AggregationColumns];
			});
			const datasetObj = {
				label: key,
				data: datasetData,
			};
			return datasetObj;
		});
		chartData.datasets = datasets;
		this.Chartdata = chartData;
	}
	PrepareChartdataWithoutGroupBy(data) {
		const chartdata = { labels: [], datasets: [{ data: [], label: '' }] };

		data.forEach((item) => {
			chartdata.labels.push(item[this.GroupByTableColumns]);
			chartdata.datasets[0].data.push(item[this.AggregationColumns]);
		});
		chartdata.datasets[0].label = this.AggregationColumns;
		this.Chartdata = chartdata;
	}
	groupByFieldName(data, fieldName) {
		const groupedData = {};

		data.forEach((item) => {
			const fieldValue = item[fieldName];

			if (!groupedData[fieldValue]) {
				groupedData[fieldValue] = [item];
			} else {
				groupedData[fieldValue].push(item);
			}
		});

		return groupedData;
	}
	GetPalette() {
		if (this.Chartdata) {
			if (this.ChartPalette) {
				this.metaService
					.ReadPalette(this.ChartPalette)
					.subscribe((paletteInfo) => {
						let colorsArray = [];
						if (paletteInfo.Palette && paletteInfo.Palette.length > 0) {
							paletteInfo.Palette.forEach((color) => {
								colorsArray.push(Color.HexFromColor(color));
							});
						}
						if (colorsArray && colorsArray.length > 0) {
							this.DefaultPalette = colorsArray;
						}
						this.Chartdata = {
							...this.Chartdata,
							datasets: this.SetPrimeNGChartPalette(this.Chartdata.datasets),
						};
						this.cdRef.detectChanges();
					});
				return;
			}
			this.Chartdata = {
				...this.Chartdata,
				datasets: this.SetPrimeNGChartPalette(this.Chartdata.datasets),
			};
			this.cdRef.detectChanges();
		}
	}
	SetPrimeNGChartPalette(seriesData: any[]): any {
		if (this.GroupByDate) {
			const coloredSeries = seriesData.map((series, index) => {
				const backgroundColorArray = series.data.map(
					(data) => this.DefaultPalette[index % this.DefaultPalette.length]
				);
				const colorObject = {
					...series,
					backgroundColor: backgroundColorArray,
				};
				if (this.ChartType === 'line') {
					colorObject.borderColor =
						this.DefaultPalette[index % this.DefaultPalette.length];
					colorObject.fill = false;
					colorObject.tension = 0.4;
				}
				return colorObject;
			});
			return coloredSeries;
		}
		const backgroundColor = [];
		if (seriesData[0]) {
			seriesData[0].data.forEach((series, index) => {
				backgroundColor.push(
					this.DefaultPalette[index % this.DefaultPalette.length]
				);
			});
		}
		return [
			{
				...seriesData[0],
				backgroundColor: backgroundColor,
			},
		];
	}

	ChangeChartSettings() {
		if (!this.ChartType) {
			NotificationHelper.Error('@@Please select chart type', '@@Error');
			return;
		}
		// const chartTypes = ['bar', 'line', 'radar'];
		// if (!chartTypes.includes(this.ChartType)) {
		// 	this.Options.plugins.legend.display = false;
		// }
		// if (this.LegendPosition && chartTypes.includes(this.ChartType)) {
		// 	if (this.LegendPosition === 'hide') {
		// 		this.Options.plugins.legend.display = false;
		// 	}
		// 	if (this.LegendPosition !== 'hide') {
		// 		this.Options.plugins.legend.display = true;
		// 		this.Options.plugins.legend.position = this.LegendPosition;
		// 	}
		// }
		if (this.HideXAxisGrid) {
			this.Options.scales.x.grid.display = false;
		}
		if (!this.HideXAxisGrid) {
			this.Options.scales.x.grid.display = true;
		}
		if (this.HideYAxisGrid) {
			this.Options.scales.y.grid.display = false;
		}
		if (!this.HideYAxisGrid) {
			this.Options.scales.y.grid.display = true;
		}
		if (this.XLabel) {
			this.Options.scales.x.title.text = this.XLabel;
		}
		if (this.YLabel) {
			this.Options.scales.y.title.text = this.YLabel;
		}
		if (this.BoldXLabel) {
			this.Options.scales.x.title.font.weight = 900;
		}
		if (this.ItalicXLabel) {
			this.Options.scales.x.title.font.style = 'italic';
		}
		if (this.ColorXLabel) {
			const color = Color.HexFromColor(this.ColorXLabel);
			this.Options.scales.x.title.color = color;
		}
		if (this.BoldYLabel) {
			this.Options.scales.y.title.font.weight = 900;
		}
		if (this.ItalicYLabel) {
			this.Options.scales.y.title.font.style = 'italic';
		}
		if (this.ColorYLabel) {
			const color = Color.HexFromColor(this.ColorYLabel);
			this.Options.scales.y.title.color = color;
		}
		if (this.FontSizeXLabel) {
			this.Options.scales.x.title.font.size = this.FontSizeXLabel;
		}
		if (this.FontSizeYLabel) {
			this.Options.scales.y.title.font.size = this.FontSizeYLabel;
		}
		if (this.GridColor) {
			const color = Color.HexFromColor(this.GridColor);
			this.Options.scales.x.grid.color = color;
			this.Options.scales.y.grid.color = color;
		}
		if (!this.GridColor) {
			this.Options.scales.x.grid.color = surfaceBorder;
			this.Options.scales.y.grid.color = surfaceBorder;
		}
		if (this.ChartType) {
			const gridTypes = ['line', 'bar', 'radar'];
			if (gridTypes.includes(this.ChartType)) {
				this.Options.scales.x.display = true;
				this.Options.scales.y.display = true;
			}
			if (!gridTypes.includes(this.ChartType)) {
				this.Options.scales.x.display = false;
				this.Options.scales.y.display = false;
			}
		}
		this.cdRef.detectChanges();
	}
	getMonthNameByNumber(monthNumber) {
		const monthNames = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];

		// Validate that the monthNumber is within the valid range
		if (monthNumber >= 1 && monthNumber <= 12) {
			// Return the month name from the array
			return monthNames[monthNumber - 1]; // Adjusting to 0-based index
		} else {
			return 'Invalid month number';
		}
	}

	ngOnDestroy() {
		if (this.zoomSubscription) {
			this.zoomSubscription.unsubscribe();
		}
	}
}

export class SimpleReportingPanel extends BasePanel {
	static override SIDS = ['7c88102a-3d44-4799-b86a-0e6e5d24fd6f'];
	static override SetSelectedItem(item) {
		SimpleReportingPanel.CheckItem();
		SimpleReportingPanel.CheckFilterText();
	}
	private static ResetDataBinding(LayoutElement: any, idList: any) {
		if (LayoutElement) {
			LayoutElement.SRDataSource = null;
			LayoutElement.SRDataSourceID = null;
			LayoutElement.SRDataModelID = null;
			LayoutElement.SRDataFilter = null;
			idList.push(LayoutElement.ID);
			if (LayoutElement.Elements && LayoutElement.Elements.length > 0) {
				LayoutElement.Elements.forEach((item: any) => {
					SimpleReportingPanel.ResetDataBinding(item, idList);
				});
			}
		}
	}
	private static DataModels: BehaviorSubject<any> = new BehaviorSubject(null);
	private static Tables: BehaviorSubject<any> = new BehaviorSubject(null);
	private static TableColumns: BehaviorSubject<any> = new BehaviorSubject(null);
	private static AggregationColumns: BehaviorSubject<any> = new BehaviorSubject(
		null
	);
	private static Palettes: BehaviorSubject<any> = new BehaviorSubject(null);
	private static CheckItem() {
		if (SimpleReportingPanel.SelectedItem) {
			if (
				(SimpleReportingPanel.SelectedItem.SRDataModelID == null &&
					SimpleReportingPanel.SelectedItem.SRDataSource != null) ||
				SimpleReportingPanel.SelectedItem.SourceType == 0
			) {
				SimpleReportingPanel.dataService
					.GetList('dynamic', 'GetAllStaticTables')
					.subscribe((tables) => {
						SimpleReportingPanel.Tables.next(tables);
					});
			}
			if (SimpleReportingPanel.SelectedItem.SRDataModelID) {
				SimpleReportingPanel.dataModelService
					.GetModels()
					.subscribe((models) => {
						SimpleReportingPanel.DataModels.next(models);
					});
				if (SimpleReportingPanel.SelectedItem.SRDataSource) {
					this.dataModelService
						.GetFilterInfo(SimpleReportingPanel.SelectedItem.SRDataSource)
						.subscribe((result) => {
							SimpleReportingPanel.TableColumns.next(result.Columns);
						});
					SimpleReportingPanel.dataService
						.GetListWithID(
							'dynamic',
							'GetTable',
							SimpleReportingPanel.SelectedItem.SRDataSource
						)
						.subscribe((result) => {
							SimpleReportingPanel.AggregationColumns.next(result.Fields);
						});
					if (SimpleReportingPanel.SelectedItem.SourceType != null) {
						switch (SimpleReportingPanel.SelectedItem.SourceType) {
							case SimpleReportingPanel.SelectedItem:
								break;
							case 0:
								break;
							case 1:
								SimpleReportingPanel.dataService
									.GetItem(
										'dynamic',
										'GetNonCCOContainersByDataModel',
										SimpleReportingPanel.SelectedItem.SRDataModelID
									)
									.subscribe((data) => {
										const container = [];
										data.forEach((tab) => {
											container.push({
												Value: tab.SID,
												Caption: tab.Caption,
											});
										});
										SimpleReportingPanel.Tables.next(container);
									});
								break;
							case 2:
								SimpleReportingPanel.dataService
									.GetItem(
										'dynamic',
										'GetTablesByDataModel',
										SimpleReportingPanel.SelectedItem.SRDataModelID
									)
									.subscribe((tableList) => {
										const tables = [];
										tableList.forEach((tab) => {
											tables.push({
												Value: tab.SID,
												Caption: tab.Caption,
											});
										});
										SimpleReportingPanel.Tables.next(tables);
									});
								break;
						}
					} else {
						SimpleReportingPanel.dataService
							.GetItem(
								'dynamic',
								'GetNonCCOContainersByDataModel',
								SimpleReportingPanel.SelectedItem.SRDataModelID
							)
							.subscribe((data) => {
								let tables = [];
								if (data && data.length > 0) {
									let retVal = data.find(
										(tab) =>
											tab.SID == SimpleReportingPanel.SelectedItem.SRDataSource
									);
									if (retVal) {
										SimpleReportingPanel.SelectedItem.SourceType = 1;
										data.forEach((tab) => {
											tables.push({
												Value: tab.SID,
												Caption: tab.Caption,
											});
										});
										SimpleReportingPanel.Tables.next(tables);
									}
								}
							});
						SimpleReportingPanel.dataService
							.GetItem(
								'dynamic',
								'GetTablesByDataModel',
								SimpleReportingPanel.SelectedItem.SRDataModelID
							)
							.subscribe((data) => {
								let tables = [];
								if (data && data.length > 0) {
									let retVal = data.find(
										(tab) =>
											tab.SID == SimpleReportingPanel.SelectedItem.SRDataSource
									);
									if (retVal) {
										SimpleReportingPanel.SelectedItem.SourceType = 2;
										data.forEach((tab) => {
											tables.push({
												Value: tab.SID,
												Caption: tab.Caption,
											});
										});
										SimpleReportingPanel.Tables.next(tables);
									}
								}
							});
					}
				}
			}
		}
	}
	private static ColumnText: BehaviorSubject<any> = new BehaviorSubject(null);
	private static FilterText: BehaviorSubject<any> = new BehaviorSubject(null);
	private static SortText: BehaviorSubject<any> = new BehaviorSubject(null);

	private static CheckFilterText() {
		let columnText = null;
		let filterText = null;
		let sortText = null;
		if (
			SimpleReportingPanel.SelectedItem &&
			SimpleReportingPanel.SelectedItem.SRDataFilter
		) {
			if (
				SimpleReportingPanel.SelectedItem.SRDataFilter.Columns &&
				SimpleReportingPanel.SelectedItem.SRDataFilter.Columns.length > 0
			) {
				const list = [];
				SimpleReportingPanel.SelectedItem.SRDataFilter.Columns.forEach(
					(col) => {
						list.push(col.Name);
					}
				);
				columnText = list.join(', ');
			}
			if (
				SimpleReportingPanel.SelectedItem.SRDataFilter.Filters &&
				SimpleReportingPanel.SelectedItem.SRDataFilter.Filters.length > 0
			) {
				filterText = RequestFilter.getRequestFilterText(
					SimpleReportingPanel.SelectedItem.SRDataFilter.Filters[0]
				);
			}
			if (
				SimpleReportingPanel.SelectedItem.SRDataFilter.Sort &&
				SimpleReportingPanel.SelectedItem.SRDataFilter.Sort.length > 0
			) {
				const list = [];
				SimpleReportingPanel.SelectedItem.SRDataFilter.Sort.forEach((x) => {
					list.push(x.Name + ' (' + (x.Order === 0 ? 'ASC' : 'DESC') + ')');
				});
				sortText = list.join(', ');
			}
		}
		SimpleReportingPanel.ColumnText.next(columnText);
		SimpleReportingPanel.FilterText.next(filterText);
		SimpleReportingPanel.SortText.next(sortText);
	}
	private static dataService;
	private static dataModelService;
	private static chartPaletteService;
	private static containerSerice;
	static InitPanel() {
		SimpleReportingPanel.dataModelService =
			InjectorHelper.InjectorInstance.get<DataModelService>(DataModelService);
		SimpleReportingPanel.dataService =
			InjectorHelper.InjectorInstance.get<DataService>(DataService);
		SimpleReportingPanel.chartPaletteService =
			InjectorHelper.InjectorInstance.get<MetaService>(MetaService);
		SimpleReportingPanel.containerSerice =
			InjectorHelper.InjectorInstance.get<SelfServiceObjectService>(
				SelfServiceObjectService
			);
		this.InitSelectedItem();

		SimpleReportingPanel.chartPaletteService
			.ReadPaletteInfos()
			.subscribe((paletteInfos: any) => {
				if (paletteInfos) {
					SimpleReportingPanel.Palettes.next(paletteInfos);
				}
			});
		PROPERTYGROUPS.push({
			SID: '7c88102a-3d44-4799-b86a-0e6e5d24fd6f',
			ID: 'SimpleReporting',
			Caption: '@@Data for Reporting',
			Index: 100,
			Content: GenericMenuTab,
			Display: PropertyGroupDisplay.Grid,
			Columns: ['1fr', '1fr'],
			Rows: ['auto'],
			CheckVisibilityAsync: (item) => {
				return new Promise((resolve) => {
					const selected = LayoutService.SelectedItem.getValue();
					if (
						selected &&
						selected.ElementType &&
						selected.ElementType === 'reporting'
					) {
						const layout = LayoutHelper.GetLayout();
						const resolution = LayoutHelper.GetActiveResolution(layout);
						MetaHelper.FindDataBindingProperties(resolution, selected).then(
							(result) => {
								resolve(!result || !result.IsDataBinding);
							}
						);
					} else {
						resolve(false);
					}
				});
			},
		});

		LayoutService.ViewType.subscribe((vt) => {
			if (vt == ViewType.Edit && SimpleReportingPanel.SelectedItem) {
				SimpleReportingPanel.CheckItem();
			}
		});

		const dstype = [
			{ Caption: '@@None', Value: null },
			{ Caption: '@@Static', Value: 0 },
			{ Caption: '@@Container', Value: 1 },
			{ Caption: '@@Dynamic', Value: 2 },
		];

		PROPERTIES.push({
			ID: 'SourceType',
			Parent: 'SimpleReporting',
			Content: new ComponentPortal(ComboboxThemeControl),
			Label: '@@DataSourceType',
			Column: 1,
			ColSpan: 2,
			Row: 1,
			InitArgs: {
				Required: true,
				Placeholder: '',
				Multiple: false,
				ItemsSource: dstype,
				DisplayMemberPath: 'Caption',
				ValueMemberPath: 'Value',
				Action: (value) => {
					if (value && value.Event) {
						delete SimpleReportingPanel.SelectedItem.SRDataSource;
						delete SimpleReportingPanel.SelectedItem.SRDataModelID;
						delete SimpleReportingPanel.SelectedItem.SRDataSourceID;
						delete SimpleReportingPanel.SelectedItem.SRDataFilter;
						const mlpcv = new MultiPropertyChangeValue();
						[
							'SRDataSource',
							'SRDataSourceID',
							'SRDataModelID',
							'SRDataFilter',
						].forEach((x) => {
							mlpcv.Properties.push({
								PropertyName: x,
								Value: null,
							});
						});
						SimpleReportingPanel.ResetDataBinding(
							SimpleReportingPanel.SelectedItem,
							mlpcv.ElementIDs
						);
						LayoutService.OnMultiLayoutPropertyChanged([mlpcv]);
						switch (value.Event.value) {
							case null:
								break;
							case 0:
								SimpleReportingPanel.dataService
									.GetList('dynamic', 'GetAllStaticTables')
									.subscribe((tables) => {
										SimpleReportingPanel.Tables.next(tables);
									});
								break;
							case 1:
							case 2:
								SimpleReportingPanel.dataModelService
									.GetModels()
									.subscribe((models) => {
										SimpleReportingPanel.DataModels.next(models);
									});
								break;
						}
					}
				},
			},
		});

		PROPERTIES.push({
			ID: 'SRDataModelID',
			Parent: 'SimpleReporting',
			Content: new ComponentPortal(ComboboxThemeControl),
			Label: '@@DataModel',
			Column: 1,
			ColSpan: 2,
			Row: 2,
			CheckVisibility: (item) => {
				return item.SourceType == 1 || item.SourceType == 2;
			},
			InitArgs: {
				Required: true,
				Placeholder: '',
				Multiple: false,
				ItemsSourceSub: SimpleReportingPanel.DataModels,
				DisplayMemberPath: 'Caption',
				ValueMemberPath: 'SID',
				Action: (value) => {
					if (value && value.Event && value.Event.value) {
						if (SimpleReportingPanel.SelectedItem) {
							if (SimpleReportingPanel.SelectedItem.SRDataSource != null) {
								SimpleReportingPanel.SelectedItem.SRDataSource = null;
								SimpleReportingPanel.SelectedItem.SRDataFilter = null;
							}
							if (SimpleReportingPanel.SelectedItem.SourceType == 1) {
								SimpleReportingPanel.dataService
									.GetItem(
										'dynamic',
										'GetNonCCOContainersByDataModel',
										SimpleReportingPanel.SelectedItem.SRDataModelID
									)
									.subscribe((data) => {
										const container = [];
										data.forEach((tab) => {
											container.push({
												Value: tab.SID,
												Caption: tab.Caption,
											});
										});
										SimpleReportingPanel.Tables.next(container);
									});
							}
							if (SimpleReportingPanel.SelectedItem.SourceType == 2) {
								SimpleReportingPanel.dataService
									.GetItem(
										'dynamic',
										'GetTablesByDataModel',
										SimpleReportingPanel.SelectedItem.SRDataModelID
									)
									.subscribe((tableList) => {
										const tables = [];
										tableList.forEach((tab) => {
											tables.push({
												Value: tab.SID,
												Caption: tab.Caption,
											});
										});
										SimpleReportingPanel.Tables.next(tables);
									});
							}
						}
					}
				},
			},
		});

		PROPERTIES.push({
			ID: 'SRDataSource',
			Parent: 'SimpleReporting',
			Content: new ComponentPortal(ComboboxThemeControl),
			Label: '@@Data Table',
			Column: 1,
			ColSpan: 2,
			Row: 3,
			CheckVisibility: (item) => {
				return item.SourceType == 0 || item.SRDataModelID;
			},
			InitArgs: {
				Required: true,
				Placeholder: '',
				Multiple: false,
				ItemsSourceSub: SimpleReportingPanel.Tables,
				DisplayMemberPath: 'Caption',
				ValueMemberPath: 'Value',
				Action: (ev) => {
					const selected = ev.SelectedItem;
					if (selected) {
						this.dataModelService
							.GetFilterInfo(selected.SRDataSource)
							.subscribe((result) => {
								SimpleReportingPanel.TableColumns.next(result.Columns);
							});
						SimpleReportingPanel.dataService
							.GetListWithID(
								'dynamic',
								'GetTable',
								SimpleReportingPanel.SelectedItem.SRDataSource
							)
							.subscribe((result) => {
								SimpleReportingPanel.AggregationColumns.next(result.Fields);
							});
						const changedProps = [];
						if (selected.SourceType == 0 && selected.SRDataModelID != null) {
							selected.SRDataModelID = null;
							changedProps.push('SRDataModelID');
						}
						changedProps.push('SRDataSource');
						selected.SRDataFilter = new RequestOptions();
						changedProps.push('SRDataFilter');
						SimpleReportingPanel.CheckFilterText();
						LayoutService.LayoutChanged.next(null);
					}
				},
			},
		});

		PROPERTIES.push({
			ID: 'SRDataFilter',
			Parent: 'SimpleReporting',
			Content: new ComponentPortal(ButtonThemeControl),
			Column: 1,
			ColSpan: 2,
			Row: 4,
			CheckVisibility: (item) => {
				return item.SRDataSource;
			},
			InitArgs: {
				Caption: '@@Filter & Sorting',
				Action: (ev) => {
					const selected = ev.SelectedItem;
					if (selected && selected.SRDataSource) {
						this.dataModelService
							.GetFilterInfo(selected.SRDataSource)
							.subscribe((filterInfo) => {
								if (filterInfo) {
									const initArgs = new RequestOptionsDialogArgs();
									initArgs.SystemVariables =
										FilterHelper.GetVariables(selected);
									if (selected.SRDataFilter) {
										initArgs.RequestOptions = selected.SRDataFilter;
									}
									initArgs.ShowFilter = filterInfo.CanFilter;
									filterInfo.Columns.forEach((x) => {
										const col = new RequestOptionsColumn();
										col.Name = x.Name;
										col.Type = x.DataType;
										col.CanFilter = filterInfo.CanFilter;
										col.CanSelect = filterInfo.CanSelectColumns;
										initArgs.Columns.push(col);
									});

									RequestOptionsControl.ShowDialog(
										initArgs,
										'@@Spalten, Filter und Sortierung',
										(r) => {
											selected.SRDataFilter = r;
											SimpleReportingPanel.CheckFilterText();
											LayoutService.OnLayoutPropertyChanged(
												selected.ID,
												'SRDataFilter',
												r
											);
										}
									);
								}
							});
					}
				},
			},
		});

		PROPERTIES.push({
			ID: null,
			Label: '@@Spalten',
			Parent: 'SimpleReporting',
			DataSource: SimpleReportingPanel.ColumnText,
			Content: new ComponentPortal(TextAreaThemeControl),
			Column: 1,
			ColSpan: 2,
			Row: 5,
			CheckVisibility: (item) => {
				return (
					item.SRDataFilter &&
					item.SRDataFilter.Columns &&
					item.SRDataFilter.Columns.length > 0
				);
			},
			InitArgs: {
				Disabled: true,
			},
		});

		PROPERTIES.push({
			ID: null,
			Label: '@@Filter',
			Parent: 'SimpleReporting',
			DataSource: SimpleReportingPanel.FilterText,
			Content: new ComponentPortal(TextAreaThemeControl),
			Column: 1,
			ColSpan: 2,
			Row: 6,
			CheckVisibility: (item) => {
				return (
					item.SRDataFilter &&
					item.SRDataFilter.Filters &&
					item.SRDataFilter.Filters.length > 0
				);
			},
			InitArgs: {
				Disabled: true,
			},
		});

		PROPERTIES.push({
			ID: null,
			Parent: 'SimpleReporting',
			DataSource: SimpleReportingPanel.SortText,
			Content: new ComponentPortal(TextAreaThemeControl),
			Label: '@@Sortierung',
			Column: 1,
			ColSpan: 2,
			Row: 7,
			CheckVisibility: (item) => {
				return (
					item.SRDataFilter &&
					item.SRDataFilter.Sort &&
					item.SRDataFilter.Sort.length > 0
				);
			},
			InitArgs: {
				Disabled: true,
			},
		});

		const groupby = [
			{ Caption: '@@Year', Value: 'Year' },
			{ Caption: '@@Month', Value: 'Month' },
			{ Caption: '@@Day', Value: 'Day' },
		];
		PROPERTIES.push({
			ID: 'GroupByTableColumns',
			Parent: 'SimpleReporting',
			Content: new ComponentPortal(ComboboxThemeControl),
			Label: '@@Group by Table Columns *',
			Column: 1,
			ColSpan: 2,
			Row: 8,
			InitArgs: {
				Placeholder: '@@Columns',
				ItemsSourceSub: SimpleReportingPanel.TableColumns,
				Multiple: false,
				ValueMemberPath: 'Name',
				DisplayMemberPath: 'Name',
			},
		});
		PROPERTIES.push({
			ID: 'GroupByDate',
			Parent: 'SimpleReporting',
			Content: new ComponentPortal(ComboboxThemeControl),
			Label: '@@Group By Date',
			Column: 1,
			Row: 9,
			InitArgs: {
				Placeholder: '@@Group By',
				Multiple: false,
				ItemsSource: groupby,
				ValueMemberPath: 'Value',
				DisplayMemberPath: 'Caption',
			},
		});

		PROPERTIES.push({
			ID: 'GroupByDateColumns',
			Parent: 'SimpleReporting',
			Content: new ComponentPortal(ComboboxThemeControl),
			Label: '@@Date Column *',
			Column: 2,
			Row: 9,
			CheckVisibility: (item) => {
				return item.GroupByDate;
			},
			InitArgs: {
				Required: true,
				Placeholder: '@@Date Column *',
				ItemsSourceSub: SimpleReportingPanel.TableColumns,
				Multiple: false,
				ValueMemberPath: 'Name',
				DisplayMemberPath: 'Name',
			},
		});

		const aggregationTypes = [
			{ Caption: '@@Sum', Value: 'sum' },
			{ Caption: '@@Average', Value: 'average' },
			{ Caption: '@@Count', Value: 'count' },
			{ Caption: '@@Min', Value: 'min' },
			{ Caption: '@@Max', Value: 'max' },
		];

		PROPERTIES.push({
			ID: 'AggregationColumns',
			Parent: 'SimpleReporting',
			Content: new ComponentPortal(ComboboxThemeControl),
			Label: '@@Aggregate Value *',
			Column: 2,
			Row: 10,
			InitArgs: {
				Required: true,
				Placeholder: '@@Aggregation Value',
				ItemsSourceSub: SimpleReportingPanel.AggregationColumns,
				Multiple: false,
				ValueMemberPath: 'Name',
				DisplayMemberPath: 'Name',
			},
		});

		PROPERTIES.push({
			ID: 'Aggregation',
			Parent: 'SimpleReporting',
			Content: new ComponentPortal(ComboboxThemeControl),
			Label: '@@Aggregation *',
			Column: 1,
			Row: 10,
			InitArgs: {
				Required: true,
				Placeholder: '@@Aggregation',
				Multiple: false,
				ItemsSource: aggregationTypes,
				ValueMemberPath: 'Value',
				DisplayMemberPath: 'Caption',
			},
		});

		PROPERTIES.push({
			ID: 'NoOfRecords',
			Parent: 'SimpleReporting',
			Content: new ComponentPortal(TextboxThemeControl),
			Label: '@@Max no of results',
			Column: 1,
			ColSpan: 2,
			Row: 12,
			InitArgs: {
				InputType: 'number',
			},
		});

		PROPERTIES.push({
			ID: null,
			Parent: 'SimpleReporting',
			Content: new ComponentPortal(ButtonThemeControl),
			Column: 1,
			ColSpan: 2,
			Row: 13,
			InitArgs: {
				Caption: '@@Preview',
				Action: (ev: any) => {
					const selected = ev.SelectedItem;
					if (
						!selected.ChartType ||
						!selected.AggregationColumns ||
						!selected.Aggregation ||
						!selected.GroupByTableColumns ||
						!selected.SRDataSource ||
						!selected.SourceType ||
						!selected.SRDataModelID
					) {
						NotificationHelper.Error(
							'@@Please select all the required fields',
							'@@Error'
						);
						return;
					}
					if (selected && selected.SRDataSource) {
						const filters = FilterHelper.PrepareFilter(selected);
						if (
							selected.GroupByTableColumns &&
							selected.GroupByTableColumns.length > 0
						) {
							filters.GroupBy = [selected.GroupByTableColumns];
						}
						if (
							selected.AggregationColumns &&
							selected.AggregationColumns.length > 0
						) {
							const aggregationObj = {
								Field: selected.AggregationColumns,
								Operation: selected.Aggregation,
								Alias: selected.AggregationColumns,
							};
							filters.Aggregations = [aggregationObj];
						}
						if (selected.GroupByDateColumns && selected.GroupByDate) {
							filters.GroupByDate = {
								Field: selected.GroupByDateColumns,
								GroupingLevel: selected.GroupByDate,
							};
						}
						if (!selected.GroupByDate) {
							delete filters.GroupByDate;
						}
						if (!selected.GroupByDate) {
							delete filters.GroupByDate;
						}
						filters.EndRow = null;
						function GetMaxNoOfResults(array, maxResults, sortByField) {
							// Sort the array in descending order based on the specified field
							array.sort((a, b) => b[sortByField] - a[sortByField]);

							// Slice the array to get the top-rated movies based on the maxResults
							const topResults = array.slice(0, maxResults);

							return topResults;
						}
						SimpleReportingPanel.containerSerice
							.ExecuteObjectQueryExtended(selected.SRDataSource, filters)
							.subscribe((data) => {
								let result;
								if (data && data.length > 0) {
									selected.NoOfRecords && selected.NoOfRecords > 0
										? (result = GetMaxNoOfResults(
												data[0].Data,
												selected.NoOfRecords,
												selected.AggregationColumns
										  ))
										: (result = data[0].Data);
								}
								ReportingPreviewComponent.OpenDialog(
									result,
									(template: any) => {
										if (template) {
										}
									}
								);
							});
					}
				},
			},
		});
	}
}
