import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Output } from '@angular/core';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { SliderOrientation } from '../../../models/enums/slider.orientation.enum';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../base.component';

@Component({
    selector: 'evi-slider',
    templateUrl: './slider.control.html',
    styleUrls: ['./slider.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderControl extends IBaseComponent {

    static Type: any = 'slider';
    static Default = {
        Type: 'slider',
        Layout: {
            DataSource: 0,
            Minimum: 0,
            Maximum: 150,
            Step: 1,
            Range: false,
            Orientation: SliderOrientation.Horizontal,
            thumbLabel: true,
            _Editable: true,
        }
    };

    @Output() ValueChanged = new EventEmitter<any>();

    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('valuechanged');
    }

    ControlInitialized() {
        this.cdRef.detectChanges();
        this.Subscriptions['selection'] = LayoutService.SelectionChanged.subscribe((value) => {
            this.cdRef.detectChanges();
        });
    }

    onChange(ev) {
        this.triggerEvent('valuechanged');
        this.ValueChanged.emit(this.DataSource);
    }

    setFocus() {
        let control = document.getElementById(this.LayoutElement.ID);
        if (control) {
            control.focus();
        }
    }
}
export class SliderPanel extends BasePanel {
    static override SIDS = ['383ef075-2ada-4f3a-a86f-a0d304152cd0']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'383ef075-2ada-4f3a-a86f-a0d304152cd0',
            ID: 'slider',
            Caption: '@@Slider',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'slider';
            }
        });
        PROPERTIES.push({
            ID: "Orientation",
            Parent: "slider",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Orientation",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                EnumSource: SliderOrientation
            }
        })
        PROPERTIES.push({
            ID: "Minimum",
            Parent: "slider",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Minimum",
            InitArgs: {
                InputType: "number"
            }
        });
        PROPERTIES.push({
            ID: "Maximum",
            Parent: "slider",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Maximum",
            InitArgs: {
                InputType: "number"
            }
        });
        PROPERTIES.push({
            ID: "Step",
            Parent: "slider",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Step",
            InitArgs: {
                InputType: "number"
            }
        });
    }
}