import { Component, ViewChild, } from '@angular/core';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { Dialog } from 'primeng/dialog';


declare var LiveAgent: any;

@Component({
	selector: 'lib-help-menu',
	templateUrl: './help.menu.html',
	styleUrls: ['./help.menu.scss'],
})
export class HelpMenuControl {
	static Type: any = 'usermenu';

	static Default = {
		Editable: true,
		Type: 'usermenu',
		Variant: 'named',
	};
	liveChatDialog = false;

	@ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
	constructor(
		private router: Router,
	) {}

	@ViewChild('chatDialog') chatDialog!: Dialog;

	ngOnInit(): void {
		
	}


	ngAfterViewInit(): void {
    this.chatDialog.onShow.subscribe(() => {
      if (this.liveChatDialog) {
        this.loadLiveAgentScript().then(() => {
          this.initializeLiveAgentButton();
        }).catch((error) => {
          console.error('Failed to load LiveAgent script:', error);
        });
      }
    });
	}
	
	OnHelpCenter() {
		this.router.navigate(['default/settings/help-center']);
		this.trigger.closeMenu();
	}


	openLiveChatDialog() {
		this.liveChatDialog = true;
		this.trigger.closeMenu();
	}


	claseLiveChatDialog() {
		this.liveChatDialog = false;
	}


	
  private loadLiveAgentScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      const existingScript = document.getElementById('la_x2s6df8d');
      if (!existingScript) {
        const scriptUrl = 'https://ga-universe.ladesk.com/scripts/track.js';
        const node = document.createElement('script');
        node.src = scriptUrl;
        node.id = 'la_x2s6df8d';
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        node.onload = () => {
          resolve();
        };
        node.onerror = () => {
          reject(new Error('Failed to load script'));
        };
        document.head.appendChild(node);
      } else {
        resolve();
      }
    });
  }

  private initializeLiveAgentButton(): void {
    try {
			const chatButtonContainer = document.getElementById('live-chatButton');
			console.log('chat button',chatButtonContainer)
      if (chatButtonContainer) {
        LiveAgent.createButton('avx216f2', chatButtonContainer);
      }
    } catch (error) {
      console.error('LiveAgent is not defined:', error);
    }
  }
}
