import {
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	Inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MediaService } from 'src/app/services/media.service';

import { GlobalSearchService } from 'src/app/services/global-search.service';
import { LayoutService } from 'src/app/services/layout.service';
import { NotificationHelper } from 'src/app/helpers/notification.helper';
import { MessageType } from 'src/app/models/enums/messagetype.enum';
import { MessageCenterDialog } from './messagecenter.dialogue';
import { RefreshTokenService } from 'src/app/services/refresh-token.service';

@Component({
	selector: 'lib-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	Subscriptions: any = {};
	SearchBox = false;
	// Global Search: variables
	filteredOptions: Observable<any[]>;
	static Type: any = 'messagecenter';
	static Default = {
		Type: 'messagecenter',
		Layout: {
			_Editable: true,
		},
	};
	@ViewChild('button', { read: ElementRef })
	button: ElementRef<HTMLInputElement>;

	Count = 0;
	ColorStyle = {};
	constructor(
		private globalSearchService: GlobalSearchService,
		private dialog: MatDialog, private router: Router,
		@Inject(LayoutService.CONTAINER_DATA) public data
	) {}
	iconURL = '';
	HomeNav = {};
	ngOnInit() {
		this.HomeNav = this.globalSearchService.flattenList[0]?.navItem;
		this.iconURL = '../../../assets/icons/ga_univers.svg';
		// this.Subscriptions['Icon'] = MediaService.IconURL.subscribe((url) => {
		// 	if (url) {
		// 		this.iconURL = url + 'ga_univers.svg';
		// 	}
		// });
		this.Subscriptions['ReceivedMessages'] =
			NotificationHelper.ReceivedMessages.subscribe((x) => {
				let count = 0;
				let style = {};
				if (x) {
					x.forEach((rm) => {
						if (!rm.Read) {
							if (count === 0) {
								switch (rm.MessageType) {
									case MessageType.Error:
										style['background'] = 'rgb(244,67,54)';
										break;
									case MessageType.Information:
										style['background'] = 'rgb(31,135,229)';
										break;
									case MessageType.Success:
										style['background'] = 'rgb(77,174,81)';
										break;
									case MessageType.Warning:
										style['background'] = 'rgb(254,152,2)';
										break;
								}
							}
							count++;
						}
					});
				}
				this.Count = count;
				this.ColorStyle = style;
			});
	}

	toggleSearch() {
		this.SearchBox = !this.SearchBox;
	}

	ngOnDestroy() {
		Object.keys(this.Subscriptions).forEach((key) => {
			this.Subscriptions[key].unsubscribe();
		});
	}

	globalSearch(value) {
		this.filteredOptions = this.globalSearchService.search(value);
	}

	// Global Search: navigate to selected route
	navigateRoute(nav) {
		this.globalSearchService.CallNavMenu(nav);
	}

	open() {
		this.dialog.open(MessageCenterDialog, {
			panelClass: 'MessageCenterDialog',
			data: {},
		});
	}

	setFocus() {
		if (this.button && this.button.nativeElement) {
			this.button.nativeElement.focus();
		}
	}

	GoHome() {
		// this.globalSearchService.CallNavMenu(this.HomeNav);
		this.router.navigate(['/default/settings/dashboard'])
	}
}
