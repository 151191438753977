import { ComponentPortal } from '@angular/cdk/portal';
import { BehaviorSubject, Subject } from 'rxjs';
import { ChartsMenuTab } from '../../appbuilder/menutabs/charts/charts.menu.tab';
import { ColumnVisibilityPropertyMenuTab } from '../../appbuilder/menutabs/columnvisibility/columnvisibility.menu.tab';
import {
    ControlsAccordion, ControlsMenuTab, LayoutAccordion, StaticAccordion, TemplateAccordion, WidgetAccordion
} from '../../appbuilder/menutabs/controls/controls.menu.tab';
import { DataBindingMenuTab } from '../../appbuilder/menutabs/databinding/databinding.menu.tab';
import { DataTableColumnsMenuTab, DataTableFixedColumnsMenuTab, DataTableSettingsMenuTab, DataTableStyleMenuTab } from '../../appbuilder/menutabs/datatable/datatable.menu.tab';
import { EventsMenuTab } from '../../appbuilder/menutabs/events/events.menu.tab';
import { LayoutMenuTab } from '../../appbuilder/menutabs/layout/layout.menu.tab';
import { ListBoxPropertyMenuTab } from '../../appbuilder/menutabs/listbox/listbox.menu.tab';
import { PageSettingsMenuTab } from '../../appbuilder/menutabs/pagesettings/pagesettings.menu.tab';
import { PropertiesMenuTab } from '../../appbuilder/menutabs/properties/properties.menu.tab';
import { ResolutionMenuTab } from '../../appbuilder/menutabs/resolution/resolution.menu.tab';
import { WorkflowMenuTabStatic } from '../../appbuilder/menutabs/statics/workflow/workflow.static.menu.tab';
import { TemplateMenuTab } from '../../appbuilder/menutabs/template/template.menu.tab';
import { TreeMenuTab } from '../../appbuilder/menutabs/tree/tree.menu.tab';
import { VariablesMenuTab } from '../../appbuilder/menutabs/variables/variables.menu.tab';
import { LogsWidgetMenu } from '../../appbuilder/menutabs/widget/joblogs/joblogs.widget.menu';
import { JobsWidgetMenu } from '../../appbuilder/menutabs/widget/jobs/jobs.widget.menu';
import { WidgetMenuTab } from '../../appbuilder/menutabs/widget/widget.menu.tab';
import { WorkflowMenuTab } from '../../appbuilder/menutabs/workflow/workflow.menu.tab';
import { AdvancedPanel } from '../../appbuilder/panels/advanced.panel';
import { ContentPanel } from '../../appbuilder/panels/content.panel';
import { CustomEventsPanel } from '../../appbuilder/panels/customevents.panel';
import { DataSourcePanel } from '../../appbuilder/panels/datasource.panel';
import { SimpleReportingStylePanel } from '../../appbuilder/panels/simplereporting.panel';
import { DecorationsPanel } from '../../appbuilder/panels/decorations.panel';
import { TransitionsPanel } from '../../appbuilder/panels/transitions.panel';
import { AccordionControl, AccordionPanel } from '../../components/controls/accordion/accordion.control';
import { ApplicationSwitcherComponent } from '../../components/controls/appswitch/appswitch.component';
import { AutocompleteControl, AutocompletePanel } from '../../components/controls/autocomplete/autocomplete.control';
import { ButtonControl, ButtonPanel } from '../../components/controls/button/button.control';
import { CarouselControl, CarouselPanel } from '../../components/controls/carousel/carousel.control';
import { ChartsControl } from '../../components/controls/charts/charts.control';
import { CheckBoxControl, CheckBoxPanel } from '../../components/controls/checkbox/checkbox.control';
import { ChipListControl, ChipListPanel } from '../../components/controls/chiplist/chiplist.control';
import { ComboBoxControl, ComboBoxPanel } from '../../components/controls/combobox/combobox.control';
import { ContentControl } from '../../components/controls/content/content.control';
import { DataCapsuleTagControl } from '../../components/controls/datacapsuletag/data.capsule.tag.control';
import { DateTimePickerControl, DateTimePickerPanel } from '../../components/controls/datetimepicker/datetimepicker.control';
import { DownloadControl, DownloadPanel } from '../../components/controls/download/download.control';
import { DropdownComponent } from '../../components/controls/dropdown/dropdown.component';
import { EditorControl, EditorPanel } from '../../components/controls/editor/editor.control';
import { EmbedControl, EmbedPanel } from '../../components/controls/embed/embed.control';
import { HrefControl, HrefPanel } from '../../components/controls/href/href.control';
import { HyperPayControl, HyperPayPanel } from '../../components/controls/hyperpay/hyperpay.control';
import { IconControl, IconPanel } from '../../components/controls/icon/icon.control';
import { IFrameControl, IFramePanel } from '../../components/controls/iframe/iframe.control';
import { ImageControl, ImagePanel } from '../../components/controls/image/image.control';
import { InputGroupControl, InputGroupPanel } from '../../components/controls/inputgroup/inputgroup.control';
import { LanguageMenu, LanguageMenuPanel } from '../../components/controls/languagemenu/language.menu';
import { ListBoxControl, ListBoxPanel } from '../../components/controls/listbox/listbox.control';
import { ListDetailControl } from '../../components/controls/listdetail/listdetail.control';
import { LoadingComponent } from '../../components/controls/loading/loading.component';
import { MarkerControl } from '../../components/controls/marker/marker.control';
import { MessageCenterControl } from '../../components/controls/messagecenter/messagecenter.control';
import { ObjectViewerControl, ObjectViewerPanel } from '../../components/controls/objectviewer/objectviewer.control';
import { PageIndexComponent, PageIndexPanel } from '../../components/controls/output/page.index.component';
import { SumComponent, SumPanel } from '../../components/controls/output/sum.component';
import { PDFViewerControl, PdfViewerPanel } from '../../components/controls/pdfviewer/pdfviewer.control';
import { ProfileManagementControl } from '../../components/controls/profilemanagement/profilemanagement.control';
import { QRComponent, QRPanel } from '../../components/controls/qr/qr.component';
import { RadioButtonControl, RadioButtonPanel } from '../../components/controls/radiobutton/radiobutton.control';
import { SchedulerControl, SchedulerPanel } from '../../components/controls/scheduler/scheduler.control';
import { SideNavControl, SideNavPanel } from '../../components/controls/sidenav/sidenav.control';
import { SliderControl, SliderPanel } from '../../components/controls/slider/slider.control';
import { StepperControl, StepperPanel } from '../../components/controls/stepper/stepper.control';
import { TabControl, TabPanel } from '../../components/controls/tab/tab.control';
import { EviTableColumnVisibilityControl } from '../../components/controls/tables/columnvisibility/columnvisibility.control';
import { DataTableControl } from '../../components/controls/tables/datatable/datatable.control';
import { TextAreaControl, TextAreaPanel } from '../../components/controls/textarea/textarea.control';
import { TextBlockControl, TextBlockPanel } from '../../components/controls/textblock/textblock.control';
import { TextBoxControl, TextBoxPanel } from '../../components/controls/textbox/textbox.control';
import { ThemeSwitchControl } from '../../components/controls/themeswitch/themeswitch.control';
import { TimespanControl, TimespanPanel } from '../../components/controls/timespan/timespan.control';
import { ToggleButtonControl, ToggleButtonPanel } from '../../components/controls/togglebutton/togglebutton.control';
import { TranslationTextBoxComponent, TranslationTextBoxPanel } from '../../components/controls/translationtextbox/translation.textbox.component';
import { TreeControl } from '../../components/controls/tree/tree.control';
import { UploadControl, UploadPanel } from '../../components/controls/upload/upload.control';
import { UserMenuControl, UserMenuPanel } from '../../components/controls/usermenu/usermenu.control';
import { VideoControl, VideoPanel } from '../../components/controls/video/video.control';
import { BootstrapGridLayout, BootstrapGridPanel } from '../../components/layouts/bootstrapgrid/bootstrapgrid.layout';
import { BootstrapRepeatLayout, BootstrapRepeatPanel } from '../../components/layouts/bootstraprepeat/bootstraprepeat.layout';
import { CanvasLayout } from '../../components/layouts/canvas/canvas.layout';
import { ExpansionPanelLayout, ExpansionPanelPanel } from '../../components/layouts/expansionpanel/expansion.panel.layout';
import { FlexLayout, FlexPanel } from '../../components/layouts/flex/flex.layout';
import { GridLayout, GridPanel } from '../../components/layouts/grid/grid.layout';
import { NavigationLayout } from '../../components/layouts/navigation/navigation.layout';
import { RasterLayout, RasterPanel } from '../../components/layouts/raster/raster.layout';
import { RepeatingLayout, RepeatingPanel } from '../../components/layouts/repeating/repeating.layout';
import { TemplateLayout } from '../../components/layouts/template/template.layout';
import { WidgetLayout } from '../../components/layouts/widget/widget.layout';
import { DataSourceStaticControl } from '../../components/staticclasscontrols/datasource/datasource.static.control';
import { WorkflowStaticControl } from '../../components/staticclasscontrols/workflow/workflow.static.control';
import {SimpleReportingControl, SimpleReportingPanel} from '../../components/controls/simplereporting/simplereporting.component'
import { ActionsWidget } from '../../components/widgets/actions/actions.widget';
import { NetworkConnection } from '../../helpers/network.helpers';
import { ComponentCategory } from '../../models/enums/componentcategory.enum';
import { MenuTabPosition } from '../../models/enums/menutabposition.enum';
import { Page } from '../../models/enums/page.enum';
import { ViewType } from '../../models/enums/viewtype.enum';
import {
    CONTROLGROUPS, GLOBALEVENTREGISTRY, MENUTABREGISTRY, PROPERTYGROUPS, PROPERTYPANELS, REGISTRY, WIDGETREGISTRY
} from '../../services/dynamic.component.service';
import { LanguageService } from '../../services/language.service';
import { LayoutService } from '../../services/layout.service';
import { NavigationService } from '../../services/navigation.service';
import { JobsLogsComponent } from '../../settings/jobs/joblogs/jobs.logs';
import { JobsQueueComponent } from '../../settings/jobs/queue/jobs.queue';
import { GMapControl, GoogleMapPanel } from 'src/app/components/controls/gmap/gmap.control';

export class ComponentRegistryHelper {
    public static Build() {
        let index = 1;
        //#region Controls
        //REGISTRY['action'] = {
        //    Control: BaseAction,
        //    Icon: 'toolbox_action',
        //    Label: '@@Action',
        //    type: 'Control',
        //    Category: ComponentCategory.Other,
        //    page: [Page.Ribbon],
        //    MenuTab: ActionMenuTab
        //};
        REGISTRY['appswitch'] = {
            Control: ApplicationSwitcherComponent,
            Icon: 'toolbox_appswitch',
            Label: '@@ApplicationSwitcher',
            type: 'Control',
            Category: ComponentCategory.Other,
            page: [Page.Layout]
        };
        REGISTRY['textbox'] = {
            Control: TextBoxControl,
            Icon: 'toolbox_textbox',
            Label: '@@TextBox',
            type: 'Control',
            Category: ComponentCategory.Text,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: TextBoxPanel
        };
        REGISTRY['inputgroup'] = {
            Control: InputGroupControl,
            Icon: 'toolbox_inputgroup',
            Label: '@@InputGroup',
            type: 'Control',
            Category: ComponentCategory.Text,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: InputGroupPanel
        };
        REGISTRY['textarea'] = {
            Control: TextAreaControl,
            Icon: 'toolbox_textarea',
            Label: '@@TextArea',
            type: 'Control',
            Category: ComponentCategory.Text,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: TextAreaPanel
        };
        REGISTRY['translationtextbox'] = {
            Control: TranslationTextBoxComponent,
            Icon: 'toolbox_translationbox',
            Label: '@@TranslationTextBox',
            type: 'Control',
            Category: ComponentCategory.Text,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: TranslationTextBoxPanel
        };
        REGISTRY['editor'] = {
            Control: EditorControl,
            Icon: 'toolbox_editor',
            Label: '@@Editor',
            type: 'Control',
            Category: ComponentCategory.Text,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: EditorPanel
        };
        REGISTRY['qr'] = {
            Control: QRComponent,
            Icon: 'toolbox_qr',
            Label: '@@QR',
            type: 'Control',
            Category: ComponentCategory.Other,
            page: [Page.App, Page.Ribbon, Page.Layout, Page.Print],
            Panel: QRPanel
        };
        REGISTRY['datatable'] = {
            Control: DataTableControl,
            Icon: 'toolbox_table',
            Label: '@@DataTable',
            type: 'Control',
            Category: ComponentCategory.Data,
            page: [Page.App, Page.Ribbon, Page.Layout]
        };
        PROPERTYGROUPS.push({
            SID:'60127b5e-ad44-4ffb-a0e5-b8774ddd2132',
            Caption: '@@DataTableSettings', Index: 100, Content: DataTableSettingsMenuTab, CheckOnChange: DataTableSettingsMenuTab.CheckOnChange
        });
        PROPERTYGROUPS.push({
            SID:'36f699f1-84e1-406f-b425-8c0954a0b981',
            Caption: '@@DataTableColumns', Index: 101, Content: DataTableColumnsMenuTab, CheckOnChange: DataTableColumnsMenuTab.CheckOnChange
        });
        PROPERTYGROUPS.push({
            SID: '613c632d-5c25-4abc-be8a-0324fadb7cb7',
            Caption: '@@DataTableFixedColumns', Index: 102, Content: DataTableFixedColumnsMenuTab, CheckOnChange: DataTableFixedColumnsMenuTab.CheckOnChange
        });
        PROPERTYGROUPS.push({
            SID: 'ee5952a8-0ef9-4188-aaec-74601b8167c8',
            Caption: '@@DataTableStyle', Index: 103, Content: DataTableStyleMenuTab, CheckOnChange: DataTableStyleMenuTab.CheckOnChange
        });
        REGISTRY['tablecolumnvisibility'] = {
            Control: EviTableColumnVisibilityControl,
            Icon: 'toolbox_columnvisibility',
            Label: '@@ColumnVisibility',
            type: 'Control',
            Category: ComponentCategory.Other,
            page: [Page.App, Page.Ribbon, Page.Layout],
            MenuTab: ColumnVisibilityPropertyMenuTab
        };
        REGISTRY['checkbox'] = {
            Control: CheckBoxControl,
            Icon: 'toolbox_checkbox',
            Label: '@@CheckBox',
            type: 'Control',
            Category: ComponentCategory.Data,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: CheckBoxPanel
        };
        REGISTRY['combobox'] = {
            Control: ComboBoxControl,
            Icon: 'toolbox_combobox',
            Label: '@@ComboBox',
            type: 'Control',
            Category: ComponentCategory.Data,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: ComboBoxPanel
        };
        REGISTRY['dropdown'] = {
            Control: DropdownComponent,
            Icon: 'toolbox_dropdown',
            Label: '@@DropDown',
            type: 'Control',
            Category: ComponentCategory.Other,
            page: [Page.App, Page.Ribbon, Page.Layout]
        };
        REGISTRY['button'] = {
            Control: ButtonControl,
            Icon: 'toolbox_button',
            Label: '@@Button',
            type: 'Control',
            Category: ComponentCategory.Button,
            page: [Page.App, Page.Ribbon, Page.Layout]
        };
        REGISTRY['togglebutton'] = {
            Control: ToggleButtonControl,
            Icon: 'toolbox_togglebutton',
            Label: '@@ToggleButton',
            type: 'Control',
            Category: ComponentCategory.Button,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: ToggleButtonPanel
        };
        REGISTRY['sidenav-toggle'] = {
            Control: SideNavControl,
            Icon: 'toolbox_sidenav',
            Label: '@@SideNavToggle',
            children: [],
            type: 'Control',
            Category: ComponentCategory.Button,
            page: [Page.Layout],
            Panel: SideNavPanel
        };
        REGISTRY['themeswitch'] = {
            Control: ThemeSwitchControl,
            Icon: 'toolbox_themeswitch',
            Label: '@@ThemeSwitch',
            type: 'Control',
            Category: ComponentCategory.Other,
            page: [Page.App, Page.Ribbon, Page.Layout]
        };
        REGISTRY['messagecenter'] = {
            Control: MessageCenterControl,
            Icon: 'toolbox_messagecenter',
            Label: '@@MessageCenter',
            type: 'Control',
            Category: ComponentCategory.Other,
            page: [Page.App, Page.Ribbon, Page.Layout]
        };
        REGISTRY['radiobutton'] = {
            Control: RadioButtonControl,
            Icon: 'toolbox_radiobutton',
            Label: '@@RadioButton',
            type: 'Control',
            Category: ComponentCategory.Button,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: RadioButtonPanel
        };
        REGISTRY['datetimepicker'] = {
            Control: DateTimePickerControl,
            Icon: 'toolbox_datetimepicker',
            Label: '@@DateTimePicker',
            type: 'Control',
            Category: ComponentCategory.Data,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: DateTimePickerPanel
        };
        REGISTRY['timespan'] = {
            Control: TimespanControl,
            Icon: 'toolbox_timespan',
            Label: '@@TimeSpan',
            type: 'Control',
            Category: ComponentCategory.Data,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: TimespanPanel
        };
        REGISTRY['scheduler'] = {
            Control: SchedulerControl,
            Icon: 'toolbox_scheduler',
            Label: '@@Scheduler',
            type: 'Control',
            Category: ComponentCategory.Data,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: SchedulerPanel
        };
        REGISTRY['tree'] = {
            Control: TreeControl,
            Icon: 'toolbox_tree',
            Label: '@@Tree',
            type: 'Control',
            Category: ComponentCategory.Other,
            page: [Page.App, Page.Ribbon, Page.Layout],
            MenuTab: TreeMenuTab
        };
        REGISTRY['listbox'] = {
            Control: ListBoxControl,
            Icon: 'toolbox_listbox',
            Label: '@@ListBox',
            type: 'Control',
            Category: ComponentCategory.Data,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: ListBoxPanel
        };
        REGISTRY['listdetail'] = {
            Control: ListDetailControl,
            Icon: 'toolbox_listdetail',
            Label: '@@ListDetail',
            type: 'Control',
            Category: ComponentCategory.Data,
            page: [],
            MenuTab: ListBoxPropertyMenuTab
        };
        REGISTRY['chiplist'] = {
            Control: ChipListControl,
            Icon: 'toolbox_chips',
            Label: '@@ChipList',
            type: 'Control',
            Category: ComponentCategory.Data,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: ChipListPanel
        };
        REGISTRY['content'] = {
            Control: ContentControl,
            Icon: 'toolbox_content',
            Label: '@@Content',
            type: 'Control',
            page: [Page.Layout],
            Category: ComponentCategory.Other
        };
        REGISTRY['slider'] = {
            Control: SliderControl,
            Icon: 'toolbox_slider',
            Label: '@@Slider',
            type: 'Control',
            Category: ComponentCategory.Data,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: SliderPanel
        };
        REGISTRY['image'] = {
            Control: ImageControl,
            Icon: 'toolbox_image',
            Label: '@@Image',
            type: 'Control',
            Category: ComponentCategory.Media,
            page: [Page.App, Page.Ribbon, Page.Layout, Page.Print],
            Panel: ImagePanel
        };
        REGISTRY['icon'] = {
            Control: IconControl,
            Icon: 'toolbox_icon',
            Label: '@@Icon',
            type: 'Control',
            Category: ComponentCategory.Media,
            page: [Page.App, Page.Layout]
        };
        REGISTRY['objectviewer'] = {
            Control: ObjectViewerControl,
            Icon: 'toolbox_objectviewer',
            Label: '@@Dateiviewer',
            type: 'Control',
            page: [Page.App],
            Category: ComponentCategory.Media,
            Panel: ObjectViewerPanel
        };
        // REGISTRY[PDFViewerControl.Type] = {
        //     Control: PDFViewerControl,
        //     Icon: 'toolbox_pdfviewer',
        //     Label: '@@PDFViewer',
        //     type: 'Control',
        //     page: [Page.App],
        //     Category: ComponentCategory.Media,
        //     Panel: PdfViewerPanel
        // };
        REGISTRY['marker'] = {
            Control: MarkerControl,
            Icon: 'toolbox_marker',
            Label: '@@Marker',
            type: 'Control',
            Category: ComponentCategory.Data,
            page: [Page.App, Page.Layout],
            // MenuTab: ObjectViewerPropertyMenuTab
        };
        REGISTRY['profilemanagement'] = {
            Control: ProfileManagementControl,
            Icon: 'toolbox_profilemanagement',
            Label: '@@ProfileManagement',
            type: 'Control',
            Category: ComponentCategory.Other,
            page: [Page.App, Page.Layout]
        };
        REGISTRY['textblock'] = {
            Control: TextBlockControl,
            Icon: 'toolbox_textblock',
            Label: '@@TextBlock',
            type: 'Control',
            Category: ComponentCategory.Text,
            page: [Page.App, Page.Ribbon, Page.Layout, Page.Print],
            Panel: TextBlockPanel
        };
        REGISTRY['googlemap'] = {
            Control: GMapControl,
            Icon: 'maps',
            Label: '@@GoogleMap',
            type: 'Control',
            Category: ComponentCategory.Other,
            page: [Page.App, Page.Layout],
            Panel: GoogleMapPanel
        };
        REGISTRY['href'] = {
            Control: HrefControl,
            Icon: 'toolbox_href',
            Label: '@@Href',
            type: 'Control',
            Category: ComponentCategory.Text,
            page: [Page.App, Page.Ribbon, Page.Layout, Page.Print],
            Panel: HrefPanel
        };
        REGISTRY['languagemenu'] = {
            Control: LanguageMenu,
            Icon: 'toolbox_languagemenu',
            Label: '@@Sprachauswahl',
            type: 'Control',
            Category: ComponentCategory.Other,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: LanguageMenuPanel
        };
        REGISTRY['usermenu'] = {
            Control: UserMenuControl,
            Icon: 'toolbox_usermenu',
            Label: '@@Usermenu',
            type: 'Control',
            page: [Page.App, Page.Ribbon, Page.Layout],
            Category: ComponentCategory.Other,
            Panel:UserMenuPanel
        };
        REGISTRY['accordion'] = {
            Control: AccordionControl,
            Icon: 'toolbox_accordion',
            Label: '@@Accordion',
            type: 'Control',
            Category: ComponentCategory.Layout,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: AccordionPanel
        };
        REGISTRY['stepper'] = {
            Control: StepperControl,
            Icon: 'toolbox_stepper',
            Label: '@@Stepper',
            type: 'Control',
            Category: ComponentCategory.Layout,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: StepperPanel
        };
        REGISTRY['tab'] = {
            Control: TabControl,
            Icon: 'toolbox_tab',
            Label: '@@Tab',
            type: 'Control',
            Category: ComponentCategory.Layout,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: TabPanel
        };
        REGISTRY['iframe'] = {
            Control: IFrameControl,
            Icon: 'toolbox_iframe',
            Label: '@@IFrame',
            type: 'Control',
            Category: ComponentCategory.ExternalContent,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: IFramePanel
        };
        REGISTRY['embed'] = {
            Control: EmbedControl,
            Icon: 'toolbox_embed',
            Label: '@@Embed',
            type: 'Control',
            Category: ComponentCategory.ExternalContent,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: EmbedPanel
        };
        REGISTRY['autocomplete'] = {
            Control: AutocompleteControl,
            Icon: 'toolbox_autocomplete',
            Label: '@@Autocomplete',
            type: 'Control',
            Category: ComponentCategory.Data,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: AutocompletePanel
        };
        REGISTRY['carousel'] = {
            Control: CarouselControl,
            Icon: 'toolbox_carousel',
            Label: '@@Carousel',
            type: 'Control',
            Category: ComponentCategory.Media,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: CarouselPanel
        };
        REGISTRY['video'] = {
            Control: VideoControl,
            Icon: 'toolbox_video',
            Label: '@@Video',
            children: [],
            type: 'Control',
            Category: ComponentCategory.Media,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: VideoPanel
        };
        REGISTRY['charts'] = {
            Control: ChartsControl,
            Icon: 'toolbox_chart',
            Label: '@@Charts',
            type: 'Control',
            Category: ComponentCategory.Data,
            page: [Page.App, Page.Ribbon, Page.Layout],
            MenuTab: ChartsMenuTab
        };
        REGISTRY['upload'] = {
            Control: UploadControl,
            Icon: 'toolbox_upload',
            Label: '@@Upload',
            type: 'Control',
            Category: ComponentCategory.Media,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: UploadPanel
        };
        REGISTRY['download'] = {
            Control: DownloadControl,
            Icon: 'toolbox_download',
            Label: '@@Download',
            type: 'Control',
            Category: ComponentCategory.Media,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: DownloadPanel
        };
        REGISTRY['loading'] = {
            Control: LoadingComponent,
            Icon: 'toolbox_loading',
            Label: '@@LoadingIndicator',
            type: 'Control',
            Category: ComponentCategory.Other,
            page: [Page.App, Page.Ribbon, Page.Layout]
        };
        REGISTRY['reporting'] = {
            Control: SimpleReportingControl,
            Icon: 'chart',
            Label: '@@Reporting',
            type: 'Control',
            Category: ComponentCategory.Other,
            page: [Page.App, Page.Ribbon, Page.Layout],
            Panel: SimpleReportingPanel
        };
        REGISTRY[PageIndexComponent.Type] = {
            Control: PageIndexComponent,
            Icon: 'toolbox_pagecount',
            Label: '@@Seitenzahl',
            type: 'Control',
            page: [Page.Print],
            Panel: PageIndexPanel
        };
        REGISTRY[SumComponent.Type] = {
            Control: SumComponent,
            Icon: 'toolbox_sum',
            Label: '@@Summe',
            type: 'Control',
            page: [Page.Print],
            Panel: SumPanel
        };
        REGISTRY[DataCapsuleTagControl.Type] = {
            Control: DataCapsuleTagControl,
            Icon: 'toolbox_capsuletag',
            Label: '@@Capsule Tag Control',
            type: 'Control',
            page: [Page.App],
            Category: ComponentCategory.Other
        };
        REGISTRY[HyperPayControl.Type] = {
            Control: HyperPayControl,
            Icon: 'toolbox_download',
            Label: '@@HyperPay',
            type: 'Control',
            Category: ComponentCategory.Other,
            page: [Page.App, Page.Layout],
            Panel: HyperPayPanel
        };
        //#endregion
        //#region Layouts
        REGISTRY['raster'] = {
            Control: RasterLayout,
            Icon: 'toolbox_raster',
            Label: '@@Raster',
            type: 'Layout',
            page: [Page.App, Page.Ribbon, Page.Layout],
            Category: ComponentCategory.Layout,
            Panel: RasterPanel
        };
        REGISTRY['grid'] = {
            Control: GridLayout,
            Icon: 'toolbox_grid',
            Label: '@@Grid',
            type: 'Layout',
            Category: ComponentCategory.Layout,
            page: [Page.App, Page.Ribbon, Page.Layout, Page.Print]
        };
        REGISTRY['bootstrapgrid'] = {
            Control: BootstrapGridLayout,
            Icon: 'toolbox_bootstrapgrid',
            Label: '@@BootstrapGrid',
            type: 'Layout',
            Category: ComponentCategory.Layout,
            page: [Page.App, Page.Layout]
        };
        
        REGISTRY['flex'] = {
            Control: FlexLayout,
            Icon: 'toolbox_flex',
            Label: '@@Flex',
            type: 'Layout',
            page: [Page.App, Page.Ribbon, Page.Layout],
            Category: ComponentCategory.Layout,
            Panel: FlexPanel
        };
        REGISTRY['canvas'] = {
            Control: CanvasLayout,
            Icon: 'toolbox_canvas',
            Label: '@@Canvas',
            type: 'Layout',
            Category: ComponentCategory.Layout,
            page: [Page.App, Page.Ribbon, Page.Layout, Page.Print]
        };
        REGISTRY['repeat'] = {
            Control: RepeatingLayout,
            Icon: 'toolbox_repeat',
            Label: '@@Repeat',
            type: 'Layout',
            Category: ComponentCategory.Layout,
            page: [Page.App, Page.Ribbon, Page.Layout, Page.Print],
            Panel: RepeatingPanel
        };
        REGISTRY['bootstraprepeat'] = {
            Control: BootstrapRepeatLayout,
            Icon: 'toolbox_bootstraprepeat',
            Label: '@@BootstrapRepeat',
            type: 'Layout',
            Category: ComponentCategory.Layout,
            page: [Page.App, Page.Layout],
            Panel: BootstrapRepeatPanel
        };
        REGISTRY['expansionpanel'] = {
            Control: ExpansionPanelLayout,
            Icon: 'toolbox_expansionpanel',
            Label: '@@Expansion Panel',
            type: 'Layout',
            Category: ComponentCategory.Layout,
            page: [Page.App, Page.Layout],
            Panel: ExpansionPanelPanel
        };
        REGISTRY['template'] = {
            Control: TemplateLayout,
            Icon: 'toolbox_template',
            Label: '@@Template',
            type: 'Layout',
            Category: ComponentCategory.Layout,
            page: [Page.None]
        };
        REGISTRY['widget'] = {
            Control: WidgetLayout,
            Icon: 'toolbox_widget',
            Label: '@@Widget',
            type: 'Layout',
            Category: ComponentCategory.Layout,
            page: [Page.None],
            MenuTab: WidgetMenuTab
        };
        REGISTRY['navicontrol'] = {
            Control: NavigationLayout,
            Icon: 'toolbox_sidenav',
            Label: '@@Navigation',
            type: 'Layout',
            page: [Page.Layout],
            Category: ComponentCategory.Layout,
        };
        //#endregion
        //#region StaticControls
        REGISTRY['workflow'] = {
            Control: WorkflowStaticControl,
            Icon: 'toolbox_workflow',
            Label: '@@Workflow',
            type: 'StaticControl',
            page: [],
            MenuTab: WorkflowMenuTabStatic
        };
        REGISTRY['datasource'] = {
            Control: DataSourceStaticControl,
            Icon: 'toolbox_datasource',
            Label: '@@Datenquelle',
            children: [],
            type: 'StaticControl',
            page: []
        };
        //#endregion

        
        //#region MenuTabs
        MENUTABREGISTRY.push({ ID: 'client_toolbox', Icon: 'toolbox_toolbox', Tooltip: '@@Toolbox', Index: 10 * index++, Content: ControlsMenuTab, Position: MenuTabPosition.Left });
        MENUTABREGISTRY.push({ ID: 'client_layout', Icon: 'toolbox_layout', Tooltip: '@@Layout', Index: 10 * index++, Content: LayoutMenuTab, Position: MenuTabPosition.Left });
        MENUTABREGISTRY.push({
            ID: 'client_properties',
            Icon: 'toolbox_properties',
            Tooltip: '@@Properties',
            Index: 10 * index++,
            Content: PropertiesMenuTab,
            CheckVisible: PropertiesMenuTab.CheckVisible,
            Position: MenuTabPosition.Right
        });
        MENUTABREGISTRY.push({ ID: 'client_workflow', Icon: 'toolbox_clientworkflow', Tooltip: '@@Workflow', Index: 10 * index++, Content: WorkflowMenuTab, Position: MenuTabPosition.Navi });
        MENUTABREGISTRY.push({ ID: 'client_resolution', Icon: 'toolbox_resolution', Tooltip: '@@Resolutions', Index: 10 * index++, Content: ResolutionMenuTab, Position: MenuTabPosition.Navi });
        MENUTABREGISTRY.push({ ID: 'client_settings', Icon: 'toolbox_settings', Tooltip: '@@PageSettings', Index: 10 * index++, Content: PageSettingsMenuTab, Position: MenuTabPosition.Navi });

        MENUTABREGISTRY.sort((a, b) => a.Index - b.Index);
        //#endregion

        index = 1;
        //#region ControlGroups
        CONTROLGROUPS.push({ SID:'Layout', Caption: '@@Layouts', Index: 10 * index++, Content: LayoutAccordion });
        CONTROLGROUPS.push({ SID: 'Controls',Caption: '@@Controls', Index: 10 * index++, Content: ControlsAccordion });
        // do not remove this line of code
        // CONTROLGROUPS.push({ SID: 'StaticControls',Caption: '@@Static Controls', Index: 10 * index++, Content: StaticAccordion });
        CONTROLGROUPS.push({
            SID: 'Templates', Caption: '@@Templates', Index: 10 * index++, Content: TemplateAccordion, CheckVisible: TemplateAccordion.CheckVisible
        });
        CONTROLGROUPS.push({
            SID: 'Widgets', Caption: '@@Widgets', Index: 10 * index++, Content: WidgetAccordion, CheckVisible: WidgetAccordion.CheckVisible
        });
        CONTROLGROUPS.sort((a, b) => a.Index - b.Index);
        //#endregion

        //#region PropertyGroups
        let Initialized = false;
        LayoutService.ViewType.subscribe((vt) => {
            if (vt == ViewType.Edit && !Initialized) {
                PROPERTYPANELS.push(AdvancedPanel)
                AdvancedPanel.InitPanel();
                PROPERTYPANELS.push(DecorationsPanel)
                DecorationsPanel.InitPanel();
                PROPERTYPANELS.push(DataSourcePanel)
                DataSourcePanel.InitPanel();
                PROPERTYPANELS.push(SimpleReportingStylePanel);
                SimpleReportingStylePanel.InitPanel();
                PROPERTYPANELS.push(CustomEventsPanel)
                CustomEventsPanel.InitPanel();
                PROPERTYPANELS.push(ContentPanel)
                ContentPanel.InitPanel();
                PROPERTYPANELS.push(TransitionsPanel)
                TransitionsPanel.InitPanel();
                PROPERTYPANELS.push(GridPanel)
                GridPanel.InitPanel();
                
                PROPERTYPANELS.push(BootstrapGridPanel)
                BootstrapGridPanel.InitPanel();
                PROPERTYPANELS.push(ButtonPanel)
                ButtonPanel.InitPanel();
                PROPERTYPANELS.push(IconPanel)
                IconPanel.InitPanel();
                Initialized = true;
            }
        })
        
        PROPERTYGROUPS.push({
            Index: 10,
            CheckOnChange: (menuItem) => {
                let regEntry;
                const selected = LayoutService.SelectedItem.getValue();
                if (selected && selected.ElementType) {
                    regEntry = REGISTRY[selected.ElementType];
                }
                if (regEntry) {
                    if (regEntry.MenuTab) {
                        menuItem.Caption = regEntry.Label;
                        menuItem.Content = new ComponentPortal(regEntry.MenuTab);
                        menuItem.Visible = true;
                        menuItem.Expanded = true;
                    }
                    if (regEntry.Panel) {
                        if (PROPERTYPANELS && PROPERTYPANELS.indexOf(regEntry.Panel) == -1) {
                            PROPERTYPANELS.push(regEntry.Panel);
                            regEntry.Panel.InitPanel();
                            LayoutService.PropertyPanelsChanged.next(null);
                        }
                    }
                }
                if (!(regEntry && regEntry.MenuTab)) {
                    menuItem.Caption = '';
                    menuItem.Content = null;
                    menuItem.Visible = false;
                    menuItem.Expanded = false;
                }
            }
        });
        PROPERTYGROUPS.push({
            Caption: '@@Template', Index: 10, Content: TemplateMenuTab, CheckOnChange: TemplateMenuTab.CheckOnChange
        });
        PROPERTYGROUPS.push({
            SID: '963766af-1c48-448f-8872-9923d3e74b14',
            Caption: '@@Events', Index: 200, Content: EventsMenuTab, CheckOnChange: EventsMenuTab.CheckOnChange, Badge: true, BadgeSub: EventsMenuTab.BadgeValue
        });
        PROPERTYGROUPS.push({
            SID: 'df964b12-966a-4599-adb9-024ff113617f',
            Caption: '@@DataBinding', Index: 204, Content: DataBindingMenuTab, CheckOnChange: DataBindingMenuTab.CheckOnChange
        });
        PROPERTYGROUPS.push({
            SID: '0a5145d6-9925-4939-82fb-c8f573574832',
            Caption: '@@Variables', Index: 202, Content: VariablesMenuTab, CheckOnChange: VariablesMenuTab.CheckOnChange, Badge: true, BadgeSub: VariablesMenuTab.BadgeValue, BadgeColor:'green'
        });
        PROPERTYGROUPS.sort((a, b) => a.Index - b.Index);
        //#endregion

        //#region Widgets
        WIDGETREGISTRY['Jobs'] = {
            Control: JobsQueueComponent,
            Icon: 'toolbox_job',
            Label: '@@Jobs',
            MenuTab: JobsWidgetMenu,
            Changes: new Subject<any>()
        };
        WIDGETREGISTRY['JobsLogs'] = {
            Control: JobsLogsComponent,
            Icon: 'toolbox_logs',
            Label: '@@Logs',
            MenuTab: LogsWidgetMenu,
            Changes: new Subject<any>()
        };
        WIDGETREGISTRY['Actions'] = {
            Control: ActionsWidget,
            Icon: 'toolbox_actionwidget',
            Label: '@@Actions',
            MenuTab: null,
            Changes: null,
            HideInControlsMenu: true
        };
        //#endregion

        //#region GlobalEvents
        GLOBALEVENTREGISTRY.push(
            { ID: '5ac4779b-b130-4c84-9e1c-6dc20321a824', Caption: '@@Navigation Start', Trigger: NavigationService.NavigationStart },
            { ID: '43fb1953-88c6-44a8-9855-1a98d119b3fb', Caption: '@@Navigation End', Trigger: NavigationService.NavigationEnd },
            { ID: '33D74F05-386B-4A9F-8162-1585EBDC8ED8', Caption: '@@PopState', Trigger: NavigationService.PopState },
            { ID: 'b544e871-eb6b-4883-af35-d3c43d0b84e6', Caption: '@@OnlineStatus', Trigger: NetworkConnection.Online },
            { ID: 'BC983912-ADC3-4F18-A6B7-1A9E5024BACE', Caption: '@@LanguageChanged', Trigger: LanguageService.LanguageChanged },
            { ID: '20c50242-4e3d-481f-a8c9-eb93a4c24b12', Caption: '@@ScreenSize', Trigger: NavigationService.ScreenSize }
        )
        //#endregion
    }
}
