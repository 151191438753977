import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { CustomCSSVariable } from '../models/style/customcssvar.model';
import { RequestBase } from './request-base';

@Injectable()
export class MetaService extends RequestBase {

    static UpdateRibbonSettings: Subject<any> = new Subject();
    static UpdateContextMenuSettings: Subject<any> = new Subject();

    BasePath = 'api/meta';
    SideNavPath = 'api/sidenav';

    constructor(httpClient: HttpClient, private ngZone: NgZone) {
        super(httpClient);
        this.ngZone.runOutsideAngular(() => {
            window.addEventListener('resize', this.checkZoom.bind(this));
        });
    }
    //#region Layout
    ReadLayoutById(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadLayoutById?id=' + id, this.options);
    }
    ReadLayoutByKey(key: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadLayoutByKey?key=' + key, this.options);
    }
    ReadLayouts(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadLayouts', this.options);
    }
    AddOrUpdateLayout(data: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateLayout', data, this.options);
    }
    AddOrUpdateLayoutWithKey(data: string, key: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateLayoutWithKey?key=' + key, data, this.options);
    }
    RemoveLayout(id: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/RemoveLayout?id=' + id, this.options);
    }
    CopyLayout(oldKey: string, newKey: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/CopyLayout?oldKey=' + oldKey + '&newKey=' + newKey, this.options);
    }
    //#endregion
    //#region Template
    ReadTemplateById(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadTemplateById?id=' + id, this.options);
    }
    ReadTemplateByKey(key: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadTemplateByKey?key=' + key, this.options);
    }
    ReadTemplates(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadTemplates', this.options);
    }
    ReadTemplateInfos(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadTemplateInfos', this.options);
    }
    AddOrUpdateTemplate(data: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateTemplate', data, this.options);
    }
    AddOrUpdateTemplateWithKey(data: string, key: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateTemplateWithKey?key=' + key, data, this.options);
    }
    RemoveTemplate(id: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/RemoveTemplate?id=' + id, this.options);
    }
    //#endregion
    //#region Widget
    ReadWidgetById(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadWidgetById?id=' + id, this.options);
    }
    ReadWidgetByKey(key: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadWidgetByKey?key=' + key, this.options);
    }
    ReadWidgets(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadWidgets', this.options);
    }
    ReadWidgetInfos(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadWidgetInfos', this.options);
    }
    AddOrUpdateWidget(data: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateWidget', data, this.options);
    }
    AddOrUpdateWidgetWithKey(data: string, key: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateWidgetWithKey?key=' + key, data, this.options);
    }
    RemoveWidget(id: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/RemoveWidget?id=' + id, this.options);
    }
    //#endregion
    //#region DeploymentAction
    ReadDeploymentActionById(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadDeploymentActionById?id=' + id, this.options);
    }
    ReadDeploymentActions(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/ReadDeploymentActions', filter, this.options);
    }
    ReadDeploymentActionInfos(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadDeploymentActionInfos', this.options);
    }
    AddOrUpdateDeploymentAction(data: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateDeploymentAction', data, this.options);
    }
    RemoveDeploymentAction(id: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/RemoveDeploymentAction?id=' + id, this.options);
    }
    //#endregion
    //#region Ribbon
    ReadRibbonById(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadRibbonById?id=' + id, this.options);
    }
    ReadRibbonByKey(key: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadRibbonByKey?key=' + key, this.options);
    }
    ReadRibbons(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadRibbons', this.options);
    }
    ReadRibbonInfos(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadRibbonInfos', this.options);
    }
    AddOrUpdateRibbon(data: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateRibbon', data, this.options);
    }
    AddOrUpdateRibbonWithKey(data: string, key: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateRibbonWithKey?key=' + key, data, this.options);
    }
    RemoveRibbon(id: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/RemoveRibbon?id=' + id, this.options);
    }
    //#endregion
    //#region ContextMenu
    ReadContextMenuById(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadContextMenuById?id=' + id, this.options);
    }
    ReadContextMenuByKey(key: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadContextMenuByKey?key=' + key, this.options);
    }
    ReadContextMenus(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadContextMenus', this.options);
    }
    ReadContextMenuInfos(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadContextMenuInfos', this.options);
    }
    AddOrUpdateContextMenu(data: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateContextMenu', data, this.options);
    }
    AddOrUpdateContextMenuWithKey(data: string, key: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateContextMenuWithKey?key=' + key, data, this.options);
    }
    RemoveContextMenu(id: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/RemoveContextMenu?id=' + id, this.options);
    }
    //#endregion
    //#region SideNav
    GetAllPages(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.SideNavPath + '/GetAllPages', this.options);
    }
    LoadAllPages(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.SideNavPath + '/LoadAllPages', this.options);
    }
    AddPage(page, key): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.SideNavPath + '/AddPage?key=' + key, page, this.options);
    }
    UpdatePage(page): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.SideNavPath + '/UpdatePage', page, this.options);
    }
    LoadPage(key): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.SideNavPath + '/LoadPage?key=' + key, this.options);
    }
    CanEditPage(key): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.SideNavPath + '/CanEditPage?key=' + key, this.options);
    }
    RequestPageLock(page): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.SideNavPath + '/RequestPageLock', page, this.options);
    }
    SavePageToChangeLog(page): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.SideNavPath + '/SavePageToChangeLog', page, this.options);
    }
    TryDeletePage(id, ignoreVersions): Observable<any> {
        const path = this.API_BASE_URL + this.SideNavPath + '/TryDeletePage?id=' + id + '&ignoreVersions=' + ignoreVersions;
        return this.http.delete<any>(path, this.options);
    }
    ChangePageKey(id, key): Observable<any> {
        const path = this.API_BASE_URL + this.SideNavPath + '/ChangePageKey?id=' + id + '&key=' + key;
        return this.http.get<any>(path, this.options);
    }
    IsValidPageKey(key): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.SideNavPath + '/IsValidPageKey?key=' + key, this.options);
    }
    LoadAllTemplates(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.SideNavPath + '/LoadAllTemplates', this.options);
    }
    LoadTemplateInfo(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.SideNavPath + '/LoadTemplateInfo?id=' + id, this.options);
    }
    LoadTemplate(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.SideNavPath + '/LoadTemplate?id=' + id, this.options);
    }
    RequestTemplateLock(template): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.SideNavPath + '/RequestTemplateLock', template, this.options);
    }
    SaveTemplateToChangeLog(template): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.SideNavPath + '/SaveTemplateToChangeLog', template, this.options);
    }
    TryDeleteTemplate(id, ignoreVersions): Observable<any> {
        const path = this.API_BASE_URL + this.SideNavPath + '/TryDeleteTemplate?id=' + id + '&ignoreVersions=' + ignoreVersions;
        return this.http.delete<any>(path, this.options);
    }
    GetNavigationStructures(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.SideNavPath + '/GetNavigationStructures', this.options);
    }
    LoadNavigationStructures(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.SideNavPath + '/LoadNavigationStructures', this.options);
    }
    GetNavigationStructure(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.SideNavPath + '/GetNavigationStructure?id=' + id, this.options);
    }
    LoadNavigationStructure(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.SideNavPath + '/LoadNavigationStructure?id=' + id, this.options);
    }
    GetNavigationStructureByKey(key): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.SideNavPath + '/GetNavigationStructureByKey?key=' + key, this.options);
    }
    LoadNavigationStructureByKey(key): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.SideNavPath + '/LoadNavigationStructureByKey?key=' + key, this.options);
    }
    LoadNavigationStructurePropertyByKey(key, prop): Observable<any> {
        const path = this.API_BASE_URL + this.SideNavPath + '/LoadNavigationStructurePropertyByKey?key=' + key + '&prop=' + prop;
        return this.http.get<any>(path, this.options);
    }
    GetDefaultNavigationStructure(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.SideNavPath + '/GetDefaultNavigationStructure', this.options);
    }
    RequestNavigationStructureLock(item): Observable<any> {
        const path = this.API_BASE_URL + this.SideNavPath + '/RequestNavigationStructureLock';
        return this.http.post<any>(path, item, this.options);
    }
    SaveNavigationStructureToChangeLog(item): Observable<any> {
        const path = this.API_BASE_URL + this.SideNavPath + '/SaveNavigationStructureToChangeLog';
        return this.http.post<any>(path, item, this.options);
    }
    ChangeNavigationStructureKey(id, key): Observable<any> {
        const path = this.API_BASE_URL + this.SideNavPath + '/ChangeNavigationStructureKey?id=' + id + '&key=' + key;
        return this.http.get<any>(path, this.options);
    }
    AddOrUpdateNavigationStructureWithKey(item, key): Observable<any> {
        const path = this.API_BASE_URL + this.SideNavPath + '/AddOrUpdateNavigationStructureWithKey?key=' + key;
        return this.http.post<any>(path, item, this.options);
    }
    DeleteNavigationStructure(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.SideNavPath + '/DeleteNavigationStructure?id=' + id, this.options);
    }
    TryDeleteNavigationStructure(id, ignoreVersions): Observable<any> {
        const p = this.API_BASE_URL + this.SideNavPath + '/TryDeleteNavigationStructure?id=' + id + '&=ignoreVersions' + ignoreVersions;
        return this.http.delete<any>(p, this.options);
    }

    TryDeleteMyApp(key:string): Observable<any> {
        const p = this.API_BASE_URL + 'api/deleteappmyapp?NavigationKey=' + key;
        return this.http.delete<any>(p, this.options);
    }
    GetNavigationNodesForStructure(key): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.SideNavPath + '/GetNavigationNodesForStructure?key=' + key, this.options);
    }
    SaveNavigationNode(node): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.SideNavPath + '/SaveNavigationNode', node, this.options);
    }
    DeleteNavigationNodes(req): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.SideNavPath + '/DeleteNavigationNodes', req, this.options);
    }
    //#endregion
    //#region Styles
    GetControlStyles(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetControlStyles', this.options);
    }

    GetControlStyle(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetControlStyle?id=' + id, this.options);
    }

    SaveControlStyle(obj): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveControlStyle', obj, this.options);
    }

    DeleteControlStyle(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteControlStyle?id=' + id, this.options);
    }
    //#endregion
    //#region MetaTags
    GetTags(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetTags', this.options);
    }

    GetTag(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetTag?id=' + id, this.options);
    }

    SaveTag(obj): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveTag', obj, this.options);
    }

    DeleteTag(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteTag?id=' + id, this.options);
    }
    //#endregion
    //#region Themes
    GetThemes(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetThemes', filter, this.options);
    }
    GetAllThemes(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAllThemes', this.options);
    }
    GetDefaultTheme(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetDefaultTheme', this.options);
    }
    GetTheme(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetTheme?id=' + id, this.options);
    }

    SaveTheme(obj): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveTheme', obj, this.options);
    }

    DeleteTheme(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteTheme?id=' + id, this.options);
    }
    //#endregion
    //#region CustomCSSVariables
    GetAllCustomCSSVariables(): Observable<CustomCSSVariable[]> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAllCustomCSSVariables', this.options);
    }
    GetCustomCSSVariables(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetCustomCSSVariables', filter, this.options);
    }
    GetCustomCSSVariable(id): Observable<CustomCSSVariable> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetCustomCSSVariable?id=' + id, this.options);
    }

    SaveCustomCSSVariable(obj): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveCustomCSSVariable', obj, this.options);
    }

    DeleteCustomCSSVariable(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteCustomCSSVariable?id=' + id, this.options);
    }
    //#endregion
    //#region CustomCSS
    GetAllCustomCSS(filter): Observable<any[]> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetAllCustomCSS', filter,this.options);
    }
    GetCustomCSS(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetCustomCSS', filter, this.options);
    }
    GetCustomCSSByID(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetCustomCSS?id=' + id, this.options);
    }
    GetCustomCSSByIDs(ids): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetCustomCSSByIDS', ids, this.options);
    }

    SaveCustomCSS(obj): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveCustomCSS', obj, this.options);
    }

    DeleteCustomCSS(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteCustomCSS?id=' + id, this.options);
    }
    //#endregion
    //#region ERD
    ReadERDById(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadERDById?id=' + id, this.options);
    }
    ReadERDByKey(key: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadERDByKey?key=' + key, this.options);
    }
    ReadERDs(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadERDs', this.options);
    }
    ReadERDInfos(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadERDInfos', this.options);
    }
    AddOrUpdateERD(data: any): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateERD', data, this.options);
    }
    AddOrUpdateERDWithKey(data: any, key: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateERDWithKey?key=' + key, data, this.options);
    }
    RemoveERD(id: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/RemoveERD?id=' + id, this.options);
    }
    //#endregion


    //#region Events
    ReadEventInfos(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadEventInfos', this.options);
    }
    ReadEvent(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadEvent?id=' + id, this.options);
    }
    AddOrUpdateEvent(ev): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateEvent', ev, this.options);
    }
    DeleteEvent(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteEvent?id=' + id, this.options);
    }
    //#endregion

    //#region ChartPalette
    ReadPaletteInfos(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadPaletteInfos', this.options);
    }
    ReadPalette(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadPalette?id=' + id, this.options);
    }
    SavePalette(p): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SavePalette', p, this.options);
    }
    DeletePalette(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeletePalette?id=' + id, this.options);
    }
    //#endregion

    ReadChangeDates(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadChangeDates', this.options);
    }
    
    GetTemplateUsage(id: string): Observable<any> {
		return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetTemplateUsage?id=' + id, this.options);
	}

    private zoomSubject = new BehaviorSubject<number>(window.devicePixelRatio);
    zoom$ = this.zoomSubject.asObservable();

    private checkZoom() {
        const currentZoom = window.devicePixelRatio;
        this.ngZone.run(() => {
            this.zoomSubject.next(currentZoom);
        });
    }

    // import application
    Import(formData) {
        return this.http.post(this.API_BASE_URL + 'api/prebuiltapps/import', formData, {headers: new HttpHeaders({})})
    }
}

@Injectable()
export class OutputService extends RequestBase {
    BasePath = 'api/output';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    ReadOutputById(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadOutputById?id=' + id, this.options);
    }

    ReadOutputInfos(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadOutputInfos', this.options);
    }

    AddOrUpdateOutput(data: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateOutput', data, this.options);
    }

    RemoveOutput(id: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/RemoveOutput?id=' + id, this.options);
    }

    GetModelInfos(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetModelInfos', this.options);
    }
}
