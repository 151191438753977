import { NgModule } from '@angular/core';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { DragDropModule } from 'primeng/dragdrop';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SliderModule } from 'primeng/slider';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ListboxModule } from 'primeng/listbox';
import { TabViewModule } from 'primeng/tabview';
import { ColorPickerModule } from 'primeng/colorpicker';
import { TableModule } from 'primeng/table';
import { TreeModule } from 'primeng/tree';
import { MegaMenuModule } from 'primeng/megamenu';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { SplitterModule } from 'primeng/splitter';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SelectButtonModule } from 'primeng/selectbutton';
import { AccordionModule } from 'primeng/accordion';
import { TooltipModule } from 'primeng/tooltip';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { EditorModule } from 'primeng/editor';
import {ImageModule} from "primeng/image";
import {CarouselModule} from "primeng/carousel";
import {ChipModule} from "primeng/chip";
import { DialogModule } from 'primeng/dialog';

@NgModule({
    imports: [
        FileUploadModule,
        DragDropModule,
        SliderModule,
        ChartModule,
        InputTextModule,
        CheckboxModule,
        InputTextareaModule,
        ChipsModule,
        AutoCompleteModule,
        DropdownModule,
        MultiSelectModule,
        ListboxModule,
        TabViewModule,
        ColorPickerModule,
        TableModule,
        TreeModule,
        MegaMenuModule,
        VirtualScrollerModule,
        SplitterModule,
        InputSwitchModule,
        SelectButtonModule,
        KeyFilterModule,
        AccordionModule,
        TooltipModule,
        ToggleButtonModule,
        EditorModule,
        ImageModule,
        FileUploadModule,
        CarouselModule,
        ChipModule,
        DialogModule
    ],
    exports: [
        FileUploadModule,
        DragDropModule,
        ChartModule,
        SliderModule,
        InputTextModule,
        CheckboxModule,
        InputTextareaModule,
        ChipsModule,
        AutoCompleteModule,
        DropdownModule,
        MultiSelectModule,
        ListboxModule,
        TabViewModule,
        ColorPickerModule,
        TableModule,
        TreeModule,
        MegaMenuModule,
        VirtualScrollerModule,
        SplitterModule,
        InputSwitchModule,
        SelectButtonModule,
        KeyFilterModule,
        AccordionModule,
        TooltipModule,
        ToggleButtonModule,
        EditorModule,
        ImageModule,
        FileUploadModule,
        CarouselModule,
        ChipModule,
        DialogModule
    ]
})
export class PrimeNgModule { }
