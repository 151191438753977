import { ChangeDetectorRef, Component, ComponentFactoryResolver } from "@angular/core";
import { TranslateFormatText } from "../../helpers/array.helpers";
import { TranslateHelper } from "../../helpers/injector.helper";
import { SecurityService } from "../../services/security.service";
import { DataCheck } from "../../workflow/workflow.dialog";
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from "../base.list.settings";
import { IdentityMFAMailDialog } from './identity/identity.mfa.mail.dialog';

@Component({
    selector: 'provider-settings',
    templateUrl: '../base.list.settings.html'
})
export class ProviderSettings extends BaseListSettings {

    LastIdentityConfig;

    constructor(protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef, private service: SecurityService) {
        super(factoryResolver, cdRef);
    }

    getContentType() {
        return ProviderDetail;
    }
    
    loadList(handler) {
        this.service.GetSecurityProviderInfos().subscribe((result) => {
            if (result) {
                var list = [];
                result.forEach(function (entry) {
                    if (entry.Id === '9d252462-2180-4339-9354-53bb9a233afc') {
                        list.push({
                            Caption: entry.Name,
                            ID: entry.Id,
                            HideDelete: true,
                            HideEdit:true,
                            CanNotDelete : true,
                            CanNotEdit : true
                        })
                        return
                    }
                    list.push({
                        Caption: entry.Name,
                        ID: entry.Id
                    });
                });
                handler(list);
            }
        });
    }
    protected init(): void {
        this.InitArgs = {ID: 'provider'};
    }
    loadData(data) {
        this.service.GetSecurityProviderConfigByKey(data).subscribe(result => {
            if (result) {
                if (result.Config) {
                    result.Config = JSON.parse(result.Config);
                } else {
                    result.Config = {};
                }
                this.setSelectedItem(result);
            }
        });
    }

    getNewItem() {
        this.Component.valid = false;
        return {
            Caption: TranslateHelper.TranslatorInstance.instant('@@Neuer Provider'),
            Config: {}
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Provider \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Provider \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Provider loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        //const retVal = new TranslateFormatText('@@Provider \'{0}\' erfolgreich gespeichert.');
        //retVal.FormatParams.push(sel.Caption);
        //return retVal;
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Provider \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Provider speichern');
        return retVal;
    }

    delete(data, handler) {
        this.service.DeleteSecurityProviderConfig(data).subscribe((res) => {
            handler(res);
        });
    }

    saveInternal(item, handler) {
        this.service.AddOrUpdateSecurityProviderConfig(item).subscribe((result) => {
            if (result) {
                handler(result, result, item.Caption);
                if (item && item.ID == '9d252462-2180-4339-9354-53bb9a233afc' && item.Config) {
                    if (this.LastIdentityConfig &&
                        (!this.LastIdentityConfig.MFA || !this.LastIdentityConfig.MFA.IsActive) &&
                        (item.Config.MFA && item.Config.MFA.IsActive)) {
                        IdentityMFAMailDialog.ShowDialog(null);
                    }
                    this.LastIdentityConfig = JSON.parse(JSON.stringify(item.Config));
                }
            }
        });
    }

    handleNew(item, result) {
        item.ID = result;
    }

    protected async checkCanSave(): Promise<DataCheck> {
        var retVal = new DataCheck();
        if (this.Component.valid == false) {
            retVal.IsCorrect = false;
            retVal.Error = TranslateHelper.TranslatorInstance.instant('@@Bitte befuellen Sie alle Felder');
        }
        return retVal;
    }

    setSelectedItem(item) {
        super.setSelectedItem(item);
        if (item && item.ID == '9d252462-2180-4339-9354-53bb9a233afc' && item.Config) {
            this.LastIdentityConfig = JSON.parse(JSON.stringify(item.Config));
        } else {
            this.LastIdentityConfig = null;
        }
    }

    static GetSettingsEntry() {
        return {
            Caption: '@@Provider',
            ID: 'provider',
            Icon: 'recent_actors',
            Index: 3,
            Parent: 'userCont',
            Security: {
                Name: 'evidanza.App.Shared.Security.UsermanagementRight',
                Value: 16
            },
            Content: ProviderSettings
        }
    }
}

@Component({
    selector: 'provider-detail',
    templateUrl: './provider.settings.html',
    styleUrls: ['./provider.settings.css']
})
export class ProviderDetail extends BaseListDetail {
    SecurityProviderTypes = [];
    valid = false;

    constructor(private service: SecurityService) {
        super();
    }

    ngOnInit(): void {
        this.service.GetSecurityProviderTypes().subscribe(result => {
            if (result) {
                var list = [];
                result.forEach(function (item) {
                    item.Id = item.Id.toLowerCase();
                    if (item.Id != '9d252462-2180-4339-9354-53bb9a233afc') {
                        list.push(item);
                    }
                });
                this.SecurityProviderTypes = list;
            }
        });
    }
}