import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ArrayHelpers, TranslateFormatText } from '../../helpers/array.helpers';
import { EnumHelper } from '../../helpers/enum.helper';
import { TriggerState } from '../../models/enums/triggerstate.enum';
import { StandardRequestBase } from '../../services/request-base';
import { SchedulerService, TRIGGER_DICT, WORKER_DICT } from '../../services/scheduler.service';
import { DataCheck } from '../../workflow/workflow.dialog';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';
import {NotificationHelper} from "../../helpers/notification.helper";
import {LayoutService} from "../../services/layout.service";

@Component({
    selector: 'scheduler-settings',
    templateUrl: '../base.list.settings.html'
})
export class SchedulerSettings extends BaseListSettings {

    static GetSettingsEntry() {
        return {
            Caption: '@@Scheduler',
            ID: 'scheduler',
            Icon: 'schedule',
            Index: 2,
            Parent: 'data',
            Security: {
                Name: 'evidanza.App.Shared.Security.DataRight',
                Value: 128
            },
            Content: SchedulerSettings
        };
    }

    constructor(protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef,
        private service: SchedulerService, private translate: TranslateService) {
        super(factoryResolver, cdRef);
    }

    getContentType() {
        return SchedulerDetail;
    }

    loadList(handler) {
        this.service.GetActiveTriggerStates().subscribe(result => {
            if (result) {
                const list = [];
                result.forEach(entry => {
                    list.push({
                        Caption: entry.Caption,
                        ID: entry.SID
                    });
                });
                handler(list);
            }
        });
    }

    loadData(data) {
        this.service.LoadTrigger(data).subscribe(result => {
            if (result) {
                this.setSelectedItem(result);
            }
        });
    }

    getNewItem() {
        const retVal = {
            Name: this.translate.instant('@@Neuer Trigger'),
            IsEnabled: true,
            ExecutionType: 1,
            Culture: null
        };
        const actLCID = parseInt(this.translate.currentLang, 10);
        if (!isNaN(actLCID) && this.Component && this.Component.Cultures && this.Component.Cultures.some(lang => lang.LCID === actLCID)) {
            retVal.Culture = actLCID;
        }
        return retVal;
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Trigger \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Trigger \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Trigger loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Trigger \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Name);
        retVal.Title = new TranslateFormatText('@@Trigger speichern');
        return retVal;
    }

    delete(data, handler) {
        this.service.DeleteTrigger(data).subscribe((res) => {
            if (res) {
                handler(res);
            }
        });
    }

    protected async checkCanSave(): Promise<DataCheck> {
        const retVal = new DataCheck();
        const sel = this.Component.SelectedItem;
        if (sel) {
            const errorList = [];
            if (!this.Component.TriggerTypes.some(tt => tt.ID === sel.Type)) {
                errorList.push(this.translate.instant('@@Trigger-Typ auswaehlen'));
            }
            if (!sel.WorkerClassId) {
                errorList.push(this.translate.instant('@@Trigger-Aktion auswaehlen'));
            }
            if (errorList.length > 0) {
                retVal.IsCorrect = false;
                retVal.Error = errorList.join('\n');
            }
        }
        return retVal;
    }

    saveInternal(item, handler) {
        if(item?.Name.trim() == '') {
            NotificationHelper.Error('Name field is required', '@@Error');
            LayoutService.Loading.next(false);
            return;
        }
        const content = Object.assign({}, item);
        if (content.ExecutionContextAsObject) {
            content.ExecutionContext = JSON.stringify(content.ExecutionContextAsObject);
            delete content.ExecutionContextAsObject;
        }
        const serStruct = {
            Type: null,
            Content: JSON.stringify(content)
        };
        this.Component.TriggerTypes.some(tt => {
            if (tt.ID === item.Type) {
                serStruct.Type = tt.ConfigTypeName;
                return true;
            }
            return false;
        });
        this.service.SaveTrigger(serStruct).subscribe((result) => {
            if (result) {
                handler(result, result.SID, result.Caption);
            }
        });
    }

    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
    }
}


@Component({
    selector: 'scheduler-detail',
    templateUrl: './scheduler.settings.html',
    styleUrls: ['./scheduler.settings.css']
})
export class SchedulerDetail extends BaseListDetail implements AfterViewInit {
    TriggerStates;
    TriggerTypes = [];
    WorkerTypes = [];
    Cultures = [];

    @ViewChild('dynamicTrigger', { read: ViewContainerRef }) dynamicTrigger: ViewContainerRef;
    @ViewChild('dynamicWorker', { read: ViewContainerRef }) dynamicWorker: ViewContainerRef;

    TriggerSubScription;
    WorkerSubScription;

    get Name() {
        if (this.SelectedItem) {
            return this.SelectedItem.Name;
        }
        return '';
    }
    set Name(val) {
        if (this.SelectedItem) {
            this.SelectedItem.Name = val;
        }
    }

    get TriggerType() {
        if (this.SelectedItem) {
            return this.SelectedItem.Type;
        }
        return null;
    }
    set TriggerType(val) {
        if (this.SelectedItem) {
            this.SelectedItem.Type = val;
        }
    }

    get WorkerType() {
        if (this.SelectedItem) {
            return this.SelectedItem.WorkerClassId;
        }
        return null;
    }
    set WorkerType(val) {
        if (this.SelectedItem) {
            this.SelectedItem.WorkerClassId = val;
        }
    }

    constructor(private service: SchedulerService, private fR: ComponentFactoryResolver, private standardService: StandardRequestBase) {
        super();
        const list = [];
        EnumHelper.GetDropdownValues(TriggerState).forEach(dd => {
            list.push({
                Caption: dd.value,
                Value: dd.type,
                Selected: true
            });
        });
        this.TriggerStates = list;
    }

    ngOnInit(): void {
        this.service.GetTriggerTypes().subscribe((result) => {
            if (result) {
                result.forEach(k => {
                    k.Selected = true;
                });
                this.TriggerTypes = result;
            }
        });
        this.service.GetWorkerTypes().subscribe((result) => {
            if (result) {
                const ds = [];
                Object.keys(result).forEach(k => {
                    ds.push({
                        Caption: result[k],
                        Value: k
                    });
                });
                ArrayHelpers.sortAlphabetical(ds, 'Caption');
                this.WorkerTypes = ds;
            }
        });
        this.standardService.executeGet('config/api/config', 'GetSelectedLanguages').subscribe(languages => {
            if (languages) {
                this.Cultures = languages;
            }
        });
    }

    ngAfterViewInit(): void {
        this.checkTrigger();
        this.checkWorker();
    }

    triggerChanged() {
        this.checkTrigger();
        this.OnItemChanged();
    }

    OnWorkerChanged() {
        if (this.SelectedItem) {
            this.SelectedItem.ExecutionContext = null;
            this.SelectedItem.ExecutionContextAsObject = null;
        }
        this.checkWorker();
        this.OnItemChanged();
    }

    setSelectedItem(item) {
        super.setSelectedItem(item);
        this.checkTrigger();
        this.checkWorker();
    }

    checkTrigger() {
        if (this.TriggerSubScription) {
            this.TriggerSubScription.unsubscribe();
            this.TriggerSubScription = null;
        }
        if (this.dynamicTrigger) {
            this.dynamicTrigger.clear();
            if (this.SelectedItem) {
                const type = TRIGGER_DICT.get(this.SelectedItem.Type);
                if (type) {
                    const factory = this.fR.resolveComponentFactory(type);
                    const comp = factory.create(this.dynamicTrigger.parentInjector);
                    this.dynamicTrigger.insert(comp.hostView);
                    const component: any = comp.instance;
                    if (component) {
                        component.Trigger = this.SelectedItem;
                        this.TriggerSubScription = component.TriggerHasChanged.subscribe(() => { this.OnItemChanged(); });
                    }
                }
            }
        }
    }

    checkWorker() {
        if (this.WorkerSubScription) {
            this.WorkerSubScription.unsubscribe();
            this.WorkerSubScription = null;
        }
        if (this.dynamicWorker) {
            this.dynamicWorker.clear();
            if (this.SelectedItem) {
                const type = WORKER_DICT.get(this.SelectedItem.WorkerClassId);
                if (type) {
                    const factory = this.fR.resolveComponentFactory(type);
                    const comp = factory.create(this.dynamicWorker.parentInjector);
                    this.dynamicWorker.insert(comp.hostView);
                    const component: any = comp.instance;
                    if (component) {
                        component.Trigger = this.SelectedItem;
                        this.WorkerSubScription = component.TriggerHasChanged.subscribe(() => { this.OnItemChanged(); });
                    }
                }
            }
        }
    }
}

