import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../base.component';

@Component({
    selector: 'evi-iframe',
    templateUrl: './iframe.control.html',
    styleUrls: ['./iframe.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IFrameControl extends IBaseComponent {

    static Type: any = 'iframe';
    static Default = { Type: 'iframe',Layout: {
        _Editable: true,
    } };

    URL;
   
    constructor(private sanitizer: DomSanitizer, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
    }

    ControlInitialized() {
        this.CreateUrl();
    }
    onLayoutElementChanged() {
        this.CreateUrl();
    }
    CreateUrl() {
        if (this.LayoutElementValue.URL) {
            this.URL = this.sanitizer.bypassSecurityTrustResourceUrl(this.LayoutElementValue.URL);
        }
    }
}
export class IFramePanel extends BasePanel {
    static override SIDS = ['54067d9b-233f-46ef-9a42-ad550f446f41']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'54067d9b-233f-46ef-9a42-ad550f446f41',
            ID: 'iframe',
            Caption: '@@IFrame',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'iframe';
            }
        })

        PROPERTIES.push({
            ID: "URL",
            Parent: "iframe",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@URL",
            InitArgs: {
                InputType: "text"
            }
        });
    }
}