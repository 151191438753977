import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { FormulaTextBoxThemeControl } from '../../../appbuilder/controls/formulabox/formula.textbox.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { CacheService } from '../../../cache/cache.service';
import { ACaptionGetter, DefaultCaptionGetter } from '../../../helpers/acaptiongetter';
import { ArrayHelpers } from '../../../helpers/array.helpers';
import { EnumHelper } from '../../../helpers/enum.helper';
import { FilterHelper } from '../../../helpers/filter.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { RTLHelper } from '../../../helpers/rtl.helper';
import { VariablesNodeInformation } from '../../../models/basic/formulaEditor.model';
import { Comparer } from '../../../models/enums/comparer.enum';
import { Order } from '../../../models/enums/order.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ElementProperty } from '../../../models/layoutbase.model';
import { RequestFilter } from '../../../models/rest/requestfilter';
import { RequestOptions } from '../../../models/rest/requestoptions';
import { DataService } from '../../../services/data.service';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LanguageService } from '../../../services/language.service';
import { LayoutService } from '../../../services/layout.service';
import { ComponentFilterControl } from '../../common/componentfilter/componentfilter.control';
import { IBaseComponent } from '../base.component';

@Component({
    selector: 'evi-combobox',
    templateUrl: './combobox.control.html',
    styleUrls: ['./combobox.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComboBoxControl extends IBaseComponent {
    static Type: any = 'combobox';
    static Default = { SelectedItem: null, Type: 'combobox',Layout: {
        _Editable: true,
    } };
    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
    //#region SelectedItem
    SelectedItemValue;

    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        this.SelectedItemChange.emit(this.SelectedItemValue);
        this.cdRef.detectChanges();
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion
    //#region DataSource
    @Input()
    get DataSource() {
        return this.SelectedItemValue;
    }
    set DataSource(val) {
        this.SelectedItemValue = val;
        this.InternalSelection = null;
        this.CheckData();
        this.DataSourceChange.emit(this.SelectedItemValue);
        this.SelectedItemChange.emit(this.SelectedItemValue);
        this.triggerEvent('DataSourceChanged', this.DataSourceValue);
        this.RefreshCount += 1;
        this.cdRef.detectChanges();
    }
    @Output() DataSourceChange = new EventEmitter<any>();
    //#endregion
    //#region ItemsSource
    ItemsSourceValue;
    @Input()
    get ItemsSource() {
        return this.ItemsSourceValue;
    }
    set ItemsSource(val) {
        if (Array.isArray(val)) {
            if (this.LayoutElementValue.DisplayMemberPath) {
                switch (this.LayoutElementValue.Order) {
                    case Order.ASC:
                        ArrayHelpers.sortAlphabetical(val, this.LayoutElementValue.DisplayMemberPath);
                        break;
                    case Order.DESC:
                        ArrayHelpers.sortAlphabetical(val, this.LayoutElementValue.DisplayMemberPath);
                        val.reverse();
                        break;
                }
            } else {
                switch (this.LayoutElementValue.Order) {
                    case Order.ASC:
                        val.sort((a, b) => {
                            const aVal = ('' + a).toLowerCase();
                            const bVal = ('' + b).toLowerCase();
                            if (aVal < bVal) {
                                return -1;
                            }
                            if (aVal > bVal) {
                                return 1;
                            }
                            return 0;
                        });
                        break;
                    case Order.DESC:
                        val.sort((a, b) => {
                            const aVal = ('' + a).toLowerCase();
                            const bVal = ('' + b).toLowerCase();
                            if (aVal > bVal) {
                                return -1;
                            }
                            if (aVal < bVal) {
                                return 1;
                            }
                            return 0;
                        });
                        break;
                }
            }
            this.InternalItemsSource = this.ConvertData(val);
            this.Count = this.InternalItemsSource.length;
        }
        
        this.ItemsSourceValue = val;
        this.CheckData();
        this.ItemsSourceChange.emit(this.ItemsSourceValue);
        this.cdRef.detectChanges();
    }

    @Output() ItemsSourceChange = new EventEmitter<any>();
    //#endregion
    //#region InternalItemsSource
    InternalItemsSourceValue;

    @Input()
    get InternalItemsSource() {
        return this.InternalItemsSourceValue;
    }
    set InternalItemsSource(val) {
        //if (Array.isArray(val) && this.LayoutElementValue.DisplayMemberPath) {
        //    switch (this.LayoutElementValue.Order) {
        //        case Order.ASC:
        //            ArrayHelpers.sortAlphabetical(val, this.LayoutElementValue.DisplayMemberPath);
        //            break;
        //        case Order.DESC:
        //            ArrayHelpers.sortAlphabetical(val, this.LayoutElementValue.DisplayMemberPath);
        //            val.reverse();
        //            break;
        //    }
        //}
        this.InternalItemsSourceValue = val;
        this.CheckData();
        this.InternalItemsSourceChange.emit(this.InternalItemsSourceValue);
        this.cdRef.detectChanges();
    }

    @Output() InternalItemsSourceChange = new EventEmitter<any>();
    //#endregion
    //#region Caption
    CaptionValue;

    @Input()
    get Caption() {
        return this.CaptionValue;
    }
    set Caption(val) {
        this.CaptionValue = val;
        this.CaptionChange.emit(this.CaptionValue);
    }

    @Output() CaptionChange = new EventEmitter<any>();
    //#endregion
    //#region Selection
    _Selection;
    get Selection() {
        return this._Selection;
    }
    set Selection(value) {
        if (this._Selection != value) {
            this._Selection = value;
            if (this.InternalItemsSourceValue) {
                this.InternalItemsSourceValue.forEach((item) => {
                    item.selected = false;
                    item.focus = false;
                });
            }
            if (this.Multiple) {
                if (Array.isArray(value) && value.length == 0) {
                    this._Selection = null;
                    this.SelectedItem = [];
                } else {
                    if (!this.DataSource || !Array.isArray(this.DataSource)) {
                        this.SelectedItem = [];
                    }
                    const list = [];
                    if (this._Selection) {
                        this._Selection.forEach((val) => {
                            val.selected = true;
                            if (!this.ValueMemberPath || this.LayoutElementValue.IsReverse) {
                                list.push(val.data);
                            } else {
                                list.push(val.data[this.ValueMemberPath]);
                            }
                        });
                    }
                    this.SelectedItem = list;
                }
            } else {
                if (typeof (value) == 'object' && value && value.data) {
                    if (!this.ValueMemberPath || this.LayoutElementValue.IsReverse) {
                        this.SelectedItem = value.data;
                    } else {
                        if (typeof (value.data) == 'object') {
                            this.SelectedItem = value.data[this.ValueMemberPath] != null ? value.data[this.ValueMemberPath] : value;
                        } else {
                            this.SelectedItem = value;
                        }
                    }
                } else if (Array.isArray(value)) {

                } else {
                    this.SelectedItem = value;
                }
                if (this._Selection) {
                    this._Selection.selected = true;
                }
            }
            this.DataSourceChange.emit(this.SelectedItemValue);
            this.onSelectionChange(null);
        }
    }
    //#endregion
    //#region DisplayMemberPath
    @Input()
    get DisplayMemberPath() {
        return this.LayoutElementValue.DisplayMemberPath;
    }
    set DisplayMemberPath(val) {
        this.LayoutElementValue.DisplayMemberPath = val;
        this.UpdateCaptionGetter();
        this.DisplayMemberPathChange.emit(this.LayoutElementValue.DisplayMemberPath);
    }

    @Output() DisplayMemberPathChange = new EventEmitter<any>();
    //#endregion
    //#region ValueMemberPath
    @Input()
    get ValueMemberPath() {
        return this.LayoutElementValue.ValueMemberPath;
    }
    set ValueMemberPath(val) {
        this.LayoutElementValue.ValueMemberPath = val;
        this.ValueMemberPathChange.emit(this.LayoutElementValue.ValueMemberPath);
    }

    @Output() ValueMemberPathChange = new EventEmitter<any>();
    //#endregion
    //#region Multiple
    @Input()
    get Multiple() {
        return this.LayoutElementValue.Multiple;
    }
    set Multiple(val) {
        this.LayoutElementValue.Multiple = val;
        this.MultipleChange.emit(this.LayoutElementValue.Multiple);
    }

    @Output() MultipleChange = new EventEmitter<any>();
    //#endregion
    //#region Placeholder
    @Input()
    get Placeholder() {
        return this.LayoutElementValue.Placeholder;
    }
    set Placeholder(val) {
        this.LayoutElementValue.Placeholder = val;
        this.PlaceholderChange.emit(this.LayoutElementValue.Placeholder);
    }

    @Output() PlaceholderChange = new EventEmitter<any>();
    //#endregion
    SearchValue;
    Component;
    _Count = 0;
    get Count() {
        return this._Count;
    }
    set Count(value) {
        this._Count = value;
        if (value > 10) {
            this._Height = 10 * this.LineHeight;
        } else {
            this._Height = value * this.LineHeight;
        }
        if (value == 0) {
            this._Height = this.LineHeight;
        }
        if (this.LayoutElementValue.ShowFilter) {
            this._Height += 60;
        }
    }
    _Height = this.LineHeight;
    get Height() {
        if (this.HorizontalScrollBarVisible) {
            return this._Height + 15;
        } else {
            return this._Height;
        }
    }
    set Height(value) {
        this._Height = value;
    }
    hsbv;
    get HorizontalScrollBarVisible() {
        return this.hsbv;
    }
    set HorizontalScrollBarVisible(value) {
        if (this.hsbv != value) {
            this.hsbv = value;
            this.cdRef.detectChanges();
        }
    }
    ChangeHSBV(value) {
        this.hsbv = value;
    }
    get LineHeight() {
        if (this.LayoutElementValue.LineHeight) {
            return this.LayoutElementValue.LineHeight;
        } else {
            return 40;
        }
    }
    get PageSize() {
        if (this.LayoutElementValue.PageSize) {
            return this.LayoutElementValue.PageSize;
        } else {
            return 20;
        }
    }
    get MaxHeight() {
        if (this.LayoutElementValue.ComboMaxHeight) {
            return this.LayoutElementValue.ComboMaxHeight;
        } else {
            return 400;
        }
    }
    DisableAutoLoad = false;
    DataSourceID;
    FieldList = [];
    CaptionGetter: ACaptionGetter = new DefaultCaptionGetter();
    @Output() SelectionChanged = new EventEmitter<any>();
    SelectedObject;
    SelectionLoaded = false;
    InternalSelection;
    IsRtl = false;
    DelayTrigger = false;
    constructor(private dataService: DataService, private translate: TranslateService,
        cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.Component = this;
        this.EventList.push('selectionchanged');
        this.EventList.push('newentryselected');
        this.EventList.push('OnFocus');
        this.PropertyList.push(new ElementProperty('ItemsSource', 'object', '@@ItemsSource'));
        this.PropertyList.push(new ElementProperty('InternalItemsSource', 'object', '@@InternalItemsSource'));        
    }
    ngOnInit() {
        super.ngOnInit();
        this.IsRtl = RTLHelper.Direction === 'rtl';
        this.Subscriptions['RTL'] = RTLHelper.DirectionChanged.subscribe((dir) => {
            this.IsRtl = dir === 'rtl';
        });
        LanguageService.LanguageChanged.subscribe(() => {
            this.UpdateCaptionGetter();
            this.cdRef.detectChanges();
        })
    }
    ControlInitialized() {
        this.initConfig();
        this.Subscriptions['Values'] = this.LayoutElementValue.ValuesChanged.subscribe((Property) => {
            this.initConfig();
        });
    }
    
    initConfig() {
        this.UpdateCaptionGetter();

        this.DisableAutoLoad = this.LayoutElementValue.DisableAutoLoad;
        if (this.LayoutElementValue.IsEnum) {
            if (this.LayoutElementValue && this.LayoutElementValue.DataSourceID) {
                EnumHelper.GetEnumValues(this.LayoutElementValue.DataSourceID).then((data) => {
                    this.DisplayMemberPath = 'Caption';
                    this.ValueMemberPath = 'Value';
                    this.ItemsSource = data;
                    this.cdRef.detectChanges();
                });
            }
        } else {
            if (this.LayoutElementValue.DataSourceID && this.LayoutElementValue.DataSourceID !== '00000000-0000-0000-0000-000000000000') {
                CacheService.ReadTable(this.LayoutElementValue.DataSourceID).then((result) => {
                    this.DataSourceID = this.LayoutElementValue.DataSourceID;
                    this.FieldList = result['Fields'];
                    this.UpdateCaptionGetter();
                    this.CheckData();
                    this.setCount(true);
                });
            } else {
                MetaHelper.FindTableProperties(this.LayoutValue, this.LayoutElementValue).then(result => {
                    if (result) {
                        if (result.IsEnum) {
                            EnumHelper.GetEnumValues(result.SID).then((data) => {
                                this.DisplayMemberPath = 'Caption';
                                this.ValueMemberPath = 'Value';
                                this.ItemsSource = data;
                                this.cdRef.detectChanges();
                            });
                        } else if (result.Fields) {
                            const field = result.Fields.find(x => x.ID === this.LayoutElementValue.DataSource);
                            if (field && MetaHelper.FieldFitsForElementType(field, this.LayoutElementValue.ElementType)) {
                                this.DataSourceID = this.LayoutElementValue.DataSourceID;
                                this.FieldList = result.Fields;
                                this.UpdateCaptionGetter();
                                this.CheckData();
                                this.setCount(true);
                            }
                        }
                    }
                });
            }
        }
    }

    clicked() {
        this.triggerEvent('OnFocus', this.DataSource);
    }
    Focus = false;
    setFocus() {
        const control = document.getElementById(this.LayoutElementValue.ID);
        if (control) {
            control.focus();
        }
    }

    getDisplayName(data) {
        if (this.CaptionGetter) {
            return this.CaptionGetter.GetCaption(data);
        }
        return '';
    }

    UpdateCaptionGetter() {
        this.CaptionGetter = ACaptionGetter.GetCaptionGetter(this.LayoutElementValue, this.FieldList);
    }

    MouseEnterItem(item, index) {
        if (index != this.FocusedItem) {
            this.InternalItemsSourceValue[this.FocusedItem].focus = false;
            this.FocusedItem = index;
            this.InternalItemsSourceValue[this.FocusedItem].focus = true;
        }
    }

    onFocus() {
        this.Focus = true;
        this.cdRef.detectChanges();
        this.triggerEvent('OnFocus', this.DataSource);
    }
    FocusedItem = 0;
    //#region Selection
    stopPropagation(event) {
        event.stopPropagation();
    }
    onSelectionChange(ev) {
        if (this.SelectedItemValue === '||NewEntry||' || (Array.isArray(this.SelectedItemValue) && this.SelectedItemValue.indexOf('||NewEntry||') > -1)) {
            this.triggerEvent('newentryselected', null);
        }
        this.triggerEvent('selectionchanged', this.SelectedItemValue);
        this.SelectionChanged.emit();
    }

    SelectItem(item, event) {
        if (this.Editable && !this.Disabled) {
            if (this.Multiple) {
                if (this.ValueMemberPath && item.data[this.ValueMemberPath] != null) {
                    if (event && event.stopPropagation) {
                        event.stopPropagation();
                    }
                    let list = [];
                    if (this._Selection) {
                        list = [...this._Selection];
                    }
                    let toFind = list.find((value) => {
                        if (this.ValueMemberPath) {
                            return value.data[this.ValueMemberPath] == item.data[this.ValueMemberPath];
                        }
                        return value == item
                    });
                    let index = list.indexOf(toFind);
                    if (index >= 0) {
                        item.selected = false;
                        list.splice(index, 1);
                    } else {
                        item.selected = true;
                        list.push(item);
                    }
                    this.Selection = list;
                }
            } else {
                if (this.ValueMemberPath && item.data && item.data[this.ValueMemberPath] == null) {
                    this.Selection = null;
                } else {
                    this.Selection = item;
                }
            }
        }
    }
    ClearSelection(event) {
        event.stopPropagation();
        this.Selection = null;
    }

    //#endregion
    //#region Data
    LoadData(start, end) {
        if (this.DataSourceID && !this.DisableAutoLoad) {
            const options = FilterHelper.PrepareFilter(this.LayoutElementValue);
            if (this.LayoutElementValue.DisplayMemberPath) {
                if (typeof this.LayoutElementValue.Order === 'number') {
                    options.Sort = [{
                        Name: this.LayoutElementValue.DisplayMemberPath,
                        Order: this.LayoutElementValue.Order,
                        Position: 0
                    }];
                }
                if (this.SearchValue) {
                    if (!options.Filters) {
                        options.Filters = [];
                    }
                    let filter = new RequestFilter();
                    filter.Name = this.LayoutElementValue.DisplayMemberPath;
                    filter.Operator = Comparer.Like;
                    filter.Value = this.SearchValue;
                    options.Filters.push(filter);
                }
            }
            options.StartRow = start;
            options.EndRow = end;
            if (this.LayoutElementValue.LoadAll) {
                options.StartRow = null;
                options.EndRow = null;
            }


            this.dataService.SearchObjects('dynamicdata', this.DataSourceID, options).subscribe((data) => {
                if (this.LayoutElementValue) {
                    if (this.LayoutElementValue.NewEntry) {
                        if (this.ValueMemberPath) {
                            const item = {};
                            item[this.ValueMemberPath] = '||NewEntry||';
                            item[this.DisplayMemberPath] = this.translate.instant('@@NewEntry');
                            data.unshift(item);
                        }
                    }
                    if (this.LayoutElementValue.EmptyEntry) {
                        if (this.ValueMemberPath) {
                            const item = {};
                            item[this.ValueMemberPath] = null;
                            item[this.DisplayMemberPath] = this.translate.instant('@@None');
                            data.unshift(item);
                        }
                    }
                }
                let list = this.ConvertData(data);
                if (this.LayoutElementValue.LoadAll) {
                    this.InternalItemsSourceValue = list;
                } else {
                    this.InternalItemsSourceValue.splice(start, end - start, ...list);
                }
                if (this.FocusedItem >= start && this.FocusedItem < end) {
                    if (this.InternalItemsSourceValue && this.InternalItemsSourceValue.length > 0) {
                        this.InternalItemsSourceValue[this.FocusedItem].focus = true;
                    }
                }
                this.CheckSelection();
                this.CheckData();
                this.cdRef.detectChanges();                
            });
        }
        if (this.LayoutElementValue.IsEnum) {
            this.CheckSelection();
        }
        this.DisableAutoLoad = false;
    }
    CheckSelection() {
        if (this._Selection && this.ValueMemberPath) {
            this.InternalItemsSourceValue.forEach((item) => {
                item.selected = false;
            });
            if (Array.isArray(this._Selection)) {
                let sels = [];
                this._Selection.forEach((sel) => {
                    let item = this.InternalItemsSourceValue.find((value) => value.data[this.ValueMemberPath] == sel.data[this.ValueMemberPath]);
                    if (item) {
                        item.selected = true;
                        sels.push(item);
                    } else {
                        sels.push(sel);
                    }
                });
                this._Selection = sels;
            } else {
                let item = this.InternalItemsSourceValue.find((value) => value.data[this.ValueMemberPath] == this._Selection.data[this.ValueMemberPath]);
                if (item) {
                    item.selected = true;
                    this._Selection = item;
                }
            }
        }
    }
    ExecuteRefresh(loadData = false) {
        this.ExecuteRefreshInternal(loadData);
    }

    ExecuteRefreshInternal(loadData) {
        return new Promise<void>((resolve) => {
            if (this.DisableAutoLoad) {
                this.DisableAutoLoad = false;
                resolve();
            } else {
                this.setCount(false).then(x => {
                    if (x && (loadData || this.LayoutElementValue.LoadAll)) {
                        this.LoadData(0, this.PageSize);
                    }
                    resolve();
                });
            }
        });
    }

    setCount(checkFilter: boolean) {
        return new Promise<boolean>(resolve => {
            if (this.DataSourceID) {
                const options = FilterHelper.PrepareFilter(this.LayoutElementValue);
                // Eingebaut, falls Filter mit Variable auf Control gesetzt, die im Init noch nicht gef�llt ist
                if (checkFilter && options && options.Filters && options.Filters.length > 0) {
                    this.DelayTrigger = true;
                    resolve(false);
                } else {
                    if (this.LayoutElementValue.DisplayMemberPath) {
                        if (typeof this.LayoutElementValue.Order === 'number') {
                            options.Sort = [{
                                Name: this.LayoutElementValue.DisplayMemberPath,
                                Order: this.LayoutElementValue.Order,
                                Position: 0
                            }];
                        }
                        if (this.SearchValue) {
                            if (!options.Filters) {
                                options.Filters = [];
                            }
                            let filter = new RequestFilter();
                            filter.Name = this.LayoutElementValue.DisplayMemberPath;
                            filter.Operator = Comparer.Like;
                            filter.Value = this.SearchValue;
                            options.Filters.push(filter);
                        }
                    }
                    if (this.LayoutElementValue.LoadAll) {
                        options.StartRow = null;
                        options.EndRow = null;
                    }
                    this.dataService.CountObjects('dynamicdata', this.DataSourceID, options).subscribe((data) => {
                        let count = data;
                        if (this.LayoutElementValue.NewEntry) {
                            count += 1;
                        }
                        if (this.LayoutElementValue.EmptyEntry) {
                            count += 1;
                        }
                        this.Count = count;
                        this.InternalItemsSource = Array.from(Array(count), (v, index) => {
                            return {
                                data: {},
                                selected: false,
                                focus: false
                            }
                        });
                        this.FocusedItem = 0;
                        resolve(true);
                    });
                }
            } else {
                if (Array.isArray(this.ItemsSourceValue) && this.ItemsSourceValue.length > 0 && this.LayoutElementValue.DisplayMemberPath) {
                    if (this.SearchValue) {
                        const toLower = this.SearchValue.toLowerCase();
                        const filtered = this.ItemsSourceValue.filter(x =>
                            ('' + x[this.LayoutElementValue.DisplayMemberPath]).toLowerCase().indexOf(toLower) > -1);
                        this.Count = filtered.length;
                        this.InternalItemsSource = this.ConvertData(filtered);
                    } else if (Array.isArray(this.InternalItemsSourceValue) &&
                        this.ItemsSourceValue.length != this.InternalItemsSourceValue.length) {
                        this.Count = this.ItemsSourceValue.length;
                        this.InternalItemsSource = this.ConvertData(this.ItemsSourceValue);
                    }
                }
                resolve(false);
            }
        });
    }

    lazyLoad(event) {
        this.LoadData(event.first, event.first + event.rows);
    }
    CheckData() {
        if (!this.InternalSelection) {
            if (this.SelectedItemValue != null) {
                if (!this.LayoutElementValue.IsEnum) {
                    if (this.DataSourceID) {
                        let fetchData = [];
                        if (this.DataSource) {
                            if (this.InternalItemsSourceValue && this.InternalItemsSourceValue.length > 0) {
                                this.InternalItemsSourceValue.forEach((item) => {
                                    item.selected = false;
                                });
                                if (this.Multiple && Array.isArray(this.DataSource)) {
                                    const list = [];
                                    this.DataSource.forEach((ds) => {
                                        const found = this.InternalItemsSourceValue.find((value) => {
                                            if (value.data && typeof (value.data) == 'object') {
                                                return (this.ValueMemberPath ? value.data[this.ValueMemberPath] : value.data) == ds;
                                            }
                                        });
                                        if (found) {
                                            found.selected = true;
                                            list.push(found);
                                        } else {
                                            fetchData.push(ds);
                                        }
                                    });
                                    if (list.length > 0) {
                                        this._Selection = list;
                                    }
                                } else {
                                    const found = this.InternalItemsSourceValue.find((value) => {
                                        if (value.data && typeof (value.data) == 'object') {
                                            return (this.ValueMemberPath ? value.data[this.ValueMemberPath] : value.data) == this.DataSource;
                                        }
                                    });
                                    if (found) {
                                        found.selected = true;
                                        this._Selection = found;
                                    } else {
                                        fetchData.push(this.DataSource);
                                    }
                                }
                            } else {
                                fetchData = null;
                            }
                        } else {
                            fetchData = null;
                        }
                        if (this.ValueMemberPath && !this.LayoutElementValue.IsReverse && this.LayoutElementValue && (!fetchData || fetchData.length > 0)) {
                            //#region Create RequestOptions
                            const options = RequestOptions.CleanRequestOptions();
                            options.Filters = [];
                            const filter = new RequestFilter();
                            filter.Name = this.ValueMemberPath;
                            filter.Operator = Comparer.Equal;
                            filter.Value = this.DataSource;
                            let sendRequest = true;
                            if (this.Multiple) {
                                if (this.DataSource && this.DataSource.length > 0) {
                                    filter.Operator = Comparer.In;
                                } else {
                                    sendRequest = false;
                                }
                            }
                            options.Filters.push(filter);
                            //#endregion
                            //#region FetchData
                            if (sendRequest) {
                                this.dataService.SearchObjects('dynamicdata',
                                    this.DataSourceID, options).subscribe((data) => {
                                        this.SelectionLoaded = true;
                                        data = this.ConvertData(data);
                                        this.InternalSelection = data;
                                        if (this.Multiple) {
                                            data.forEach(x => x.selected = true);
                                            this._Selection = data;
                                        } else {
                                            data[0].selected = true;
                                            this._Selection = data[0];
                                        }
                                        this.cdRef.detectChanges();
                                    });
                            }
                            //#endregion
                        }
                    } else {
                        if (this.DataSource) {
                            if (this.InternalItemsSourceValue && this.InternalItemsSourceValue.length > 0) {
                                this.InternalItemsSourceValue.forEach((item) => {
                                    item.selected = false;
                                });
                                if (this.Multiple && Array.isArray(this.DataSource)) {
                                    const list = [];
                                    this.DataSource.forEach((ds) => {
                                        const found = this.InternalItemsSourceValue.find((value) => {
                                            if (value.data && typeof (value.data) == 'object') {
                                                return (this.ValueMemberPath ? value.data[this.ValueMemberPath] : value.data) == ds;
                                            }
                                        });
                                        if (found) {
                                            found.selected = true;
                                            list.push(found);
                                        }
                                    });
                                    if (list.length > 0) {
                                        this._Selection = list;
                                    }
                                } else {
                                    const found = this.InternalItemsSourceValue.find((value) => {
                                        if (value.data && typeof (value.data) == 'object') {
                                            return (this.ValueMemberPath ? value.data[this.ValueMemberPath] : value.data) == this.DataSource;
                                        }
                                    });
                                    if (found) {
                                        found.selected = true;
                                        this._Selection = found;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    this.DisplayMemberPath = 'Caption';
                    this.ValueMemberPath = 'Value';
                    if (this.InternalItemsSourceValue) {
                        if (this.Multiple) {
                            this._Selection = [];
                            if (!Array.isArray(this.DataSource)) {
                                this.InternalItemsSourceValue.forEach((item) => {
                                    if (item.data.Value === this.DataSource) {
                                        item.selected = true;
                                        this._Selection.push(item);
                                    } else {
                                        item.selected = false;
                                    }
                                });
                            } else {
                                this.InternalItemsSourceValue.forEach((item) => {
                                    item.selected = this.DataSource.some((it) => {
                                        if (item.data.Value === it) {
                                            this._Selection.push(item);
                                            return true;
                                        }
                                        return false;
                                    });
                                });
                            }
                        } else {
                            this.InternalItemsSourceValue.forEach((item) => {
                                if (item.data.Value === this.DataSource) {
                                    item.selected = true;
                                    this._Selection = item;
                                } else {
                                    item.selected = false;
                                }
                            });
                        }
                    }
                }
            } else {
                this._Selection = null;
            }
        }
        this.cdRef.detectChanges();
    }
    ConvertData(items) {
        let retVal = [];
        if (items) {
            items.forEach((item) => {
                retVal.push({
                    data: item,
                    selected: false,
                    focus: false
                });
            })
        }
        return retVal;
    }
    //#endregion
    //#region Menu
    onComboClick() {
        if (this.ItemsSourceValue) {
            if (this.menuTrigger) {
                this.menuTrigger.openMenu();
            }
        } else {
            this.ExecuteRefreshInternal(false).then(() => {
                if (this.menuTrigger) {
                    this.menuTrigger.openMenu();
                }
            });
        }
        this.DelayTrigger = false;
    }
    MenuOpen = false;
    menuOpened(event) {
        this.MenuOpen = true;
        if (this.LayoutElementValue.ShowFilter) {
            const textBox = document.getElementById('cbx_filter_' + this.LayoutElementValue.ID);
            if (textBox) {
                textBox.focus();
            }
        }
        if (!this.ItemsSourceValue) {
            this.ExecuteRefresh();
        }
        //this.cdRef.detectChanges();
    }
    menuClosed(event) {
        this.MenuOpen = false;
    }
    //#endregion
    //#region Keyboard
    Input(event) {
        if (this.Editable && !this.Disabled) {
            switch (event.code) {
                case 'ArrowDown':
                    if (this.MenuOpen) {
                        if (this.InternalItemsSourceValue) {
                            this.InternalItemsSourceValue[this.FocusedItem].focus = false;
                            if (this.FocusedItem + 1 < this.InternalItemsSourceValue.length) {
                                this.FocusedItem += 1;
                            }
                            this.InternalItemsSourceValue[this.FocusedItem].focus = true;
                        }
                    } else {
                        if (this.menuTrigger) {
                            this.menuTrigger.openMenu();
                        }
                    }
                    break;
                case 'ArrowUp':
                    if (this.MenuOpen) {
                        if (this.InternalItemsSourceValue) {
                            this.InternalItemsSourceValue[this.FocusedItem].focus = false;
                            if (this.FocusedItem > 0) {
                                this.FocusedItem -= 1;
                            }
                            this.InternalItemsSourceValue[this.FocusedItem].focus = true;
                        }
                    } else {
                        if (this.menuTrigger) {
                            this.menuTrigger.openMenu();
                        }
                    }
                    break;
                case 'Escape':
                    if (this.MenuOpen) {
                        if (this.menuTrigger) {
                            this.menuTrigger.closeMenu();
                        }
                    }
                    break;
                case 'Tab':
                    if (this.menuTrigger) {
                        this.menuTrigger.closeMenu();
                    }
                    break;
                case 'Space':
                case 'Enter':
                    if (this.MenuOpen) {
                        this.SelectItem(this.InternalItemsSourceValue[this.FocusedItem], null);
                        if (this.menuTrigger && !this.Multiple) {
                            this.menuTrigger.closeMenu();
                        }
                    } else {
                        if (this.menuTrigger) {
                            this.menuTrigger.openMenu();
                            event.stopPropagation();
                        }
                    }
                    break;
                case 'ShiftLeft':
                case 'ShiftRight':
                    break;
                default:
                    if (!this.MenuOpen) {
                        if (this.LayoutElementValue.ShowFilter) {
                            if (this.menuTrigger) {
                                event.stopPropagation();
                                this.SearchValue = null;
                                this.menuTrigger.openMenu();
                            }
                        }
                    }
                    break;
            }
        }
    }
    KeyUp(event) {
        if (this.Editable && !this.Disabled) {
            switch (event.code) {
                case 'ArrowDown':
                    if (this.InternalItemsSourceValue) {
                        this.InternalItemsSourceValue[this.FocusedItem].focus = false;
                        if (this.FocusedItem + 1 < this.InternalItemsSourceValue.length) {
                            this.FocusedItem += 1;
                        }
                        this.InternalItemsSourceValue[this.FocusedItem].focus = true;
                    }
                    break;
                case 'ArrowUp':
                    if (this.InternalItemsSourceValue) {
                        this.InternalItemsSourceValue[this.FocusedItem].focus = false;
                        if (this.FocusedItem > 0) {
                            this.FocusedItem -= 1;
                        }
                        this.InternalItemsSourceValue[this.FocusedItem].focus = true;
                    }
                    break;
                case 'Escape':
                    if (this.menuTrigger) {
                        this.menuTrigger.closeMenu();
                        const control = document.getElementById(this.LayoutElementValue.ID);
                        if (control) {
                            control.focus();
                        }
                    }
                    break;
                case 'Tab':
                    if (this.menuTrigger) {
                        this.menuTrigger.closeMenu();
                    }
                    break;
                case 'Space':
                case 'Enter':
                    this.SelectItem(this.InternalItemsSourceValue[this.FocusedItem], null);
                    if (this.menuTrigger && !this.Multiple) {
                        this.menuTrigger.closeMenu();
                        const control = document.getElementById(this.LayoutElementValue.ID);
                        if (control) {
                            control.focus();
                        }
                    }
                    break;
                default:
                    this.ExecuteRefresh(true);
                    break;
            }
        }
    }
    //#endregion
    OpenMenu() {
        if (this.menuTrigger) {
            this.menuTrigger.openMenu();
        }
    }   
}
export class ComboBoxPanel extends BasePanel {
    static override SIDS = ['48a37756-7378-4ca5-bb18-ead9628ab716']
    private static DisplayMemberPathSub: BehaviorSubject<any> = new BehaviorSubject(null);
    private static ValueMemberPathSub: BehaviorSubject<any> = new BehaviorSubject(null);
    private static VariablesSub: BehaviorSubject<any> = new BehaviorSubject(null);
    private static TableProperties;
    static override SetSelectedItem(item) {
        ComboBoxPanel.ReadTableProperties();
        if (item && item.DataBindingChanged) {
            item.DataBindingChanged.subscribe(() => ComboBoxPanel.ReadTableProperties())
        }
    }
    static InitPanel() {
        this.InitSelectedItem()
        PROPERTYGROUPS.push({
            SID:'48a37756-7378-4ca5-bb18-ead9628ab716',
            ID: 'combobox',
            Caption: '@@ComboBox',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'combobox';
            }
        })
        PROPERTIES.push({
            ID: "Placeholder",
            Parent: "combobox",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Placeholder",
            InitArgs: {
                DataType: 'string'
            }
        });
        PROPERTIES.push({
            ID: "DisplayMemberPath",
            Parent: "combobox",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@DisplayMemberPath",
            CheckVisibility: (item) => {
                return ComboBoxPanel.TableProperties && ComboBoxPanel.TableProperties.Fields;
            },
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSourceSub: ComboBoxPanel.DisplayMemberPathSub,
                ValueMemberPath: "Name",
                DisplayMemberPath: "TranslatedCaption"
            }
        });
        PROPERTIES.push({
            ID: "DisplayFormula",
            Parent: "combobox",
            Content: new ComponentPortal(FormulaTextBoxThemeControl),
            Label: "@@Erweiterte Anzeige",
            CheckVisibility: (item) => {
                return ComboBoxPanel.TableProperties && ComboBoxPanel.TableProperties.Fields;
            },
            InitArgs: {
                Placeholder: "@@Erweiterte Anzeige",
                VariablesSub: ComboBoxPanel.VariablesSub
            }
        });
        PROPERTIES.push({
            ID: "DisplayMemberPath",
            Parent: "combobox",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@DisplayMemberPath",
            CheckVisibility: (item) => {
                return !ComboBoxPanel.TableProperties || (ComboBoxPanel.TableProperties && !ComboBoxPanel.TableProperties.Fields);
            }
        });
        PROPERTIES.push({
            ID: "ValueMemberPath",
            Parent: "combobox",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@ValueMemberPath",
            CheckVisibility: (item) => {
                return !ComboBoxPanel.TableProperties || (ComboBoxPanel.TableProperties && !ComboBoxPanel.TableProperties.Fields);
            }
        });
        PROPERTIES.push({
            ID: "ValueMemberPath",
            Parent: "combobox",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@ValueMemberPath",
            CheckVisibility: (item) => {
                return ComboBoxPanel.TableProperties && ComboBoxPanel.TableProperties.Fields;
            },
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSourceSub: ComboBoxPanel.ValueMemberPathSub,
                ValueMemberPath: "Name",
                DisplayMemberPath: "TranslatedCaption"
            }
        });
        PROPERTIES.push({
            ID: "Order",
            Parent: "combobox",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Order",
            InitArgs: {
                Placeholder: "",
                Multiple: false,
                EnumSource: Order
            }
        });
        PROPERTIES.push({
            ID: "LineHeight",
            Parent: "combobox",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@LineHeight",
            InitArgs: {
                InputType: "number"
            }
        });
        PROPERTIES.push({
            ID: "LoadAll",
            Parent: "combobox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@LoadAll"
            }
        });
        PROPERTIES.push({
            ID: "PageSize",
            Parent: "combobox",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@PageSize",
            CheckVisibility: (item) => {
                return !item.LoadAll;
            },
            InitArgs: {
                InputType: "number"
            }
        });
        PROPERTIES.push({
            ID: "ComboMaxHeight",
            Parent: "combobox",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@ComboMaxHeight",
            InitArgs: {
                InputType: "number"
            }
        });
        PROPERTIES.push({
            ID: "Multiple",
            Parent: "combobox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Multiple"
            }
        });
        PROPERTIES.push({
            ID: "UseChips",
            Parent: "combobox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@UseChips"
            }
        });
        PROPERTIES.push({
            ID: "NewEntry",
            Parent: "combobox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@NewEntry"
            }
        });
        PROPERTIES.push({
            ID: "EmptyEntry",
            Parent: "combobox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@EmptyEntry"
            }
        });
        PROPERTIES.push({
            ID: "UseFloatingLabel",
            Parent: "combobox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@UseFloatingLabel"
            }
        });
        PROPERTIES.push({
            ID: "ShowFilter",
            Parent: "combobox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@ShowFilter"
            }
        });
        PROPERTIES.push({
            ID: "ShowClear",
            Parent: "combobox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@ShowClear"
            }
        });
        PROPERTIES.push({
            ID: "Filter",
            Parent: "combobox",
            Content: new ComponentPortal(ComponentFilterControl)
        });
    }
    static ReadTableProperties() {
        if (ComboBoxPanel.SelectedItem) {
            const variables = [];
            ComboBoxPanel.TableProperties = null;
            if (!ComboBoxPanel.SelectedItem.IsEnum) {
                if (ComboBoxPanel.SelectedItem.DataSourceID) {
                    CacheService.ReadTable(ComboBoxPanel.SelectedItem.DataSourceID).then((data) => {
                        if (data) {
                            ComboBoxPanel.TableProperties = data;

                            ComboBoxPanel.TableProperties.Fields.forEach((column) => {
                                const vni = new VariablesNodeInformation();
                                vni.VariableID = column.ID;
                                vni.Name = column.TranslatedCaption;
                                variables.push(vni);
                            });
                            ComboBoxPanel.VariablesSub.next(variables);
                            let fields = JSON.stringify(ComboBoxPanel.TableProperties.Fields)
                            let DisplayMemberFields = JSON.parse(fields);
                            DisplayMemberFields.unshift({ Name: "", TranslatedCaption: "" });
                            ComboBoxPanel.DisplayMemberPathSub.next(DisplayMemberFields);

                            let ValueMemberFields = JSON.parse(fields);

                            ValueMemberFields.unshift({ Name: '_Id', TranslatedCaption: "System.ID" });
                            ValueMemberFields.unshift({ Name: null, TranslatedCaption: "@@None" });
                            ComboBoxPanel.ValueMemberPathSub.next(ValueMemberFields);
                            LayoutService.LayoutChanged.next(null);
                        }
                    });
                }
            }
        }
    }
}