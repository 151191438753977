import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { ChartData } from '../../../helpers/chart.data';
import { FilterHelper } from '../../../helpers/filter.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { ChartSettings } from '../../../models/controls/chart.model';
import { AxisTextPosition } from '../../../models/enums/axistextposition.enum';
import { ElementProperty } from '../../../models/layoutbase.model';
import { RequestFilter } from '../../../models/rest/requestfilter';
import { Color } from '../../../models/style/color.model';
import { DataService } from '../../../services/data.service';
import { LayoutService } from '../../../services/layout.service';
import { SettingsService } from '../../../services/settings.service';
import { IBaseComponent } from '../base.component';

@Component({
    selector: 'evi-charts',
    templateUrl: './charts.control.html',
    styleUrls: ['./charts.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartsControl extends IBaseComponent {
    static Type: any = 'charts';
    static Default = {
        Caption: 'Charts', Type: 'charts', Layout:
            { vAxisTextPosition: AxisTextPosition.out, hAxisTextPosition: AxisTextPosition.out, _Editable: true,
            },
        
    };

    private SourceValue: any[];
    get Source() {
        return this.SourceValue;
    }
    set Source(val) {
        this.FillSource(val);
    }

    ChartData;

    //#region DataSource
    DataSourceValue;
    @Input()
    get DataSource() {
        return this.DataSourceValue;
    }
    set DataSource(val) {
        this.FillSource(val);
        this.DataSourceChange.emit(val);
        this.triggerEvent('DataSourceChanged', this.DataSourceValue);
    }
    @Output() DataSourceChange = new EventEmitter<any>();
    //#endregion
    //#region ChartSettings
    ChartSettingsValue;
    @Input()
    get ChartSettings() {
        if (this.ChartSettingsValue != null) {
            return this.ChartSettingsValue;
        }
        return this.LayoutElement.ChartSettings;
    }
    set ChartSettings(val) {
        this.ChartSettingsValue = val;
        this.SetChartData();
    }
    //#endregion

    DateFields = [];
    CSSStyles;
    GeneralSettings = null;    

    constructor(private dataService: DataService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('SelectionChanged');
        let settings = localStorage.getItem("GeneralSettings");
        if (settings) {
            this.GeneralSettings = JSON.parse(settings);
        }
        SettingsService.GeneralSettings.subscribe((settings) => {
            this.GeneralSettings = settings;
        });
        this.PropertyList.push(new ElementProperty('Source', 'list', '@@Source'));
        this.PropertyList.push(new ElementProperty('ChartSettings', 'object', '@@ChartSettings'));
    }

    ChartPropChanged(prop) {
        this.SetCSSClass();
        this.SetChartData();
    }

    ControlInitialized() {
        this.ExecuteRefresh();
    }
    SetCSSClass() {
        const head = document.getElementsByTagName('head')[0];

        if (this.CSSStyles) {
            this.CSSStyles.forEach((style) => {
                try {
                    head.removeChild(style);
                } catch {}
            })
        }
        this.CSSStyles = [];        
        if (this.LayoutElementValue && this.LayoutElementValue.Font && this.LayoutElementValue.Font.FontColor) {
            const colorText = Color.GetStyleText(this.LayoutElementValue.Font.FontColor);
            if (colorText) {
                let radialstyle = document.createElement('style');
                radialstyle.type = 'text/css';
                radialstyle.appendChild(document.createTextNode('.LE_' + this.LayoutElementValue.ID + ' > *, .text-stroke { fill: ' + colorText+'!important; }'));
                this.CSSStyles.push(radialstyle);
                head.appendChild(radialstyle);

                let angularstyle = document.createElement('style');
                angularstyle.type = 'text/css';
                angularstyle.appendChild(document.createTextNode('.LE_' + this.LayoutElementValue.ID + ' > *, .text-stroke { fill: ' + colorText+'!important; }'));
                this.CSSStyles.push(angularstyle);
                head.appendChild(angularstyle);
            } 
        }

        this.cdRef.markForCheck();
    }
    ExecuteRefresh() {
        this.FillSource(null);
        this.SetCSSClass();
        MetaHelper.FindTableProperties(this.Layout, this.LayoutElementValue).then((result) => {
            if (result) {
                const dse = MetaHelper.FindValidParent(this.Layout, this.LayoutElementValue);
                const filter = FilterHelper.PrepareFilter(dse);
                if (this.Variables && this.Layout.Filter) {
                    this.Layout.Filter.forEach((fil) => {
                        if (this.Variables[fil.Filter]) {
                            const item = new RequestFilter();
                            item.Name = fil.Name;
                            item.Operator = fil.Comparer;
                            item.Value = this.Variables[fil.Filter];
                            FilterHelper.AddSearchFilter(filter, item);
                        }
                    });
                }
                this.DateFields = [];
                if (result.Fields) {
                    result.Fields.forEach(x => {
                        if (x.DataTyp === 'System.DateTime') {
                            this.DateFields.push(x.Name);
                        }
                    });
                }
                this.dataService.SearchObjects('dynamicdata', result.SID, filter).subscribe((data) => {
                    if (data) {
                        this.FillSource(data);
                        this.cdRef.detectChanges();
                    }
                });
            } else if (this.DataSourceValue) {
                this.FillSource(this.DataSourceValue);
            }
        });
    }
    SelectionChanged(event) {
        this.triggerEvent('SelectionChanged', event);
    }

    FillSource(source) {
        let data;
        if (source && source.length > 0) {
            data = [];
            source.forEach(x => {
                const dataVal = Object.assign({}, x);
                this.DateFields.forEach(y => {
                    const val = dataVal[y];
                    if (typeof val !== 'undefined' && val !== null && !(val instanceof Date)) {
                        dataVal[y] = new Date(val);
                    }
                });
                data.push(dataVal);
            });
        }
        this.SourceValue = data;
        this.SetChartData();
    }

    SetChartData() {
        let chartData;
        const source = this.SourceValue;
        if (source && source.length > 0) {
            const settings = this.ChartSettings;
            if (settings) {
                const json = serialize(settings);
                const copy = deserialize(ChartSettings, json);
                if (this.LayoutElementValue && this.LayoutElementValue.UsePaletteForSingleSeries &&
                    copy.Series && copy.Series.length == 1) {
                    ChartData.GetPalette(copy).then(x => {
                        if (x && x.length > 0) {
                            const styleKey = '_PalleteStyle_';
                            copy.Series[0]['StyleKey'] = styleKey;
                            source.forEach((row, i) => {
                                row[styleKey] = '' + (i % x.length);
                            });
                            const styles = {};
                            x.forEach((y, i) => {
                                styles['' + i] = y;
                            });
                            this.ChartData = {
                                Data: {
                                    Data: source,
                                    Styles: styles
                                },
                                Settings: copy
                            };
                            this.cdRef.detectChanges();
                        } else {
                            this.ChartData = {
                                Data: source,
                                Settings: copy
                            };
                            this.cdRef.detectChanges();
                        }
                    });
                    return;
                }
                chartData = {
                    Data: source,
                    Settings: copy
                };
            }
        }
        this.ChartData = chartData;
        this.cdRef.detectChanges();
    }
}
