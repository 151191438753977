import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectorRef, Component, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { CapsuleHelper } from '../../../helpers/capsule.helper';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { LayoutBase } from '../../../models/layoutbase.model';
import { TranslatedString } from '../../../models/translatedstring.model';
import { PROPERTYGROUPS, REGISTRY } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { MetaService } from '../../../services/meta.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import { SliderOrientation } from 'src/app/models/enums/slider.orientation.enum';
import {NotificationHelper} from "../../../helpers/notification.helper";

@Component({
    selector: 'control-styles-settings',
    templateUrl: '../../base.list.settings.html'
})
export class ControlStylesSettings extends BaseListSettings implements OnDestroy {

    static PropertyList = ['Font', 'BackgroundColor', 'HorizontalContentAlignment', 'VerticalContentAlignment','HorizontalTextAlignment','VerticalTextAlignment',
        'Border', 'Width', 'Height', 'ZIndex', 'Margin', 'Padding', 'ElementType', 'Editable', 'UseDecorationsOnLayout'];

    static GetSettingsEntry() {
        return {
            Caption: '@@Control Styles',
            ID: 'controlStyles',
            Icon: 'format_color_fill',
            Index: 6,
            Parent: 'ci',
            Security: {
                Name: 'evidanza.App.Shared.Security.CorporateIdentityRight',
                Value: 32
            },
            Content: ControlStylesSettings
        };
    }

    constructor(protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef, private service: MetaService) {
        super(factoryResolver, cdRef);
    }
    ngOnDestroy(): void {
        if (this.Subscription) {
            this.Subscription.unsubscribe();
        }
        LayoutService.SelectedObject.next(null);
    }
    getContentType() {
        return ControlStylesDetail;
    }

    loadList(handler) {
        LayoutService.SelectedObject.next(null);
        this.service.GetControlStyles().subscribe(result => {
            if (result) {
                const list = [];
                result.forEach((entry) => {
                    list.push({
                        Caption: entry.Caption,
                        ID: entry.SID,
                        IsCapsule: entry.IsCapsule,
                        IsOverridden: entry.IsOverridden
                    });
                });
                handler(list);
            }
        });
    }

    loadData(data) {
        this.service.GetControlStyle(data).subscribe(result => {
            if (result) {
                if (result.StyleJSON) {
                    result.Layout = plainToClass(LayoutBase, JSON.parse(result.StyleJSON));
                } else {
                    result.Layout = new LayoutBase();
                }
                if (result.Layout.Editable !== true) {
                    result.Layout.Editable = true;
                }
                if (typeof result.Layout.ElementType !== 'string') {
                    result.Layout.ElementType = 'textarea';
                }
                this.setSelectedItem(result);
                LayoutService.SelectedObject.next(result.Layout);
            }
        });
    }

    getNewItem() {
        const retVal = {
            Caption: new TranslatedString(TranslateHelper.TranslatorInstance.instant('@@Neuer Style')),
            Layout: new LayoutBase(),
            IsNew: true
        };
        retVal.Layout['ElementType'] = 'textarea';
        retVal.Layout.Editable = true;
        LayoutService.SelectedObject.next(retVal.Layout);
        return retVal;
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Style \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Style \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Style loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Style \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Style speichern');
        return retVal;
    }

    delete(data, handler) {
        this.service.DeleteControlStyle(data).subscribe((res) => {
            handler(res);
        });
    }

    saveInternal(item, handler) {
        if (item?.Caption?.DefaultValue.trim() == '') {
            NotificationHelper.Error('Name field is required', '@@Error');
            LayoutService.Loading.next(false);
            return;
        }
        const content = Object.assign({}, item);
        if (content.Layout) {
            const style = {};
            ControlStylesSettings.PropertyList.forEach(p => {
                style[p] = content.Layout[p];
            });
            content.StyleJSON = JSON.stringify(style);
            delete content.Layout;
        }
        this.service.SaveControlStyle(content).subscribe(result => {
            if (result) {
                handler(result, result.SID, result.Caption);
            }
        });
    }

    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'control-styles-detail',
    templateUrl: './control.styles.settings.html',
    styleUrls: ['./control.styles.settings.css']
})
export class ControlStylesDetail extends BaseListDetail {
    ControlList = [];
    ControlVisible = true;
    IsCapsuleInstance = false;
    Source;
    SelectedPanel;
    Content;
    Advanced;
    Decorations;
    DataSource: string | any[];
    constructor(private translateService: TranslateService) {
        super()
    }
    ngOnInit(): void {
        this.DataSource = this.translateService.instant('@@Dies ist eine Vorschau')
        this.Content = this.ConvertToPG(PROPERTYGROUPS.find((value) => {
            return value.SID == 'dbefb974-2c57-44e2-a3b6-dcf4fe96c4a3';
        }))
        this.Advanced = this.ConvertToPG(PROPERTYGROUPS.find((value) => {
            return value.SID == '1FE28779-54CC-4478-9A19-10BD93C59262';
        }))
        this.Decorations = this.ConvertToPG(PROPERTYGROUPS.find((value) => {
            return value.SID == '005cecb9-e72c-48ee-af12-6a2602a79dcf';
        }))
        const list = [];
        Object.keys(REGISTRY).forEach(key => {
            const v = REGISTRY[key];
            const hideControls = ['@@Scheduler','@@Tree','@@ListDetail','@@Content','@@Marker','@@GoogleMap','@@Usermenu','@@Accordion','@@Stepper','@@Tab','@@IFrame','@@Embed','@@Carousel','@@Video','@@Charts','@@LoadingIndicator','@@HyperPay','@@Capsule Tag Control','@@Summe', '@@Reporting','@@DataTable','@@Dateiviewer']
            if (v['type'] === 'Control' && v['page'] !== 'ribbon'&& !hideControls.includes(v['Label'])) {
                list.push({
                    Caption: v['Label'],
                    Type: v['Control'].Default.Type
                });
            }
        });
        this.ControlList = list;
        CapsuleHelper.GetCapsuleTags().then(r => {
            if (r) {
                this.IsCapsuleInstance = true;
            }
        });
    }
    ConvertToPG(x) {
        if (x) {
            const item: any = {
                SID: x.SID ? x.SID : UUID.UUID(),
                ID: x.ID,
                Caption: x.Caption,
                Visible: false,
                PropertyGroup: x,
                Index: x.Index,
                Badge: x.Badge,
                BadgeSub: x.BadgeSub
            };
            if (x.Content) {
                item.Content = new ComponentPortal(x.Content);
                item.Visible = true;
            }
            if (typeof x.CheckOnChange === 'function') {
                item.CheckOnChange = x.CheckOnChange;
            }
            return item
        }
        return null;
    }
    onTypeChange() {
        this.ControlVisible = false;
        if (this.SelectedItem.Layout.ElementType === 'translationtextbox') {
            this.SelectedItem.Layout.Placeholder = '@@Dies ist eine Vorschau';
        }
        if (this.SelectedItem.Layout.ElementType === 'button') {
            this.SelectedItem.Layout.ButtonType = 'mat-raised-button';
            this.SelectedItem.Layout.Caption = '@@Dies ist eine Vorschau';
        }
        if (this.SelectedItem.Layout.ElementType === 'togglebutton') {
            this.SelectedItem.Layout.Caption = '@@Dies ist eine Vorschau';
        }
        if (this.SelectedItem.Layout.ElementType === 'checkbox') {
            this.SelectedItem.Layout.Caption = '@@Dies ist eine Vorschau';
        }
        if(this.SelectedItem.Layout.ElementType === 'textblock'){
            this.SelectedItem.Layout.Caption = '@@Dies ist eine Vorschau';
        }
        if (this.SelectedItem.Layout.ElementType === 'radiobutton') {
            this.SelectedItem.Layout.CustomValues = [{Caption:'@@preview radiobutton',Value:true}];
        }
        if (this.SelectedItem.Layout.ElementType === 'slider') {
            this.SelectedItem.Layout.Orientation = SliderOrientation.Horizontal
            this.SelectedItem.Layout.Minimum = 0
            this.SelectedItem.Layout.Maximum = 150
            this.SelectedItem.Layout.Step = 1
        }
        if (this.SelectedItem.Layout.ElementType === 'chiplist') {
            this.SelectedItem.Layout.ControlStyling = true;
            this.SelectedItem.Layout.Removable = true;
            this.DataSource = ['option1', 'option2', 'option3']
        }
        if (this.SelectedItem.Layout.ElementType === 'autocomplete') {
            this.SelectedItem.Layout.ControlStyling = true;
            this.SelectedItem.Layout.Source = ['option1', 'option2', 'option3']
        }
        if (this.SelectedItem.Layout.ElementType === 'upload') {
            this.SelectedItem.Layout.ButtonType = 'mat-raised-button';
            this.SelectedItem.Layout.Caption = '@@Dies ist eine Vorschau'
        }
        if (this.SelectedItem.Layout.ElementType === "image") {
            this.SelectedItem.Layout.ControlStyling = true;
            this.SelectedItem.Layout.ControlTestingURL = 'assets/img/no_image.png'
        }
        if (this.SelectedItem.Layout.ElementType === "profilemanagement") {
            this.SelectedItem.Layout.ControlStyling = true;
            this.SelectedItem.Layout.Profiles = [
                {"Name": "profile1"},
                {"Name": "profile2"},
                {"Name": "profile3"}
            ]
        }
        if (this.SelectedItem.Layout.ElementType === 'listbox') {
            this.SelectedItem.Layout.ControlStyling = true;
            this.SelectedItem.Layout.Multiple = true;
            this.SelectedItem.Layout.Checkbox = true;
            this.SelectedItem.Layout.DisableAutoLoad = true;
            this.SelectedItem.Layout.ShowFilter = true;
            this.SelectedItem.Layout.Grow = true;
            this.SelectedItem.Layout.actualDataSource = [
							{ Caption: 'Option 1',Value: '1' },
							{ Caption: 'Option 2',Value: '2'},
							{ Caption: 'Option 3',Value: '3', },
							{ Caption: 'Option 4',Value: '4' },
							{ Caption: 'Option 5',Value: '5' },
						];
        }
        if(this.SelectedItem.Layout.ElementType === "icon"){
            this.SelectedItem.Layout.ControlStyling = true;
        }
        window.setTimeout(() => {
            this.ControlVisible = true;
            this.cdRef.detectChanges();
        }, 200);
    }

    setSelectedItem(item) {
        super.setSelectedItem(item);
        if (item && item.Layout && !item.isNew) {
            this.onTypeChange();
        }
        if (item && item.IsNew === true) {
            this.onTypeChange();
        }
    }
}
