import { HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { DialogHelper, TranslateHelper } from '../../../helpers/injector.helper';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { MediaService } from '../../../services/media.service';
import { MessageBox, MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import {NotificationHelper} from "../../../helpers/notification.helper";

@Component({
    selector: 'icon-settings',
    templateUrl: './icon.settings.html',
    styleUrls: ['./icon.settings.css']
})
export class IconSettings implements OnInit {
    Icons = [];
    SelectedIcon;
    HasChanges = false;
    @ViewChild('fileInput') fileInput;
    Uploading = false;
    UploadProgress = 0;
    Uploadindeterminate = false;

    FilteredIcons = [];
    _SearchValue;
    get SearchValue() {
        return this._SearchValue;
    }
    set SearchValue(val) {
        this._SearchValue = val;
        this.FilterIcons();
    }

    static GetSettingsEntry() {
        return {
            Caption: '@@Icons',
            ID: 'icons',
            Icon: 'sentiment_satisfied',
            Index: 2,
            Parent: 'ci',
            Security: {
                Name: 'evidanza.App.Shared.Security.CorporateIdentityRight',
                Value: 4
            },
            Content: IconSettings
        };
    }

    constructor(private service: MediaService, private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    async refresh() {
        const check = await this.checkHasChanges();
        if (check) {
            this.SelectedIcon = null;
            this.FilteredIcons = [];
            this.Icons = [];
            this.service.ListObjects(MediaService.IconSourceID, '').subscribe(icons => {
                if (icons) {
                    const list = [];
                    icons.forEach(icon => {
                        list.push({
                            Name: icon.Name.replace('.svg', '')
                        });
                    });
                    this.Icons = list;
                    this.FilterIcons();
                }
                this.cdRef.detectChanges();
            });
        }
    }

    setHasChanges() {
        this.HasChanges = true;
    }

    Upload(ev) {
        const icon = this.SelectedIcon;
        if (icon && ev && ev.target) {
            const selectedFile = ev.target.files[0];
            if (selectedFile) {
                icon.ChangedFile = selectedFile;
                if (!icon.Name) {
                    icon.Name = selectedFile.name.replace('.svg', '');
                }
                const fr = new FileReader();
                fr.addEventListener('load', function () {
                    icon.Preview = 'url(' + fr.result + ')';
                }, false);
                fr.readAsDataURL(selectedFile);
                this.HasChanges = true;
            }
        }
    }

    async checkHasChanges() {
        if (this.HasChanges) {
            const retVal = await MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Wollen Sie die Aenderungen speichern?'),
                new TranslateFormatText('@@Frage'), MessageBoxButtons.YesNoAbort, MessageBoxIcon.Question);
            if (retVal === MessageBoxResult.Yes) {
                return this.save();
            } else if (retVal === MessageBoxResult.Abort) {
                return false;
            } else { // No
                if (this.SelectedIcon && !this.SelectedIcon.IsNew) {
                    delete this.SelectedIcon.ChangedFile;
                    delete this.SelectedIcon.Preview;
                }
                this.HasChanges = false;
            }
        }
        return true;
    }



    save(): boolean {
        const icon = this.SelectedIcon;

        if(this.checkIconName(icon.Name)) {
            NotificationHelper.Error('@@Icon name cannot start with _SYSTEM', '@@Error');
            return ;
        }

        if (icon) {
            const errorList = [];
            if (typeof icon.Name !== 'string' || icon.Name === '') {
                errorList.push(TranslateHelper.TranslatorInstance.instant('@@Give it a name'));
            } else if (this.Icons.some(i => i === icon.Name )) {
                errorList.push(TranslateHelper.TranslatorInstance.instant('@@Name already exists'));
            }
            if (!icon.ChangedFile) {
                errorList.push(TranslateHelper.TranslatorInstance.instant('@@Choose a file'));
            }
            if (errorList.length > 0) {
                const textVal = '@@Changes could not be saved. Please check the following settings:\n{0}';
                const text = new TranslateFormatText(textVal);
                text.FormatParams.push(errorList.join('\n'));
                MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Warning'), MessageBoxButtons.Ok, MessageBoxIcon.Warning);
                return false;
            }
            const uploadData = new FormData();
            uploadData.append(icon.Name + '.svg', icon.ChangedFile, icon.ChangedFile.name);
            this.service.UploadSpecific(MediaService.IconSourceID, uploadData).subscribe(event => {
                uploadData.forEach((value) => {
                    switch (event.type) {
                        case HttpEventType.Sent:
                            this.Uploading = true;
                            break;
                        case HttpEventType.UploadProgress:
                            this.UploadProgress = Math.round(100 * event.loaded / event.total);
                            if (this.UploadProgress === 100) {
                                this.Uploadindeterminate = true;
                                this.Uploading = false;
                                this.UploadProgress = 0;
                            }
                            break;
                        case HttpEventType.Response:
                            this.Uploadindeterminate = false;
                            delete icon.ChangedFile;
                            delete icon.Preview;
                            if (icon.IsNew) {
                                delete icon.IsNew;
                                this.Icons.push(icon);
                            }
                            const url = MediaService.IconURL.getValue();
                            const sani = this.sanitizer.bypassSecurityTrustResourceUrl(url + icon.Name + '.svg');
                            this.iconRegistry.addSvgIcon(icon.Name, sani);
                            break;
                    }
                });
            });
            this.HasChanges = false;
        }
        return true;
    }

    // icon name can't start with key '_SYSTEM'
    // '_SYSTEM' keyword reserved for system icons
    checkIconName(iconName){
        return iconName.startsWith('_SYSTEM');
    }
    addItem() {
        if (this.HasChanges) {
            const config = {
                data: {
                    Data: {
                        Message: new TranslateFormatText('@@Do you want to save the changes?'),
                        Title: new TranslateFormatText('@@Frage'),
                        Buttons: MessageBoxButtons.YesNoAbort,
                        Icon: MessageBoxIcon.Question
                    }
                }
            };
            const dialogRef = DialogHelper.DialogInstance.open(MessageBox, config);
            dialogRef.afterClosed().subscribe(retVal => {
                if (retVal === MessageBoxResult.Yes) {
                    this.save();
                    this.OnAddNew();
                } else if (retVal === MessageBoxResult.Abort) {
                    return false;
                } else { // No
                    if (this.SelectedIcon && !this.SelectedIcon.IsNew) {
                        delete this.SelectedIcon.ChangedFile;
                        delete this.SelectedIcon.Preview;
                    }
                    this.HasChanges = false;
                    this.OnAddNew();
                }
            });
        } else {
            this.OnAddNew();
        }
    }

    OnAddNew() {
        if (this.SelectedIcon) {
            this.SelectedIcon.Selected = false;
        }
        this.SelectedIcon = {
            Name: null,
            IsNew: true,
            Selected: true
        };
        this.HasChanges = true;
        if (this.fileInput) {
            this.fileInput.nativeElement.click();
        }
    }

    async deleteItem() {
        const sel = this.SelectedIcon;
        if (sel) {
            const text = new TranslateFormatText('@@Are you sure you want to delete the icon \'{0}\'?');
            text.FormatParams.push(sel.Name);
            const retVal = await MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Delete'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question);
            if (retVal === MessageBoxResult.Yes) {
                this.service.DeleteFile(MediaService.IconSourceID, sel.Name + '.svg').subscribe(result => {
                    if (result) {
                        this.SelectedIcon = null;
                        this.Icons.splice(this.Icons.indexOf(sel), 1);
                        this.FilterIcons();
                        this.HasChanges = false;
                    }
                });
            }
        }
    }

    getImage() {
        const retVal = {};
        if (this.SelectedIcon && this.SelectedIcon.Preview) {
            retVal['background-image'] = this.SelectedIcon.Preview;
        }
        return retVal;
    }

    async setSelected(icon) {
        const check = await this.checkHasChanges();
        if (check) {
            this.Icons.forEach(i => { i.Selected = false; });
            icon.Selected = true;
            this.SelectedIcon = icon;
        }
    }

    FilterIcons() {
        if (this.SelectedIcon) {
            this.SelectedIcon.Selected = false;
            this.SelectedIcon = null;
        }
        let val = this._SearchValue;
        if (val) {
            val = val.toLowerCase();
            this.FilteredIcons = this.Icons.filter(icon => {
                return icon.Name.toLowerCase().indexOf(val) >= 0;
            });
        } else {
            this.FilteredIcons = this.Icons;
        }
    }
}
