import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { FormulaTextBoxThemeControl } from '../../../appbuilder/controls/formulabox/formula.textbox.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { ACaptionGetter, DefaultCaptionGetter } from '../../../helpers/acaptiongetter';
import { FilterHelper } from '../../../helpers/filter.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { RTLHelper } from '../../../helpers/rtl.helper';
import { VariablesNodeInformation } from '../../../models/basic/formulaEditor.model';
import { Comparer } from '../../../models/enums/comparer.enum';
import { Order } from '../../../models/enums/order.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { RequestFilter } from '../../../models/rest/requestfilter';
import { RequestSort } from '../../../models/rest/requestsort';
import { DataService } from '../../../services/data.service';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { ComponentFilterControl } from '../../common/componentfilter/componentfilter.control';
import { IBaseComponent } from '../base.component';

@Component({
    selector: 'evi-listbox-component',
    templateUrl: './listbox.control.html',
    styleUrls: ['./listbox.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListBoxControl extends IBaseComponent {
    static Type: any = 'listbox';
    static Default = { SelectedItem: null, Type: 'listbox', Layout: {
        _Editable: true,
    } };

    listDataSource: IListBoxDataSource;
    actualDataSource = [];
    dataSourceSub;
    DisableAutoLoad = false;

    get Order() {
        return this.LayoutElementValue.Order;
    }
    //#region SelectedItem
    SelectedItemValue;

    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        const obsCount = this.BeforeSelectionChange.observers.length;
        if (obsCount > 0) {
            const that = this;
            const args = new BeforeSelectionArgs(obsCount, () => {
                that.SelectedItemValue = val;
            });
            this.BeforeSelectionChange.emit(args);
        } else {
            this.SelectedItemValue = val;
        }
        this.triggerEvent('selectionchanged', this.SelectedItemValue);
        this.SelectedItemChange.emit(this.SelectedItemValue);
        if (this.LayoutElementValue.UseAsSelection) {
            if (this.LayoutElementValue.IsShared && !this.LayoutElementValue.IsReverse) {
                if (this.LayoutElementValue.IsShared && !this.LayoutElementValue.IsReverse) {
                    const list = [];
                    this.SelectedItemValue.forEach((item) => {
                        list.push(item._Id);
                    });
                    this.DataSourceValue = list;
                    this.DataSourceChange.emit(list);
                }
            } else {
                this.DataSourceChange.emit(this.SelectedItemValue);
            }
        }
        this.ItemClicked.emit(this.SelectedItemValue);
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion
    //#region DataSource
    @Input()
    get DataSource() {
        return this.DataSourceValue;
    }
    set DataSource(val) {
        if (this.LayoutElementValue.UseAsSelection) {
            if (this.LayoutElementValue.IsShared && !this.LayoutElementValue.IsReverse) {
                const list = [];
                if (val) {
                    val.forEach((item) => {
                        list.push({ _Id: item });
                    });
                }
                this.SelectedItemValue = list;
            } else {
                this.SelectedItemValue = val;
            }
            this.SelectedItemChange.emit(this.SelectedItemValue);
            this.cdRef.detectChanges();
        }
        this.DataSourceValue = val;
        this.triggerEvent('DataSourceChanged', this.DataSourceValue);
        this.DataSourceChange.emit(this.DataSourceValue);
    }

    @Output() DataSourceChange = new EventEmitter<any>();
    //#endregion
    //#region ItemsSource
    @Input()
    get ItemsSource() {
        return this.DataSource;
    }
    set ItemsSource(val) {
        this.DataSource = val;
        this.ItemsSourceChange.emit(val);
    }

    @Output() ItemsSourceChange = new EventEmitter<any>();
    //#endregion

    //#region DisplayMemberPath
    @Input()
    get DisplayMemberPath() {
        return this.LayoutElementValue.DisplayMemberPath;
    }
    set DisplayMemberPath(val) {
        this.LayoutElementValue.DisplayMemberPath = val;
        this.DisplayMemberPathChange.emit(this.LayoutElementValue.DisplayMemberPath);
    }

    @Output() DisplayMemberPathChange = new EventEmitter<any>();
    //#endregion
    SearchValue;

    @Output() BeforeSelectionChange = new EventEmitter<any>();
    @Output() ItemClicked = new EventEmitter<any>();
    IsRtl = false;
    constructor(private dataService: DataService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.listDataSource = new SimpleDataSource(this, { Fields: [] });
        this.EventList.push('selectionchanged');
        this.EventList.push('OnFocus');
    }

    ngOnInit() {
        super.ngOnInit();
        this.Subscriptions['Values'] = this.LayoutElementValue.ValuesChanged.subscribe((Property) => {
            this.SetMaxHeight();
            this.ExecuteRefresh();
        });
        this.IsRtl = RTLHelper.Direction === 'rtl';
        this.Subscriptions['RTL'] = RTLHelper.DirectionChanged.subscribe((dir) => {
            this.IsRtl = dir === 'rtl';
            this.ExecuteRefresh();
        });        
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.listDataSource) {
            this.listDataSource.unsubscribe();
        }
    }
    MaxHeight;
    SetMaxHeight() {
        let maxHeight;
        if (this.LayoutElementValue.MaxHeight) {
            if (typeof this.LayoutElementValue.MaxHeight.Value === 'number') {
                maxHeight = this.LayoutElementValue.MaxHeight.Value;
            } else if (this.LayoutElementValue.MaxHeight.Value) {
                maxHeight = parseInt(this.LayoutElementValue.MaxHeight.Value + '', 10);
            }
        }
        if (typeof maxHeight === 'number') {
            if (this.LayoutElementValue.ShowFilter) {
                maxHeight -= 54;
            }
            maxHeight -= 2;
            this.MaxHeight = Math.max(0, maxHeight) + 'px';
        } else {
            this.MaxHeight = null;
        }
        this.cdRef.detectChanges();
    }
    ControlInitialized() {
        if (this.LayoutElement.ControlStyling) {
            this.actualDataSource = this.LayoutElement.actualDataSource;
            return;
        }
        this.DisableAutoLoad = this.LayoutElementValue.DisableAutoLoad;
        this.SetMaxHeight();

        if (this.listDataSource) {
            this.listDataSource.unsubscribe();
        }
        if (this.dataSourceSub) {
            this.dataSourceSub.unsubscribe();
        }
        MetaHelper.FindDataBindingProperties(this.Layout, this.LayoutElementValue).then(result => {
            if (result) {
                if (result.IsDataBinding) {
                    if (result.IsShared) {
                        this.listDataSource = new SharedDataBindingSource(this, result.Table, result.KeyColumns,
                            this.dataService, this.DisableAutoLoad);
                    } else {
                        this.listDataSource = new SimpleDataSource(this, result.Table);
                    }
                } else {
                    this.listDataSource = new DataSourceListSource(this, result.Table, this.dataService, this.DisableAutoLoad);
                }
            } else {
                this.listDataSource = new SimpleDataSource(this, { Fields: [] });
            }
            if (this.listDataSource) {
                this.dataSourceSub = this.listDataSource.DataSource.subscribe(x => {
                    this.actualDataSource = x ?? [];
                    if (this.LayoutElementValue.Multiple && this.SelectedItemValue) {
                        this.SelectedItemValue = [...this.SelectedItemValue];
                    }
                    this.cdRef.detectChanges();
                });
            }
            this.cdRef.detectChanges();
        });
    }

    resetSearch() {
        this.SearchValue = '';
        this.change();
    }

    change() {
        if (this.listDataSource) {
            this.listDataSource.updateSearch(this.SearchValue);
        }
    }

    clicked() {
        this.triggerEvent('OnFocus', this.DataSource);
    }

    onItemClick(item) {
        if (this.SelectedItem !== item) {
            this.SelectedItem = item;
        }
    }
    setSelection(sel) {
        if (sel && sel.options) {
            const newSel = [];
            if (this.SelectedItemValue) {
                newSel.push(...this.SelectedItemValue);
            }
            sel.options.forEach(x => {
                const val = x.value;
                if (x.selected) {
                    newSel.push(val);
                } else {
                    let index = -1;
                    newSel.some((y, i) => {
                        if (this.compareSel(val, y)) {
                            index = i;
                            return true;
                        }
                        return false;
                    });
                    if (index > -1) {
                        newSel.splice(index, 1);
                    }
                }
            });
            this.SelectedItem = newSel;
        }
    }

    onScrollDown() {
        if (this.listDataSource) {
            this.listDataSource.onScroll();
        }
    }

    ExecuteRefresh() {
        this.listDataSource = null;
        setTimeout(() => { this.ControlInitialized(); }, 20);
        /*this.change();*/
    }

    compareSel(opt, sel): boolean {
        if (typeof opt === 'object' && typeof sel === 'object') {
            return opt._Id === sel._Id;
        }
        return opt === sel;
    }
}

export interface IListBoxDataSource {
    DataSource: Subject<any[]>;

    updateSearch(searchVal: string);
    onScroll();
    unsubscribe();
}

export abstract class AListBoxDataSource implements IListBoxDataSource {
    SearchValue;
    CaptionGetter = new DefaultCaptionGetter();
    Subscriptions = [];

    DataSource = new Subject<any[]>();

    constructor(protected listBox: ListBoxControl, table) {
        this.CaptionGetter = ACaptionGetter.GetCaptionGetter(listBox.LayoutElement, table.Fields);
        this.Subscriptions.push(listBox.DisplayMemberPathChange.subscribe(x => {
            this.CaptionGetter = ACaptionGetter.GetCaptionGetter(listBox.LayoutElement, table.Fields);
        }));
    }

    unsubscribe() {
        this.Subscriptions.forEach(x => {
            if (x.unsubscribe) {
                x.unsubscribe();
            }
        });
    }

    updateSearch(searchVal: string) {
        this.SearchValue = searchVal;
        this.onFilterChanged();
    }

    abstract onFilterChanged();
    abstract onScroll();

    getDisplayName(data) {
        if (this.CaptionGetter) {
            return this.CaptionGetter.GetCaption(data);
        }
        return '';
    }
}

export class SimpleDataSource extends AListBoxDataSource {
    InternalDataSource = [];

    constructor(protected listBox: ListBoxControl, table) {
        super(listBox, table);
        this.dataSourceChanged();
        this.Subscriptions.push(listBox.DataSourceChange.subscribe(x => {
            this.dataSourceChanged();
        }));
    }

    dataSourceChanged() {
        const ds = this.listBox.DataSource;
        if (Array.isArray(ds)) {
            this.InternalDataSource = ds;
        } else {
            this.InternalDataSource = [];
        }
        this.onFilterChanged();
    }


    onFilterChanged() {
        const list = [];
        if (this.SearchValue) {
            this.InternalDataSource.forEach(x => {
                const caption = this.getDisplayName(x);
                if (caption.indexOf(this.SearchValue) > -1) {
                    list.push({
                        Caption: caption,
                        Data: x
                    });
                }
            });
        } else {
            this.InternalDataSource.forEach(x => {
                list.push({
                    Caption: this.getDisplayName(x),
                    Data: x
                });
            });
        }
        switch (this.listBox.Order) {
            case Order.ASC:
                list.sort((a, b) => {
                    if (a.Caption < b.Caption) {
                        return -1;
                    }
                    if (a.Caption > b.Caption) {
                        return 1;
                    }
                    return 0;
                });
                break;
            case Order.DESC:
                list.sort((a, b) => {
                    if (a.Caption < b.Caption) {
                        return 1;
                    }
                    if (a.Caption > b.Caption) {
                        return -1;
                    }
                    return 0;
                });
                break;
        }
        this.DataSource.next(list);
    }

    onScroll() {
    }
}

export class SharedDataBindingSource extends AListBoxDataSource {
    DataSourceID;
    DataSourceElement;
    FilterKeys = [];

    constructor(protected listBox: ListBoxControl, table, private keyCols: string[], private dS: DataService, private DisableAutoLoad) {
        super(listBox, table);
        this.DataSourceID = table.SID;
        this.DataSourceElement = MetaHelper.FindValidParent(listBox.Layout, listBox.LayoutElement);
        this.FilterKeys = listBox.DataSource;
        this.onFilterChanged();
        this.Subscriptions.push(listBox.DataSourceChange.subscribe(x => {
            this.FilterKeys = x;
            this.onFilterChanged();
        }));
    }

    onFilterChanged() {
        this.DataSource.next([]);
        if (!this.DisableAutoLoad) {
            this.LoadData();
        }
        this.DisableAutoLoad = false;
    }

    onScroll() {
    }

    LoadData() {
        if (this.FilterKeys != null) {
            const keyFilter = new RequestFilter();
            if (this.keyCols && this.keyCols.length === 1) {
                keyFilter.Name = this.keyCols[0];
                keyFilter.Operator = Comparer.Equal;
            } else {
                if (this.FilterKeys.length === 0) {
                    return;
                }
                keyFilter.Name = '_Id';
                keyFilter.Operator = Comparer.In;
            }
            keyFilter.Value = this.FilterKeys;
            const filter = FilterHelper.PrepareFilter(this.DataSourceElement);
            if (this.listBox.DisplayMemberPath) {
                if (this.SearchValue) {
                    const search = new RequestFilter();
                    search.Name = this.listBox.DisplayMemberPath;
                    search.Operator = Comparer.Like;
                    search.Value = this.SearchValue;
                    FilterHelper.AddSearchFilter(filter, search);
                }
                if (this.listBox.Order != null) {
                    const sort = new RequestSort();
                    sort.Name = this.listBox.DisplayMemberPath;
                    sort.Order = this.listBox.Order;
                    filter.Sort = [sort];
                }
            }
            FilterHelper.AddSearchFilter(filter, keyFilter);
            this.dS.SearchObjects('dynamicdata', this.DataSourceID, filter).subscribe((data) => {
                if (data) {
                    const list = [];
                    data.forEach(x => {
                        list.push({
                            Caption: this.getDisplayName(x),
                            Data: x
                        });
                    });
                    this.DataSource.next(list);
                }
            });
        }
    }
}

export class DataSourceListSource extends AListBoxDataSource {
    DataSourceID;
    DataSourceElement;
    pageIndex = 0;
    pageLength = 100;
    loaded = false;
    InternalDataSource = [];

    constructor(protected listBox: ListBoxControl, table, private dataService: DataService, private DisableAutoLoad) {
        super(listBox, table);
        this.DataSourceID = table.SID;
        this.DataSourceElement = MetaHelper.FindValidParent(listBox.Layout, listBox.LayoutElement);
        if (!DisableAutoLoad) {
            this.LoadData(true);
        }
        DisableAutoLoad = false;
    }

    onFilterChanged() {
        this.pageIndex = 0;
        this.loaded = false;
        this.InternalDataSource = [];
        this.DataSource.next(this.InternalDataSource);
        this.LoadData(true);
    }

    onScroll() {
        this.LoadData(false);
    }

    LoadData(shouldRemoveDuplication:Boolean) {
        if (!this.loaded && this.DataSourceID) {
            const filter = FilterHelper.PrepareFilter(this.DataSourceElement);
            if (this.listBox.DisplayMemberPath) {
                if (this.SearchValue) {
                    const search = new RequestFilter();
                    search.Name = this.listBox.DisplayMemberPath;
                    search.Operator = Comparer.Like;
                    search.Value = this.SearchValue;
                    FilterHelper.AddSearchFilter(filter, search);
                }
                if (this.listBox.Order != null) {
                    const sort = new RequestSort();
                    sort.Name = this.listBox.DisplayMemberPath;
                    sort.Order = this.listBox.Order;
                    filter.Sort = [sort];
                }
            }
            filter.StartRow = this.pageIndex * this.pageLength;
            filter.EndRow = filter.StartRow + this.pageLength;
            this.dataService.SearchObjects('dynamicdata', this.DataSourceID, filter).subscribe((data) => {
                this.pageIndex += 1;
                if (data && data.length > 0) {
                    const list = [];
                    data.forEach(x => {
                        list.push({
                            Caption: this.getDisplayName(x),
                            Data: x
                        });
                    });
                    if (shouldRemoveDuplication) {
                        this.InternalDataSource = list;
                        this.pageIndex = 1;
                    } else {
                        this.InternalDataSource = [...this.InternalDataSource, ...list];
                    }
                    this.DataSource.next(this.InternalDataSource);
                } else {
                    this.loaded = true;
                }
            });
        }
    }
}

export class BeforeSelectionArgs {
    private observerCount: number;
    private onFinish;
    private cancel = false;

    constructor(observerCount: number, onFinish: any) {
        this.observerCount = observerCount;
        this.onFinish = onFinish;
    }

    set(cancel: boolean) {
        if (cancel) {
            this.cancel = true;
        }
        this.observerCount--;
        if (this.observerCount === 0 && !this.cancel && typeof this.onFinish === 'function') {
            this.onFinish();
        }
    }
}

export class ListBoxPanel extends BasePanel {
    static override SIDS = ['6eb4dd49-9ce8-4a76-82ce-48eac9edbd06']
    static override ElementType = 'listbox';
    private static DisplayMemberPathSub: BehaviorSubject<any> = new BehaviorSubject(null);
    private static VariablesSub: BehaviorSubject<any> = new BehaviorSubject(null);
    private static TableProperties;
    static override SetSelectedItem(item) {
        ListBoxPanel.ReadTableProperties();
    }
    static override SetSelectedLayout(item) {
        ListBoxPanel.ReadTableProperties();
    }
    static InitPanel() {
        this.InitSelectedItem();
        this.InitSelectedLayout();
        PROPERTYGROUPS.push({
            SID: '6eb4dd49-9ce8-4a76-82ce-48eac9edbd06',
            ID: 'listbox',
            Caption: '@@ListBox',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'listbox';
            }
        });

        PROPERTIES.push({
            ID: "DisplayMemberPath",
            Parent: "listbox",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@DisplayMemberPath",
            CheckVisibility: (item) => {
                return ListBoxPanel.TableProperties && ListBoxPanel.TableProperties.Fields;
            },
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSourceSub: ListBoxPanel.DisplayMemberPathSub,
                ValueMemberPath: "Name",
                DisplayMemberPath: "TranslatedCaption"
            }
        });
        PROPERTIES.push({
            ID: "DisplayFormula",
            Parent: "listbox",
            Content: new ComponentPortal(FormulaTextBoxThemeControl),
            Label: "@@Erweiterte Anzeige",
            CheckVisibility: (item) => {
                return ListBoxPanel.TableProperties && ListBoxPanel.TableProperties.Fields;
            },
            InitArgs: {
                Placeholder: "@@Erweiterte Anzeige",
                VariablesSub: ListBoxPanel.VariablesSub
            }
        });
        PROPERTIES.push({
            ID: "DisplayMemberPath",
            Parent: "listbox",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@DisplayMemberPath",
            CheckVisibility: (item) => {
                return !ListBoxPanel.TableProperties || (ListBoxPanel.TableProperties && !ListBoxPanel.TableProperties.Fields);
            }
        });
        PROPERTIES.push({
            ID: "Order",
            Parent: "listbox",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Order",
            InitArgs: {
                Placeholder: "",
                Multiple: false,
                EnumSource: Order
            }
        });
        PROPERTIES.push({
            ID: "UseAsSelection",
            Parent: "listbox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@UseAsSelection"
            },
            CheckVisibility: (item) => {
                return item.IsShared && item.Multiple;
            }
        });
        PROPERTIES.push({
            ID: "Multiple",
            Parent: "listbox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Multiple"
            }
        });
        PROPERTIES.push({
            ID: "Checkbox",
            Parent: "listbox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Checkbox"
            },
            CheckVisibility: (item) => {
                return item.Multiple;
            }
        });
        PROPERTIES.push({
            ID: "DisableAutoLoad",
            Parent: "listbox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@DisableAutoLoad"
            }
        });
        PROPERTIES.push({
            ID: "ShowFilter",
            Parent: "listbox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@ShowFilter"
            }
        });
        PROPERTIES.push({
            ID: "Grow",
            Parent: "listbox",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Grow"
            }
        });
        PROPERTIES.push({
            ID: "Filter",
            Parent: "listbox",
            Content: new ComponentPortal(ComponentFilterControl)
        });
    }
    static ReadTableProperties() {
        if (ListBoxPanel.SelectedItem && ListBoxPanel.SelectedLayout) {
            ListBoxPanel.TableProperties = null;
            MetaHelper.FindDataBindingProperties(ListBoxPanel.SelectedLayout, ListBoxPanel.SelectedItem).then(result => {
                if (result) {
                    ListBoxPanel.TableProperties = result.Table;
                    const fields = result.Table.Fields;
                    if (fields) {
                        const variables = [];
                        fields.forEach(field => {
                            const vni = new VariablesNodeInformation();
                            vni.VariableID = field.ID;
                            vni.Name = field.TranslatedCaption;
                            variables.push(vni);
                        });
                        ListBoxPanel.VariablesSub.next(variables);
                    }
                    let fieldscopy = JSON.stringify(ListBoxPanel.TableProperties.Fields)
                    let DisplayMemberFields = JSON.parse(fieldscopy);
                    DisplayMemberFields.unshift({ Name: "", TranslatedCaption: "@@Keine Auswahl" });
                    ListBoxPanel.DisplayMemberPathSub.next(DisplayMemberFields);
                    LayoutService.LayoutChanged.next(null);
                }
            });
        }
    }
}
